import { FC } from 'react';
import { HourProps } from './Hour.type';
import { StyledHourWrapper, StyledHour, StyledHourLine, StyledHourContainer } from './Hour.styled';


export const Hour: FC<HourProps> = ({ hour }) => {
    return (
        <StyledHourContainer>
            <StyledHourWrapper>
                <StyledHour>{hour}:00</StyledHour>
                <StyledHourLine />
            </StyledHourWrapper>
        </StyledHourContainer>
    )
}

import styled from '@emotion/styled';
import { Box, List } from '@mui/material';
import { zIndex } from 'Layouts/theme';

export const StyledSpacesMenuList = styled(List, {
    shouldForwardProp: prop => prop !== 'getStartedAllItemsResolved'
  })<{
    getStartedAllItemsResolved: boolean;
}>`
    padding: 0;
    max-height: ${props => !props.getStartedAllItemsResolved ? 'calc( 100vh - 310px - 226px )' : 'calc( 100vh - 310px )'};
    height: 100%;
    owerflow-x: unset;
    overflow: auto;
    & .MuiList-root $navigationItem {
        padding-left: 40px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.08);
    }
`
export const StyledSideMenuWrapper = styled(Box)`
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-image: linear-gradient(0deg, #FFECE7 42%, transparent);
        z-index: ${zIndex};
    }
`
export const StyledPublicTitle = styled('div')`
    padding: 16px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`
export const StyledNavigationItem = styled(Box)`
    height: 65px;
    padding-top: 10px;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.08);
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    & img {
        width: 24px;
        margin-right: 8px;
        &.avatar {
            border-radius: 50%;
        }
    }
    &:hover {
        background: rgba(0,0,0,.08);
    }
`
export const StyledLogoWrapper = styled(Box)`
    cursor: pointer;
    display: flex;
    & img {
        width: 90px;
    }
`
export const StyledMenuSpaceTitle = styled('div')`
    font-weight: 500;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    border-top: 1px solid rgba(0,0,0,.01);
`
export const StyledCreateAccountButton = styled('div')`
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: #202020;
    position: absolute;
    color: var(--text-neutral, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    cursor: pointer;
`
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Input } from "@/components/v3/Fields/Input/Input.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { Box, styled } from "@mui/material";

export const StyledOnboardingContent = styled('div')`
    border-radius: 8px;
    background: #FFF;
    padding: 24px;
    margin-top: 24px;
`;
export const StyledOnboardingStep = styled(Box)`
    display: flex;
    flex-direction: column;
    max-width: 720px;
    width: 100%;
`;
export const StyledInput = styled(Input)`
    width: 100%;
`;
export const StyledInputWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledSendInviteButton = styled(Button)`
    margin-left: 8px;
`;
export const StyledFilledCard = styled(FilledCard)`
    min-height: 390px;
    margin-top: 24px;
`;
export const StyledActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
`;

export const StyledNoUsers = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 390px;
`;
export const StyledIndividualTip = styled('div')`
    margin-top: 24px;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
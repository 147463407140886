import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { HttpClient } from "../../axios/HttpClient";
import { envUrl } from "../../service/helpers";
import { fetchOrganizationById$ } from "../../service/OrganizationService";
import { useMe } from "providers/MeProvider/Me.provider";
import { availablePlans } from "./SelectPlan.const";
import { PlanRadioGroup } from "./components/PlanRadioGroup/PlanRadioGroup.component";
import { StyledBtnContainer, StyledButton, StyledOnboardingContent, StyledOnboardingStep, StyledPlansWrapper } from "./SelectPlan.styled";
import { EventTypes, useAmplitude } from "@/service/TrackingService";

export const SelectPlan = () => {
    const history = useHistory();
    const [plan, setPlan] = useState('Team');
    const isTeam = plan === 'Team';
    const [department, setDepartment] = useState<string>('');
    const [teamSize, setTeamSize] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const { user } = useMe();
    const userId = user?.id;
    const isFilled = isTeam ? !!teamSize && !!department : !!department;

    const handleTeamSizeChange = (event: SelectChangeEvent<string>) => {
        setTeamSize(event.target.value)
    };

    const handleDepartmentChange = (event: SelectChangeEvent<string>) => {
        setDepartment(event.target.value)
    };
    const trackEvent = useAmplitude();
    const connectPlanHandler = () => {
        HttpClient.put(`${envUrl}/organization-service/v1/payment/subscription`, {
            name: plan.toUpperCase(),
            userId,
        }).then(() => {
            trackEvent(EventTypes.ONBOARDING_SELECT_PLAN, {
                plan: plan.toUpperCase(),
                teamSize,
                department,
                companyName,
            })
        });
        if (!!user?.organizationId) {
            fetchOrganizationById$(user?.organizationId).subscribe(organization => {
                HttpClient.put(`${envUrl}/organization-service/v1/organizations/${organization.id}`, {
                    teamSize: teamSize,
                    industry: department,
                    email: organization.email,
                    name: (isTeam && !!user?.googleSsoUserId) ? companyName : organization.name,
                }).then(response => response)
            });
        }
        localStorage.setItem("onboardingStep", "manage-integrations");
        localStorage.setItem("plan", plan.toUpperCase());
        history.push('/manage-integrations');
    }
    return (
        <StyledOnboardingContent>
            <StyledOnboardingStep>
                <Box m={2}>
                    <Box style={{ fontSize: '14px' }}>{isTeam ? '1 / 4' : '1 / 3'}</Box>
                    <span style={{ color: '#2F2C2C', fontSize: '24px', fontWeight: 700, lineHeight: '1.43' }}>Your plan</span>
                </Box>
                <StyledPlansWrapper >
                    <PlanRadioGroup options={availablePlans} value={plan} setValue={setPlan} setIndustry={setDepartment} setTeamSize={setTeamSize} />
                    <Box style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', maxHeight: 228, padding: '16px 0' }}>
                        {isTeam && <>
                            {!!user?.googleSsoUserId && <FormControl variant="outlined" style={{ width: '100%', marginBottom: 16 }}>
                                <TextField
                                    label="Company name"
                                    id="company-name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    variant="outlined"
                                />
                            </FormControl>}
                            <FormControl variant="outlined" style={{ width: '100%', marginBottom: 16 }}>
                                <InputLabel id="team-size-label">Team size</InputLabel>
                                <Select label="Team size"
                                    labelId="team-size-label"
                                    id="team-size"
                                    value={teamSize}
                                    onChange={handleTeamSizeChange}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2-10">2-10</MenuItem>
                                    <MenuItem value="11-100">11-100</MenuItem>
                                    <MenuItem value="101-500">101-500</MenuItem>
                                    <MenuItem value="1000+ employees">1000+ employees</MenuItem>
                                </Select>
                            </FormControl>
                        </>}
                        <FormControl variant='outlined' style={{ width: '100%' }}>
                            <InputLabel id="department-label">Department</InputLabel>
                            <Select label="department"
                                displayEmpty
                                labelId="department-label"
                                id="department"
                                value={department}
                                onChange={handleDepartmentChange}
                            >
                                <MenuItem value="Design">Design</MenuItem>
                                <MenuItem value="Finance">Finance</MenuItem>
                                <MenuItem value="Human resources">Human resources</MenuItem>
                                <MenuItem value="IT">IT</MenuItem>
                                <MenuItem value="Management">Management</MenuItem>
                                <MenuItem value="Marketing">Marketing</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                                <MenuItem value="Product management">Product management</MenuItem>
                                <MenuItem value="Sales">Sales</MenuItem>
                                <MenuItem value="Software development">Software development</MenuItem>
                                <MenuItem value="Support">Support</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <StyledBtnContainer>
                        <StyledButton disabled={!isFilled} onClick={connectPlanHandler}>{isTeam ? 'Start 14 days trial' : 'Continue'}</StyledButton>
                    </StyledBtnContainer>
                </StyledPlansWrapper>
            </StyledOnboardingStep>
        </StyledOnboardingContent>
    )
}
import { NodeCard } from "@/components/v3/List/NodeCard/NodeCard.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 0 32px 0 32px;
    @media (max-width: 1024px) {
        padding-left: 0;
        padding-right: 0;
    }
`;
export const StyledSectionHeader = styled(SectionHeader)`
    @media (max-width: 1024px) {
        padding-right: 32px;
        padding-left: 32px;
    }
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledScrollWrapper = styled('div')`
    overflow-y: auto;
    @media (max-width: 1024px) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;
export const StyledNodesWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'countOfNodes'
})<{countOfNodes: number}>`
    display: grid;
    grid-template-columns: repeat(${({countOfNodes}) => countOfNodes}, 1fr);
    grid-gap: 16px;
    margin-top: 16px;
    padding-bottom: 16px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        grid-template-columns: repeat(6, 1fr);
        min-width: 1536px;
    }
`;

export const StyledNode = styled(NodeCard)`
    // margin-bottom: 8px;
`;
export const StyledEmptyCardWrapper = styled('div')`
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
`
import React, { useContext, useMemo } from 'react';
import { MainContextType } from './Main.type';

import { EnvType } from './Main.type';

const MainContext = React.createContext<MainContextType>({
    env: 'qa'
});
export const useMain = () => useContext<MainContextType>(MainContext);

export const MainProvider = (props: { children: React.ReactNode }) => {
  const env = useMemo((): EnvType => {
    switch (window.location.hostname) {
        case 'memory.senseapp.ai':
            return 'prod';
        case 'ui.qa.svc.clu':
            return 'qa';
        case 'localhost':
            return 'local';
        case 'feature':
            return 'feature';
        default:
            return 'qa';
    }
  }, []);
  
  const contextValue: MainContextType = {
    env
  };
  return <MainContext.Provider value={contextValue}>
    {props.children}
  </MainContext.Provider>;
};

export default MainContext;

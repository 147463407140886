import {
  RoutePathType,
  ParamNameType,
  RouteQueryType,
  RouteParamType,
} from "../Router.type";
export const addQueryParam = (path: RoutePathType, paramName: ParamNameType, value: RouteQueryType): RoutePathType => {
    const addQueryToString = (p: RoutePathType, val: RouteParamType): RoutePathType => {
        const index = p.indexOf("?");
        
        if (index>-1) {
            return `${p}&${paramName}=${val}`;
        }
        return `${p}?${paramName}=${val}`;
    }
    let p = path;
    if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index++) {
            p = addQueryToString(p, value[index])
        }
    } else {
        p = addQueryToString(p, value)
    }
    return p;
}
import { FC, useEffect, useState } from "react";
import { AddCommentDialogProps } from "./AddCommentDialog.type";
import { StyledCancelButton, StyledDescription, StyledMentionArea, StyledTextField } from "./AddCommentDialog.styled";
import { CreateCommentFetch } from "@/axios/AiService/Comments/Comments.api";
import { ToastService } from "@/service/ToastService";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { getFullName } from "@/utils/v3/GetFullName/GetFullName.util";

export const AddCommentDialog: FC<AddCommentDialogProps> = ({ nodeId, value, onChange, onNewComment }) => {
    const { users } = useMe();
    const [comment, setComment] = useState<string>('');
    useEffect(() => {
        setComment('');
    }, [value]);
    const addHandler = async () => {
        await CreateCommentFetch({nodeId}, {
            content: comment
        }).then((res) => {
            ToastService.showToast('success', 'Comment was successfully added');
            onNewComment(res.data);
        });
        onChange(false);
    };
    return <Dialog value={value} onChange={onChange} size="small" >
        <DialogLayout title="Add comment" actions={<>
            <StyledCancelButton size="large" variant="error" onClick={() => onChange(false)}>
                Cancel
            </StyledCancelButton>
            <Button size="large" onClick={addHandler}>
                Add
            </Button>
        </>}>
            <StyledMentionArea value={comment} onChange={setComment} mentions={users.map(el => ({ label: getFullName(el.firstName, el.lastName, el.email), value: el.id, avatar: el.avatar }))} />
            {/* <StyledTextField multiline rows={4} style={{ width: '100%' }} label="Comment" placeholder="Type your comment here" value={comment} onChange={(e) => setComment(e.target.value)} /> */}
            <StyledDescription>
                Your comment will be visible to everyone in your workspace. It will not be visible in shared spaces and resources. 
            </StyledDescription>
        </DialogLayout>
    </Dialog>
};
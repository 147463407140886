import { FC, useState } from "react";
import { StyledButton, StyledCreateSpace, StyledAddIcon } from "./CreateSpace.styled";
import { useRouter } from "@/providers/Router/Router.provider";

export const CreateSpace: FC = () => {
    const router = useRouter();
    return <StyledCreateSpace>
        <StyledButton onClick={() => {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'create-space'
                }
            });
        }} icon={<StyledAddIcon />}>Create space</StyledButton>
    </StyledCreateSpace>
};
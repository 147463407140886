import { FC } from "react";
import { SharedLayoutProps } from "./SharedLayout.type";
import { StyledContent, StyledHeader, StyledLogo, StyledWrapper } from "./SharedLayout.styled";
import { useRouter } from "@/providers/Router/Router.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { Button } from "../../Fields/Button/Button.component";

export const SharedLayout: FC<SharedLayoutProps> = ({ children }) => {
    const router = useRouter();
    const { user } = useMe();
    return (
        <StyledWrapper>
            <StyledHeader>
                <StyledLogo onClick={() => router.push({
                    name: user?.id ? 'forYou' : 'login'
                })}>
                    <img src="/icons/sense-logo.svg" alt="" />
                </StyledLogo>
                {!user?.id && <Button size="medium" onClick={() => router.push({
                    name: 'signup'
                })}>Create Sense account</Button>}
            </StyledHeader>
            <StyledContent>
                {children}
            </StyledContent>
        </StyledWrapper>
    );
};
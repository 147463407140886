import { SvgIcon, SvgIconProps } from '@mui/material';

export const EmojiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="8 8 24 24" {...props}>
        <path d="M32 20C31.9971 23.033 30.8466 25.9526 28.7796 28.1722C26.7126 30.3918 23.8823 31.7471 20.8571 31.9657C20.5743 31.9914 20.2871 32 20 32C16.8174 32 13.7652 30.7357 11.5147 28.4853C9.26428 26.2348 8 23.1826 8 20C8 16.8174 9.26428 13.7652 11.5147 11.5147C13.7652 9.26428 16.8174 8 20 8C20.2871 8 20.5743 8.00857 20.8571 8.03429C23.8823 8.25294 26.7126 9.6082 28.7796 11.8278C30.8466 14.0474 31.9971 16.967 32 20Z" fill="#B5B5B5"/>
        <path d="M20.0001 24.5389C18.2492 24.5409 16.534 24.0436 15.0557 23.1053C15.0075 23.0754 14.9657 23.0362 14.9328 22.9901C14.8998 22.9439 14.8764 22.8917 14.8638 22.8364C14.8512 22.7811 14.8496 22.7239 14.8593 22.668C14.869 22.6121 14.8896 22.5587 14.9201 22.5109C14.9505 22.4631 14.9902 22.4218 15.0367 22.3894C15.0832 22.357 15.1357 22.3341 15.1912 22.3221C15.2466 22.3102 15.3039 22.3093 15.3596 22.3197C15.4154 22.33 15.4685 22.3513 15.516 22.3823C16.8574 23.2316 18.4125 23.6824 20.0001 23.6824C21.5878 23.6824 23.1429 23.2316 24.4843 22.3823C24.5318 22.3513 24.5849 22.33 24.6407 22.3197C24.6964 22.3093 24.7537 22.3102 24.8091 22.3221C24.8645 22.3341 24.917 22.357 24.9636 22.3894C25.0101 22.4218 25.0498 22.4631 25.0802 22.5109C25.1107 22.5587 25.1313 22.6121 25.141 22.668C25.1507 22.7239 25.1491 22.7811 25.1365 22.8364C25.1239 22.8917 25.1004 22.9439 25.0675 22.9901C25.0345 23.0362 24.9928 23.0754 24.9446 23.1053C23.4663 24.0436 21.7511 24.5409 20.0001 24.5389Z" fill="white"/>
        <path d="M24.2856 20.596C25.2323 20.596 25.9999 19.4447 25.9999 18.0246C25.9999 16.6044 25.2323 15.4531 24.2856 15.4531C23.3388 15.4531 22.5713 16.6044 22.5713 18.0246C22.5713 19.4447 23.3388 20.596 24.2856 20.596Z" fill="white"/>
        <path d="M15.7143 20.596C16.6611 20.596 17.4286 19.4447 17.4286 18.0246C17.4286 16.6044 16.6611 15.4531 15.7143 15.4531C14.7675 15.4531 14 16.6044 14 18.0246C14 19.4447 14.7675 20.596 15.7143 20.596Z" fill="white"/>
    </SvgIcon>
  );
};

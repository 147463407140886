import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { ChannelsGetQueryParams } from './Types/Get/Get.type';
import { ChannelsSynchronizeBodyParams } from './Types/Synchronize/Synchronize.type';

export const GetChannelsFetch = (query: ChannelsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/slack-integration-service/v1/channels`, {
        params: query,
    });
}
export const SynchronizeChannelsFetch = (body: ChannelsSynchronizeBodyParams) => {
    return HttpClient.post(`${envUrl}/slack-integration-service/v1/channels/synchronize`, body);
}
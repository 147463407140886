import { FC } from "react";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { StyledLink, StyledOpenLinkIcon } from "./OpenButton.styled";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";

export const OpenButton: FC = () => {
    const trackEvent = useAmplitude();
    const { node } = useNode();
    return (
        <StyledLink target="_blank" href={node?.originalURI} rel="noreferrer">
            <Button icon={<StyledOpenLinkIcon />} onClick={() => trackEvent(EventTypes.ORIGIN_NODE_LINK_OPENED)}>Open</Button>
        </StyledLink>
    )
}
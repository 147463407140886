import { FC } from "react";

import { SpaceTagContent } from "./SpaceTag.content";
import { SpaceTagPageProvider } from "@/providers/v3/SpaceTagPage/SpaceTagPage.provider";
import { SpacePageCommonProvider } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { NodeNeighboursDrawer } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/NodeNeighboursDrawer.component";

export const SpaceTagPage: FC = () => {
    return <SpacePageCommonProvider>
        <SpaceTagPageProvider>
            <SpaceTagContent />
            <NodeNeighboursDrawer/>
        </SpaceTagPageProvider>
    </SpacePageCommonProvider>
};
import { FC, useMemo } from "react";
import { CalendarEventProps } from "./CalendarEvent.type";
import { StyledCalendarEvent, StyledCaption, StyledContent, StyledHeader, StyledIcon, StyledMaskedIcon, StyledSmallIcon, StyledSmallMaskedIcon, StyledTitle } from "./CalendarEvent.styled";
import { calculateHeight, calculateTop } from "./CalendarEvent.utils";
import moment from 'moment';
import { BASE_LEFT } from "../../../../../../../../MeetingsTimeline.const";
import { CSSProperties } from "@mui/styles";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";

export const CalendarEvent: FC<CalendarEventProps> = ({ event, events, startHour, eventWidth, eventLeft, baseWidth, useSkeleton=true }) => {
    const { user } = useMe();
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED' && useSkeleton;
    const { setActiveNodeId } = useNode();
    const height = calculateHeight(event.start, event.end as Date)
    const top = calculateTop(event.start, startHour);
    const eventLabel = useMemo(() => {
        switch (event.calendarName) {
            case 'google_calendar':
                return 'Google Calendar';
            case 'outlook_calendar':
                return 'Microsoft Teams';
        }
    }, [event.calendarName]);
    const startTime = moment(event.start).format('HH:mm');
    const endTime = moment(event.end).format('HH:mm');

    const durationInMinutes = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes();
    const shouldShowSmallIcon = eventWidth <= baseWidth / 8 && eventWidth > 20 && height <= baseWidth / 8 && height > 20;
    const shouldVerySmallIcon = !shouldShowSmallIcon && eventWidth <= baseWidth / 8 && eventWidth > 10 && height <= baseWidth / 8 && height > 10;
    const padding = useMemo((): CSSProperties['padding'] => {
        if (shouldShowSmallIcon || shouldVerySmallIcon) {
            return '0px'
        }
        if (durationInMinutes <= 15) {
            return '0px 12px'
        }
        if (durationInMinutes < 60) {
            return '4px 12px'
        }
        return '10px 12px'
    }, [durationInMinutes, shouldShowSmallIcon, shouldVerySmallIcon]);
    const alignItems = useMemo((): CSSProperties['alignItems'] => {
        if (shouldShowSmallIcon || shouldVerySmallIcon) {
            return 'center'
        }
        if (durationInMinutes >= 60) {
            return 'flex-start'
        }
        return 'center'
    }, [durationInMinutes, shouldShowSmallIcon, shouldVerySmallIcon]);
    const justifyContent = useMemo((): CSSProperties['justifyContent'] => {
        if (shouldShowSmallIcon || shouldVerySmallIcon) {
            return 'center'
        }
        if (durationInMinutes >= 60) {
            return 'flex-start'
        }
        return 'center'
    }, [durationInMinutes, shouldShowSmallIcon, shouldVerySmallIcon]);
    const smallIconSize = useMemo((): CSSProperties['width'] => {
        if (height < 20) {
            return '10px'
        }
        return '20px'
    }, [height]);
    const shouldShowIcon = height > 12 && eventWidth > baseWidth / 4;
    const iconSize = useMemo((): CSSProperties['width'] => {
        if (height > 12 && height < 24) {
            return '10px'
        }
        return '24px'
    }, [height]);

    return (
        <StyledCalendarEvent
            padding={padding}
            alignItems={alignItems}
            justifyContent={justifyContent}
            top={top} left={eventLeft + BASE_LEFT}
            width={eventWidth} height={height}
            title={isSubscriptionExpired ? undefined : `${event.title} · ${startTime}–${endTime} · ${eventLabel}`}
            onClick={() => setActiveNodeId(event.id)}
            className="node"
        >
            {shouldShowSmallIcon && <>
                {!isSubscriptionExpired && <StyledSmallIcon width={smallIconSize} height={smallIconSize} src={`/icons/calendar/${event.calendarName}.png`} alt={event.title} />}
                {isSubscriptionExpired && <StyledSmallMaskedIcon width={smallIconSize} height={smallIconSize} />}
            </>}
            {!shouldShowSmallIcon && <StyledHeader>
                {shouldShowIcon && <>
                    {!isSubscriptionExpired && <StyledIcon width={iconSize} height={iconSize} src={`/icons/calendar/${event.calendarName}.png`} alt={event.title} />}
                    {isSubscriptionExpired && <StyledMaskedIcon width={iconSize} height={iconSize} />}
                </>}
                <StyledContent width={shouldShowIcon ? 'calc(100% - 30px)' : '100%'}>
                    <StyledTitle useSkeleton={useSkeleton} lineHeight={durationInMinutes <= 15 ? '1' : '20px'}>{event.title}</StyledTitle>
                    {durationInMinutes > 45 && <StyledCaption ellipsis={!isSubscriptionExpired} useSkeleton={useSkeleton} >{startTime}–{endTime} · {eventLabel}</StyledCaption>}
                </StyledContent>
            </StyledHeader>}
        </StyledCalendarEvent>
    )
}
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledRoot = styled('div')`
    background-color: #fff;
    padding: 24px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding-top: 0;
    }
`;
export const StyledBackWrapper = styled('div')`
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledBackIconWrapper = styled('div')`
    padding: 8px;
    cursor: pointer;
`;
export const StyledTabsWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobile'
})<{isMobile: boolean}>`
    position: ${({ isMobile }) => isMobile ? 'static' : 'fixed'};
    width: ${({ isMobile }) => isMobile ? '100%' : '256px'};
    height: calc(680px - 80px);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
`;
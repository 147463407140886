import { FC, useState, useEffect, useMemo } from "react";
import { SpaceProps } from "./Space.type";
import { StyledDropdownIcon, StyledNoTags, StyledWrapper, StyledSpaceContant, StyledSpaceName, StyledTagsContainer, StyledContent, StyledSpaceImage, StyledEmojiWrapper } from "./Space.styled";
import { Collapse } from '@mui/material';
import { GetSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { SpaceTagType, SpaceTagsAxiosResponseSuccessType } from "@/axios/AiService/SpaceTags/Types/Get/Get.type";
import { SpaceTag } from "./components/SpaceTag/SpaceTag.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledEmoji } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.styled";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";

export const Space: FC<SpaceProps> = ({ space, isOpen, setIsOpen, onAdd }) => {
    const useCollapse = space.application ==='Sense';
    const [tags, setTags] = useState<SpaceTagType[]>([]);
    const loadTags = async () => {
        await GetSpaceTagsFetch(space.id).then((res: SpaceTagsAxiosResponseSuccessType) => {
            setTags(res.data);
        });
    };
    useEffect(() => {
        if (isOpen) {
            loadTags();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isOpen]);
    const listOfTagsJsx = tags.map((tag) => {
        return <SpaceTag onAdd={() => onAdd(space.id, tag.id)} tag={tag} key={tag.id} />
    });
    const onToggleHandler = async () => {
        if (useCollapse) {
            if (isOpen) {
                setIsOpen(false)
            } else {
                if (tags.length>0) {
                    setIsOpen(true);
                } else {
                    await loadTags();
                    setIsOpen(true)
                }
            }
        }
    };
    const imgSrc = useMemo(() => {
        if (space.private) {
          return '/icons/private-space-active-icon.svg';
        }
        return getIconPath(space.application, true);
      }, [space.private, space.application]);
    const imgJsx = useMemo(() => {
        if (!!space.emoji) {
          return <StyledEmojiWrapper>
            <StyledEmoji>{space.emoji}</StyledEmoji>
          </StyledEmojiWrapper>
        }
        return <StyledSpaceImage src={imgSrc} />
      }, [imgSrc, space.emoji]);
    return <StyledWrapper>
        <StyledContent>
            <StyledSpaceContant useCollapse={useCollapse} onClick={onToggleHandler}>
                {useCollapse && <StyledDropdownIcon isOpened={isOpen} />}
                {imgJsx}
                {/* <StyledApplicationIcon application={space.application} /> */}
                <StyledSpaceName>{space.name}</StyledSpaceName>
            </StyledSpaceContant>
            <Button center onClick={() => onAdd(space.id)}>Add here</Button>
        </StyledContent>
        {useCollapse && <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <StyledTagsContainer>
                {listOfTagsJsx}
            </StyledTagsContainer>
            {tags.length===0 && <StyledNoTags>No tags</StyledNoTags>}
        </Collapse>}
    </StyledWrapper>
};
import { FC } from "react";
import { StyledMeetingsTimeline, StyledMeetingsTimelineWrapper } from "./MeetingsTimeline.styled";
import { MeetingsTimelineProps } from "./MeetingsTimeline.type";
import { Background } from "./components/Background/Background.component";
import { CalendarEvents } from "./components/CalendarEvents/CalendarEvents.component";
import { CalendarEventType } from "./components/CalendarEvents/components/CalendarGroup/components/CalendarCluster/components/CalendarEvent/CalendarEvent.type";
import { BASE_WIDTH } from "./MeetingsTimeline.const";

export const MeetingsTimeline: FC<MeetingsTimelineProps> = ({ meetings, baseWidth = BASE_WIDTH, useSkeleton = true }) => {
    const events: CalendarEventType[] = meetings.map(meeting => ({
        id: meeting.id,
        title: meeting.title,
        calendarName: meeting.type,
        start: new Date(meeting.startTime),
        end: new Date(meeting.endTime),
    }));
    const earliestEvent = events.sort((a, b) => a.start.getTime() - b.start.getTime())[0];
    const startHour = earliestEvent.start.getHours();

    return (
        <StyledMeetingsTimelineWrapper>
            <StyledMeetingsTimeline>
                <Background startHour={startHour} />
                <CalendarEvents events={events} startHour={startHour} baseWidth={baseWidth} useSkeleton={useSkeleton} />
            </StyledMeetingsTimeline>
        </StyledMeetingsTimelineWrapper>
    )
}
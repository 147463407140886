import { Box } from '@mui/material';
import { HttpClient } from '../../../axios/HttpClient';
import { ToastService } from '../../../service/ToastService';
import { useState } from 'react';
import {envUrl} from "../../../service/helpers";
import { StyledFormWrapper } from '../SignupFromInvite/SignupFromInvite.styled';
import { StyledLoginPage, StyledTitle, StyledCard, StyledInput, StyledSubmitButton } from '../Login/Login.styled';
import { ErrorsType } from './RequestResetPasswordPage.type';
import { useRouter } from '@/providers/Router/Router.provider';


export const RequestResetPassword = () => {
  const [isSent, setIsSent] = useState<boolean>(false);

  const router = useRouter();

  const [errors, setErrors] = useState<ErrorsType>({});
  const [email, setEmail] = useState<string>('');
  const validate = () => {
    let tempErrors: ErrorsType = {};
    if (!email) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email)) tempErrors.email = "Invalid email format";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const submit = () => {
    if (validate()) {
      HttpClient.post(`${envUrl}/organization-service/v1/users/reset-password`, {
        email: email,
      })
        .then(response => response.data)
        .then(response => {
          setIsSent(true);
          ToastService.showToast('success', 'Request to reset your password successfully transmitted.');
          setTimeout(() => router.push({
            name: 'login'
          }), 5000);
        })
        .catch(error => {
          ToastService.showToast('error', error?.response?.data?.errorMessage);
        });
    }
  }
  return (
    <StyledLoginPage>
      <StyledFormWrapper>
        {!isSent && <>
          <StyledTitle>Forgot password</StyledTitle>
          <StyledCard>
            <div>
              <StyledInput error={errors.email} value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
            </div>
            <StyledSubmitButton size="large" onClick={submit}>Request new password</StyledSubmitButton>
          </StyledCard>
        </>}
        {isSent && (
          <Box>
            Please check your email-box, you will get an email with the further instructions. You will be redirected to
            the login page in the next couple of seconds
          </Box>
        )}
      </StyledFormWrapper>
    </StyledLoginPage>
  );
};

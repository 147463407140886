import { FC, Fragment } from "react";
import { SpaceCategoryProps } from "./SpaceCategory.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { WalkthroughWrapper } from "@/providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component";
import { generateTitle } from "./SpaceCategory.utils";
import { StyledListItemIcon, } from "./SpaceCategory.styled";
import { StyledSpaceTagContainer, StyledSpaceTagText } from "../SpaceTag/SpaceTag.styled";

export const SpaceCategory: FC<SpaceCategoryProps> = ({
    category,
    useWalkthrough,
    spaceId
}) => {
    const router = useRouter();
    let item = <StyledSpaceTagContainer
        isActive={router.name==='spaceCategory' && router.params.category === category}
        onClick={() => {
            router.push({
            name: 'spaceCategory',
            params: {
                spaceId: spaceId,
                category: category
            }
            })
        }}
        id={useWalkthrough ? 'walkthrough-organised' : ''}
    >
        <StyledListItemIcon>
            <img src="/icons/icon-folder.svg" alt="" />
        </StyledListItemIcon>
        <StyledSpaceTagText>{generateTitle(category)}</StyledSpaceTagText>
    </StyledSpaceTagContainer>
    if (useWalkthrough) {
        return <WalkthroughWrapper
            usePortal
            variant="walkthrough-organised"
            title="Everything is automatically organised"
            description="Sense will be constantly categorising all resources relevant to the space"
            targetId="walkthrough-organised"
            maxWidth={320}
        >
            {item}
        </WalkthroughWrapper>
    }
    return <Fragment>{item}</Fragment>;
};
import { IconButton, Typography } from "@mui/material";
import { ConfirmDialogProps } from "./ConfirmDialog.type";
import { Close } from '@mui/icons-material';
import { StyledActions, StyledDescription, StyledDialog, StyledDialogContent, StyledDialogTitle, StyledLinkWrapper, StyledCancel, StyledSubmit } from "./ConfirmDialog.styled";

export const ConfirmDialog = ({ isOpened, setIsOpened, revoke }: ConfirmDialogProps) => {
    const revokeHandle = () => {
        revoke();
        setIsOpened(false);
    }
    return <StyledDialog
        open={isOpened}
        onClose={() => setIsOpened(false)}
        aria-labelledby="settings-dialog-title"
        aria-describedby="settings-dialog-description"
    >
        <StyledDialogTitle id="alert-dialog-title">
            <Typography variant="h1" style={{ fontSize: '24px ', fontWeight: 600 }}>
                Confirm revoke access
            </Typography>
            <IconButton
                onClick={() => setIsOpened(false)}
                style={{
                position: 'absolute',
                right: 8,
                top: 8,
                }}
            >
                <Close />
            </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
            <StyledDescription>
                Everybody who received this sharing link will never be able to access this shared space anymore. 
            </StyledDescription>
            <StyledLinkWrapper>
                <StyledActions>
                    <StyledCancel onClick={() => setIsOpened(false)}>Cancel</StyledCancel>
                    <StyledSubmit onClick={revokeHandle}>Revoke</StyledSubmit>
                </StyledActions>
            </StyledLinkWrapper>
        </StyledDialogContent>
    </StyledDialog>
}
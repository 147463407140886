import { Box, Grid, styled } from "@mui/material";

export const StyledMainContent = styled(Grid)`
    display: flex;
    flex-grow: 1;
`;
export const StyledMainContentTop = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isSubscriptionExpired',
})<{isSubscriptionExpired: boolean}>`
    display: flex;
    height: 48px;
    border-bottom: 1px solid rgba(0,0,0,.08);
    align-items: center;
    padding: 32px 16px;
    justify-content: space-between;
    position: fixed;
    top: ${({isSubscriptionExpired}) => isSubscriptionExpired ? '56px' : '0'};
    width: calc(100% - 320px);
    background-color: #FFFFFF;
    z-index: 100;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;
export const StyledGridContent = styled('div', {
    shouldForwardProp: (prop) => prop !== 'displaySidebar',
})<{displaySidebar: boolean}>`
    width: 100%;
    padding-left: ${({displaySidebar}) => displaySidebar ? '320px' : '0'};
`;
export const StyledMainContentNode = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;
export const StyledMainContentWrapper = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
export const StyledGridMenu = styled('div')`
    position: fixed;
    z-index: 101;
    top: 0;
    bottom: 0;
    max-width: 320px;
    width: 320px;
`;
export const StyledContent = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSubscriptionExpired' && prop !== 'isSharedSpace',
})<{isSubscriptionExpired: boolean; isSharedSpace: boolean}>`
    max-width: 100vw;
    flex-grow: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: ${({isSubscriptionExpired, isSharedSpace}) => {
        const headerHeight = 65;
        const subscriptionheaderHeight = 56;
        if (isSharedSpace) {
            return '0';
        }
        return isSubscriptionExpired ? `${headerHeight + subscriptionheaderHeight}px` : `${headerHeight}px`
    }};
`;
export const StyledPro = styled(Box)`
    width: 33px;
    height: 20px;
    background: linear-gradient(248.17deg, #D251FF 9.36%, #2F2C2C 100%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 1px 4px;
    margin-top: 4px;
`;
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { SpacesNodesGetQueryParams } from './Types/Get/Get.type';
import { GetPostSpacesNodesGetBodyParams } from './Types/GetPost/GetPost.type';
import { AddNodeToSpaceBodyParams } from './Types/Post/Post.type';
import { RemoveNodeFromSpaceBodyParams } from "./Types/Delete/Delete.type";

export const GetSpacesNodesFetch = (spaceId: string, query?: SpacesNodesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/nodes`, {
        params: {
            users: !!query?.users.length ? query.users.join(',') : undefined,
            apps: !!query?.apps.length ? query.apps.join(',') : undefined,
        },
    });
}
export const GetPostSpacesNodesFetch = (spaceId: string, body: GetPostSpacesNodesGetBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/${spaceId}/nodes`, body);
}
export const AddNodeToSpaceFetch = (body: AddNodeToSpaceBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/nodes`, body);
}
export const DeleteNodesFetch = (body: RemoveNodeFromSpaceBodyParams) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/spaces/nodes`, {
        data: body,
    });
}
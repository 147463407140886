import { FC, useMemo } from "react";
import { EmptyStateProps } from "./EmptyState.type";
import { StyledAppsWrapper, StyledDescription, StyledTitle } from "./EmptyState.styled";
import { Typography } from "@mui/material";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { App } from "@/components/v3/List/App/App.component";

export const EmptyState: FC<EmptyStateProps> = ({
    categoryName,
    listOfApps,
}) => {
    const title = useMemo(() => {
        return `Get ${categoryName.toLowerCase()} from your apps`
    }, [categoryName]);
    const filteredListOfApps = useMemo(() => {
        return listOfApps.filter(app => {
            return app.isConnected === false;
        });
    }, [listOfApps]);
    const listOfAppsJsx = filteredListOfApps.map((app, i) => {
        return <App key={app.title} app={app} />
    });
    return <EmptyStateLayoyt
        value={!filteredListOfApps.length}
        emptyNode={<Typography variant="body1" style={{ padding: '0 24px' }}>
            No resources in this categories
        </Typography>}
    >
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>Sense will automatically categorise, interconnect and organize all resources from connected apps</StyledDescription>
        <StyledAppsWrapper>
            {listOfAppsJsx}
        </StyledAppsWrapper>
    </EmptyStateLayoyt>;
};
import { styled } from "@mui/material";
import { MenuIcon } from "@/icons/v3/menu.icon";
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
    padding: 0;
    .MuiList-root {
        padding-top: 4px;
        padding-bottom: 4px;
        margin-top: 0;
        min-width: 120px;
    }
  }
`

export const StyledMenuIcon = styled(MenuIcon)`
    width: 16px;
    height: 16px;
`;
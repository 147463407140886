import { ButtonProps, ConfirmationDialogProps } from "./ConfirmationDialog.type";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledCancelButton, StyledContent } from "./ConfirmationDialog.styled";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";

export const ConfirmationDialog = ({
    value,
    onChange,
    onSubmit,
    title = "Are you sure?",
    content,
    submitButtonProps,
    cancelButtonProps
}: ConfirmationDialogProps) => {
    const defaultSubmitButtonProps: ButtonProps = {
        size: "large",
        children: "Ok",
    };
    const defaultCancelButtonProps: ButtonProps = {
        size: "large",
        variant: "secondary",
        children: "Cancel",
    };
    const submitProps = {
        ...defaultSubmitButtonProps,
        ...submitButtonProps
    };
    const cancelProps = {
        ...defaultCancelButtonProps,
        ...cancelButtonProps
    };
    return (
        <Dialog
            value={value}
            onChange={onChange}
            size="small"
        >
            <DialogLayout title={title} actions={<>
                <StyledCancelButton {...cancelProps} onClick={() => onChange(false)} />
                <Button {...submitProps} onClick={onSubmit} />
            </>} >
                {content && <StyledContent>{content}</StyledContent>}
            </DialogLayout>
        </Dialog>
    )
}
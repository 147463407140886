import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { ActivitiesDetailsGetQueryParams } from './Types/Get/Get.type';

export const GetActivitiesDetailsFetch = (query: ActivitiesDetailsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/actions/details`, {
        params: {
            ...query,
            limit: query.limit || 6,
            users: !!query.users?.length ? query.users.join(',') : undefined,
            apps: !!query.apps?.length ? query.apps.join(',') : undefined,
            relations: !!query.relations?.length ? query.relations.join(',') : undefined,
        },
    });
}
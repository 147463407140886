import { FC } from "react";
import { EmptyStateLayoytProps } from "./EmptyStateLayoyt.type";

export const EmptyStateLayoyt: FC<EmptyStateLayoytProps> = ({
    children,
    emptyNode,
    value,
}) => {
    return <>
        {value ? emptyNode : children}
    </>
};
import { FC, useState } from "react";
import { Menu as MenuComponent } from "@/components/v3/Other/Menu/Menu.component";
import { MenuItem } from "@/components/v3/Other/MenuItem/MenuItem.component";
import { MenuProps } from "./Menu.type";
import { EditIcon } from "@/icons/v3/edit.icon";
import { DeleteIcon } from "@/icons/v3/delete.icon";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";
import { DeleteSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { ToastService } from "@/service/ToastService";
import { useRouter } from "@/providers/Router/Router.provider";

export const Menu: FC<MenuProps> = ({
    space,
}) => {
    const router = useRouter();
    const { confirm, component } = useConfirm({
        submitButtonProps: {
            children: "Delete",
        }
    });
    const deleteHandler = () => {
        confirm(async (result) => {
            if (result) {
                await DeleteSpaceFetch(space.id).then(data => {
                    ToastService.showToast('success', 'Space was deleted!');
                    router.push({
                        name: 'forYou'
                    })
                    window.location.reload();
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred!');
                });
            }
        });
    };
    return <>
        <MenuComponent
            buttonProps={{
                variant: 'secondary'
            }}
        >
            <MenuItem icon={<EditIcon />} onClick={() => {
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'edit-space'
                    }
                });
            }}>Edit</MenuItem>
            <MenuItem icon={<DeleteIcon />} onClick={deleteHandler} variant="error">Delete</MenuItem>
        </MenuComponent>
        {component}
    </>
};
import { FC } from "react";
import { SvgIconProps } from '@mui/material';

export const EndIcon1: FC<SvgIconProps> = (props) => {
    return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M3.67969 4.5332V7.01758H2.58984C1.38867 7.01758 0.410156 7.9668 0.410156 9.17383C0.410156 10.3809 1.38281 11.3594 2.58984 11.3594C3.78516 11.3594 4.76367 10.3809 4.76367 9.17383V8.0957H7.23047V9.17383C7.23047 10.3809 8.20898 11.3594 9.4043 11.3594C10.6113 11.3594 11.584 10.3809 11.584 9.17383C11.584 7.9668 10.6055 7.01758 9.4043 7.01758H8.31445V4.5332H9.4043C10.6055 4.52734 11.584 3.57812 11.584 2.37109C11.584 1.16992 10.6113 0.185547 9.4043 0.185547C8.20898 0.185547 7.23047 1.16992 7.23047 2.37109V3.45508H4.76367V2.37109C4.76367 1.16992 3.78516 0.185547 2.58984 0.185547C1.38281 0.185547 0.410156 1.16992 0.410156 2.37109C0.410156 3.57812 1.38867 4.52734 2.58984 4.5332H3.67969ZM2.60742 3.4668C1.99805 3.4668 1.50586 2.97461 1.50586 2.37109C1.50586 1.77344 1.99805 1.28125 2.58984 1.28125C3.18164 1.28125 3.67383 1.77344 3.67383 2.38867V3.4668H2.60742ZM9.39258 3.4668H8.32031V2.38867C8.32031 1.77344 8.8125 1.28125 9.4043 1.28125C10.002 1.28125 10.4883 1.77344 10.4883 2.37109C10.4883 2.97461 9.99609 3.4668 9.39258 3.4668ZM4.76953 7.02344V4.52148H7.23047V7.02344H4.76953ZM2.60742 8.07812H3.67383V9.15625C3.67383 9.76562 3.18164 10.2578 2.58984 10.2578C1.99805 10.2578 1.50586 9.77148 1.50586 9.16797C1.50586 8.57031 1.99805 8.07812 2.60742 8.07812ZM9.39258 8.07812C9.99609 8.07812 10.4883 8.57031 10.4883 9.16797C10.4883 9.77148 10.002 10.2578 9.4043 10.2578C8.8125 10.2578 8.32031 9.76562 8.32031 9.15625V8.07812H9.39258Z" fill="#202020" fillOpacity="0.5"/>
    </svg>
};
export const EndIcon2: FC<SvgIconProps> = (props) => {
    return <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M5.14258 11.2598C4.28125 11.2598 3.8125 10.75 3.8125 9.92383V7.375H1.50391C0.988281 7.375 0.595703 7.06445 0.595703 6.57812C0.595703 6.2793 0.724609 6.08008 0.988281 5.81641L6.23242 0.560547C6.4668 0.320312 6.72461 0.191406 7 0.191406C7.27539 0.191406 7.52734 0.320312 7.76758 0.560547L13.0117 5.81641C13.2637 6.07422 13.4043 6.2793 13.4043 6.57812C13.4043 7.06445 13.0117 7.375 12.4902 7.375H10.1875V9.92383C10.1875 10.75 9.71875 11.2598 8.85156 11.2598H5.14258ZM5.38281 10.0996H8.61719C8.81641 10.0996 8.96289 9.95312 8.96289 9.75391V6.50195C8.96289 6.36719 9.02148 6.30859 9.15625 6.30859H11.7051C11.7461 6.30859 11.7637 6.29102 11.7637 6.26758C11.7637 6.24414 11.7578 6.22656 11.7344 6.20312L7.11133 1.59766C7.07031 1.55664 7.03516 1.54492 7 1.54492C6.96484 1.54492 6.92969 1.55664 6.88867 1.59766L2.26562 6.20312C2.24219 6.22656 2.23633 6.24414 2.23633 6.26758C2.23633 6.29102 2.25391 6.30859 2.29492 6.30859H4.83789C4.97852 6.30859 5.03125 6.36719 5.03125 6.50195V9.75391C5.03125 9.95898 5.17773 10.0996 5.38281 10.0996Z" fill="#202020" fillOpacity="0.5"/>
    </svg>
};
export const EndIcon3: FC<SvgIconProps> = (props) => {
    return <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M4.01758 9.14062C6.02148 9.14062 7.24023 8.16211 7.24023 6.62109V6.61523C7.24023 5.30859 6.49023 4.59961 4.74414 4.23047L3.81836 4.03711C2.76367 3.80859 2.2832 3.42188 2.2832 2.78906V2.7832C2.2832 2.04492 2.95117 1.54688 4 1.54102C5.00195 1.54102 5.68164 2.01562 5.79883 2.80078L5.80469 2.88281H7.08203L7.07617 2.7832C6.98828 1.36523 5.78125 0.404297 4.01758 0.404297C2.23047 0.404297 0.958984 1.39453 0.953125 2.83594V2.8418C0.953125 4.0957 1.75586 4.85742 3.41406 5.20898L4.33398 5.40234C5.45312 5.64844 5.91602 6.01758 5.91602 6.69727V6.70312C5.91602 7.49414 5.20117 8.00391 4.07031 8.00391C2.93945 8.00391 2.16016 7.51758 2.04297 6.73828L2.03125 6.66211H0.753906L0.759766 6.75C0.865234 8.23828 2.11914 9.14062 4.01758 9.14062Z" fill="#202020" fillOpacity="0.5"/>
    </svg>
};
import { FC, useEffect, useMemo, useState } from "react";
import { StyledSpacesWrapper } from "./Spaces.styled";
import { TeamSpacesDropdown } from "./components/TeamSpacesDropdown/TeamSpacesDropdown.component";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { SpaceType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { WalkthroughWrapper } from "@/providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component";
import { Space } from "./components/Space/Space.component";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Spaces: FC = () => {
    const [node, setNode] = useState('All Spaces');
    const { spaces: spacesNativeData, mySpaces: mySpacesNativeData } = useSpaces();
    const { user } = useMe();
    const spacesSorted = useMemo(() => {
      return spacesNativeData.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }, [spacesNativeData]);
    const mySpacesSorted = useMemo(() => {
      return mySpacesNativeData.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }, [mySpacesNativeData]);
    const compare = (a: SpaceType, b: SpaceType) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
    }
    const teamSpaces = spacesSorted.filter(space => space.application === 'Sense').sort(compare);
    const mySpacesNative = spacesSorted.filter(space => {
      return space.creatorId === user?.id || (user?.id && space.userIds.includes(user?.id));
    }).sort(compare);
    const mySpacesManual = mySpacesSorted
    const mySpaces = [...mySpacesNative, ...mySpacesManual]
    const slackSpaces = spacesSorted.filter(space => space.application === 'Slack').sort(compare);
    const confluenceSpaces = spacesSorted.filter(space => space.application === 'Confluence').sort(compare);
    const jiraSpaces = spacesSorted.filter(space => space.application === 'Jira').sort(compare);
    const asanaSpaces = spacesSorted.filter(space => space.application === 'Asana').sort(compare);
    const githubSpaces = spacesSorted.filter(space => space.application === 'Github').sort(compare);
    const clickUpSpaces = spacesSorted.filter(space => space.application === 'ClickUp').sort(compare);
    const trelloSpaces = spacesSorted.filter(space => space.application === 'Trello').sort(compare);
    const microsoftTeamsSpaces = spacesSorted.filter(space => space.application === 'MicrosoftTeams').sort(compare);
    const notionSpaces = spacesSorted.filter(space => space.application === 'Notion').sort(compare);

    const selectedSpace = localStorage.getItem('selectedSpace');
    useEffect(() => {
      selectedSpace && setNode(selectedSpace)
    }, [selectedSpace])
    return <>
        <TeamSpacesDropdown
            setNode={setNode}
            teamSpaces={teamSpaces}
            mySpaces={mySpaces}
            slackSpaces={slackSpaces}
            jiraSpaces={jiraSpaces}
            asanaSpaces={asanaSpaces}
            githubSpaces={githubSpaces}
            clickUpSpaces={clickUpSpaces}
            trelloSpaces={trelloSpaces}
            confluenceSpaces={confluenceSpaces}
            microsoftTeamSpaces={microsoftTeamsSpaces}
            notionSpaces={notionSpaces}
        />
        <WalkthroughWrapper
          variant="walkthrough-spaces"
          title={Array.isArray(spacesSorted) && spacesSorted.length > 1 ? 'All your projects will be here' : 'Self-organised spaces'}
          description={Array.isArray(spacesSorted) && spacesSorted.length > 1 ? 'Sense will look through all apps you connected and pack your projects in self-organised spaces. You can easily switch between your sources. ' : 'Discover what your projects might look like'}
          targetId="walkthrough-spaces"
        >
          <StyledSpacesWrapper>
            {node === 'All Spaces' && spacesSorted.map((space) => <Space space={space} key={space.id} />)}
            {node === 'My Spaces' && mySpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Sense' && teamSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Slack' && slackSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Confluence' && confluenceSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Jira' && jiraSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Asana' && asanaSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'GitHub' && githubSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'ClickUp' && clickUpSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Trello' && trelloSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'Notion' && notionSpaces.map((space) => <Space space={space} key={space.id} />)}
            {node === 'MicrosoftTeams' && microsoftTeamsSpaces.map((space) => <Space space={space} key={space.id} />)}
          </StyledSpacesWrapper>
        </WalkthroughWrapper>
    </>
};
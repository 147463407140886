import { useContext, createContext, FC } from 'react';
import { ChromeExtensionProviderProps, ChromeExtensionContextType, ChromeExtensionCtxType } from './ChromeExtension.type';
import ChromeExtensionService from './services/ChromeExtension/ChromeExtension.srvice';

const defaultValue: ChromeExtensionContextType = {
    version: '0',
    versionNumber: 0,
    ctx: 'top_app',
    isIframe: false,
    localStorage: {
        getItem: async () => undefined,
        setItem: async () => undefined,
    },
};

const ChromeExtensionContext = createContext<ChromeExtensionContextType>(defaultValue);
export const useChromeExtension = () => useContext<ChromeExtensionContextType>(ChromeExtensionContext);

export const ChromeExtensionProvider: FC<ChromeExtensionProviderProps> = (props: ChromeExtensionProviderProps) => {
    const ctx = document.documentElement.dataset.senseai_extension_ctx ? (document.documentElement.dataset.senseai_extension_ctx as ChromeExtensionCtxType) : 'top_app';
    const version = document.documentElement.dataset.senseai_extension_version ? document.documentElement.dataset.senseai_extension_version : '0';
    // const ctx = 'iframe_app';
    // const version = '1.4.1.45';
    const versionNumber = Number(version.replace(/\./g, ''))
    const setLocalStorage = async (key: string, value: string | null) => {
        // localStorage.setItem(`test-${key}`, value ?? '');
        ChromeExtensionService.setLocalStorage({
            [key]: value
        });
    };
    const getLocalStorage = async (key: string) => {
        // const value = localStorage.getItem(`test-${key}`);
        // return value;
        const results = await ChromeExtensionService.getLocalStorage([key]);
        if (results.success && results.data) {
            return results.data[key];
        }
        return null;
    };
    const isIframe = ctx === 'iframe_app';
    const config: ChromeExtensionContextType = {
        version: version,
        versionNumber,
        ctx: ctx,
        isIframe,
        localStorage: {
            getItem: getLocalStorage,
            setItem: setLocalStorage,
        },
    };
    return <ChromeExtensionContext.Provider value={config}>
        {props.children}
    </ChromeExtensionContext.Provider>;

};

export default ChromeExtensionContext;

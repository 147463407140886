import { styled } from "@mui/material";
import { Node } from "./components/Node/Node.component";
import { SizeType } from "../../Activity.type";
import { TextSkeleton } from "@/components/v3/Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledWrapper = styled('div')<{size: SizeType}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0px;
    @media (max-width: 768px) {
        padding-bottom: 0;
        ${({size}) => size === 'small' && 'display: block;'}
        ${({size}) => size === 'large' && 'margin-bottom: 4px;'}
    }
`;
export const StyledNameWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledNodeWrapper = styled(TextSkeleton)<{size: SizeType}>`
    margin-left: ${({size}) => size === 'large' ? '8px;' : '32px;'}
`;
export const StyledName = styled(TextSkeleton)<{size: SizeType}>`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 8px;
    @media (max-width: 768px) {
        ${({size}) => size === 'small' && 'margin-bottom: 4px;'}
    }
`;
export const StyledAction = styled('span')`
    color: #636363;
    font-weight: 400;
`;
export const StyledNode = styled(Node)`
    margin-left: 4px;
    @media (max-width: 768px) {
        margin-left: 0;
    }
`;
export const StyledDate = styled(TextSkeleton)<{size: SizeType}>`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
    @media (max-width: 768px) {
        ${({size}) => size === 'small' && 'display: block;'}
        ${({size}) => size === 'small' && 'margin-top: 4px;'}
        margin-left: 0;
    }
`;
import { FC } from "react";
import { SectionProps } from "./Section.type";
import { StyledLabel } from "./Section.styled";

export const Section: FC<SectionProps> = ({
    label, children
}) => {
    return <>
        <StyledLabel title={label} />
        {children}
    </>
}
import { FC, useMemo } from "react";
import { 
    StyledDrawerCard,
    StyledDrawerContent,
    StyledDrawerWrapper,
    StyledNoteContent,
    StyledNoteTitle,
    StyledNoteWrapper,
} from "./DrawerContent.styled";
import { useNode } from "providers/v3/NodeProvider/Node.provider";
import { DrawerContentProps } from "./DrawerContent.type";
import { useChromeExtension } from "@/providers/ChromeExtension/ChromeExtension.provider";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { Header } from "./components/Header/Header.component";
import { People } from "./components/People/People.component";
import { Divider } from "@/components/v3/Other/Divider/Divider.component";
import { Relationships } from "./components/Relationships/Relationships.component";
import { Activities } from "./components/Activities/Activities.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { ConnectApps } from "@/components/v3/Sections/ConnectApps/ConnectApps.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { NodeTypeType } from "@/types/Node/Node.type";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { PageHeader } from "@/components/v3/Sections/PageHeader/PageHeader.component";

export const DrawerContent: FC<DrawerContentProps> = ({
    fullWidth = false,
    commentHeight = 0
}) => {
    const {
        node,
        nodes,
        slackMessages,
        teamsMessages,
        atlassianTaskComments,
        asanaTaskComments,
        githubIssueComments,
        githubPullComments,
        clickUpTaskComments,
        trelloCardComments,
        figmaTaskComments,
        isFetching,
        relationships
    } = useNode();
    const { isSharedSpace, isSharedNode, isShared } = useIsShared();
    const chromeExtension = useChromeExtension();
    const messages = useMemo(() => {
        return [
            ...slackMessages,
            ...teamsMessages,
            ...atlassianTaskComments,
            ...asanaTaskComments,
            ...githubIssueComments,
            ...githubPullComments,
            ...clickUpTaskComments,
            ...trelloCardComments,
            ...figmaTaskComments
        ]
    }, [asanaTaskComments, atlassianTaskComments, clickUpTaskComments, figmaTaskComments, githubIssueComments, githubPullComments, slackMessages, teamsMessages, trelloCardComments]);
    const actors = relationships?.actors || [];
    const activitiesWithComments = [...messages, ...(node?.comments || []), ...actors]
    const isEmptyActivities = !activitiesWithComments.length;
    const isEmptyRelationships = !nodes.length;
    
    return (
        <StyledDrawerWrapper isSharedNode={isSharedNode} commentHeight={(isSharedSpace || isSharedNode) ? 0 : commentHeight}>
            <StyledDrawerContent
                fullWidth={fullWidth || (chromeExtension.isIframe && !isSharedNode)}
                isSharedNode={isSharedNode}
            >
                <LoaderLayout isLoading={isFetching} transparent={!!node}>
                    <StyledDrawerCard isSharedNode={isSharedNode}>
                        {node?.nodeType !== NodeTypeType.NOTE && <>
                            <Header />
                            <People />
                        </>}
                        {node?.nodeType === NodeTypeType.NOTE && <>
                            <Divider />
                            <StyledNoteWrapper>
                                {!!node.name && <StyledNoteTitle >{node.name}</StyledNoteTitle>}
                                <StyledNoteContent>
                                    {node.content && parse(sanitizeHtml(node.content, {
                                        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                                        allowedAttributes: {
                                            ...sanitizeHtml.defaults.allowedAttributes,
                                            'a': ['href', 'title', 'target']
                                        }
                                    }))}
                                </StyledNoteContent>
                            </StyledNoteWrapper>
                        </>}
                        <Divider />
                        <EmptyStateLayoyt value={isEmptyActivities && isEmptyRelationships} emptyNode={!isShared && <ConnectApps
                            title="Get related resources automatically"
                            description="Connect apps to automatically get previous versions, tasks, documents, discussions, files, links related to this resource."
                            applications={['Slack', 'Asana', 'Notion', 'GoogleDrive']}
                        />}>
                            <Relationships />
                            <Activities />
                        </EmptyStateLayoyt>
                    </StyledDrawerCard>
                </LoaderLayout>
            </StyledDrawerContent>
        </StyledDrawerWrapper>
    );
}
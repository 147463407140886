import { FC } from "react";
import { ForYouContent } from "./ForYou.content";
import { ForYouPageProvider } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { NodeNeighboursDrawer } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/NodeNeighboursDrawer.component";

export const ForYouPage: FC = () => {
    return <ForYouPageProvider>
        <ForYouContent />
        <NodeNeighboursDrawer />
    </ForYouPageProvider>
};
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
`;
export const StyledContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledLink = styled('a')`
    color: #202020;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 4px 12px;

`;
export const StyledTermsLink = styled(StyledLink)`
    border-right: 1px solid rgba(0, 0, 0, 0.08);
`;
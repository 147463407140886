import { FC, useEffect, useState } from "react";
import { AddResourcesDialogProps } from "./AddResourcesDialog.type";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { StyledDialogLayout } from "@/providers/v3/AddInSpace/components/AddInSpaceModal/AddInSpaceModal.styled";
import { AddResourcesForm } from "@/components/v3/Common/AddResourcesForm/AddResourcesForm.component";
import { NodeType } from '@/components/v3/Common/AddResourcesForm/components/AddNodeItem/AddNodeItem.type';
import { useRouter } from "@/providers/Router/Router.provider";

export const AddResourcesDialog: FC<AddResourcesDialogProps> = ({
    value, onChange,
    resources, onResources
}) => {
    const router = useRouter();
    const onChangeHandler = (value: boolean) => {
        if (!value) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    tab: undefined,
                }
            })
        }
        onChange(value);
    };
    useEffect(() => {
        if (value) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    tab: 'sense',
                }
            })
        }
    }, [value]);
    const [isFetchingLocal, setIsFetchingLocal] = useState<boolean>(false);
    const addHandler = (node: NodeType) => {
        if (!resources.map(node =>node.id).includes(node.id)) {
            const updatedResources = [...resources, node];
            onResources(updatedResources);
        }
        setIsFetchingLocal(false);
    };
    
    const removeHandler = (node: NodeType) => {
        const updatedResources = resources.filter(resource => resource.id !== node.id);
        onResources(updatedResources);
        setIsFetchingLocal(false);
    };
    
    return <Dialog value={value} onChange={() => onChangeHandler(false)}>
        <StyledDialogLayout
            title="Add to note"
            onCloseClick={() => onChangeHandler(false)}
            useContetPaddings={false}
            isLoading={isFetchingLocal}
        >
            <AddResourcesForm
                onAdd={addHandler}
                onRemove={removeHandler}
                asKeyResource={false}
                isFetchingLocal={isFetchingLocal}
                setIsFetchingLocal={setIsFetchingLocal}
                listOfAddedNodes={resources}
            />
        </StyledDialogLayout>
    </Dialog>
};
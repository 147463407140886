
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Arrow = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path d="M7 10L12 15L17 10L7 10Z" fill="#636363"/>
    </SvgIcon>
  );
};



import { useEffect } from "react";
import { HttpClient } from "../../../axios/HttpClient";
import { envUrl } from "../../../service/helpers";
import { ToastService } from "../../../service/ToastService";
import { useRouter } from "@/providers/Router/Router.provider";
import { StyledContainer, StyledLoginButton, StyledText, StyledWrapper } from "./EmailConfirmed.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const EmailConfirmedPage = () => {
    const router = useRouter();
    const { logout } = useMe();

    useEffect(() => {
        HttpClient.post(`${envUrl}/organization-service/v1/organizations/email/confirmation`,
        {
          code: router.params.confirmationCode
        }).then(data => ToastService.showToast('success', 'email was successfully confirmed'));
    }, [router])

    return (
            <StyledContainer>
                <StyledWrapper>
                    <StyledText>Your email has been confirmed.</StyledText>
                    <StyledLoginButton center onClick={logout}>Login</StyledLoginButton>
                </StyledWrapper>
            </StyledContainer>
    )
}
import { FC } from "react";
import { TabValueType, TabsProps } from "./Tabs.type";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";
import { ActivitiesIcon } from "@/icons/v3/activities.icon";
import { ResourcesIcon } from "@/icons/v3/resources.icon";

export const Tabs: FC<TabsProps> = ({
    value,
    onChange
}) => {
    const { useNewActivityUI } = useFeatureFlags();
    return <StyledWrapper>
        {!!useNewActivityUI && <StyledTabs value={value} onChange={(newVal) => onChange(newVal as TabValueType)}>
            <StyledTab value="RECENT_ACTIVITIES" icon={<ActivitiesIcon />}>Activities</StyledTab>
            <StyledTab value="ALL_RELATIONSHIPS" icon={<ResourcesIcon />}>Resources</StyledTab>
            {/* <StyledTab value="NOTES" icon={<NotesIcon />}>Notes</StyledTab> */}
        </StyledTabs>}
        {!useNewActivityUI && <StyledTabs value={value} onChange={(newVal) => onChange(newVal as TabValueType)}>
            <StyledTab value="COMMON_RELATIONSHIPS">Common relationships</StyledTab>
            <StyledTab value="RECENT_ACTIVITIES">Recent activities</StyledTab>
            <StyledTab value="ALL_RELATIONSHIPS">All relationships</StyledTab>
        </StyledTabs>}
    </StyledWrapper>
};
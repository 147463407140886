import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const StyledContainer = styled(Box)`
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledButton = styled(Button)`
    margin: 16px 150px;
`;
export const StyledEmail = styled('span')`
    color: #202020;
    font-weight: 500;
`;
export const Styledtext = styled('div')`
    text-align: center;
    font-weight: 400;
`;
import { IconProps } from "./icon.type";

export const FilterIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path d="M6.75 13.5023H11.25" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 9.00226H13.5" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.25 4.50006H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

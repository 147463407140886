import { FC, useEffect, useRef, useState } from "react";
import { ModeType, TagProps } from "./Tag.type";
import { StyledContainer, StyledEmojiIcon, StyledEmojiSelect, StyledInput, StyledName, StyledWrapper } from "./Tag.styled";
import { Menu } from "./components/Menu/Menu.component";


export const Tag: FC<TagProps> = ({
    tag, setTag, onRemove
}) => {
    const [mode, setMode] = useState<ModeType>(!!tag.name ? 'view' : 'edit');
    const [name, setName] = useState<string>(tag.name);
    useEffect(() => {
        setMode(!!tag.name ? 'view' : 'edit');
        setName(tag.name);
    }, [tag.name]);
    const setEmojiHandler = (emoji: string) => {
        setTag({
            ...tag,
            emoji
        })
    };
    const onEditHandler = () => {
        setTimeout(() => {
            setMode('edit');
        }, 0);
    };
    const onBlurHandler = () => {
        if (name) {
            setMode('view');
            setTag({
                ...tag,
                name
            })
        }
    };
    return <StyledWrapper>
        <StyledContainer>
            <StyledEmojiSelect onChange={(e) => {
                setEmojiHandler(e.native)
            }} >
                {!!tag.emoji ? tag.emoji : <StyledEmojiIcon />}
            </StyledEmojiSelect>
            {mode === 'edit' && <StyledInput
                placeholder="Tag name"
                autoFocus
                value={name}
                onBlur={onBlurHandler}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onBlurHandler();
                    }
                    if (e.key === 'Escape') {
                        onBlurHandler();
                    }
                }}
                onChange={(e) => setName(e.target.value)}
            />}
            {mode === 'view' && <StyledName>{tag.name}</StyledName>}
        </StyledContainer>
        <Menu onEdit={onEditHandler} onDelete={onRemove} />
    </StyledWrapper>
};
import { FC } from "react";
import { StyledWrapper } from "./Signup.styled";
import { SectionDescription } from "./components/SectionDescription/SectionDescription.component";
import { SectionForm } from "./components/SectionForm/SectionForm.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

export const SignupPage: FC = () => {
    const isDesktop = useRenederAbove('small');
    return <StyledWrapper>
        {isDesktop && <SectionDescription />}
        <SectionForm />
    </StyledWrapper>
};
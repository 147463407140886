import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Editor } from "@/components/v3/Fields/Editor/Editor.component";
import { StyledHeader as StyledDialogHeader } from "@/components/v3/Layouts/DialogLayout/DialogLayout.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { AddIcon } from "@/icons/v3/add.icon";
import { styled } from "@mui/material";

export const StyledHeader = styled(StyledDialogHeader)`
    padding-top: 0;
`;
export const StyledContentWrapper = styled(LoaderLayout)`
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 57px);
`;
export const StyledEditor = styled(Editor)`
    flex: 1
`;
export const StyledResourcesWrapper = styled('div')`
    
`;
export const StyledAddResourcesButton = styled(Button)`
    margin-top: 16px;
`;
export const StyledAddIcon = styled(AddIcon)`
    & path {
        fill: #202020;
    }
`
export const StyledFilledCard = styled(FilledCard)`
    margin-top: 16px;
`
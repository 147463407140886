import { styled } from "@mui/material";

export const StyledMeetingsTimlineWrapper = styled('div')`
    padding-left: 12px;
    padding-top: 12px;
    max-height: 216px;
    overflow-y: hidden;
`;
export const StyledAppsWrapper = styled('div')`
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed rgba(0, 0, 0, 0.16);
`;
export const StyledAppsSectionWrapper = styled('div')`
    padding: 0px 24px 24px 24px;
`;
import { styled } from "@mui/material";
import { Loader } from "components/Loader/Loader.component";
import { DoneIcon } from "icons/done.icon";
import { ReloadIcon } from "icons/reload.icon";

export const StyledProgress = styled('div')`
    position: fixed;
    bottom: 16px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
`
export const StyledProgressContainer = styled('div')`
    padding: 12px 16px;
    border-radius: 16px;
    background: #202020;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: auto;
`
export const StyledProgressLoader = styled(Loader)`
    width: 32px;
    height: 32px;
    margin-right: 16px;
    & > span {
        width: 32px !important;
        height: 32px !important;
        & svg {
            & > circle {
                stroke: #fff;
            }
        }
    }
`
export const StyledDoneIcon = styled(DoneIcon)`
    margin-right: 16px;
`
export const StyledProgressTitle = styled('div')`
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`
export const StyledProgressCaption = styled('div')`
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`
export const StyledReloadButtonContainer = styled('div')`
    display: flex;
    height: 32px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
    margin-left: 24px;
    color: #202020
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
`
export const StyledReloadIcon = styled(ReloadIcon)`
    margin-right: 8px;
    width: 18px;
    height: 18px;
`
import { FC, useEffect, useMemo, useState } from "react";
import { SelectChannelsModalProps } from "./SelectChannelsModal.type";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { StyledCancelButton, StyledInput } from "./SelectChannelsModal.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Channel } from "./components/Channel/Channel.component";
import { useChannels } from "@/providers/v3/Channels/Channels.provider";

export const SelectChannelsModal: FC<SelectChannelsModalProps> = ({
    isOpened, setIsOpened,
    channels: channelsProp,
    setChannels: setChannelsProp,
    applicationName,
    application,
    channelsTitle,
    isLoadingChannels
}) => {
    const { appChannels: listOfChannels, isLoading } = useChannels();
    const [search, setSearch] = useState<string>('');
    const [channels, setChannels] = useState<string[]>(channelsProp);
    useEffect(() => {
        setChannels(channelsProp);
    }, [channelsProp, isOpened]);
    
    const filteredListOfChannels = useMemo(() => {
        const searchTrim = search.toLowerCase().trim();
        return listOfChannels[application].filter((channel) => {
            return channel.name?.toLowerCase().includes(searchTrim);
        })
    }, [application, listOfChannels, search]);
    const listOfChannelsJsx = filteredListOfChannels.map((channel, i) => {
        return <Channel key={i} channel={channel} value={channels} onChange={setChannels} />
    });
    const doneHandler = () => {
        setChannelsProp(channels);
        setIsOpened(false);
    };
    return <Dialog value={isOpened} onChange={setIsOpened}>
        <DialogLayout
            isLoading={isLoading || isLoadingChannels}
            title={`${applicationName} ${channelsTitle}`}
            actions={<>
                <StyledCancelButton size="large" variant="error" onClick={() => setIsOpened(false)}>
                    Cancel
                </StyledCancelButton>
                <Button size="large" onClick={doneHandler}>
                    Done
                </Button>
            </>}
        >
            <StyledInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Find channels" />
            {listOfChannelsJsx}
        </DialogLayout>
    </Dialog>
};
import { styled } from "@mui/material";
import { Button } from "../../Fields/Button/Button.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { Image } from "./components/Image/Image.component";

export const StyledWrapper = styled('div')`
    padding: 24px;
    border-radius: 12px;
    background: #F2F7FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: column;
    }
`;
export const StyledContent = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
export const StyledDescriptionWrapper = styled('div')`
    margin-left: 24px;
    width: 100%;
`;
export const StyledTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
`;
export const StyledDescription = styled('div')`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
export const StyledAtionsWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 230px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        margin-top: 16px;
        justify-content: center;
    }
`;
export const StyledSkipButton = styled(Button)`
    margin-right: 8px;
`;
export const StyledImage = styled(Image)`
    width: 77px
    height: 77px;
    min-width: 77px;
    min-height: 77px;
`;
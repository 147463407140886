import styled from "styled-components";

export const StyledDesctiption = styled('div')`
    color: #636363;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 0px;
`;
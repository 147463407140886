import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)`
    width: 100%;
    & .MuiOutlinedInput-root {
        background: rgb(0,0,0,0.08);
        border-radius: 6px;
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        font-size: 14px;
        font-family: Poppins;
    }
    & .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
    & .MuiIconButton-root {
        color: rgba(32, 32, 32, 0.5);
        padding: 0;
    }
`;
import { FC } from "react";
import { NodeMenu } from "@/components/v3/List/Node/components/Actions/components/NodeMenu/NodeMenu.component";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";

export const Menu: FC = () => {
    const { node, update, onClose } = useNode();
    
    return <>
        {node && <NodeMenu
            node={node}
            showCopy
            onUpdate={update}
            onNewComment={() => undefined}
            onDeleteFromSense={() => {
                onClose();
                const updateSpaceData = new CustomEvent('updateDataByNodeDrawer');
                window.dispatchEvent(updateSpaceData);
            }}
            buttonProps={{
                variant: 'secondary'
            }}
        />}
    </>
};
import {
  RoutePathType,
  RouteNameType,
  ParamsType,
  QueriesType,
} from "../Router.type";
import { routes } from "../routes";
import { replaceParam } from "./replaceParam.util";
import { addQueryParam } from "./addQueryParam.util";
export const getPathWithParams = (routeName: RouteNameType, params?: ParamsType, queries?: QueriesType): RoutePathType => {
    let path = routes[routeName].path;
    if (params) {
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const param = params[key];
                path = replaceParam(path, key, param);
            }
        }
    }
    if (queries) {
        for (const key in queries) {
            if (Object.prototype.hasOwnProperty.call(queries, key) && queries[key] !== undefined) {
                const query = queries[key];
                path = addQueryParam(path, key, query);
            }
        }
    }
    
    return path
}
import { styled } from "@mui/material";

export const StyledContent = styled('div')`
    padding: 24px 0;
`
export const StyledStepTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`
export const StyledStep3 = styled('div')`
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 24px;
`
export const StyledStep3Title = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
`
export const StyledActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
`
export const StyledSubmit = styled('div')`
    display: flex;
    height: 32px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #1D1A1A;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
`
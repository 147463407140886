import { FC } from "react";
import { StyledFilledCard } from "./FreePlanTip.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const FreePlanTip: FC = () => {
    const { user } = useMe();
    return <>
        {user?.paymentPlan.name === 'INDIVIDUAL' && <StyledFilledCard>
            You are on <b>Free Plan</b>. It’s only available to create max. <b>3 spaces</b>. Resources history is limited to <b>30 days</b>. Upgrade your plan to start using Sense without limits.
        </StyledFilledCard>}
    </>
};
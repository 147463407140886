import { Button } from "@/components/v3/Fields/Button/Button.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 24px 32px 0 32px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledContainer = styled('div')`
    border-radius: 12px;
    background: #FFECE7;
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: column;
    }
`;
export const StyledDescription = styled('div')`
    max-width: 560px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        margin-top: 16px;
    }
`;
export const StyledButton = styled(Button)`
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
    }
`;
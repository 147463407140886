import { FC, useMemo, useState } from "react";
import { StyledCalendarIcon, StyledTitleText } from "./Emails.styled";
import { StyledWrapper, StyledFiltersWrapper, StyledSelect, StyledFiltersContainer } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import moment from "moment";
import { SpaceNodeTypeWithRelationships } from "@/providers/v3/SpacePage/SpacePage.type";
import { formatGroupDate } from "@/utils/v3/FormatGroupDate/FormatGroupDate.util";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { getPeopleOptionsFromNodes } from "@/utils/v3/GetPeopleOptionsFromNodes/GetPeopleOptionsFromNodes.util";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyState } from "@/pages/v3/Space/components/Emails/components/EmptyState/EmptyState.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Emails: FC = () => {
    const {
        users, user,
    } = useMe();
    const {
        listOfEmailResentActivities,
        isFetchingEmailRecentActivities,
        update,
    } = useForYouPage();
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const filteredListOfEmails = useMemo(() => {
        return listOfEmailResentActivities.filter((node) => {
            if (selectedPeople.length) {
                const actorIds = node.relationships?.actors?.map(actor => actor.userId) || [];
                if (!actorIds.some(id => selectedPeople.includes(id))) {
                    return false;
                }
            }
            return true;
        });
    }, [listOfEmailResentActivities, selectedPeople]);
    const groups = useMemo(() => {
        const groupedByDay = new Map();
        filteredListOfEmails.forEach(wrapper => {
            const date = moment.utc(wrapper.node.createdAt).local().startOf('day');
            const dateKey = date.format('YYYY-MM-DD');
                if (!groupedByDay.has(dateKey)) {
                    groupedByDay.set(dateKey, []);
                }
                groupedByDay.get(dateKey).push(wrapper);
        });
        return Array.from(groupedByDay.values()) as (SpaceNodeTypeWithRelationships)[][];
    }, [filteredListOfEmails]);
    const peopleOptions = useMemo((): OptionType<string>[] => {
        return getPeopleOptionsFromNodes(listOfEmailResentActivities);
    }, [listOfEmailResentActivities]);
    const groupsJsx = useMemo(() => {
        return groups.map((group, i) => {
            return <CategoryGroup
                key={i}
                title={<>
                    <StyledCalendarIcon />
                    <StyledTitleText>{formatGroupDate(group[0].node.createdAt)}</StyledTitleText>
                </>}
                nodes={group.map((wrapper) => {
                    return {
                        node: wrapper.node,
                        users: users,
                        relationships: wrapper.relationships,
                        onUpdate: update,
                        importantUserIds: wrapper.importantUserIds
                    }
                })}
            />
        });
    }, [groups, users, update]);
    const isEmptySpace = !groups.length && !isFetchingEmailRecentActivities;
    const isEmailIntegrationConnected = user && user.integrations && (user.integrations.outlook.active || user.integrations.gmail.active);
    return <LoaderLayout isLoading={isFetchingEmailRecentActivities} transparent={!!groups.length}>
        <EmptyStateLayoyt
            value={isEmptySpace || !isEmailIntegrationConnected}
            emptyNode={<EmptyState />}
        >
            <StyledWrapper>
            <StyledFiltersWrapper>
                <StyledFiltersContainer>
                    <StyledSelect
                        label="People"
                        value={selectedPeople}
                        onChange={(val: any) => setSelectedPeople(val)}
                        options={peopleOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                </StyledFiltersContainer>
            </StyledFiltersWrapper>
            {groupsJsx}
        </StyledWrapper>
        </EmptyStateLayoyt>
    </LoaderLayout>
};
import { FC } from "react";
import { SectionHeaderProps } from "./SectionHeader.type";
import { StyledSectionCaption, StyledSectionHeader, StyledSectionTitle } from "./SectionHeader.styled";

export const SectionHeader: FC<SectionHeaderProps> = ({
    title, caption, children, className
}) => {
    return <StyledSectionHeader className={className}>
        <div>
            <StyledSectionTitle>{title}</StyledSectionTitle>
            {!!caption && <StyledSectionCaption>{caption}</StyledSectionCaption>}
        </div>
        {children}
    </StyledSectionHeader>
}
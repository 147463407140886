import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { VerifyBodyParams } from './Types/Verify/Verify.type';
import { DisableBodyParams } from './Types/Disable/Disable.type';
import { ValidateBodyParams } from './Types/Validate/Validate.type';

export const OtpGenerateFetch = () => {
    return HttpClient.post(`${envUrl}/organization-service/v1/auth/otp/generate`);
}
export const OtpVerifyFetch = (body: VerifyBodyParams) => {
    return HttpClient.post(`${envUrl}/organization-service/v1/auth/otp/verify`, body);
}
export const OtpDisableFetch = (body: DisableBodyParams) => {
    return HttpClient.post(`${envUrl}/organization-service/v1/auth/otp/disable`, body);
}
export const OtpValidateFetch = (body: ValidateBodyParams) => {
    return HttpClient.post(`${envUrl}/organization-service/v1/auth/otp/validate`, body);
}
export enum AppTypes {
  TeamSpaces = 'Team Spaces',
  Confluence = 'Confluence',
  Jira = 'Jira',
  Asana = 'Asana',
  Github = 'Github',
  ClickUp = 'ClickUp',
  Trello = 'Trello',
  Figma = 'Figma',
  Dropbox = 'Dropbox for Business',
  Sharepoint = 'Sharepoint',
  Slack = 'Slack',
  Outlook = 'Outlook',
  MicrosoftTeams = 'Microsoft Teams',
  Notion = 'Notion',
  GoogleDrive = 'Google Drive',
  GoogleCalendar = 'Google Calendar',
  Gmail = 'Gmail',
}
export type AppTypesKeys = keyof typeof AppTypes;
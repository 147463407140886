import { FC } from "react";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { KeyResourcesProps } from "./KeyResources.type";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { StyledAddIcon, StyledEmptyCardWrapper } from "./KeyResources.styled";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";

export const KeyResources: FC<KeyResourcesProps> = ({
    onUpdate
}) => {
    const {
        isFetchingKeyResources,
        listOfKeyResources,
        listOfSpaceUsers,
    } = useSpacePageCommon();
    const { addInSpace } = useAddInSpace();
    const { isShared } = useIsShared();
    const isLargeScreen = useRenederAbove('large');
    const countOfNodes = isLargeScreen ? 8 : 6;
    const listOfAllKeyResources = [...listOfKeyResources];
    const { items, showMore, setShowMore } = useShowMore({ items: listOfAllKeyResources, limit: countOfNodes });
    return <LoaderLayout isLoading={isFetchingKeyResources} transparent={!!listOfAllKeyResources.length}>
        <LoaderLayout isLoading={isFetchingKeyResources} transparent={!!listOfAllKeyResources.length}>
            <EmptyStateLayoyt
                value={!listOfAllKeyResources.length && !isFetchingKeyResources}
                emptyNode={!isShared && <StyledEmptyCardWrapper>
                    <EmptyCard title="Add key resources" onClick={() => addInSpace(true)}>
                        Add the most important resources for this space
                    </EmptyCard>
                </StyledEmptyCardWrapper>}
            >
                <CategoryGroup
                    useShowAll
                    title="Key resources"
                    actions={<>
                        {!!listOfAllKeyResources.length && !isShared && <Button
                            onClick={() => addInSpace(true)}
                            icon={<StyledAddIcon />}
                            variant="tertiary"
                            iconSize="large"
                        />}
                    </>}
                    nodes={items.map((wrapper) => {
                        return {
                            node: wrapper.node,
                            users: listOfSpaceUsers,
                            onUpdate: onUpdate,
                            importantUserIds: wrapper.importantUserIds
                        }
                    })}
                    nodeSize="medium"
                    cols={2}
                />
                {!showMore && listOfAllKeyResources.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
            </EmptyStateLayoyt>
        </LoaderLayout>
    </LoaderLayout>
};
import { TagIcon as TagWhiteIcon } from "@/icons/tag-white.icon";
import { Button, styled } from "@mui/material";

export const StyledSpaceTag = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 40px;
`
export const StyledSpaceTagContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
export const StyledSpaceTagText = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 12px;
`

export const StyledWhiteTagIcon = styled(TagWhiteIcon)`
    width: 24px;
    height: 24px;
    strokeWidth: 1px;
    stroke: rgba(0, 0, 0, 0.08);
`
export const StyledEmojiWrapper = styled('div')`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledEmoji = styled('div')`
    padding-top: 2px;
    font-size: 16px;
`;
import { PlanType, RadioGroupProps } from "./PlanRadioGroup.type";
import {Plan} from "./components/Plan/Plan.component";

export const PlanRadioGroup = ({ options,value, setValue, setIndustry, setTeamSize }: RadioGroupProps) => {

    const handleChange = (e: any) => {
        setValue(e);
        setTeamSize('');
        setIndustry('')
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: 'space-between', width: 720 }}>
                {options.map((item: PlanType) => (
                    <>
                        <Plan
                            price={item.price}
                            name={item.planName}
                            onChange={handleChange}
                            selectedValue={value}
                            value={item.planName}
                            users={item.users}
                            features={item.availableFeatures}
                            integrations={item.includedIntegrations}
                        />
                    </>
                ))}
            </div>
        </>
    );
};
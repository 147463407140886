import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 120px;
    width: 100%;
    background: #F4F4F4;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
    }
`;
export const StyledContainer = styled("div")`
    width: 100%;
    max-width: 720px;
`;
export const StyledTitle = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
`;
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
        background: rgba(0, 0, 0, 0.06)
    }
`;
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";
import { AddIcon } from "@/icons/v3/add.icon";

export const StyledSectionHeader = styled(SectionHeader)`
    padding: 16px 16px 16px 24px;
`
export const StyledList = styled('div')`
    padding: 0px 24px 24px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
export const StyledAddIcon = styled(AddIcon)`
    & path {
        fill: #202020;
    }
`
export const StyledEmptyCardWrapper = styled('div')`
    padding: 0px 24px 24px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
import { FC, Fragment } from "react";
import { SpaceCategoryProps } from "./SpaceCategory.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { generateTitle } from "./SpaceCategory.utils";
import { StyledListItemButton, StyledListItemIcon, StyledListItemText } from "./SpaceCategory.styled";

export const SpaceCategory: FC<SpaceCategoryProps> = ({
    category,
}) => {
    const router = useRouter();
    let item = <StyledListItemButton
        selected={router.name==='publicSpaceCategory' && router.params.category === category}
        onClick={() => {
            router.push({
                name: 'publicSpaceCategory',
                params: {
                    code: router.params.code as string,
                    category: category
                }
            })
        }}
    >
        <StyledListItemIcon>
            <img src="/icons/icon-folder.svg" alt="" />
        </StyledListItemIcon>
        <StyledListItemText primary={generateTitle(category)} />
    </StyledListItemButton>
    return <Fragment>{item}</Fragment>;
};
import { FC } from "react";
import { StyledActions, StyledWrapper } from "./DeleteData.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";
import { StyledSectionHeader } from "../../ManageIntegrationsContent.styled";
import { IntegrationsEraseDataFetch } from "@/axios/SenseFacade/Integrations/Integrations.api";
import { DeleteDataProps } from "./DeleteData.type";

export const DeleteData: FC<DeleteDataProps> = ({
  isLoading, setIsLoading
}) => {
    const { component, confirm } = useConfirm({
        title: 'Delete integrations data',
        content: <>
          <StyledSectionHeader title="Are you sure you want to delete all data from the integrations?" />
          <div>
            All resources and updates from all integrations will be deleted for all users in your workspace. This action can not be restored.
          </div>
        </>,
        submitButtonProps: {
          children: 'Delete',
          variant: 'error'
        },
        cancelButtonProps: {
          children: 'Cancel',
          variant: 'primary'
        }
      });
    const deleteDataFetch = async () => {
      setIsLoading(true);
      await IntegrationsEraseDataFetch();
      setIsLoading(false);
      window.location.href = `${window.location.origin}/for-you`
    };
    const deleteData = () => {
        confirm((result) => {
            if (result) {
              deleteDataFetch()
            }
        });
    };
    return (
        <>
            <StyledWrapper>
                All resources and updates from all integrations will be deleted for all users in your workspace
            </StyledWrapper>
            <StyledActions>
                <Button onClick={deleteData} variant="error">Delete integrations data</Button>
            </StyledActions>
            {component}
        </>
    );
};
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
`;
export const StyledImg = styled('img')`
    width: 64px;
    height: 64px;
`;
export const StyledMaskedImg = styled('div')`
    width: 64px;
    height: 64px;
    display: block;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 6px;
`;
export const StyledEmojiIcon = styled('div')`
    width: 64px;
    height: 64px;
    font-size: 64px;
    letter-spacing: 0;
    line-height: 1;
    margin-left: -22px;
`
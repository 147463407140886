import { FC, useEffect, useMemo, useState } from "react";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { EditSpaceForm } from "@/components/v3/Common/EditSpaceForm/EditSpaceForm.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledCancelButton, StyledDialogLayout } from "./CreateSpaceModal.styled";
import { SpaceTypeType } from "@/components/v3/Common/EditSpaceForm/components/SpaceType/SpaceType.type";
import { SpaceTagType } from "@/components/v3/Common/EditSpaceForm/components/Tags/components/Tag/Tag.type";
import { CreateSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { ToastService } from "@/service/ToastService";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { CreateSpaceAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/CreateSpace/CreateSpace.type";
import { APPS, useChannels } from "@/providers/v3/Channels/Channels.provider";
import { CreateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { defaultEmoji } from "@/const/emoji.const";
import { UpdateFetch } from "@/axios/OrganizationService/Users/Users.api";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { ErrorsType } from "../../Common/EditSpaceForm/EditSpaceForm.type";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";


export const CreateSpaceModal: FC = () => {
    const [errors, setErrors] = useState<ErrorsType>({});
    const [name, setName] = useState<string>('');
    const [emoji, setEmoji] = useState<string | null>(defaultEmoji);
    const [spaceType, setSpaceType] = useState<SpaceTypeType>('Public');
    const [tags, setTags] = useState<SpaceTagType[]>([]);
    const [users, setUsers] = useState<string[]>([]);
    const [channels, setChannels] = useState<string[]>([]);
    const { user, loadUser } = useMe();
    const { value, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'create-space' });
    const router = useRouter();
    const onChange = (value: boolean) => {
        if (value) {
            onChangeIsOpened(value);
        } else {
            onChangeIsOpened(value, {
                spaceApp: undefined
            });
        }
    };
    const { appChannels } = useChannels();
    const trackEvent = useAmplitude();
    const { spaces, loadSpaces } = useSpaces();
    useEffect(() => {
        if (!value) {
            setName('');
            setEmoji(defaultEmoji);
            setSpaceType('Public');
            setTags([]);
            setUsers([]);
            setChannels([]);
            setErrors({});
        }
    }, [value])
    const createSpace = async (): Promise<string | null> => {
        return await CreateSpaceFetch(spaceType==='Private' && !!user ? {
            name: name,
            nodeType: 'SPACE',
            channelIds: channels,
            userIds: users,
            private: true,
            creatorId: user.id,
            emoji
        } : {
            name: name,
            nodeType: 'SPACE',
            channelIds: channels,
            userIds: users,
            private: false,
            emoji
        }).then(async (res: CreateSpaceAxiosResponseSuccessType) => {
            trackEvent(EventTypes.SPACE_CREATED);
            channels?.forEach(id => {
                APPS.forEach(app => {
                    if (appChannels[app]?.map(el => el.id).includes(id)) {
                        trackEvent(EventTypes.APP_WAS_CONNECTED_TO_SPACE, { application: app })
                    }
                })
            })
            ToastService.showToast('success', 'Space successfully created');
            !!user?.id && await UpdateFetch(user.id, { createdSpace: true });
            await loadUser();
            return res.data.id;
        }).catch((error) => {
            ToastService.showToast(
                'error',
                error?.response?.data?.errorMessage ?? 'Some error occurred while creating space'
            );
            return null;
        });
    };
    const createTag = async (spaceId: string, tag: SpaceTagType) => {
        await CreateSpaceTagsFetch(spaceId, {
            name: tag.name,
            emoji: tag.emoji
        }).catch((err) => {
            ToastService.showToast('error', 'Some error occurred');
        });
    }
    const createTags = async (spaceId: string) => {
        for (let i = 0; i < tags.length; i++) {
            const tag = tags[i];
            await createTag(spaceId, tag);
        }
    };
    const validate = (name: string) => {
        let tempErrors: ErrorsType = {};
        if (!name) tempErrors.name = "Name is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    const manualSpaces = useMemo(() => {
        return spaces.filter(space => !space.sampleData && space.application === 'Sense')
    }, [spaces]);
    const saveHandler = async () => {
        if (manualSpaces.length >= 3 && user?.paymentPlan.name==='INDIVIDUAL') {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: [router.query.dialog as string, 'payment'],
                    plan: 'PROFESSIONAL'
                }
            })
        } else {
            if (validate(name)) {
                const sapceId = await createSpace();
                if (sapceId) {
                    await createTags(sapceId);
                    onChange(false);
                    router.push({
                        name: 'space',
                        params: {
                            spaceId: sapceId
                        }
                    });
                    localStorage.setItem('selectedSpace', 'All Spaces');
                    loadSpaces()
                }
            }
        }
    };
    return <Dialog value={value} onChange={onChange}>
        <StyledDialogLayout
            title="Create space"
            actions={<>
                <StyledCancelButton size="large" variant="error" onClick={() => onChange(false)}>
                    Cancel
                </StyledCancelButton>
                <Button size="large" onClick={saveHandler}>
                    Create space
                </Button>
            </>}
        >
            <EditSpaceForm
                name={name} setName={setName}
                emoji={emoji} setEmoji={setEmoji}
                spaceType={spaceType} setSpaceType={setSpaceType}
                tags={tags} setTags={setTags}
                users={users} setUsers={setUsers}
                channels={channels} setChannels={setChannels}
                errors={errors}
                setErrors={setErrors}
                isNativeSpace={true}
            />
        </StyledDialogLayout>
    </Dialog>
};
import styled from "styled-components";
import { CopyLinkIcon } from "@/icons/v3/copy-link.icon";
import { OpenLinkIcon } from "@/icons/v3/open-link.icon";
import { CommentIcon } from "@/icons/v3/comment.icon";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
`;
export const StyledCopyLinkIcon = styled(CopyLinkIcon)`
    width: 16px;
    height: 16px;
`;
export const StyledOpenLinkIcon = styled(OpenLinkIcon)`
    width: 16px;
    height: 16px;
`;
export const StyledCommentIcon = styled(CommentIcon)`
    & path {
        fill: #636363;
    }
`;
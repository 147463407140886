import { FC, useEffect, useMemo, useState } from "react";
import { EditPeopleDialogProps } from "./EditPeopleDialog.type";
import { SelectUsersDialog } from "@/components/v3/Modals/SelectUsersDialog/SelectUsersDialog.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { ToastService } from "@/service/ToastService";
import { UpdateSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";

export const EditPeopleDialog: FC<EditPeopleDialogProps> = ({
    value,
    onChange,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { space, update } = useSpacePageCommon();
    const { user, users: listOfUsers, loadUsers } = useMe();
    const filteredListOfUsers = useMemo(() => {
        return listOfUsers.filter((el) => el.id !== user?.id)
    }, [listOfUsers, user]);
    const saveHandler = async (users: string[]) => {
        if (!space) {
            return;
        }
        setIsLoading(true);
        await UpdateSpaceFetch(space.id, {
            ...space,
            emoji: space.emoji || null,
            userIds: users,
        }).then(() => {
            ToastService.showToast('success', 'Space has been changed!');
            update();
        }).catch((err) => {
            ToastService.showToast('error', 'Some error occurred');
        });
        setIsLoading(false);
        onChange(false);
    };
    const updateUsers = async () => {
        setIsLoading(true);
        await loadUsers();
        setIsLoading(false);
    }
    useEffect(() => {
        if (value) {
            updateUsers();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    return <SelectUsersDialog
        title="Manage users"
        isOpened={value}
        setIsOpened={onChange}
        users={space?.userIds || []}
        setUsers={saveHandler}
        listOfUsers={filteredListOfUsers}
        isLoading={isLoading}
    />
};
import { useRouter } from "@/providers/Router/Router.provider";
import { FC, useEffect, useMemo, useState } from "react";
import { Dialog } from "../../Other/Dialog/Dialog.component";
import { StyledDialogLayout } from "../CreateSpaceModal/CreateSpaceModal.styled";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { Button } from "../../Fields/Button/Button.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from "@/service/helpers";
import { CheckoutSession } from 'components/Payments';
import { PlanValueType, PeriodValueType } from "./UpgradeToTeamPlanModal.type";
import { OptionType } from "../../Fields/Select/components/Option/Option.type";
import { StyledFeatureBox, StyledFeatureLabel, StyledFeatureValue, StyledPaymentPeriodSelect } from './UpgradeToTeamPlanModal.styled';
import { Box } from "@mui/material";
import { Select } from "../../Fields/Select/Select.component";
import { Divider } from "../../Other/Divider/Divider.component";
import { FreePlanTip } from "./components/FreePlanTip/FreePlanTip.component";
import { ToastService } from "@/service/ToastService";

export const UpgradeToTeamPlanModal: FC = () => {
    const router = useRouter();
    const { value: valueIsOpened, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'payment' });
    const value = valueIsOpened && !router.query.spaceApp;
    const onChangeIsOpenedHaandler = (value: boolean) => {
        onChangeIsOpened(value, {
            invite: undefined,
            plan: undefined,
        });
    };
    const { user, invitedUsers, confirmedInvitedUsers, loadInvitedUsers } = useMe();
    const userId = user?.id;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const [plan, setPlan] = useState<PlanValueType>('TEAM');
    const [paymentInterval, setPaymentInterval] = useState<PeriodValueType>('MONTHLY');
    useEffect(() => {
        if (router.query.plan === 'PROFESSIONAL') {
            setPlan('PROFESSIONAL');
        } else {
            setPlan('TEAM');
        }
    }, [router.query.plan]);
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    useEffect(() => {
        if (value) {
            loadInvitedUsers();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const getRedirectLinkHandlerClick = async () => {
        setIsLoading(true);
        const isOnboarding = router.type.includes('onboarding')
        const isCreateSpace = Array.isArray(router.query.dialog) ? router.query.dialog.includes('create-space') : router.query.dialog === 'create-space';
        const isEditSpace = Array.isArray(router.query.dialog) ? router.query.dialog.includes('edit-space') : router.query.dialog === 'edit-space';
        let inviteParams = isOnboarding ? `?invite=${router.query.invite}` : `?dialog=manage-users&invite=${router.query.invite}`;
        if (isCreateSpace) {
            inviteParams += `&isCreateSpace=true`
        }
        if (isEditSpace) {
            inviteParams += `&isEditSpace=true`
        }
        let frontendUrlParams = !!router.query.invite ? inviteParams : '';      
        const frontendUrl = `${window.location.origin}${window.location.pathname}${frontendUrlParams}`;
        await HttpClient.get<CheckoutSession>(`${envUrl}/organization-service/v1/payment?userId=${userId}&paymentInterval=${paymentInterval}&frontendUrl=${encodeURIComponent(frontendUrl)}&paymentPlanName=${plan}`)
        .then(response => response.data.checkoutSessionUrl)
        .then(data => {
            window.location.href = data
        });
        setIsLoading(false);
    }


    const handleChange = (newVal: unknown) => {
        setPaymentInterval(newVal as PeriodValueType);
    };
    const handleChangePlan = (newVal: PlanValueType) => {
        setPlan(newVal);
    };

    const periodOptions: OptionType<PeriodValueType>[] = [
        { value: 'YEARLY', label: 'Yearly' },
        { value: 'MONTHLY', label: 'Monthly' },
    ]
    const planOptions: OptionType<PlanValueType>[] = [
        { value: 'TEAM', label: 'Team' },
        { value: 'PROFESSIONAL', label: 'Professional' },
    ]
    const pricePerSeat = useMemo(() => {
        if (plan === 'TEAM') {
            if (paymentInterval === 'YEARLY') {
                return 13
            }
            return 15
        } else if (plan === 'PROFESSIONAL') {
            if (paymentInterval === 'YEARLY') {
                return 7
            }
            return 9
        }
        return 1;
    }, [paymentInterval, plan]);
    const priceFactor = useMemo(() => {
        if (paymentInterval === 'YEARLY') {
            return 12
        }
        return 1
    }, [paymentInterval]);
    
    const usersToBeInvitedJsx = plan==='PROFESSIONAL' ? 'No' : 'Unlimited';
    const availableFeaturesJsx = plan==='PROFESSIONAL' ? <>
        <div>All except Sharing,</div>
        <div>Marking as important for someone else,</div>
        <div>Mentioning other users</div>
    </> : 'All';
    return <Dialog size="large" value={value} onChange={onChangeIsOpenedHaandler}>
        <StyledDialogLayout
            isLoading={isLoading}
            title="Upgrade subscription plan"
            onCloseClick={() => onChangeIsOpenedHaandler(false)}
            actions={<>
                <Button size="large" onClick={getRedirectLinkHandlerClick}>
                    Proceed to payment
                </Button>
            </>}
        >
            <FreePlanTip />
            <Box style={{ width: '100%' }}>
                <Select
                    label="Subscription plan"
                    options={planOptions}
                    value={plan}
                    onChange={handleChangePlan}
                    fullWidth
                    size="large"
                />
                <StyledPaymentPeriodSelect
                    label="Payment period"
                    options={periodOptions}
                    value={paymentInterval}
                    onChange={handleChange}
                    fullWidth
                    size="large"
                />
                <StyledFeatureBox style={{ marginTop: '28px' }}>
                    <StyledFeatureLabel>Seat price (per month)</StyledFeatureLabel>
                    <StyledFeatureValue>${pricePerSeat}</StyledFeatureValue>
                </StyledFeatureBox>
                <StyledFeatureBox style={{ marginTop: '16px' }}>
                    <StyledFeatureLabel>Seats</StyledFeatureLabel>
                    <StyledFeatureValue>{amountOfPaidUsers}</StyledFeatureValue>
                </StyledFeatureBox>
                <StyledFeatureBox style={{ marginTop: '16px' }}>
                    <StyledFeatureLabel>Total</StyledFeatureLabel>
                    <StyledFeatureValue>${amountOfPaidUsers * pricePerSeat * priceFactor}</StyledFeatureValue>
                </StyledFeatureBox>
                <Divider />
                <Box style={{ color: '#636363', marginBottom: '12px', marginTop: '28px' }}>Plan details</Box>
                    <StyledFeatureBox>
                        <StyledFeatureLabel>Included spaces</StyledFeatureLabel>
                        <StyledFeatureValue>Unlimited</StyledFeatureValue>
                    </StyledFeatureBox>
                    <StyledFeatureBox>
                        <StyledFeatureLabel>History limit</StyledFeatureLabel>
                        <StyledFeatureValue>Unlimited</StyledFeatureValue>
                    </StyledFeatureBox>
                    <StyledFeatureBox>
                        <StyledFeatureLabel>Users to be invited</StyledFeatureLabel>
                        <StyledFeatureValue>{usersToBeInvitedJsx}</StyledFeatureValue>
                    </StyledFeatureBox>
                    <StyledFeatureBox>
                        <StyledFeatureLabel>Available features</StyledFeatureLabel>
                        <StyledFeatureValue>{availableFeaturesJsx}</StyledFeatureValue>
                    </StyledFeatureBox>
                    <StyledFeatureBox style={{ marginBottom: '56px' }}>
                        <StyledFeatureLabel>Included integration</StyledFeatureLabel>
                        <StyledFeatureValue>Unlimited</StyledFeatureValue>
                    </StyledFeatureBox>
                </Box>
        </StyledDialogLayout>
    </Dialog>
}
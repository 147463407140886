import { ListItem } from "../../List/ListItem/ListItem.component";
import { StyledCaption, StyledContainer, StyledRadio, StyledTitle } from "./RadioItem.styled";
import { RadioItemProps } from "./RadioItem.type";

export const RadioItem = <T,>({ val, value, onChange, label, caption, className }: RadioItemProps<T>) => {
    const isActive = val === value;
    return (
        <StyledContainer className={className} onClick={() => onChange(val)}>
            <StyledRadio value={val} checked={isActive} />
            <ListItem title={label}  caption={caption} />
        </StyledContainer>
    )
};
import React, { FC } from 'react';
import { GoogleDriveIcon } from 'icons/googleDriveIcon';
import { GoogleDocsIcon } from 'icons/googleDocsIcon';
import { SharePointIcon } from 'icons/sharepointIcon';
import { SenseIcon } from 'icons/senseIcon';
import { TeamsIcon } from 'icons/TeamsIcon';
import { SlackIcon } from 'icons/SlackIcon';
import { JiraIcon } from 'icons/JiraIcon';
import { ConfluenceIcon } from 'icons/confluenceIcon';
import { DropBoxIcon } from 'icons/dropBoxIcon';
import { OutlookIcon } from 'icons/outlook';
import {GoogleSheetIcon} from "../../icons/googleSheetIcon";
import {GoogleSlideIcon} from "../../icons/googleSlideIcon";
import {NotionIcon} from "../../icons/NotionIcon";
import {GmailIcon} from "../../icons/Gmail.icon";
import {GoogleCalendarIcon} from "../../icons/GoogleCalendar.icon";
import {ApplicationIconProps} from "./ApplicationIcon.type";

export const ApplicationIcon: FC<ApplicationIconProps> = ({ application, favicon, categories, className }) => {
  if (categories?.some((category) => category === 'file')) {
    return <img src="/icons/icon_file.svg" className={className} alt='' />;
  }
  if (favicon && application !== 'Google Slides') {
    return <img src={favicon} className={className} alt='' />;
  }
  switch (application) {
    case 'Google Docs': {
      return <GoogleDocsIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Google Drive': {
      return <GoogleDriveIcon className={className} viewBox="0 0 32 32" />;
    }
    case 'SharePoint': {
      return <SharePointIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'MicrosoftTeams' || 'Microsoft Teams': {
      return <TeamsIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Microsoft Teams': {
      return <TeamsIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Slack': {
      return <SlackIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Outlook': {
      return <OutlookIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Jira': {
      return <JiraIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Confluence': {
      return <ConfluenceIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Dropbox': {
      return <DropBoxIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Google Slides': {
      return <GoogleSlideIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Google Sheets': {
      return <GoogleSheetIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Notion': {
      return <NotionIcon className={className} viewBox="0 0 24 24" />;
    }
    case 'Gmail': {
      return <GmailIcon className={className} viewBox="0 0 24 24" />
    }
    case 'Google Calendar': {
      return <GoogleCalendarIcon className={className} viewBox="0 0 24 24" />
    }
    case 'linkedin.com': {
      return <img className={className} src='/icons/icon_linkedin.png' alt='' />
    }
    case 'Asana': {
      return <img className={className} src='/icons/icon-asana.png' alt='' />
    }
    case 'Github': {
      return <img className={className} src='/icons/icon-github.png' alt='' />
    }
    case 'ClickUp': {
      return <img className={className} src='/icons/icon-click_up.png' alt='' />
    }
    case 'Trello': {
      return <img className={className} src='/icons/icon-trello.png' alt='' />
    }
    case 'Figma': {
      return <img className={className} src='/icons/icon-figma.png' alt='' />
    }
    default:
      return <SenseIcon className={className} viewBox="0 0 40 40" />;
  }
};

import { useMe } from "@/providers/MeProvider/Me.provider";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import moment from "moment";
import { FC, useMemo, useState } from "react";
import { StyledButton, StyledSpaceWrapper, StyledMeeting, StyledMeetings, StyledMeetingsContainer, StyledNoMeetings } from "./Sidebar.styled";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon } from "@/icons/v3/calendar.icon";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { EmptyState } from "./components/EmptyState/EmptyState.component";
import { Popover } from "@mui/material";
import { MeetingsTimeline } from "./components/MeetingsTimeline/MeetingsTimeline.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { IntegrationType } from "@/axios/SenseFacade/Users/Types/Get/Get.type";

export const Sidebar: FC = () => {
    const {
        dailyCalendarEvents,
        notDailyCalendarEvents,
        isLoadingGoogleCalendarEvents,
        selectedDate,
        setSelectedDate
    } = useForYouPage();
    
    const { user } = useMe();
    const isVisibleIntegrationProgress = useMemo(() => {
        if (!user?.id) {
          return false;
        }
        const calendarIntegrations: IntegrationType[] = [user.integrations?.google_calendar, user.integrations?.outlook].filter((el => !!el)) as IntegrationType[]
        return Object.values(calendarIntegrations).some((integration: IntegrationType) => {
          if (integration.accounts && integration.accounts.length) {
            return integration.accounts.some(account => account.inProcess) || integration.inProcess;
          }
          return integration.inProcess;
        });
      }, [user]);
    const isCalendarConnected = user && user.integrations && (user.integrations.google_calendar.active || user.integrations.outlook.active);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const buttonText = useMemo((): string => {
        const today = moment();
        const selected = moment(selectedDate);
        
        if (selected.isSame(today, 'day')) {
            return "Today";
        } else if (selected.isSame(today.clone().add(1, 'day'), 'day')) {
            return "Tomorrow";
        } else if (selected.isSame(today.clone().subtract(1, 'day'), 'day')) {
            return "Yesterday";
        } else {
            return selected.format("MMMM D, YYYY");
        }
    }, [selectedDate]);
    const isEmpty = dailyCalendarEvents.length === 0 && notDailyCalendarEvents.length === 0;
    return (
        <>
            <StyledSpaceWrapper>
                <StyledButton disabled={isEmpty} center variant="secondary" onClick={handleButtonClick} icon={<CalendarIcon />}>{buttonText}</StyledButton>
            </StyledSpaceWrapper>
            <StyledSpaceWrapper>
                <SectionHeader title='Meetings' />
            </StyledSpaceWrapper>
            <LoaderLayout isLoading={isLoadingGoogleCalendarEvents || isVisibleIntegrationProgress} transparent={!isEmpty} >
                <EmptyStateLayoyt emptyNode={<EmptyState />} value={!isCalendarConnected}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <DateCalendar
                                value={dayjs(selectedDate)}
                                onChange={(newValue) => {
                                    newValue && setSelectedDate(newValue.toDate());
                                    handleClose();
                                }}
                            />
                        </Popover>
                    </LocalizationProvider>
                    {!!dailyCalendarEvents.length && <StyledMeetings>
                        {dailyCalendarEvents.map(calendarEvent => (<>
                            <StyledMeeting>{calendarEvent.title} — All day</StyledMeeting>
                        </>))}
                    </StyledMeetings>}
                    {isEmpty && <StyledNoMeetings>No meetings on this day</StyledNoMeetings>}
                    <StyledMeetingsContainer>
                        {notDailyCalendarEvents.length > 0 && <MeetingsTimeline meetings={notDailyCalendarEvents} />}
                    </StyledMeetingsContainer>
                </EmptyStateLayoyt>
            </LoaderLayout>
        </>
    )
};
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    position: relative;
`;
export const StyledGradient = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, #FFF 100%);
`;
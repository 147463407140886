import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  ignore?: (RefObject<T> | string)[],
): void {
  useEffect(() => {
    const listener = (event: Event): void => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      if (ignore) {
        for (let j = 0; j < ignore.length; j++) {
          const element = ignore[j];
          if (typeof element === 'string') {
            const ignoreEls = document.querySelectorAll(element);
            for (let i = 0; i < ignoreEls.length; i++) {
              if (ignoreEls[i].contains(event?.target as Node)) {
                return;
              }
            }
          } else {
            const ignoreEl = element?.current;
            if (ignoreEl?.contains(event?.target as Node)) {
              return;
            }
          }
        }
      }

      handler(event);
    };
    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, handler, ignore]);
}
export default useOnClickOutside;

import { FC } from "react";
import { GradientLayoutProps } from "./GradientLayout.type";
import { StyledGradient, StyledWrapper } from "./GradientLayout.styled";

export const GradientLayout: FC<GradientLayoutProps> = ({
    children,
    className
}) => {
    return <StyledWrapper className={className}>
        {children}
        <StyledGradient />
    </StyledWrapper>;
}
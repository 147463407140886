import { UserAvatar } from "../../../../Other/UserAvatar/UserAvatar.component";
import { ImportnatIcon } from "@/icons/important.icon";
import { styled } from "@mui/material";

export const StyledNodeImportant = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hasImportantUsers'
})<{hasImportantUsers: boolean}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:  ${({hasImportantUsers}) => hasImportantUsers ? '2px 2px 2px 8px;' : '2px 8px 2px 8px;'}
    border-radius: 999px;
    background: rgba(255, 214, 0, 0.24);
`;
export const StyledText = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
`;
export const StyledNodeImportantIcon = styled(ImportnatIcon)`
    width: 16px;
    height: 16px;
`;
export const StyledNodeImportantPeople = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 8px;
`;
export const StyledNodeImportantPerson = styled(UserAvatar)`
    margin-left: -4px;
`;
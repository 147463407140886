import { FC } from "react";
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon: FC<SvgIconProps> = (props) => {
    return <SvgIcon xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
        <g clipPath="url(#clip0_10148_2046)">
        <path d="M13.7066 13.707C13.8941 13.5195 13.9994 13.2652 13.9994 13C13.9994 12.7348 13.8941 12.4805 13.7066 12.293L11.0356 9.62199C10.9943 9.58165 10.9681 9.52828 10.9616 9.4709C10.955 9.41352 10.9685 9.35563 10.9996 9.30699C11.8199 8.06546 12.15 6.56359 11.9259 5.09252C11.7018 3.62146 10.9396 2.28597 9.78685 1.34496C8.63413 0.403953 7.17309 -0.075557 5.68694 -0.000613396C4.2008 0.0743302 2.79541 0.698389 1.7432 1.75059C0.691004 2.80279 0.066945 4.20818 -0.00799865 5.69433C-0.0829423 7.18048 0.396568 8.64152 1.33758 9.79423C2.27859 10.9469 3.61407 11.7092 5.08514 11.9333C6.55621 12.1574 8.05808 11.8273 9.29961 11.007C9.3478 10.9753 9.40543 10.9612 9.46279 10.9671C9.52016 10.9729 9.57377 10.9983 9.61461 11.039L12.2856 13.71C12.3788 13.8037 12.4896 13.878 12.6117 13.9286C12.7337 13.9792 12.8646 14.0051 12.9967 14.0048C13.1289 14.0045 13.2596 13.9781 13.3815 13.9269C13.5033 13.8758 13.6138 13.8011 13.7066 13.707ZM1.99961 6C1.99961 5.20887 2.23421 4.43551 2.67373 3.77771C3.11326 3.11992 3.73797 2.60723 4.46888 2.30448C5.19978 2.00173 6.00405 1.92251 6.77997 2.07685C7.5559 2.2312 8.26863 2.61216 8.82804 3.17157C9.38745 3.73098 9.76841 4.44371 9.92275 5.21963C10.0771 5.99556 9.99788 6.79982 9.69513 7.53073C9.39238 8.26163 8.87969 8.88635 8.22189 9.32587C7.56409 9.7654 6.79074 9.99999 5.99961 9.99999C4.93875 9.99999 3.92133 9.57857 3.17118 8.82842C2.42104 8.07828 1.99961 7.06086 1.99961 6Z" fill="rgba(32, 32, 32, 0.5)"/>
        </g>
        <defs>
        <clipPath id="clip0_10148_2046">
            <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
    </SvgIcon>
};
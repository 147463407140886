import { FC, useState } from "react";
import { StyledCommentIcon, StyledCopyLinkIcon, StyledOpenLinkIcon, StyledWrapper } from "./Actions.styled";
import { ActionsProps } from "./Actions.type";
import useCopyToClipboard from '@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook';
import { NodeMenu } from "./components/NodeMenu/NodeMenu.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { AddCommentDialog } from "./components/NodeMenu/components/AddCommentDialog/AddCommentDialog.component";
import { NodeCommentType } from "@/types/NodeComment/NodeComment.type";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { NodeTypeType } from "@/types/Node/Node.type";
import { People } from "../../../People/People.component";

export const Actions: FC<ActionsProps> = (props) => {
    const { isShared } = useIsShared();
    const { node, people, size = 'large', onNewComment, onUpdate, hadeCopyIcon, hideMenuIcon, actions } = props;
    const [isOpenedAddCommentDialog, setIsOpenedAddCommentDialog] = useState<boolean>(false);
    const { copyToClipboard } = useCopyToClipboard();
    const isMobile = useRenederBelow('tablet');
    const trackEvent = useAmplitude();
    const copyHandle = () => {
        copyToClipboard(node.originalURI, 'Link copied to clipboard');
    };
    const openHandle = () => {
        trackEvent(EventTypes.ORIGIN_NODE_LINK_OPENED);
        window.open(node.originalURI, '_blank');
    };
    const onNewCommentHandler = (comment: NodeCommentType) => {
        onNewComment && onNewComment(comment);
        !onNewComment && onUpdate && onUpdate();
    };
    const isNote = node?.nodeType === NodeTypeType.NOTE;
    return <StyledWrapper className="ignore--node-actions">
        {(size==='large' || size==='medium') && !isMobile && <People people={people} />}
        {!hideMenuIcon && <NodeMenu showCopy={size==='small'} showAddComment={size==='small'} {...props} onNewComment={onNewCommentHandler} />}
        {size==='large' && !isMobile && !hideMenuIcon && !isShared && <Button variant="tertiary" icon={<StyledCommentIcon />} onClick={() => setIsOpenedAddCommentDialog(true)} />}
        {size==='large' && !isMobile && !hadeCopyIcon && !isNote && <Button variant="tertiary" icon={<StyledCopyLinkIcon />} onClick={copyHandle} />}
        {!isNote && size!=='medium' && <Button variant="tertiary" icon={<StyledOpenLinkIcon />} onClick={openHandle} />}
        {actions}
        <AddCommentDialog nodeId={node.id} value={isOpenedAddCommentDialog} onChange={setIsOpenedAddCommentDialog} onNewComment={onNewCommentHandler} />
    </StyledWrapper>
};
import { Box, Button, Typography, styled } from "@mui/material";
import { zIndex } from 'Layouts/theme';

export const StyledToggleBtnWrapper = styled('div')`
    padding: 16px;
`;
export const StyledToggleBtn = styled('div')`
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    &:hover {
        cursor: pointer;
    }
`;
export const StyledDropDown = styled(Box)`
    width: 288px;
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
    z-index: ${zIndex.spaceMenuDropdown};
`;
export const StyledMySpaces = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid lightgray;
    cursor: pointer;
`;
export const StyledTeamSpaces = styled(StyledMySpaces)`
    border-top: none;
    border-bottom: 1px solid lightgray;
`;
export const StyledArrow = styled('img')<{active: boolean}>`
    transition: 250ms transform;
    transform-origin: center;
    transform: ${({active}) => active ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
export const StyledConnect = styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    color: #636363;
    padding: 8px 16px;
`;
export const StyledConnectBtn = styled(Button)`
    margin: 8px;
`;
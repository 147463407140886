import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { RelationActionType } from "@/types/Relations/Relations.type";

export const listOfActivityOptions: OptionType<string>[] = [
    {
        label: 'Mentioned you',
        value: 'MENTIONED_YOU',
    },
    {
        label: 'Assigned to you',
        value: 'ASSIGNED_TO_YOU',
    },
    {
        label: 'Task status changed',
        value: 'TASK_STATUS_CHANGED',
    },
    {
        label: 'Commented',
        value: 'COMMENTED',
    },
    {
        label: 'Created',
        value: 'CREATED',
    },
    {
        label: 'Edited',
        value: 'EDITED',
    },
    {
        label: 'Shared',
        value: 'SHARED',
    },
    {
        label: 'Sent to you',
        value: 'SENT_TO_YOU',
    },
    {
        label: 'Added manually',
        value: 'ADDED_MANUALLY',
    },
];

export const getActivityValue = (selectedRelations: RelationActionType[]) => {
    const val: string[] = [];
    if (selectedRelations.includes(RelationActionType.MENTIONS) && selectedRelations.includes(RelationActionType.CC) && selectedRelations.includes(RelationActionType.BCC)) {
        val.push('MENTIONED_YOU');
    }
    if (selectedRelations.includes(RelationActionType.ASSIGNEE)) {
        val.push('ASSIGNED_TO_YOU');
    }
    if (selectedRelations.includes(RelationActionType.UPDATED)) {
        val.push('TASK_STATUS_CHANGED');
    }
    if (selectedRelations.includes(RelationActionType.COMMENTED_BY) && selectedRelations.includes(RelationActionType.COMMENTER) && selectedRelations.includes(RelationActionType.POSTED_BY)) {
        val.push('COMMENTED');
    }
    if (selectedRelations.includes(RelationActionType.CREATED_BY) && selectedRelations.includes(RelationActionType.CREATOR)) {
        val.push('CREATED');
    }
    if (selectedRelations.includes(RelationActionType.EDITED_BY)) {
        val.push('EDITED');
    }
    if (selectedRelations.includes(RelationActionType.SHARED_TO) && selectedRelations.includes(RelationActionType.SHARED_BY)) {
        val.push('SHARED');
    }
    if (selectedRelations.includes(RelationActionType.FROM)) {
        val.push('SENT_TO_YOU');
    }
    if (selectedRelations.includes(RelationActionType.ADDED_BY)) {
        val.push('ADDED_MANUALLY');
    }
    return val;
};
export const getActivityRelations = (val: string[]) => {
    const newVal: RelationActionType[] = [];
    if (val.includes('MENTIONED_YOU')) {
        newVal.push(RelationActionType.MENTIONS);
        newVal.push(RelationActionType.CC);
        newVal.push(RelationActionType.BCC);
    }
    if (val.includes('ASSIGNED_TO_YOU')) {
        newVal.push(RelationActionType.ASSIGNEE);
    }
    if (val.includes('TASK_STATUS_CHANGED')) {
        newVal.push(RelationActionType.UPDATED);
    }
    if (val.includes('COMMENTED')) {
        newVal.push(RelationActionType.COMMENTED_BY);
        newVal.push(RelationActionType.COMMENTER);
        newVal.push(RelationActionType.POSTED_BY);
    }
    if (val.includes('CREATED')) {
        newVal.push(RelationActionType.CREATED_BY);
        newVal.push(RelationActionType.CREATOR);
    }
    if (val.includes('EDITED')) {
        newVal.push(RelationActionType.EDITED_BY);
    }
    if (val.includes('SHARED')) {
        newVal.push(RelationActionType.SHARED_TO);
        newVal.push(RelationActionType.SHARED_BY);
    }
    if (val.includes('SENT_TO_YOU')) {
        newVal.push(RelationActionType.FROM);
    }
    if (val.includes('ADDED_MANUALLY')) {
        newVal.push(RelationActionType.ADDED_BY);
    }
    return newVal;
};
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddTagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" stroke="#202020" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g clipPath="url(#clip0_9717_45502)">
      <path d="M9 1.28516V16.7137" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.28564 9H16.7142" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_9717_45502">
      <rect width="18" height="18" fill="white"/>
      </clipPath>
      </defs>
    </SvgIcon>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { ShareSpaceDialogProps } from "./ShareSpaceDialog.type";
import { StyledActions, StyledCreateButton, StyledDescription, StyledLinkWrapper } from "./ShareSpaceDialog.styled";
import useCopyToClipboard from "@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook";
import { SharingSpaceGenerateFetch, SharingSpaceGetFetch, SharingSpaceRevokeFetch } from "@/axios/AiService/Sharing/Sharing.api";
import { SharingSpaceGenerateAxiosResponseSuccessType } from "@/axios/AiService/Sharing/Types/SpaceGenerate/SpaceGenerate.type";
import { SharingSpaceGetAxiosResponseSuccessType } from "@/axios/AiService/Sharing/Types/SpaceGet/SpaceGet.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Input } from "@/components/v3/Fields/Input/Input.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";
import { ToastService } from "@/service/ToastService";

export const ShareSpaceDialog = ({ isOpened, setIsOpened }: ShareSpaceDialogProps) => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { confirm, component } = useConfirm({
        title: 'Confirm revoke access',
        content: 'Everybody who received this sharing link will never be able to access this shared space anymore.',
        submitButtonProps: {
            children: 'Revoke',
        }
    });
    const [code, setCode] = useState<string>('');
    const { copyToClipboard } = useCopyToClipboard();
    const isMobile = useRenederBelow('tablet');
    const submitHandle = useCallback(async () => {
        setIsLoading(true);
        await SharingSpaceGenerateFetch({
            spaceId: router.params.spaceId as string,
        }).then((res: SharingSpaceGenerateAxiosResponseSuccessType) => {
            setCode(res.data.code);
            ToastService.showToast('success', 'Link has been created!');
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
        setIsLoading(false);
    }, [router.params.spaceId]);
    const loadCode = useCallback(async () => {
        setIsLoading(true);
        await SharingSpaceGetFetch({
            spaceId: router.params.spaceId as string,
        }).then((res: SharingSpaceGetAxiosResponseSuccessType) => {
            setCode(!!res.data.length ? res.data[0].code : '');
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
        setIsLoading(false);
    }, [router.params.spaceId]);
    const revokeHandle = useCallback(async () => {
        confirm(async (newVal) => {
            if (!code || !newVal) {
               return; 
            }
            setIsLoading(true);
            await SharingSpaceRevokeFetch({
                code: code,
            }).then((res: SharingSpaceGenerateAxiosResponseSuccessType) => {
                setCode('');
                ToastService.showToast('success', 'Link has been revoked!');
            }).catch(() => {
                ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
            });
            setIsLoading(false);
        });
    }, [code, confirm]);
    useEffect(() => {
        if (isOpened) {
            loadCode();
        }
    }, [isOpened, loadCode]);
    useEffect(() => {
        setCode('');
    }, [router.params.spaceId]);

    const link = useMemo(() => {
        if (!code) {
            return '';
        }
        return `${window.location.protocol}//${window.location.host}/public/space/${code}`;
    }, [code]);
    const copyHandle = () => {
        copyToClipboard(link, 'Link copied to clipboard');
    }
    const isShared = !!link;
    const buttonsJsx = <>
        {!isShared && <StyledCreateButton size="large" onClick={submitHandle}>Create sharing link</StyledCreateButton>}
        {!!isShared && <>
            <Button size="large" variant="tertiary" onClick={revokeHandle}>Revoke</Button>
            <Button size="large" onClick={copyHandle}>Copy link</Button>
        </>}
    </>
    return <Dialog
        value={isOpened}
        onChange={setIsOpened}
        size="small"
    >
        <DialogLayout
            title="Share space"
            onCloseClick={() => setIsOpened(false)}
            actions={isMobile ? buttonsJsx : undefined}
            isLoading={isLoading}
        >
            <StyledDescription>
                Anyone with the link will be able to browse everything within this space, except emails and discussions. They might not have access to the resources from your apps, if you didn’t grant them this access via your apps. 
            </StyledDescription>
            <StyledLinkWrapper>
                <Input
                    id="code"
                    type="code"
                    label="Sharing link"
                    value={link}
                    disabled={!link}
                />
                {!isMobile && <StyledActions>
                    {buttonsJsx}
                </StyledActions>}
            </StyledLinkWrapper>
        </DialogLayout>
        {component}
    </Dialog>
}
import { Select } from "@/components/v3/Fields/Select/Select.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 16px 32px 40px 32px;
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledFiltersWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    @media (max-width: ${BREAKPOINTS.mobileRotate}px) {
        margin-top: -6px;
        margin-bottom: 10px;
    }
`;
export const StyledFiltersContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`;
export const StyledRelationsFiltersWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
`;
export const StyledSelect = styled(Select)`
    margin-right: 12px;
    @media (max-width: ${BREAKPOINTS.mobileRotate}px) {
        margin-top: 6px;
        margin-bottom: 6px;
    }
`;
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { ScaledCloseIcon } from "@/icons/v3/close.icon";
import { StyledCloseButtonWrapper, StyledLineButtons } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/NodeNeighboursDrawer.styled";
import { StyledDrawerContent, StyledDrawerWrapper } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/components/DrawerContent/DrawerContent.styled";
import { Drawer } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { StyledAddIcon, StyledAddResourcesButton, StyledContentWrapper, StyledEditor, StyledFilledCard, StyledHeader, StyledResourcesWrapper } from "./CreateNoteDrawer.styled";
import { CreateNoteDrawerProps, ErrorsType, ModeType } from "./CreateNoteDrawer.type";
import { CreateNoteFetch, UpdateNoteFetch } from "@/axios/AiService/Notes/Notes.api";
import { useRouter } from "@/providers/Router/Router.provider";
import { CreateNoteAxiosResponseSuccessType } from "@/axios/AiService/Notes/Types/Create/Create.type";
import { GetSpaceTagsFetch, UpdateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { SpaceTagsAxiosResponseSuccessType } from "@/axios/AiService/SpaceTags/Types/Get/Get.type";
import { ToastService } from "@/service/ToastService";
import { AddNodeToSpaceFetch } from "@/axios/AiService/SpacesNodes/SpacesNodes.api";
import { GetNodeFetch } from "@/axios/AiService/Node/Node.api";
import { NodeAxiosResponseSuccessType } from "@/axios/AiService/Node/Types/Get/Get.type";
import { AddResourcesDialog } from "./components/AddResourcesDialog/AddResourcesDialog.component";
import { Node } from "./components/Node/Node.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { NodeType } from "@/components/v3/Common/AddResourcesForm/components/AddNodeItem/AddNodeItem.type";
import { GetNodeRelationsFetch } from "@/axios/AiService/NodeRelations/NodeRelations.api";
import { NodeRelationType, NodeRelationsAxiosResponseSuccessType } from "@/axios/AiService/NodeRelations/Types/Get/Get.type";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Get/Get.type";
import { SelectSpaceDialog } from "@/components/v3/Common/SelectSpaceForm/SelectSpaceDialog.component";
import { Input } from "@/components/v3/Fields/Input/Input.component";

export const CreateNoteDrawer: FC<CreateNoteDrawerProps> = ({
    onUpdate,
}) => {
    const [isOpenAddResources, setIsOpenAddResources] = useState<boolean>(false);
    const { value: isOpenedCreate, onChange: setIsOpenedCreate } = useIsModalOpened({ name: 'create-note' });
    const { value: isOpenedEdit, onChange: setIsOpenedEdit } = useIsModalOpened({ name: 'edit-note' });
    const isOpened = isOpenedCreate || isOpenedEdit;
    const mode: ModeType = isOpenedCreate ? 'create' : 'edit';
    const setIsOpened = isOpenedCreate ? setIsOpenedCreate : (val: boolean) => {
        setIsOpenedEdit(val, { note: undefined, tab: undefined, });
    };
    const isMobile = useRenederBelow(560);
    const [isOpenedSelectSpace, setIsOpenedSelectSpace] = useState<boolean>(false);
    const [content, setContent] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [resources, setResources] = useState<NodeType[]>([]);
    const [errors, setErrors] = useState<ErrorsType>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingNodes, setIsLoadingNodes] = useState<boolean>(false);
    const fullWidth = isMobile;
    const router = useRouter();
    const spaceId = router.params.spaceId as string;
    const tagId = router.params.tagId as string | undefined;
    const noteId = router.query.note as string | undefined;
    
    const validate = () => {
        let tempErrors: ErrorsType = {};
        if (!content) tempErrors.content = "Content is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    const addToSpaceTagHandler = async (spaceId: string, spaceTagId: string, nodeId: number) => {
        await GetSpaceTagsFetch(spaceId).then(async (res: SpaceTagsAxiosResponseSuccessType) => {
          const spaceTag = res.data.find((spaceTag) => spaceTag.id === spaceTagId);
          if (!spaceTag) {
            console.error('Tag not found', res.data, spaceTagId);
            return;
          }
          await UpdateSpaceTagsFetch(spaceId, spaceTagId, {
            ...spaceTag,
            emoji: spaceTag.emoji || null,
            nodeIds: [...spaceTag.nodeIds, nodeId],
          }).then(async () => {
            ToastService.showToast('success', '1 resource was added to the space!');
            onUpdate()
          }).catch(error => {
            ToastService.showToast('error', 'Some error occurred! Please try again later!');
          });
        })
        .catch(error => {
          ToastService.showToast('error', 'Some error occurred! Please try again later!');
        });
      }
    const addToSpaceHandler = async (spaceId: string, nodeId: number) => {
        await AddNodeToSpaceFetch({
          spaceId: spaceId,
          nodeIds: [nodeId],
        }).then(async () => {
          ToastService.showToast('success', '1 resource was added to the space!');
          onUpdate()
        })
        .catch(() => {
          ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
      }
    const createHandler = async (spaceIdProp?: string, tagIdProp?: string) => {
        if (validate() && (spaceId || spaceIdProp)) {
            setIsOpenedSelectSpace(false)
            setIsLoading(true);
            await CreateNoteFetch({
                nodeIds: resources.map(node => node.id),
                name: name,
                content,
            }).then(async (res: CreateNoteAxiosResponseSuccessType) => {
                const node = res.data
                if (!!tagId || !!tagIdProp) {
                    await addToSpaceTagHandler(spaceId || spaceIdProp!, (tagId || tagIdProp!), node.id);
                } else {
                    await addToSpaceHandler(spaceId || spaceIdProp!, node.id);
                }
                setIsOpened(false);
            }).catch(() => {
            });
            setIsLoading(false);
        } else {
            setIsOpenedSelectSpace(true)
        }
    };
    const saveHandler = async () => {
        if (validate() && noteId) {
            setIsLoading(true);
            await UpdateNoteFetch(Number(noteId), {
                content,
                nodeIds: resources.map(node => node.id),
            }).then(async () => {
                setIsOpened(false);
                onUpdate();
            }).catch(() => {
            });
            setIsLoading(false);
        }
    };
    const loadNodeRelations = useCallback(async () => {
        if (!noteId) return;
        setIsLoadingNodes(true)
        await GetNodeRelationsFetch({
            nodeId: Number(noteId),
        }).then(async (resRel: NodeRelationsAxiosResponseSuccessType) => {
            await GetNodesFetch({
                nodeIds: (resRel.data as NodeRelationType).relations.map(el => el.nodeId)
            }).then((res: NodesAxiosResponseSuccessType) => {
                setResources(res.data);
            });
        });
        setIsLoadingNodes(false)
    }, [noteId])
    const loadNote = useCallback(async () => {
        if (mode === 'edit' && noteId) {
            setIsLoading(true);
            await GetNodeFetch(Number(noteId)).then((res: NodeAxiosResponseSuccessType) => {
                setContent(res.data.content || '');
            });
            setIsLoading(false);
        }
    }, [mode, noteId]);
    useEffect(() => {
        loadNote();
    }, [loadNote]);
    useEffect(() => {
        loadNodeRelations();
    }, [loadNodeRelations]);
    useEffect(() => {
        if (!isOpened) {
            setName('');
            setContent('');
            setResources([]);
        }
    }, [isOpened]);

    const listOfNodesJsx = resources.map((node) => {
        return <Node key={node.id} node={node} onRemove={() => {
            setResources(resources.filter((resource) => resource.id !== node.id));
        }} />
    });
    return (
        <Drawer
            anchor="right"
            open={isOpened}
            onClose={() => setIsOpened(false)}
            style={{ pointerEvents: 'none', }}
            PaperProps={{
                style: {
                    boxShadow: '-8px 16px 40px 2px rgba(0, 0, 0, 0.24)',
                    pointerEvents: 'auto',
                    width: fullWidth ? '100%' : 'auto',
                }
            }}
            ModalProps={{
                hideBackdrop: true,
                disableScrollLock: true,
            }}
        >
            <StyledCloseButtonWrapper>
                <Button onClick={() => setIsOpened(false)} icon={<ScaledCloseIcon />} variant="tertiary" />
                <StyledLineButtons>
                    <Button onClick={() => mode === 'create' ? createHandler() : saveHandler()}>Save</Button>
                    {/* <Menu /> */}
                </StyledLineButtons>
            </StyledCloseButtonWrapper>
            <StyledDrawerWrapper commentHeight={0} isSharedNode={false}>
                <StyledDrawerContent fullWidth={fullWidth} isSharedNode={false}>
                    <StyledHeader>
                        {mode=== 'create' ? 'Create note' : 'Edit note'}
                    </StyledHeader>
                    <StyledContentWrapper isLoading={isLoading} transparent>
                        {mode === 'create' && <Input onFocus={() => {
                            setErrors({
                                ...errors,
                                name: undefined
                            })
                        }} error={errors.name} label="Note title" value={name} onChange={(e) => setName(e.target.value)} />}
                        <StyledEditor value={content} onChange={setContent} toolbar={{
                            inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['bold', 'italic', 'underline', 'strikethrough'],
                            },
                            list: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['unordered', 'ordered'],
                            },
                            options: ['inline', 'list'],
                            fontSize: {
                                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                            },
                            textAlign: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['left', 'center', 'right'],
                            },
                            history: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['undo', 'redo'],
                            },
                        }}/>
                        <LoaderLayout isLoading={isLoadingNodes} transparent={!!resources.length}>
                            {!!resources.length && <StyledFilledCard>
                                {listOfNodesJsx}
                            </StyledFilledCard>}
                            <StyledAddResourcesButton icon={<StyledAddIcon />} variant="secondary" onClick={() => setIsOpenAddResources(true)}>Add resources</StyledAddResourcesButton>
                        </LoaderLayout>
                    </StyledContentWrapper>
                </StyledDrawerContent>
            </StyledDrawerWrapper>
            <AddResourcesDialog
                value={isOpenAddResources}
                onChange={setIsOpenAddResources}
                resources={resources}
                onResources={setResources}
            />
            <SelectSpaceDialog value={isOpenedSelectSpace} onChange={setIsOpenedSelectSpace} onInput={createHandler} />
        </Drawer>
    )
};
import { Box, styled } from "@mui/material";
import { Select } from "../../Fields/Select/Select.component";

export const StyledFeatureBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`;
export const StyledFeatureLabel = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
export const StyledFeatureValue = styled('div')`
    color: #202020;
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
export const StyledPaymentPeriodSelect = styled(Select)`
    margin-top: 16px;
`;
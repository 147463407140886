import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding-top: 16px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`;
import { styled } from "@mui/material";

export const StyledSection = styled('div')`
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
`
export const StyledTitle = styled('div')`
    color: #636363;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const getParamsFromPath = (pathname: string, routePattern: string): Record<string, string> => {
    const paramNames = routePattern.match(/\[(\w+)\]/g) || [];
    const pattern = new RegExp(`^${routePattern.replace(/\//g, '\\/').replace(/\[(\w+)\]/g, '([^/]+)')}$`);
    const match = pattern.exec(pathname);
  
    const params: Record<string, string> = {};
  
    if (match) {
      paramNames.forEach((param, index) => {
        const paramName = param.replace(/[\[\]]/g, ''); // remove [ and ]
        params[paramName] = match[index + 1];
      });
    }
    return params;
};
  
  
import { FC, useMemo } from "react";
import { SpaceTagProps } from "./SpaceTag.type";
import { StyledSpaceTagContainer, StyledSpaceTagText, StyledDarkTagIcon, StyledWhiteTagIcon, StyledEmojiWrapper, StyledEmoji } from "./SpaceTag.styled";
import { useRouter } from "@/providers/Router/Router.provider";

export const SpaceTag: FC<SpaceTagProps> = ({ tag, spaceId }) => {
    const router = useRouter();
    const isActive = router.name==='spaceTag' && router.params.tagId === tag.id;
    const imgJsx = useMemo(() => {
        if (!!tag.emoji) {
          return <StyledEmojiWrapper>
            <StyledEmoji>{tag.emoji}</StyledEmoji>
          </StyledEmojiWrapper>
        }
        return <>
            {isActive && <StyledDarkTagIcon />}
            {!isActive && <StyledWhiteTagIcon />}
        </>
      }, [isActive, tag.emoji]);
    return <StyledSpaceTagContainer isActive={isActive} onClick={() => router.push({
        name: 'spaceTag',
        params: {
            tagId: tag.id,
            spaceId: spaceId
        }
    })}>
        {imgJsx}
        <StyledSpaceTagText >{tag.name}</StyledSpaceTagText>
    </StyledSpaceTagContainer>
}
import { FC } from "react";
import { TabProps } from "./Tab.type";
import { StyledIconWrapper, StyledText, StyledWrapper } from "./Tab.styled";

export const Tab: FC<TabProps> = ({
    children,
    icon,
    val,
    value,
    onChange,
}) => {
    const isActive = val === value;
    return <StyledWrapper isActive={isActive} onClick={() => onChange(val)}>
        {!!icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
        <StyledText>{children}</StyledText>
    </StyledWrapper>;
};
import { Box, styled } from "@mui/material";

export const StyledOnboardingContent = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 40px;
`;
export const StyledOnboardingStep = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    max-width: 720px;
    width: 100%;
`;
export const StyledContentRelationships = styled(Box)`
    padding: 0;
`;
export const StyledListBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    align-content: stretch;
`;
export const StyledButton = styled(Box)`
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    cursor: pointer;
    padding: 0px 8px 0px 8px;
    font-weight: 500;
    height: 32px;
    align-items: center;
    color: #FFFFFF;
    background-color: #202020;
    & img {
        width: 16px;
        height: 16px;
    }
    & span {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
        text-align: center;
        flex-grow: 1;
    }
`;
export const StyledLinkButton = styled(StyledButton)`
    text-decoration: underline;
    color: #202020;
    background: none;
    &:hover {
        background: rgba(0,0,0,.08);
    }
`;
export const StyledOnboardingButtons = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-content: space-between;
`;
export const StyledChromeExtensionPicture = styled(Box)`
    width: 720px;
    height: 560px;
`;
import { FC } from "react";
import { UserAvatarProps } from "./UserAvatar.type";
import { StyledImg, StyledMaskedImg } from "./UserAvatar.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const UserAvatar: FC<UserAvatarProps> = ({
    avatar,
    size = 'medium',
    className,
    title,
    rounded = true,
    useSkeleton = true,
    isExpired = false
}) => {
    const { user } = useMe();
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const skeletonState = useSkeleton && (isSubscriptionExpired || (isExpired && isIndividual));
    return <>
        {!skeletonState && <StyledImg rounded={rounded} src={avatar || "/icons/no_avatar.svg"} size={size} className={className} title={title} />}
        {skeletonState && <StyledMaskedImg rounded={rounded} size={size} className={`${className} skeleton-img`} />}
    </>
};
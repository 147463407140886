import { FC } from "react";
import { StyledMainNavigation } from "./MainNavigation.styled";
import { MenuItem } from "./components/MenuItem/MenuItem.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";

export const MainNavigation: FC = () => {
    const router = useRouter();
    const{ user } = useMe();
    const handleDrawerClosing = () => {

    };
    return <StyledMainNavigation>
        <MenuItem
            title="For you"
            routeName="forYou"
            icon={user?.avatar || '/icons/no_avatar.svg'}
            walkthrough={{
                variant: 'walkthrough-for-you',
                title: 'What is important for you',
                description: 'Important discussions, resources, tasks, emails from your apps. ',
                targetId: 'for-you',
            }}
            handleDrawerClosing={handleDrawerClosing}
        />
        <MenuItem
            title="Important"
            routeName="important"
            icon={router.name==='important' ? '/icons/important-active-icon.svg' : '/icons/important-icon.svg'}
            handleDrawerClosing={handleDrawerClosing}
        />
        <MenuItem
            title="People"
            routeName="people"
            icon={router.name==='people' ? '/icons/people-active-icon.svg' : '/icons/people-icon.svg'}
            handleDrawerClosing={handleDrawerClosing}
        />
    </StyledMainNavigation>
};
import { FC, useState } from "react";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { IconButton } from '@mui/material';
import { MenuRounded } from '@mui/icons-material';
import { Sidebar } from '../Sidebar/Sidebar.component';
import { StyledDrawer, StyledSearchWrappper, StyledWrappper } from "./Header.styled";
import { Search } from "./components/Search/Search.component";
import { User } from "./components/User/User.componment";

export const Header: FC = () => {
    const [isOpenedSidebar, setIsOpenedSidebar] = useState<boolean>(false);
    const shoudRenederSidebar = useRenederBelow('small');
    const { user } = useMe();
    const isSubscriptionExpired = user?.subscriptionStatus === "EXPIRED";
    
    return <>
        <StyledWrappper>
            {shoudRenederSidebar && <>
                <StyledDrawer open={isOpenedSidebar} onClose={() => setIsOpenedSidebar(false)}>
                    <Sidebar />
                </StyledDrawer>
                <IconButton onClick={() => setIsOpenedSidebar(!isOpenedSidebar)}>
                    <MenuRounded />
                </IconButton>
            </>}
            <StyledSearchWrappper>
                <Search disabled={isSubscriptionExpired} />
            </StyledSearchWrappper>
        </StyledWrappper>
        <User />
    </>
};
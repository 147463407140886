import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const DoneIcon: FC = (props: SvgIconProps) => {
    return <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_9521_54238)">
      <path d="M12 23.9921C18.6274 23.9921 24 18.6231 24 12C24 5.37693 18.6274 0.00787354 12 0.00787354C5.37258 0.00787354 0 5.37693 0 12C0 18.6231 5.37258 23.9921 12 23.9921Z" fill="#008D17"/>
      <path d="M10.9978 18.1095L5.48047 13.8109L6.86347 12.036L10.504 14.8721L16.3671 6.41138L18.2166 7.69313L10.9978 18.1095Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_9521_54238">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </SvgIcon>
};
import { Dialog, DialogTitle } from '@mui/material';
import { styled } from "@mui/material";

export const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        min-width: 650px;
        @ media (max-width: 600px) {
            width: 80%;
            widthMax: 720px;
            minWidth: 0;
        }
    }
`
export const StyledDialogTitle = styled(DialogTitle)`
    border-bottom: 1px solid rgba(0,0,0,.08);
`
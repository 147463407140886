import { styled } from "@mui/material";


export const StyledDrawerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'commentHeight' && prop !== 'isSharedNode',
})<{
  commentHeight: number;
  isSharedNode: boolean
}>`
  position: relative;
  ${({ isSharedNode }) => !isSharedNode && `min-height: calc(100vh - 67px);`}
  padding-bottom: ${props => props.commentHeight}px;
`
export const StyledDrawerContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'isSharedNode',
})<{
  fullWidth: boolean;
  isSharedNode: boolean;
}>`
  width: ${props => (props.fullWidth ? '100%' : '560px')};
  @media (max-width: 560px) {
    width: 100%;
  }
  position: relative;
  overflow: ${props => (props.isSharedNode ? 'initial' : 'auto')};
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    height: 30px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(0,0,0,0.08);
  }
`
export const StyledDrawerCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSharedNode',
})<{
  isSharedNode: boolean;
}>`
  background: ${props => (props.isSharedNode ? '#fff' : 'none')};
  border-radius: ${props => (props.isSharedNode ? '6px' : '0')};
`
export const StyledNoteWrapper = styled('div')`
  padding: 24px;
`
export const StyledNoteTitle = styled('div')`
  color: #202020;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
`
export const StyledNoteContent = styled('div')`
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  & p {
    margin: 0;
  }
`
import React, { createContext, useContext, useEffect } from "react";
import { WalkthroughProviderProps, WalkthroughContextType, WalkthroughVatiantType, WalkthroughHookReturnValueType } from "./Walkthrough.type";
import { useMe } from "providers/MeProvider/Me.provider";
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from "service/helpers";


const defaultConfig: WalkthroughContextType = {
    steps: [],
    step: -1,
    onGotIt: () => undefined,
    onSkip: () => undefined,
};

export const WalkthroughContext = createContext<WalkthroughContextType>(defaultConfig);
export const useWalkthrough = (variant: WalkthroughVatiantType): WalkthroughHookReturnValueType => {
    const context = useContext(WalkthroughContext);
    const shemaIndex = context.steps.findIndex((step) => step === variant);

    return {
        ...context,
        isOpened: shemaIndex === context.step,
        onGotIt: () => context.onGotIt(variant),
    };

};

export const WalkthroughProvider: React.FC<WalkthroughProviderProps> = (
    { children }: WalkthroughProviderProps
) => {
    const { user } = useMe();
    const [step, setStep] = React.useState<number>(-1);
    // useEffect(() => {
    //     // start disable onboarding
    //     // if (user && user.id && !user.walkThroughCompleted && user.isOnboarded) {
    //     if (!!user && !!user.id && !user.walkThroughCompleted) {
    //     // end disable onboarding
    //         const localStorageStep = localStorage.getItem('walkthroughStep');
    //         const initialStep = localStorageStep
    //             ? Number(localStorageStep)
    //             : 0;
    //         setTimeout(() => {
    //             setStep(initialStep)
    //         }, 5000);
    //     }
    // }, [user]);
    if (!user) {
        return <>{children}</>;
    }
    const steps: WalkthroughVatiantType[] = [
        'walkthrough-for-you',
        'walkthrough-spaces',
        'walkthrough-organised',
        'walkthrough-updates',
        'walkthrough-own',
        'walkthrough-search',
        'walkthrough-help',
    ]

    const completeWalkThrough = async () => {
        localStorage.removeItem('walkthroughStep');
        HttpClient.patch(`${envUrl}/organization-service/v1/users/${user.id}`, {
            walkThroughCompleted: true,
        });
    }
    const skipWalkThrough = async () => {
        completeWalkThrough();
        const nextStep = steps.length + 1;
        setStep(nextStep)
        localStorage.setItem('walkthroughStep', nextStep.toString());
    }
    const onGotItHandle = (variant: WalkthroughVatiantType) => {
        const shemaIndex = steps.findIndex((step) => step === variant);
        if (shemaIndex === steps.length - 1) {
            completeWalkThrough()
        }
        const nextStep = shemaIndex + 1;
        setStep(nextStep);
        localStorage.setItem('walkthroughStep', nextStep.toString());

    };
    const config: WalkthroughContextType = {
        step,
        steps,
        onGotIt: onGotItHandle,
        onSkip: skipWalkThrough
    }
    return (
        <>
            <WalkthroughContext.Provider value={config}>
                {children}
            </WalkthroughContext.Provider>
        </>
    );
};
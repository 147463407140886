import { FC } from "react";
import { CalendarClusterProps } from "./CalendarCluster.type";
import { CalendarEvent } from "./components/CalendarEvent/CalendarEvent.component";
import { CalendarGroup } from "../../CalendarGroup.component";

export const CalendarCluster: FC<CalendarClusterProps> = ({ cluster, startHour, clusterWidth, clusterLeft, baseWidth, useSkeleton=true }) => {
    if (cluster.groups.length > 0) {
        const groupsJsx = cluster.groups.map((group, i) => {
            return (
                <CalendarGroup useSkeleton={useSkeleton} group={group} key={i} startHour={startHour} groupWidth={clusterWidth} groupLeft={clusterLeft} baseWidth={baseWidth} />
            )
        })
        return (
            <>
                {groupsJsx}
            </>
        )
    }
    const eventWidth = clusterWidth / cluster.events.length;
    const eventsJsx = cluster.events.map((event, i) => {
        const eventLeft = eventWidth * i + clusterLeft;
        return (
            <CalendarEvent useSkeleton={useSkeleton} event={event} key={i} events={cluster.events} startHour={startHour} eventWidth={eventWidth} eventLeft={eventLeft} baseWidth={baseWidth} />
        )
    })
    return (
        <>
            {eventsJsx}
        </>
    )
}
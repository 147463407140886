import { Button } from "@/components/v3/Fields/Button/Button.component";
import { styled } from "@mui/material";

export const StyledCancelButton = styled(Button)`
    margin-right: 8px;
`;
export const StyledContent = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
import styled from "styled-components";
import { IconProps } from "./icon.type";

export const CloseIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M4.94922 5.05023L14.8487 14.9497" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.94922 14.9498L14.8487 5.05027" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export const ScaledCloseIcon = styled(CloseIcon)`
    transform: scale(1.4);
`;
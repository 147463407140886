import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { SpacesGetQueryParams } from './Types/Get/Get.type';
import { SpacesSynchronizeBodyParams } from './Types/Synchronize/Synchronize.type';

export const GetSpacesFetch = (query: SpacesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/clickup-service/v1/spaces`, {
        params: query,
    });
}
export const SynchronizeSpacesFetch = (body: SpacesSynchronizeBodyParams) => {
    return HttpClient.post(`${envUrl}/clickup-service/v1/grants`, body);
}
import { styled } from "@mui/material";
import { Node } from "../Node/Node.component";

export const StyledWrapper = styled('div')`
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledNode = styled(Node)`
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
`;
export const StyledActivitiesWrapper = styled('div')`
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
`;
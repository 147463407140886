import { styled } from "@mui/material";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledWrapper = styled('div')`
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
        border: 1px solid #202020;
    }
`;
export const StyledImageWrapper = styled('div')`
    background: #F4F4F4;
    padding: 32px;
    display: flex;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    overflow: hidden;
`;
export const StyledContentWrapper = styled('div')`
    padding: 16px;
`;
export const StyledName = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
`;

export const StyledApplication = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
import { ConfirmationDialog } from '@/components/ConfirmationDialog/ConfirmationDialog.component';
import { useState, useCallback } from 'react';

export const useConfirm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState<null | ((value: boolean) => void)>(null);

    const closeDialog = useCallback(() => {
        setIsOpen(false);
        onConfirm && onConfirm(false);
        setOnConfirm(null);
    }, [onConfirm]);

    const submitHandler = useCallback(() => {
        setIsOpen(false);
        onConfirm && onConfirm(true);
        setOnConfirm(null);
    }, [onConfirm]);

    const confirm = (callback: (value: boolean) => void) => {
        setIsOpen(true);
        setOnConfirm(() => callback);
    }

    const component = <ConfirmationDialog open={isOpen} closeDialog={closeDialog} submitHandler={submitHandler} />;

    return { confirm, component };
}

import { FC } from "react";
import { StyledCard, StyledLogo, StyledSectionDescription, StyledTitle } from "./SectionDescription.styled";
import { DESCTIPTION_ITEMS } from "./SectionDescription.const";
import { DesctiptionItem } from "./components/DesctiptionItem/DesctiptionItem.component";

export const SectionDescription: FC = () => {
    const desctiptionItemsJsx = DESCTIPTION_ITEMS.map((item, i) => {
        return <DesctiptionItem key={i} description={item} />
    });
    return <StyledSectionDescription>
        <StyledLogo>
            <img src="/icons/sense-logo.svg" alt="" />
        </StyledLogo>
        <StyledCard>
            <StyledTitle>All your work at one place</StyledTitle>
            {desctiptionItemsJsx}
        </StyledCard>
    </StyledSectionDescription>
};
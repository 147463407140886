import { IconProps } from "./icon.type"

export const EditIcon = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0_10156_7985)">
                <path d="M11.7812 1.39081L14.6097 4.21924L4.5763 14.2526L1.74788 11.4242L11.7812 1.39081Z" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.74603 11.424L0.332031 15.6667L4.5747 14.2527L1.74603 11.424Z" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.6092 4.21867L11.7812 1.39067L12.2526 0.919334C12.6298 0.555018 13.135 0.353429 13.6594 0.357986C14.1838 0.362543 14.6854 0.57288 15.0562 0.943697C15.427 1.31451 15.6374 1.81614 15.6419 2.34053C15.6465 2.86492 15.4449 3.37013 15.0806 3.74733L14.6092 4.21867Z" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_10156_7985">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
import { styled } from "@mui/material";
export const StyledWrapper = styled('div')`
    .toolbarClassName {
        background: #F4F4F4;
        border: none;
        border-radius: 8px;
    }
    .wrapperClassName {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        background: #FFF;
        box-sizing: border-box;
        height: 100%;
        * {
            box-sizing: content-box;
        }
    }
    .editorClassName {
        padding: 16px;
        height: calc(100% - 92px);
        .public-DraftStyleDefault-block {
            margin: 0;
        }
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.3);
            height: 30px;
        }
        &::-webkit-scrollbar-track {
            background: rgb(0,0,0,0.08);
        }
    }
    .rdw-option-wrapper, .rdw-dropdown-wrapper {
        background: transparent;
        transition: all 0.1s ease-in-out;
        &:hover {
            background: #fff;
            box-shadow: none;
            border: 1px solid #202020;
        }
    }
`;
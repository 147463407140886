import { ChangeEvent, useState } from "react";
import { Switch } from "@mui/material"
import { SectionField } from "./components/SectionField/SectionField.component"
import { StyledSwitcherContainer, StyledEmail, StyledDeleteAccount, StyledSectionHeader } from "./Content.styled";
import { TwoFAModal } from "./components/TwoFAModal/TwoFAModal.component";
import { DisableTwoFAModal } from "./components/DisableTwoFAModal/DisableTwoFAModal.component";
import { OtpGenerateFetch } from "@/axios/OrganizationService/Otp/Otp.api";
import { OtpGenerateAxiosResponseSuccessType } from "@/axios/OrganizationService/Otp/Types/Generate/Generate.type";
import { useMe } from "providers/MeProvider/Me.provider";
import { Section } from "./components/Section/Section.component";
import { UpdateNotificationSettingsFetch } from "@/axios/OrganizationService/Users/Users.api";
import { SectetType } from "./components/TwoFAModal/TwoFAModal.type";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { EmailsGoodbyeFetch } from "@/axios/OrganizationService/Emails/Emails.api";
import { ToastService } from "@/service/ToastService";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";

const defaultSecret: SectetType = {
    otpAuthURL: "",
    secretKey: "",
}

export const Content = () => {
    const { user, loadUser } = useMe();
    const [secret, setSecret] = useState<SectetType>({ ...defaultSecret });
    const [isOpenedDiableModal, setIsOpenedDiableModal] = useState<boolean>(false);
    const handleOTPChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            await OtpGenerateFetch().then((res: OtpGenerateAxiosResponseSuccessType) => {
                setSecret(res.data)
            })
        } else {
            setIsOpenedDiableModal(true);
        }
    };
    const handleDailyDigestChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!user?.id) {
            return;
        }
        await UpdateNotificationSettingsFetch(user?.id, {
            dailyDigestNotification: event.target.checked
        }).then(() => {
            loadUser();
        })
    };
    const { confirm, component } = useConfirm({
        title: "Delete account",
        content: <>
          <StyledSectionHeader title="Are you sure you want to delete your Sense workspace account?" />
            <div>
                Your data will be lost forever and this action is not possible to revert. If you confirm account deletion, you will be redirected to a short survey. After completing it, our team will delete your account and its data. 
            </div>
        </>,
        cancelButtonProps: {
          children: "Cancel",
          variant: 'primary',
        },
        submitButtonProps: {
          children: "Delete account",
          variant: 'error'
        },
      });
    const deleteAccountHandler = () => {
        confirm(async (result) => {
            if (!!result) {
                await EmailsGoodbyeFetch().then(() => {
                    ToastService.showToast('success', 'The removal request has been sent on your email!');
                }).catch(() => {
                    ToastService.showToast('error', 'Some error occurred!');
                })
            }
        })
    };
    return <>
        <Section label="General settings">
            <SectionField title="User email">
                <StyledEmail>{user?.email}</StyledEmail>
            </SectionField>
            <SectionField title="Security">
                <StyledSwitcherContainer>
                    Two-Factor Authentication (2FA)
                    <Switch
                        value={user?.totpEnabled}
                        checked={user?.totpEnabled}
                        onChange={handleOTPChange}
                        color="primary"
                    />
                </StyledSwitcherContainer>
            </SectionField>
        </Section>
        <Section label="Notification settings">
            <StyledSwitcherContainer>
                Daily email digest
                <Switch
                    value={!!user?.notificationSettings.dailyDigestNotification}
                    checked={!!user?.notificationSettings.dailyDigestNotification}
                    onChange={handleDailyDigestChange}
                    color="primary"
                />
            </StyledSwitcherContainer>
        </Section>
        <StyledDeleteAccount onClick={deleteAccountHandler} variant="error">Delete account</StyledDeleteAccount>
        <TwoFAModal secret={secret} onClose={() => setSecret({ ...defaultSecret })} />
        <DisableTwoFAModal value={isOpenedDiableModal} onClose={() => setIsOpenedDiableModal(false)} />
        {component}
    </>
}
import { Box, Dialog, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledGoToSlackPageButton } from './ConnectionNotificatiionDialog.styled';

export const ConnectionNotificationDialog = () => {

  const [openDialog, setOpenDialog] = useState(false);
  const search = useLocation().search;
  const notification = new URLSearchParams(search).get('notification');

  const history = useHistory();
  const texts = {
    "connect.shared.drive": {
      "title": "Shared drive is not found",
      "description": "We only support Google Drive accounts having a Shared Drive, we don't work with your private data.",
    },
    "connect.missing.scope": {
      "title": "Missing permissions",
      "description": "You have not provided all the necessary permissions to use the integration. The app has been disconnected.\nTry to connect it again and grant requested permissions",
    },
  }
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (notification === 'connect.shared.drive') {
      setTitle(texts[notification].title)
      setDescription(texts[notification].description)
      setOpenDialog(true);
    }

    if (notification === 'connect.missing.scope') {
      setTitle(texts[notification].title)
      setDescription(texts[notification].description)
      setOpenDialog(true);
    }
  }, [setOpenDialog]);

  return (
    <Dialog open={openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <Box p={3} style={{ whiteSpace: 'pre-line'}}>
        {description}
      </Box>
      <Box p={3} style={{ display: 'flex', justifyContent: 'right' }}>
        <StyledGoToSlackPageButton
          onClick={() => {
            history.replace(window.location.pathname);
            setOpenDialog(false);
          }}
        >
          Ok
        </StyledGoToSlackPageButton>
      </Box>
    </Dialog>
  );
};

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  searchResultWrapper: {
    padding: '24px',
    paddingBottom: '0px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
}));
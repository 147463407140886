import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Header } from "./components/Header/Header.component";
import { Tabs } from "./components/Tabs/Tabs.component";
import { TabValueType } from "./components/Tabs/Tabs.type";
import { All } from "./components/All/All.component";
import { MyWorkspace } from "./components/MyWorkspace/MyWorkspace.component";
import { External } from "./components/External/External.component";
import { GetPeopleRecentActivitiesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { GetPeopleRecentActivitiesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/GetPeopleRecentActivities/GetPeopleRecentActivities.type";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { PersonTypeWithUser } from "./People.type";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";

export const PeopleContent: FC = () => {
    const { users } = useMe();
    const [activeTab, setActiveTab] = useState<TabValueType>('ALL');
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [listOfPersons, setListOfPersons] = useState<PersonTypeWithUser[]>([]);
    const loadActivities = useCallback(async () => {
        setIsFetching(true);
        await GetPeopleRecentActivitiesFetch({
            limitPerUser: 2
        }).then((res: GetPeopleRecentActivitiesAxiosResponseSuccessType) => {
            setListOfPersons(users.map((mainUser) => {
                const activities = res.data.find((el) => el.userId === mainUser.id);
                if (activities) {
                    return {
                        activities: activities.activities,
                        person: mainUser,
                        userId: mainUser.id
                    };
                }
                return {
                    activities: [],
                    person: mainUser,
                    userId: mainUser.id
                }
            }));
        });
        setIsFetching(false);
    }, [users]);
    const { myWorkspaceUsers, externalUsers } = useMemo(() => {
        const myWorkspaceUsers = listOfPersons.filter(el => !el.person.isGhost) || [];
        const externalUsers = listOfPersons.filter(el => el.person.isGhost) || [];
        return { myWorkspaceUsers, externalUsers }
    }, [listOfPersons])
    useEffect(() => {
        loadActivities();
    }, [loadActivities]);
    return <MainLayout>
        <Header/>
        <Tabs value={activeTab} onChange={setActiveTab} />
        {activeTab === 'ALL' && <All isFetching={isFetching} myWorkspaceUsers={myWorkspaceUsers} externalUsers={externalUsers} />}
        {activeTab === 'MY_WORKSPACE' && <MyWorkspace isFetching={isFetching} myWorkspaceUsers={myWorkspaceUsers}  />}
        {activeTab === 'EXTERNAL' && <External isFetching={isFetching} externalUsers={externalUsers} />}
        <CreateNoteDrawer onUpdate={loadActivities} />
    </MainLayout>
};
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';
import { AddFromTabsProps, ChromeTabType } from "./AddFromTabs.type";
import { useMe } from '@/providers/MeProvider/Me.provider';
import { SetNodeRelationsFetch } from '@/axios/AiService/NodeRelations/NodeRelations.api';
import { StyledSectionHeader } from './AddFromTabs.styled';
import { EmptyStateLayoyt } from '@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component';
import { AddNodeItem } from '../AddNodeItem/AddNodeItem.component';
import { NodeType } from '../AddNodeItem/AddNodeItem.type';
import { FilledCard } from '@/components/v3/Other/FilledCard/FilledCard.component';
import { NodeTypeType } from '@/types/Node/Node.type';
import { CreateNodesFetch } from '@/axios/AiService/Nodes/Nodes.api';
import { NodesCreateResponseSuccessType } from '@/axios/AiService/Nodes/Types/Create/Create.type';
// import { TABS_MOCK } from './AddFromTans.mock';

export const AddFromTabs = ({ setIsFetchingLocal, listOfAddedNodes, onAdd, onRemove }: AddFromTabsProps) => {
  const [tabs, setTabs] = useState<ChromeTabType[]>([]);
  const { user } = useMe();

  useEffect(() => {
    const get_tabs = async () => {
      // return {
      //   tabs: TABS_MOCK
      // };
      let request_id = Date.now();
      return new Promise((resolve) => {
        if (document.documentElement.dataset.senseai_extension_installed !== "1") {
          resolve({ success: false, error_code: "extension_not_installed" });
        } else {
          let listener = function (event: any) {
            if (
              event.data.name === "exec_result" &&
              event.data.meta.request_id === request_id
            ) {
              resolve(event.data.data.result);
              window.removeEventListener("message", listener);
            }
          };
          window.addEventListener("message", listener);
          window.postMessage(
            {
              name: "get_tabs",
              meta: { request: true, request_id },
              data: { _origin: true },
            },
            "*"
          );
        }
      });
    };

    const getTabsFromChromeExtension = async () => {
      const response: any = await get_tabs();
      setTabs(response?.tabs || []);
    };

    getTabsFromChromeExtension();
  }, []);
  const listOfNodes = useMemo((): NodeType[] => {
    const nodesFromTabs = tabs.map((item) => {
      const addedNode = listOfAddedNodes.find((node) => node.originalURI === item.url);
      const nodeId = addedNode ? addedNode.id : item.id;
      const fakeNode: NodeType = {
        id: nodeId,
        name: item.title,
        favicon: item.favIconUrl,
        nodeType: NodeTypeType.RESOURCE_NODE,
        originalURI: item.url,
        application: 'chrome',
      }
      return fakeNode
    });
    return nodesFromTabs;
  }, [listOfAddedNodes, tabs]);

  const createNodeHandler = useCallback(async (node: NodeType) => {
    setIsFetchingLocal(true);
    await CreateNodesFetch([{
      name: node.name,
      originalURI: node.originalURI,
      nodeType: node.nodeType,
      favicon: node.favicon,
    }]).then((res: NodesCreateResponseSuccessType) => {
      for (let i = 0; i < res.data.length; i++) {
        const node = res.data[i];
        user?.id && SetNodeRelationsFetch([{
          nodeId: node.id,
          actors: [{
            userId: user.id,
            actionType: 'ADDED_BY',
            properties: {
              happenedAt: new Date().toISOString()
            }
          },]
        }], {
          action: 'CREATE',
        });
        onAdd(node);
      }
    }).catch((e) => {
      setIsFetchingLocal(false);
    })
  }, [onAdd, setIsFetchingLocal, user?.id]);
  const addHandler = useCallback(async (node: NodeType) => {
    await createNodeHandler(node);
  }, [createNodeHandler])
  const listOfNodesJsx = listOfNodes.map((fakeNode, i) => {
    return (
      <AddNodeItem
        listOfAddedNodes={listOfAddedNodes}
        node={fakeNode}
        onAdd={addHandler}
        onRemove={onRemove}
        key={i}
      />
    )
  });
  return (
    <>
      <FilledCard>
        <EmptyStateLayoyt
          value={!listOfNodes.length}
          emptyNode={<Typography variant="body1" style={{ padding: '0 24px' }}>
            No nodes found
          </Typography>}
        >
          <StyledSectionHeader title="Current tabs" />
          {listOfNodesJsx}
        </EmptyStateLayoyt>
      </FilledCard>
    </>
  );
};

import { PageHeader } from "@/components/v3/Sections/PageHeader/PageHeader.component";
import { styled } from "@mui/material";

export const StyledPageHeader = styled(PageHeader)`
    padding: 24px 32px 0px 32px;
    @media (max-width: 768px) {
        padding: 24px 16px 0px 16px;
    }
`;
export const StyledMainIcon = styled('img')`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`
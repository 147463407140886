import { styled } from "@mui/material";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { AddIcon } from "@/icons/v3/add.icon";

export const StyledCreateSpace = styled('div')`
    padding: 16px;
    padding-bottom: 0;
`;
export const StyledButton = styled(Button)`
    display: flex;
    justify-content: center;
    width: 100%;
`;
export const StyledAddIcon = styled(AddIcon)`
    width: 18px;
    height: 18px;
    & path {
        fill: #fff;
    }
`
import { EmojiSelect } from "@/components/v3/Fields/EmojiSelect/EmojiSelect.component";
import { Input } from "@/components/v3/Fields/Input/Input.component";
import { EmojiIcon } from "@/icons/v3/emoji.icon";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 32px);
`;
export const StyledEmojiSelect = styled(EmojiSelect)`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-right: 12px;
`
export const StyledEmojiIcon = styled(EmojiIcon)`
    width: 16px;
    height: 16px;
`
export const StyledInput = styled(Input)`
    margin-bottom: 0;
    width: calc( 100% - 24px - 12px );
    min-height: 32px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.06);
    input {
        padding: 0px 8px;
        min-height: 32px;
    }
    fieldset {
        border: none;
    }
`
export const StyledName = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`
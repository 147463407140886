import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import {
  RouterContextType,
  RouterProviderProps,
  RoutePathType,
  RouteNameType,
  ParamsType,
  QueriesType,
  PushOptionsType,
  HistoryItemType,
  RouteTypeType
} from "./Router.type";
import { useHistory, useLocation} from 'react-router-dom'
import { routes } from "./routes";
import { getPathWithParams } from "./utils/getPathWithParams.util";
import { getParamsFromPath } from "./utils/getParamsFromPath.util";
import { getQueryParams } from "./utils/getQueryParams.util";

const defaultConfig: RouterContextType = {
  name: 'login',
  type: ['public'],
  getPathWithParams: () => '',
  push: () => undefined,
  query: {},
  params: {},
  history: [],
  pathname: '/login',
  routePath: '/login'
};

export const RouterContext = createContext<RouterContextType>(defaultConfig);
export const useRouter = () => useContext<RouterContextType>(RouterContext);


export const RouterProvider: React.FC<RouterProviderProps> = (
  { children }: RouterProviderProps
) => {
  const [history, setHistory] = useState<HistoryItemType[]>([]);
  const location = useLocation();
  const reactHistory = useHistory();
  const { name, type, routePath } = useMemo(() => {
    for (const key in routes) {
      const route = routes[key as keyof typeof routes];
      const routePath = route.path;
      const routeType = route.type as RouteTypeType;
      const regexPath = `^${routePath.replace(/\//g, '\\/').replace(/\[(\w+)\]/g, '([^/]+)')}$`;
      if (new RegExp(regexPath).test(location.pathname)) {
        return { name: key as RouteNameType, type: routeType, routePath };
      }
    }
    if (location.pathname === '/') {
      reactHistory.push(routes.forYou.path);
    } else {
      reactHistory.push(routes.notFound.path);
    }
    return { name: null, type: [], routePath: null } ;
  }, [location.pathname]);
  const params = name ? getParamsFromPath(location.pathname, routes[name].path) : {};
  const query = getQueryParams(location.search);
  const getPathWithParamsHandle = (routeNameProp?: RouteNameType, params?: ParamsType, queries?: QueriesType): RoutePathType => {
      const routeName = routeNameProp ? routeNameProp : name as RouteNameType;
      return getPathWithParams(routeName, params, queries)
  }
  const pushHandle = (options: PushOptionsType): void => {
    const oldPath = getPathWithParamsHandle(name!, params, query);
    const path = getPathWithParamsHandle(options.name, options.params, options.query);
    oldPath!==path && reactHistory.push(path);
  }
  useEffect(() => {
    const h = [...history];
    h.push({
      name: name as RouteNameType,
      query: query,
      params: params,
      pathname: location.pathname,
      routePath: routePath as string
    })
    setHistory(h);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, location])
  const config: RouterContextType = {
    history,
    name: name as RouteNameType,
    type: type as RouteTypeType,
    push: pushHandle,
    getPathWithParams: getPathWithParamsHandle,
    query: query,
    params: params,
    pathname: location.pathname,
    routePath: routePath as string
  }
  
  return (
    <RouterContext.Provider value={config}>
      {children}
    </RouterContext.Provider>
  );
};
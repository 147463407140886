import { Box } from '@mui/material';

export const Privacy = () => {
  return (
    <Box m={6}>
      <h1>Privacy Policy</h1>
      <div className="block-text-wrapper">
        <p className="block-divider paragraph-2 left-aligned">
          Your privacy is important to us. It is Sense's policy to respect your privacy and comply with any applicable
          law and regulation regarding any personal information we may collect about you, including across our website,
          https://senseapp.ai, and other sites we own and operate.
          <br />
          <br />
          Personal information is any information about you which can be used to identify you. This includes information
          about you as a person (such as name, address, and date of birth), your devices, payment details, and even
          information about how you use a website or online service.
          <br />
          <br />
          In the event our site contains links to third-party sites and services, please be aware that those sites and
          services have their own privacy policies. After following a link to any third-party content, you should read
          their posted privacy policy information about how they collect and use personal information. This Privacy
          Policy does not apply to any of your activities after you leave our site.
          <br />
          <br />
          This policy is effective as of 9 March 2022.
          <br />
          <br />
          Last updated: 9 March 2022
          <br />
          <br />
          <strong>Information We Collect</strong>
          <br />
          Information we collect falls into one of two categories: “voluntarily provided” information and “automatically
          collected” information.
          <br />
          <br />
          “Voluntarily provided” information refers to any information you knowingly and actively provide us when using
          or participating in any of our services and promotions.
          <br />
          <br />
          “Automatically collected” information refers to any information automatically sent by your devices in the
          course of accessing our products and services.
          <br />
          <br />
          <strong>Log Data</strong>
          <br />
          When you visit our website, our servers may automatically log the standard data provided by your web browser.
          It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you
          visit, the time and date of your visit, the time spent on each page, and other details about your visit.
          <br />
          <br />
          Additionally, if you encounter certain errors while using the site, we may automatically collect data about
          the error and the circumstances surrounding its occurrence. This data may include technical details about your
          device, what you were trying to do when the error happened, and other technical information relating to the
          problem. You may or may not receive notice of such errors, even in the moment they occur, that they have
          occurred, or what the nature of the error is.
          <br />
          <br />
          Please be aware that while this information may not be personally identifying by itself, it may be possible to
          combine it with other data to personally identify individual persons.
          <br />
          <br />
          <strong>Personal Information</strong>
          <br />
          We may ask for personal information — for example, when you register an account or when you contact us — which
          may include one or more of the following:
          <br />
          <br />
          <span className="list-ol">
            Name
            <br />
            Email
          </span>
          <br />
          <br />
          <strong>Transaction Data</strong>
          <br />
          Transaction data refers to data that accumulates over the normal course of operation on our platform. This may
          include transaction records, stored files, user profiles, analytics data and other metrics, as well as other
          types of information, created or generated, as users interact with our services.
          <br />
          <br />
          <strong>Legitimate Reasons for Processing Your Personal Information</strong>
          <br />
          We only collect and use your personal information when we have a legitimate reason for doing so. In which
          instance, we only collect personal information that is reasonably necessary to provide our services to you.
          <br />
          <br />
          <strong>Collection and Use of Information</strong>
          <br />
          We may collect personal information from you when you do any of the following on our website:
          <br />
          <br />
          Register for an account
          <br />
          Purchase a subscription
          <br />
          Use a mobile device or web browser to access our content
          <br />
          Contact us via email, social media, or on any similar technologies
          <br />
          When you mention us on social media
          <br />
          <br />
          We may collect, hold, use, and disclose information for the following purposes, and personal information will
          not be further processed in a manner that is incompatible with these purposes:
          <br />
          <br />
          to provide you with our platform's core features and services
          <br />
          to enable you to customize or personalize your experience of our website
          <br />
          for analytics, market research, and business development, including to operate and improve our website,
          associated applications, and associated social media platforms
          <br />
          for technical assessment, including to operate and improve our app, associated applications, and associated
          social media platforms.
          <br />
          <br />
          We may combine voluntarily provided and automatically collected personal information with general information
          or research data we receive from other trusted sources. For example, If you provide us with your location, we
          may combine this with general information about currency and language to provide you with an enhanced
          experience of our site and service.
          <br />
          <br />
          <strong>Security of Your Personal Information</strong>
          <br />
          When we collect and process personal information, and while we retain this information, we will protect it
          within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure,
          copying, use, or modification.
          <br />
          <br />
          Although we will do our best to protect the personal information you provide to us, we advise that no method
          of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security.
          <br />
          <br />
          You are responsible for selecting any password and its overall security strength, ensuring the security of
          your own information within the bounds of our services.For example, ensuring any passwords associated with
          accessing your personal information and accounts are secure and confidential.
          <br />
          <br />
          <strong>How Long We Keep Your Personal Information</strong>
          <br />
          We keep your personal information only for as long as we need to. This time period may depend on what we are
          using your information for, in accordance with this privacy policy. For example, if you have provided us with
          personal information as part of creating an account with us, we may retain this information for the duration
          your account exists on our system. If your personal information is no longer required for this purpose, we
          will delete it or make it anonymous by removing all details that identify you.
          <br />
          <br />
          However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or
          reporting obligation or for archiving purposes in the public interest, scientific, or historical research
          purposes or statistical purposes.
          <br />
          <br />
          <strong>Children’s Privacy</strong>
          <br />
          We do not aim any of our products or services directly at children under the age of 13, and we do not
          knowingly collect personal information about children under 13.
          <br />
          <br />
          <strong>Disclosure of Personal Information to Third Parties</strong>
          <br />
          We may disclose personal information to:
          <br />
          <br />a parent, subsidiary, or affiliate of our company
          <br />
          third-party service providers for the purpose of enabling them to provide their services, including (without
          limitation) IT service providers, data storage, hosting and server providers, error loggers, debt collectors,
          maintenance or problem-solving providers, professional advisors, and payment systems operators
          <br />
          our employees, contractors, and/or related entities
          <br />
          our existing or potential agents or business partners
          <br />
          credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for
          goods or services we have provided to you
          <br />
          courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection
          with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal
          rights
          <br />
          third parties, including agents or sub-contractors, who assist us in providing information, products,
          services, or direct marketing to you
          <br />
          third parties to collect and process data
          <br />
          an entity that buys, or to which we transfer all or substantially all of our assets and business
          <br />
          <br />
          Third parties we currently use include:
          <br />
          <br />
          Integrations with the apps the users add
          <br />
          <br />
          <strong>International Transfers of Personal Information</strong>
          <br />
          The personal information we collect is stored and/or processed in Germany, or where we or our partners,
          affiliates, and third-party providers maintain facilities.
          <br />
          <br />
          The countries to which we store, process, or transfer your personal information may not have the same data
          protection laws as the country in which you initially provided the information. If we transfer your personal
          information to third parties in other countries: (i) we will perform those transfers in accordance with the
          requirements of applicable law; and (ii) we will protect the transferred personal information in accordance
          with this privacy policy.
          <br />
          <br />
          <strong>Your Rights and Controlling Your Personal Information</strong>
          <br />
          Your choice: By providing personal information to us, you understand we will collect, hold, use, and disclose
          your personal information in accordance with this privacy policy. You do not have to provide personal
          information to us, however, if you do not, it may affect your use of our website or the products and/or
          services offered on or through it.
          <br />
          <br />
          Information from third parties: If we receive personal information about you from a third party, we will
          protect it as set out in this privacy policy. If you are a third party providing personal information about
          somebody else, you represent and warrant that you have such person’s consent to provide the personal
          information to us.
          <br />
          <br />
          Marketing permission: If you have previously agreed to us using your personal information for direct marketing
          purposes, you may change your mind at any time by contacting us using the details below.
          <br />
          <br />
          Access: You may request details of the personal information that we hold about you.
          <br />
          <br />
          Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete,
          irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take
          reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.
          <br />
          <br />
          Non-discrimination: We will not discriminate against you for exercising any of your rights over your personal
          information. Unless your personal information is required to provide you with a particular service or offer
          (for example processing transaction data), we will not deny you goods or services and/or charge you different
          prices or rates for goods or services, including through granting discounts or other benefits, or imposing
          penalties, or provide you with a different level or quality of goods or services.
          <br />
          <br />
          Notification of data breaches: We will comply with laws applicable to us in respect of any data breach.
          <br />
          <br />
          Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint,
          please contact us using the details below and provide us with full details of the alleged breach. We will
          promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
          investigation and the steps we will take to deal with your complaint. You also have the right to contact a
          regulatory body or data protection authority in relation to your complaint.
          <br />
          <br />
          Unsubscribe: To unsubscribe from our email database or opt-out of communications (including marketing
          communications), please contact us using the details provided in this privacy policy, or opt-out using the
          opt-out facilities provided in the communication. We may need to request specific information from you to help
          us confirm your identity.
          <br />‍<br />
          <strong>Use of Cookies</strong>
          <br />
          We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece
          of data that our website stores on your computer, and accesses each time you visit, so we can understand how
          you use our site. This helps us serve you content based on preferences you have specified.
          <br />
          <br />
          Please refer to our Cookie Policy for more information.
          <br />
          <br />
          <strong>Business Transfers</strong>
          <br />
          If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we
          would include data, including your personal information, among the assets transferred to any parties who
          acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the
          extent permitted by applicable law, continue to use your personal information according to this policy, which
          they will be required to assume as it is the basis for any ownership or use rights we have over such
          information.
          <br />
          <br />
          <strong>Limits of Our Policy</strong>
          <br />
          Our website may link to external sites that are not operated by us. Please be aware that we have no control
          over the content and policies of those sites, and cannot accept responsibility or liability for their
          respective privacy practices.
          <br />
          <br />
          <strong>Changes to This Policy</strong>
          <br />
          At our discretion, we may change our privacy policy to reflect updates to our business processes, current
          acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we
          will post the changes here at the same link by which you are accessing this privacy policy.
          <br />
          <br />
          If the changes are significant, or if required by applicable law, we will contact you (based on your selected
          preferences for communications from us) and all our registered users with the new details and links to the
          updated or changed policy.
          <br />
          <br />
          If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as
          applicable, any new uses of your personal information.
          <br />
          <br />
          <strong>Contact Us</strong>
          <br />
          For any questions or concerns regarding your privacy, you may contact us using the following details:
          <br />
          <br />
          Dzianis Pomazau
          <br />
          dzianis@senseapp.ai
          <br />
          <br />
        </p>
      </div>
    </Box>
  );
};

import {
  useState,
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
  createContext,
  ReactNode,
  useContext
} from 'react';

export type ListOfIds = Array<string>;

export interface NewSpaseContext {
  channelIds: ListOfIds;
  setChannelIds: Dispatch<SetStateAction<ListOfIds>>;
  userIds: ListOfIds;
  setUserIds: Dispatch<SetStateAction<ListOfIds>>;
}

interface NewChannelProviderProps {
  children?: ReactNode;
}

export const NewSpaceContext = createContext<Partial<NewSpaseContext>>({});
export const useNewSpace = () => useContext<Partial<NewSpaseContext>>(NewSpaceContext);

export const NewChannelProvider: FC<NewChannelProviderProps> = ({ children }) => {
  const [channelIds, setChannelIds] = useState<ListOfIds>(
    [] as ListOfIds
  );
  const [userIds, setUserIds] = useState<ListOfIds>(
      [] as ListOfIds
  );

  const contextValue = useMemo(
    () => ({ channelIds, setChannelIds, userIds, setUserIds }),
    [channelIds, userIds]
  );

  return (
    <NewSpaceContext.Provider value={contextValue}>{children}</NewSpaceContext.Provider>
  );
};

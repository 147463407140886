import { styled } from "@mui/material";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { ApplicationIcon } from "@/components/ApplicationIcon/ApplicationIcon.component";

export const StyledFilledCard = styled(FilledCard)`
    margin-top: 24px;
`
export const StyledApplicationIcon = styled(ApplicationIcon)`
    width: 24px;
    height: 24px;
`
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
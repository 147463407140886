import { useModal } from "../../../../store/ModalContext";
import { WalkthroughWrapper } from 'providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component';
import { useMe } from 'providers/MeProvider/Me.provider';
import {
  StyledIcon,
  StyledItem,
  StyledLink,
  StyledTitle,
  StyledTrial,
  StyledWrapper
} from './GetStarted.styled';
import { IconResolver } from './GetStarted.type';
import { useRouter } from "@/providers/Router/Router.provider";


const countRemainingDays = (expirationDate: number) => {
    const trialExpirationDate = new Date(expirationDate);
    const currentDate = new Date();
    const timeDiff = trialExpirationDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
}

export const GetStarted = () => {
  const { user } = useMe();
  const trialExpiresAt = user && user.trialExpiresAt;
  const daysRemaining = trialExpiresAt && countRemainingDays(trialExpiresAt);
  const showDaysRemainingStyle = daysRemaining ? {display: 'block'} : {display: 'none'};
  const router = useRouter()
  const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
  return (
    <StyledWrapper>
      {daysRemaining && <StyledTrial style={showDaysRemainingStyle}>{`${daysRemaining} days of trial left`}</StyledTrial>}
      <WalkthroughWrapper
        variant="walkthrough-help"
        title="Help us to make your work perfect"
        description="Follow guidance to achieve the most efficient work process with Sense."
        targetId="walkthrough-help"
        leftMargin={16}
      >
        <StyledTitle id="walkthrough-help">Get started</StyledTitle>
      </WalkthroughWrapper>
      
      <GetStartedItem
        isResolved={user?.connectedCommunicationApp}
        content={
          <span>
            Connect at least{' '}
            <StyledLink
              onClick={() => router.push({
                name: router.name,
                params: router.params,
                query: {
                  ...router.query,
                  dialog: 'manage-integrations'
                }
              })}
            >
              1 communication app (Slack or Teams)
            </StyledLink>
          </span>
        }
      />
      <GetStartedItem
        isResolved={user?.connectedStorageApp}
        content={
          <span>
            Connect at least{' '}
            <StyledLink
              onClick={() => router.push({
                name: router.name,
                params: router.params,
                query: {
                  ...router.query,
                  dialog: 'manage-integrations'
                }
              })}
            >
              1 storage (Google Drive, Sharepoint or Dropbox)
            </StyledLink>
          </span>
        }
      />
      <GetStartedItem
        isResolved={user?.installedChromeExtension}
        content={
          <span>
            Install{' '}
            <StyledLink
              target="_blank"
              href="https://chrome.google.com/webstore/detail/sense/obemfgmikdfkejnfioiodicdhjnnojla" rel="noreferrer"
            >
              Chrome extension
            </StyledLink>
          </span>
        }
      />
      {!isIndividual && <GetStartedItem
        isResolved={user?.invitedTeamMate}
        content={
          <StyledLink
            onClick={() => {
              router.push({
                name: router.name,
                params: router.params,
                query: {
                  ...router.query,
                  dialog: 'manage-users',
                }
              })
            }}
          >
            Invite teammates
          </StyledLink>
        }
      />}
    </StyledWrapper>
  );
};



const GetStartedItem = ({ isResolved, content }: IconResolver) => {
  return (
    <StyledItem resolved={!!isResolved}>
      <StyledIcon src={isResolved ? '/icons/check_green.svg' : '/icons/check_grey.svg'} alt='' />
      {content}
    </StyledItem>
  );
};

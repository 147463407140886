import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { MentionsGetQueryParams } from './Types/Get/Get.type';

export const GetMentionsFetch = (query: MentionsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/mentions`, {
        params: {
            ...query,
            limit: query.limit || 30,
            users: !!query.users?.length ? query.users.join(',') : undefined,
        },
    });
}
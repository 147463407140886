import { Box, styled } from "@mui/material";

export const StyledNavigationItem = styled(Box, {
    shouldForwardProp: prop => prop !== 'active'
  })<{active: boolean}>`
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
    ${({active}) => active && `
        background: rgba(0, 0, 0, 0.06);
    `}
`;
export const StyledImg = styled('img', {
    shouldForwardProp: prop => prop !== 'avatar'
  })<{avatar: boolean}>`
      width: 24px;
      margin-right: 8px;
      border-radius: ${({avatar}) => avatar ? '50%' : '0'};
`;
import { styled } from "@mui/material";
import { UserAvatar } from "../UserAvatar/UserAvatar.component";

export const StyledWrapper = styled('div')`
    border-radius: 100px;
    background: #F4F4F4;
    padding: 4px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #202020;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
`;
export const StyledUserAvatar = styled(UserAvatar)`
    margin-right: 8px;
`;
import { NodeTypeType } from "@/types/Node/Node.type";
import { CategoryWrappersType, NodeWrapperType } from "./GroupNodeByCategory.type";

const pushNodeWrapperToCategory = <T extends NodeWrapperType>(categories: CategoryWrappersType<T>[], category: string, node: T): CategoryWrappersType<T>[] => {
    const newVal = [...categories];
    const index = newVal.findIndex(val => val.category === category);
    if (index>-1) {
        newVal[index].wrappers.push(node);
    } else {
        newVal.push({
            category,
            wrappers: [node],
        });
    }
    return newVal;
};
export const groupNodeWrappersByCategory = <T extends NodeWrapperType>(
    wrappers: T[]
): CategoryWrappersType<T>[] => {
    let categories: CategoryWrappersType<T>[] = [];
    wrappers.forEach(wrapper => {
        if (wrapper.node.nodeType === NodeTypeType.TASK && wrapper.node.categories.includes('task')) {
            categories = pushNodeWrapperToCategory(categories, 'Tasks', wrapper);
        } else if (wrapper.node.categories.includes('document')) {
            categories = pushNodeWrapperToCategory(categories, 'Documents', wrapper);
        } else if (wrapper.node.nodeType === NodeTypeType.NOTE) {
            categories = pushNodeWrapperToCategory(categories, 'Notes', wrapper);
        } else if (wrapper.node.nodeType === NodeTypeType.RESOURCE_NODE && wrapper.node.categories.includes('file')) {
            categories = pushNodeWrapperToCategory(categories, 'Files', wrapper);
        } else if (wrapper.node.categories.includes('email')) {
            categories = pushNodeWrapperToCategory(categories, 'Emails', wrapper);
        } else if (wrapper.node.categories.includes('link')) {
            categories = pushNodeWrapperToCategory(categories, 'Links', wrapper);
        } else if (wrapper.node.categories.includes('video')) {
            categories = pushNodeWrapperToCategory(categories, 'Video', wrapper);
        } else if (wrapper.node.categories.includes('event')) {
            categories = pushNodeWrapperToCategory(categories, 'Events', wrapper);
        } else if (wrapper.node.categories.includes('design')) {
            categories = pushNodeWrapperToCategory(categories, 'Design', wrapper);
        } else {
            categories = pushNodeWrapperToCategory(categories, 'Other', wrapper);
        }
    });

    const categoryOrder = ['Tasks', 'Documents', 'Notes', 'Files', 'Emails', 'Links', 'Video', 'Events', 'Design', 'Other'];
    categories.sort((a, b) => {
        const orderA = categoryOrder.indexOf(a.category);
        const orderB = categoryOrder.indexOf(b.category);
        return orderA - orderB;
    });
    return categories;
};

import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { NodeWrapperType, SpaceTagPageContextType } from "./SpaceTagPage.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { GetSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { SpaceTagType, SpaceTagsAxiosResponseSuccessType } from "@/axios/AiService/SpaceTags/Types/Get/Get.type";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Get/Get.type";
import { fetchNodeRelations } from "@/utils/FetchNodeRelations/FetchNodeRelations.util";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { SpaceAxiosResponseSuccessType, SpaceType } from "@/axios/AiService/Spaces/Types/Get/Get.type";
import { GetSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { GetAllResourcesFetch, GetCommonFetch } from "@/axios/SenseFacade/Public/Public.api";
import { CommonAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetCommon/GetCommon.type";
import { AllResourcesAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetAllResources/GetAllResources.type";
import { addUsersToRelations } from "@/utils/AddUsersToRelations/AddUsersToRelations.util";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { useSpacePageCommon } from "../SpacePageCommon/SpacePageCommon.provider";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";

const defaultValue: SpaceTagPageContextType = {
    isLoading: false,
    listOfNodes: [],
    space: null,
    spaceTag: null,
    update: () => undefined,
    listOfSpaceTagUsers: [],
};

const SpaceTagPageContext = createContext<SpaceTagPageContextType>(defaultValue);
export const useSpaceTagPage = () => useContext<SpaceTagPageContextType>(SpaceTagPageContext);

export const SpaceTagPageProvider = (props: { children: ReactNode }) => {
    const router = useRouter();
    const { isSharedSpace } = useIsShared();
    const {
        update: updateSpacePageCommon
    } = useSpacePageCommon();
    const spaceId = router.params.spaceId as string | undefined;
    const code = router.params.code as string | undefined;
    const { users } = useMe();
    const tagId = router.params.tagId as string;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [space, setSpace] = useState<SpaceType | null>(null);
    const [spaceTag, setSpaceTag] = useState<SpaceTagType | null>(null);
    const [listOfNodes, setListOfNodes] = useState<NodeWrapperType[]>([]);
    const loadSpace = useCallback(async () => {
        if (isSharedSpace) {
            if (!code) { return }
            await GetCommonFetch({
                code
            }).then(async (res: CommonAxiosResponseSuccessType) => {
                setSpace(res.data.space);
                const spaceTag = res.data.tags.find((tag) => tag.id === tagId);
                setSpaceTag(spaceTag || null);
                if (spaceTag) {
                    if (!code) { return }
                    await GetAllResourcesFetch({
                        code
                    }).then(async (resR: AllResourcesAxiosResponseSuccessType) => {
                        const listOfRelationships = await addUsersToRelations(res.data.users, resR.data.resourceDetails.filter(el => !!el.relationships).map(el => el.relationships!));
                        const wrappers = resR.data.resourceDetails.map((wrapper) => {
                            return {
                                node: wrapper.node,
                                relationships: listOfRelationships.find(relation => relation?.nodeId === wrapper.node.id),
                                importantUserIds: []
                            }
                        }).filter(wrapper => spaceTag.nodeIds.includes(wrapper.node.id));
                        setListOfNodes(wrappers);
                        setIsLoading(false)
                    });
                }
            });
        } else {
            if (!spaceId) { return }
            await GetSpaceFetch(spaceId).then((res: SpaceAxiosResponseSuccessType) => {
                setSpace(res.data);
            });
        }
    }, [code, isSharedSpace, spaceId, tagId])
    const loadSpaceTag = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) {return }
            await GetSpaceTagsFetch(spaceId).then(async (res: SpaceTagsAxiosResponseSuccessType) => {
                const currentSpace = res.data.find((spaceTag) => spaceTag.id === tagId);
                setSpaceTag(currentSpace || null);
                if (!currentSpace) {
                    console.error('Tag not found');
                    return;
                }
                await GetNodesFetch({
                    nodeIds: currentSpace.nodeIds
                }).then(async (res: NodesAxiosResponseSuccessType) => {
                    const listOfRelationships = await fetchNodeRelations(users, res.data.map((node) => node.id));
                    const nodeIds = res.data.map((el) => el.id)
                    const importantUsers = await fetchImportantUsers(nodeIds);
                    const wrappers = res.data.map((node) => {
                        const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                        return {
                            node: node,
                            relationships: listOfRelationships.find(relation => relation?.nodeId === node.id),
                            importantUserIds: importantInfo.userIds
                        }
                    });
                    setListOfNodes(wrappers);
                });
            });
        }
    }, [isSharedSpace, spaceId, tagId, users]);

    const loadHandle = useCallback(async () => {
        setIsLoading(true)
        updateSpacePageCommon();
        await loadSpace();
        await loadSpaceTag();
        setIsLoading(false);
    }, [loadSpace, loadSpaceTag, updateSpacePageCommon]);
    useEffect(() => {
        loadHandle();
    }, [loadHandle]);
    const trackEvent = useAmplitude();
    useEffect(() => {
        if (router.name === 'publicSpace') {
            trackEvent(EventTypes.SHARED_SPACE_TAG_VISITED)
        }
    }, [router.name, trackEvent]);
    useEffect(() => {
        const handleUpdate = () => {
            loadHandle();
        };
        window.addEventListener('updateDataByNodeDrawer', handleUpdate);
        return () => {
            window.removeEventListener('updateDataByNodeDrawer', handleUpdate);
        };
      }, [loadHandle]);

    const contextValue: SpaceTagPageContextType = {
        isLoading,
        listOfNodes,
        space,
        spaceTag,
        update: loadHandle,
        listOfSpaceTagUsers: users,
    };

    return <SpaceTagPageContext.Provider value={contextValue}>
        {props.children}
        
    </SpaceTagPageContext.Provider>;
};

export default SpaceTagPageContext;
import { useEffect, useState } from 'react';

export const usePortal = (id: string) => {
  const [target] = useState(() => {
    const el = document.createElement('div');
    el.id = id;
    return el;
  });

  useEffect(() => {
    const parentElem = document.querySelector('#root');
    if (!parentElem) return;
    parentElem.appendChild(target);
    return () => {
      parentElem.removeChild(target);
    };
  }, [target]);

  return target;
};

import { GetImportantUsersFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { GetImportantUsersAxiosResponseSuccessType, ImportantInfoType } from "@/axios/AiService/Nodes/Types/GetImportantUsers/GetImportantUsers.type";
import { ToastService } from "@/service/ToastService";

const userIds: string[] = [
    "43480285-3e51-477c-b32c-a3bcf5a1418f",
    "80f15c74-be87-48b0-9871-4b7a1e23848e",
    "a3f6bc5c-d0f9-4fee-aa18-20fb5bd5be90",
    "39ac90bc-01a8-4dcb-b3f2-59e860f30aaa",
    "ffe833e3-2333-4a57-8a74-aec122deb377",
    "d8357b7c-9d38-4118-99f2-02514d6be6fc",
    "a776f297-e6b5-4b0c-9eeb-9fb1edf46cdc",
    "acc63b47-c4b1-41f6-ac93-1c5b6e134a9e",
    "28dd43e5-5c69-421a-b17f-c32604c4a33a",
    "4f125d4e-3199-4ddf-bf1f-53cd074a6b11",
    "da1a8f85-d807-4b6d-bcbf-af857c016e8a",
    "dae503a5-b88d-45b2-bbd5-af49dec932df",
    "e289859b-9287-4020-aecd-8685b3dda174",
    "87baf9bb-dc48-49a9-a6fe-68c182f8f77d",
    "651f272c-d854-4d60-9004-dba0637ab6cb",
    "d29f389b-e4ea-4534-a1db-0fe9d961e755",
    "467923c3-d0e4-441e-aee8-9d360f54d985",
    "6417f129-c90b-4f3f-894c-995cc0cb13b5",
    "69babb0b-1332-4c67-aac8-54016b285dbc",
    "d6a2f150-4f3b-476c-8ca2-5ea68dbec66d",
    "b367fdc8-e65a-4ba4-9680-c20d6d2dc4c6",
    "ca9a94d7-b043-4fcc-ad5b-248c796048ba",
    "0fb5f947-4d75-4e12-802d-8ffabad1103f",
    "9b714d03-9a92-44d9-882d-092efb2d506c",
    "6f33e0e7-2c50-4106-907d-c8886544ce59",
    "52cd7356-fa8d-4f09-9819-066dc6134bae",
    "97ebe9ae-9790-4bfb-a7d4-0a45ee6c2e08",
    "cc3f7134-056e-4d29-b7d6-3dc8306b72c9",
    "fd57fbd2-d047-4e20-9198-57f914e9e6fe",
    "bce8835a-e3ce-45bf-8a89-909a94139d6d"
]

function getRandomElementFromArray(array: string[]) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}


export const fetchImportantUsers = async (nodeIds: number[]): Promise<ImportantInfoType[]> => {
    //for dev env
    // const mockData: ImportantInfoType[] = nodeIds.map((id) => {
    //     const randomUserIds = new Array(Math.floor(Math.random() * 3) + 1)
    //         .fill(null)
    //         .map(() => getRandomElementFromArray(userIds));
        
    //     return {
    //         nodeId: id,
    //         userIds: randomUserIds,
    //         relType: 'FOLLOWED_BY'
    //     }
    // })
    // return mockData;
  if (nodeIds.length === 0) {
    return [];
  }

  try {
    const res: GetImportantUsersAxiosResponseSuccessType = await GetImportantUsersFetch({ nodeIds });
    return res.data;
  } catch (error) {
    console.error('Error fetching important users:', error);
    ToastService.showToast('error', 'Some error occurred! Please try again later!');
    return [];
  }
}
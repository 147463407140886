import { IconProps } from './icon.type';

export const DoneIcon = (props: IconProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
  <g clipPath="url(#clip0_10651_11475)">
    <path d="M13.7096 0.291077L4.08464 13.7077L0.292969 9.91608" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_10651_11475">
      <rect width="14" height="14" fill="white"/>
    </clipPath>
  </defs>
</svg>
};

import { FC } from "react";
import { ChannelProps } from "./Channel.type";
import { Person } from "@/components/v3/List/Person/Person.component";
import { StyledWrapper } from "./Channel.styled";
import { Checkbox } from "@/components/v3/Fields/Checkbox/Checkbox.component";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";

export const Channel: FC<ChannelProps> = ({
    channel, value, onChange,
}) => {
    const isActive = value.includes(channel.id);
    const handleClick = () => {
        if (isActive) {
            onChange(value.filter((id) => id !== channel.id));
        } else {
            onChange([...value, channel.id]);
        }
    };
    return <StyledWrapper onClick={handleClick}>
        <Checkbox checked={isActive} />
        <ListItem
            title={channel.name}
        />
    </StyledWrapper>
};
import { FC, useMemo, useState } from 'react';

import { ShareSpaceDialog } from './components/ShareSpaceDialog/ShareSpaceDialog.component';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { useRouter } from '@/providers/Router/Router.provider';
import { useRenederAbove } from '@/hooks/v3/UseResponsive/UseResponsive.hook';
import { ShareIcon } from '@/icons/v3/share.icon';
import { ShareButtonProps } from './ShareButton.type';
import { StyledButton } from '../AddButton/AddButton.styled';
  
  export const ShareButton: FC<ShareButtonProps> = ({
    disabled = false
  }) => {
    const router = useRouter();
    const { user, invitedUsers, confirmedInvitedUsers } = useMe();
    const shouldRenederButtonText = useRenederAbove('tablet');
    const [isOpened, setIsOpened] = useState(false);
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    return (
        <>
            <StyledButton
                variant="secondary"
                icon={<ShareIcon />}
                disabled={disabled}
                onClick={() => {
                    if (isIndividual) {
                        router.push({
                            name: router.name,
                            params: router.params,
                            query: {
                                ...router.query,
                                dialog: 'payment',
                                plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                            }
                        })
                    } else {
                        setIsOpened(true)
                    }
                }}
            >
                {shouldRenederButtonText && 'Share'}
            </StyledButton>
            <ShareSpaceDialog isOpened={isOpened} setIsOpened={setIsOpened} />
        </>
    )
  }
  
import { FC, memo, useMemo, useState } from 'react';
import { ToastService } from 'service/ToastService';
import { NodeMenuProps } from './NodeMenu.type';
import { DeleteNodesFetch } from '@/axios/AiService/SpacesNodes/SpacesNodes.api';
import { useRouter } from '@/providers/Router/Router.provider';
import { GetSpaceTagsFetch, UpdateSpaceTagsFetch } from '@/axios/AiService/SpaceTags/SpaceTags.api';
import { SpaceTagsAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/Get/Get.type';
import { UpdateSpaceTagsAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/Put/Put.type';
import { ImportantForDialog } from './components/ImportantForDialog/ImportantForDialog.component';
import useCopyToClipboard from '@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook';
import { AddCommentDialog } from './components/AddCommentDialog/AddCommentDialog.component';
import { CreateSpaceKeyResourcesFetch, DeleteSpaceKeyResourcesFetch, DeleteSpaceKeyResourcesSuggestionsFetch } from '@/axios/AiService/Spaces/Spaces.api';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { useAddToSpace } from '@/providers/AddToSpace/AddToSpace.provider';
import { Menu } from "@/components/v3/Other/Menu/Menu.component";
import { MenuItem } from '@/components/v3/Other/MenuItem/MenuItem.component';
import { EditIcon } from '@/icons/v3/edit.icon';
import { RenameNodeModal } from './components/RenameNodeModal/RenameNodeModal.component';
import { ShareIcon } from '@/icons/v3/share.icon';
import { ShareNodeDialog } from '@/components/v3/Modals/ShareNodeDialog/ShareNodeDialog.component';
import { CommentIcon } from '@/icons/v3/comment.icon';
import { AddToIcon } from '@/icons/v3/add-to.icon';
import { RemoveFromSpaceIcon } from '@/icons/v3/remove-from-space.icon';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';
import { RemoveFromTagIcon } from '@/icons/v3/remove-from-tag.icon';
import { Divider } from '@/components/v3/Other/Divider/Divider.component';
import { RemoveFromKeyResourcesIcon } from '@/icons/v3/remove-from-key-resources.icon';
import { AddToKeyResourcesIcon } from '@/icons/v3/add-to-key-resources.icon';
import { NotImportantForMeIcon } from '@/icons/v3/not-important-for-me.icon';
import { ImportantForMeIcon } from '@/icons/v3/important-for-me.icon';
import { ImportantForIcon } from '@/icons/v3/important-for.icon';
import { CopyLinkIcon } from '@/icons/v3/copy-link.icon';
import { DeleteIcon } from '@/icons/v3/delete.icon';
import { useSpacePageCommon } from '@/providers/v3/SpacePageCommon/SpacePageCommon.provider';
import { useIsShared } from '@/hooks/v3/UseIsShared/UseIsShared.hook';
import { NodeTypeType } from '@/types/Node/Node.type';
import { DeleteNodeFetch } from '@/axios/AiService/Nodes/Nodes.api';

export const NodeMenu: FC<NodeMenuProps> = memo(({ node, spaceId: spaceIdProp, onUpdate, showCopy, showAddComment, onNewComment, onDeleteFromSense, buttonProps }) => {
  const [isOpenedRenameModal, setIsOpenedRenameModal] = useState(false);
  const { confirm, component } = useConfirm({
    cancelButtonProps: {
      variant: 'error'
    }
  });

  const router = useRouter();
  const { isShared } = useIsShared();
  const spaceIdParam = router.params.spaceId as string | undefined;
  const spaceTagId = router.params.tagId as string | undefined;
  
  const { user, followedNodes, follow, unfollow, invitedUsers, confirmedInvitedUsers } = useMe();
  const {
    listOfKeyResources,
    listOfKeyResourcesSuggestions
  } = useSpacePageCommon();
  const showKeyResources = useMemo(() => {
    return router.name === 'space' || router.name === 'spaceCategory' || router.name === 'spaceTag';
  }, [router.name]);
  const isKeyResource = useMemo(() => {
    return listOfKeyResources.map((keyResource) => keyResource.node.id).includes(node.id);
  }, [listOfKeyResources, node.id]);
  const isKeyResourceSuggestion = useMemo(() => {
    return listOfKeyResourcesSuggestions.map((keyResource) => keyResource.node.id).includes(node?.id);
  }, [listOfKeyResourcesSuggestions, node?.id]);
  const isFollowed = useMemo(() => {
    return !!followedNodes.find((followedNode) => followedNode.id === node?.id);
  }, [followedNodes, node]);
  const { addToSpace } = useAddToSpace();
  const spaceId = spaceIdProp || spaceIdParam;
  const [isOpenedImportantUsersDialog, setIsOpenedImportantUsersDialog] = useState<boolean>(false);
  const [isOpenedAddCommentDialog, setIsOpenedAddCommentDialog] = useState<boolean>(false);
  const [isOpenedShareDialog, setIsOpenedShareDialog] = useState<boolean>(false);

  const removeFromTagHandler = () => {
    confirm(async (newVal) => {
      if (newVal) {
        if (!spaceId || !spaceTagId) {
          return;
        }
        await GetSpaceTagsFetch(spaceId).then(async (res: SpaceTagsAxiosResponseSuccessType) => {
          const spaceTag = res.data.find((spaceTag) => spaceTag.id === spaceTagId);
          if (!spaceTag) {
            console.error('Tag not found');
            return;
          }
          await UpdateSpaceTagsFetch(spaceId, spaceTagId, {
            ...spaceTag,
            emoji: spaceTag.emoji || null,
            nodeIds: spaceTag.nodeIds.filter((nodeId) => nodeId !== node.id),
          }).then(async (res: UpdateSpaceTagsAxiosResponseSuccessType) => {
              onUpdate && onUpdate()
              ToastService.showToast('success', '1 resources was removed from the space!');
            })
            .catch(error => {
              ToastService.showToast('error', 'Some error occurred! Please try again later!');
            });
        })
        .catch(error => {
          ToastService.showToast('error', 'Some error occurred! Please try again later!');
        });
      }
    })
  };
  const removeFromSpaceHandler = () => {
    confirm(async (newVal) => {
      if (newVal) {
        if (!spaceId) {
          return;
        }
        await DeleteNodesFetch({
          spaceId,
          nodeIds: [node.id],
        }).then(() => {
          ToastService.showToast('success', '1 resources was removed from the space!');
          onUpdate();
        })
        .catch(error => {
          ToastService.showToast('error', 'Some error occurred! Please try again later!');
        });
      }
    })
  };
  const { copyToClipboard } = useCopyToClipboard();
  const copyHandle = () => {
    copyToClipboard(node.originalURI, 'Link copied to clipboard');
  };
  const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
  const amountOfPaidUsers = useMemo(() => {
    return invitedUsers.length + confirmedInvitedUsers.length + 1;
  }, [invitedUsers, confirmedInvitedUsers]);
  if (isShared) {
    return null;
  }
  const removeFromKeyResourcesSuggestion = () => {
    !!spaceId && DeleteSpaceKeyResourcesSuggestionsFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was removed!');
      onUpdate && onUpdate();
    })
  };
  const removeFromKeyResources = () => {
    !!spaceId && DeleteSpaceKeyResourcesFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was removed!');
      onUpdate && onUpdate()
    })
  };
  const addToKeyResources = () => {
    !!spaceId && CreateSpaceKeyResourcesFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was added!');
      onUpdate && onUpdate()
    })
  };
  const deleteFromSenseHandler = () => {
    confirm(async (newVal) => {
      if (newVal) {
        !!node.id && DeleteNodeFetch(node.id).then(() => {
          ToastService.showToast('success', 'Node was deleted!');
          !onDeleteFromSense && onUpdate();
          onDeleteFromSense && onDeleteFromSense();
        });
      }
    })
  };
  const isKeyResourceCommon = isKeyResourceSuggestion || isKeyResource;
  const removeFromKeyResourcesCommon = () => {
    if (isKeyResourceSuggestion) {
      removeFromKeyResourcesSuggestion();
    } else {
      removeFromKeyResources();
    }
  };
  const isNote = node?.nodeType === NodeTypeType.NOTE;
  return (
    <>
      <Menu buttonProps={buttonProps}>
        {isNote && <MenuItem icon={<EditIcon />} onClick={() => router.push({
          name: router.name,
          params: router.params,
          query: {
            ...router.query,
            node: undefined,
            dialog: 'edit-note',
            note: node.id
          }
        })}>Edit...</MenuItem>}
        {!isNote && <MenuItem icon={<EditIcon />} onClick={() => setIsOpenedRenameModal(true)}>Rename...</MenuItem>}
        <MenuItem icon={<ShareIcon />} onClick={() => {
          if (isIndividual) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                }
            })
        } else {
          setIsOpenedShareDialog(true)
        }
        }}>Share...</MenuItem>
        {showAddComment && <MenuItem icon={<CommentIcon />} onClick={() => setIsOpenedAddCommentDialog(true)}>Add comment...</MenuItem>}
        <MenuItem icon={<AddToIcon />} onClick={() => addToSpace(node.id)}>Add to...</MenuItem>
        <Divider />
        {showKeyResources && <>
          {isKeyResourceCommon && <MenuItem icon={<RemoveFromKeyResourcesIcon />} onClick={removeFromKeyResourcesCommon}>Remove from Key resources</MenuItem>}
          {!isKeyResourceCommon && <MenuItem icon={<AddToKeyResourcesIcon />} onClick={addToKeyResources}>Add to Key resources</MenuItem>}
        </>}
        {!!spaceTagId && <MenuItem icon={<RemoveFromTagIcon />} onClick={removeFromTagHandler}>Remove from tag</MenuItem>}
        {!!spaceId && <MenuItem icon={<RemoveFromSpaceIcon />} onClick={removeFromSpaceHandler}>Remove from space</MenuItem>}
        <Divider />
        {isFollowed && <MenuItem icon={<NotImportantForMeIcon />} onClick={() => unfollow(node.id)}>Not important for me</MenuItem>}
        {!isFollowed && <MenuItem icon={<ImportantForMeIcon />} onClick={() => follow(node.id)}>Important for me</MenuItem>}
        <MenuItem icon={<ImportantForIcon />} onClick={() => {
          if (user?.paymentPlan.name === 'TEAM' || user?.paymentPlan.name === 'PRO') {
            setIsOpenedImportantUsersDialog(true)
          } else {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                ...router.query,
                dialog: 'payment',
                plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
              }
            })
          }
        }}>Important for...</MenuItem>
        {showCopy && !isNote && <MenuItem icon={<CopyLinkIcon />} onClick={copyHandle}>Copy link</MenuItem>}
        <Divider />
        <MenuItem icon={<DeleteIcon />} variant="error" onClick={deleteFromSenseHandler}>Delete from Sense</MenuItem>
      </Menu>
      <RenameNodeModal value={isOpenedRenameModal} onChange={setIsOpenedRenameModal} node={node} onUpdate={onUpdate} />
      <ShareNodeDialog node={node} isOpened={isOpenedShareDialog} setIsOpened={setIsOpenedShareDialog} />
      <AddCommentDialog nodeId={node.id} value={isOpenedAddCommentDialog} onChange={setIsOpenedAddCommentDialog} onNewComment={onNewComment} />
      <ImportantForDialog nodeId={node.id} value={isOpenedImportantUsersDialog} onChange={setIsOpenedImportantUsersDialog} />
      {component}
    </>
  );
});

import { NodeTypeType } from "types/Node/Node.type";
import { NodeIconProps } from "./NodeIcon.type";
import { Img } from "../Img/Img.component";
import { FC } from "react";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { StyledMaskedImg } from "./NodeIcon.styled";

export const NodeIcon: FC<NodeIconProps> = ({ node, className, useSkeleton = true }) => {
  const { user } = useMe();
  const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
  const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
  const skeletonState = useSkeleton && isSubscriptionExpired;
  let imgSrc = node && node.application === 'Sense' ? '/icons/space.svg' : '/icons/google_chrome.svg';
  const getSrcByApplication = (application: string): string => {
    switch (application) {
      case 'Asana':
        return '/icons/icon-asana.png';
      case 'Github':
        return '/icons/icon-github.png';
      case 'ClickUp':
        return '/icons/icon-click_up.png';
      case 'Trello':
        return '/icons/icon-trello.png';
      case 'Figma':
        return '/icons/icon-figma.png';
      case 'Slack':
        return '/icons/icon-slack.png';
      case 'Jira':
        return '/icons/jira.svg';
      case 'Gmail':
        return '/icons/gmail.svg';
      case 'Outlook':
        return '/icons/outlook.svg';
      case 'Notion':
        return '/icons/icon-notion.svg';
      default:
        return '';
    }
  };
  if (node.nodeType === NodeTypeType.RESOURCE_NODE) {
    if (node.favicon !== undefined) {
      imgSrc = node.favicon;
    } else if (node.originalURI.includes('sharepoint')) {
      imgSrc = '/icons/sharepoint.svg';
    } else if (node.originalURI.includes('spreadsheets')) {
      imgSrc = '/icons/google_sheets.svg';
    } else if (node.originalURI.includes('presentation')) {
      imgSrc = '/icons/google_slides.svg';
    } else if (node.originalURI.includes('docs')) {
      imgSrc = '/icons/google_docs.svg';
    } else if (node.originalURI.includes('drive')) {
      imgSrc = '/icons/google_drive.svg';
    } else if (node.originalURI.includes('dropbox')) {
      imgSrc = '/icons/icon_dropbox.png';
    } else if (node.categories?.some((category) => category === 'file')) {
      const appSrc = getSrcByApplication(node.application);
      if (appSrc) {
        imgSrc = appSrc;
      } else {
        imgSrc = '/icons/icon_file.svg';
      }
    } else if (node.originalURI?.includes('linkedin')) {
      imgSrc = '/icons/icon_linkedin.png';
    }
  } else if (node.nodeType === NodeTypeType.TASK) {
    const appSrc = getSrcByApplication(node.application);
    if (appSrc) {
      imgSrc = appSrc;
    }
  } else if (node.nodeType === NodeTypeType.WIKI) {
    if (node.originalURI.includes('wiki')) {
      imgSrc = '/icons/confluence.svg';
    } else if (node.originalURI.includes('notion')) {
      imgSrc = '/icons/icon-notion.svg';
    }
  } else if (node.nodeType === NodeTypeType.EMAIL) {
    if (node.originalURI.includes('outlook')) {
      imgSrc = '/icons/outlook.svg';
    } else if (node.originalURI.includes('mail.google.com')) {
      imgSrc = '/icons/gmail.svg';
    }
  } else if (node.nodeType === NodeTypeType.BASE_NODE) {
    if (node.originalURI.includes('atlassian')) {
      imgSrc = '/icons/icon-jira.png';
    }
  } else if (node.nodeType === NodeTypeType.EVENT) {
    if (node.originalURI.includes('outlook')) {
      imgSrc = '/icons/calendar/outlook_calendar.png';
    } else if (node.originalURI.includes('google')) {
      imgSrc = '/icons/calendar/google_calendar.png';
    }
  }
  return <>
    {!skeletonState && <Img className={className} src={imgSrc} alt="" default="/icons/google_chrome.svg" />}
    {skeletonState && <StyledMaskedImg className={`${className} skeleton-img`} />}
  </>;
};

import { Person } from "@/components/v3/List/Person/Person.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { styled } from "@mui/material";

export const StyledFilledCard = styled(FilledCard)`
    margin-top: 16px;
`;

export const StyledEmptyCardWrapper = styled('div')`
    margin-top: 16px;
`
export const StyledPerson = styled(Person)`
    padding: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    border-radius: 6px;
    background: #ffffff;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
`;

import { SvgIcon, SvgIconProps } from '@mui/material';

export const Arrow = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.2 10.9333C7.6 11.4667 8.4 11.4667 8.8 10.9333L12.8 5.6C13.2944 4.94076 12.824 4 12 4H4C3.17596 4 2.70557 4.94076 3.2 5.6L7.2 10.9333Z" fill="#202020"/>
    </SvgIcon>
  );
};



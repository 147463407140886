import { FC } from 'react';
import { IconProps } from './Icon.type';
import { StyledIconWrapper, StyledSenseIcon } from './Icon.styled';
import { UserAvatar } from '@/components/v3/Other/UserAvatar/UserAvatar.component';

export const Icon: FC<IconProps> = ({ avatar, useSkeleton = true, isExpired = false }) => {
  return (
    <StyledIconWrapper>
      <UserAvatar useSkeleton={useSkeleton} isExpired={isExpired} size="large" avatar={avatar} />
      <StyledSenseIcon src='/icons/favicon.png' />
    </StyledIconWrapper>
  );
};

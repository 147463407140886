import { ErrorInfo, PureComponent, ReactElement } from 'react';
import { Box } from '@mui/material';


interface ErrorBoundaryProps {
  children: ReactElement;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
}

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }


  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, errorMessage: error.message ?? error.toString() });
  }

  render() {
    const {
      props: { children },
      state: { hasError, errorMessage }
    } = this;
    return hasError ? (
      <>
        <Box style={{ margin: '30px auto', color: '#2F2C2C' }}>
          Something went wrong! Try to reload page..
          {errorMessage && <Box>{errorMessage}</Box>}
        </Box>
      </>
    ) : (
      <>
        {children}
      </>);
  }

}
import { styled } from "@mui/material";
const HEADER_HEIGHT = 64;

export const StyledHeader = styled("div")`
    background: #FFF;
    padding: 16px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${HEADER_HEIGHT}px;
`;
export const StyledWrapper = styled("div")`
    background: #EBEBEB;
`;
export const StyledContent = styled("div")`
    overflow: auto;
    padding: 24px;
    height: calc(100vh - ${HEADER_HEIGHT}px);
`;
export const StyledLogo = styled("div")`
    padding: 12px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
`;
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Subject } from 'rxjs';
import { DataSubject } from './DataSubject';
import { HttpClient } from '../axios/HttpClient';

interface RequestConfig {
  preventImpersonation?: boolean;
  params?: any;
}

interface UpdateProps<U> {
  id?: number | string;
  obj: U;
  config?: RequestConfig;
  fetchAfterUpdate?: boolean;
}

export class DataService<T, U = T extends Array<infer Item> ? Item : T> extends DataSubject<T> {
  protected readonly baseUrl: string;

  // can be used directly only by descendant classes
  protected isFetching = false;

  // can be used outside of class instance
  public get fetching() {
    return this.isFetching;
  }

  protected readonly defaultRequestConfig: RequestConfig = {
    preventImpersonation: false,
  };

  private readonly onUpdateSubject: Subject<U> = new Subject<U>();

  constructor(_baseUrl: string, readonly _initValue: T) {
    super(_initValue);
    this.baseUrl = _baseUrl;

    this.onSave = this.onSave.bind(this);
    this.fetch = this.fetch.bind(this);
    this.update = this.update.bind(this);
    this.create = this.create.bind(this);
    this.remove = this.remove.bind(this);
    this.onSaveSuccessful = this.onSaveSuccessful.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getFetchUrl(_arg?: string): string {
    return this.baseUrl;
  }

  protected getDefaultRequestConfig(): RequestConfig {
    return this.defaultRequestConfig;
  }

  protected getUpdateUrl(id?: number | string): string {
    return id ? `${this.baseUrl}/${id}` : this.baseUrl;
  }

  public onSave(callback: (data: U) => void): void {
    this.onUpdateSubject.subscribe(callback);
  }

  public fetch(force = false, config: RequestConfig = {}, fetchArg?: string): void {
    if (force || (this.getValue() === this.initValue && !this.isFetching)) {
      this.isFetching = true;
      const reqConfig = { ...this.getDefaultRequestConfig(), ...config } as AxiosRequestConfig;
      HttpClient.get(this.getFetchUrl(fetchArg), reqConfig)
        .then(({ data }) => {
          this.next(this.convertFetchResponse(data));
        })
        .catch(() => this.reset())
        .finally(() => {
          this.isFetching = false;
        });
    }
  }

  protected convertFetchResponse = (data: any): T => {
    return data;
  };

  protected convertUpdateResponse = (data: any): U => {
    return data;
  };

  public update({ id, obj, config, fetchAfterUpdate = true }: UpdateProps<U>): Promise<U> {
    const reqConfig = { ...this.getDefaultRequestConfig(), ...config } as AxiosRequestConfig;
    return HttpClient.put(this.getUpdateUrl(id), obj, reqConfig).then(result =>
      this.onSaveSuccessful(result, fetchAfterUpdate)
    );
  }

  public create(obj: U, config?: RequestConfig, fetchAfterUpdate = true): Promise<U> {
    const reqConfig = { ...this.getDefaultRequestConfig(), ...config } as AxiosRequestConfig;
    return HttpClient.post(this.baseUrl, obj, reqConfig).then(result =>
      this.onSaveSuccessful(result, fetchAfterUpdate)
    );
  }

  public remove(id: number | string, config?: RequestConfig): Promise<void> {
    const reqConfig = { ...this.getDefaultRequestConfig(), ...config } as AxiosRequestConfig;
    return HttpClient.delete(this.getUpdateUrl(id), reqConfig).then(() => this.fetch(true));
  }

  private onSaveSuccessful({ data }: AxiosResponse<U>, fetchAfterUpdate: boolean) {
    if (fetchAfterUpdate) {
      this.fetch(true);
    }
    this.onUpdateSubject.next(this.convertUpdateResponse(data));
    return data;
  }
}

import { Dialog, DialogActions, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        min-width: 300px;
        min-height: 140px;
    }
`;
export const StyledDialogActions = styled(DialogActions)`
    margin-top: auto;
`;
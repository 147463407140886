import { FC, useMemo } from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment/moment';
import { Icon } from './components/Icon/Icon.component';
import { cutLinks } from 'utils/cutLinks.util';
import { CommentProps } from './Comment.type';
import { StyledActivity, StyledChannel, StyledDate, StyledMassage, StyledName } from './Comment.styled';
import { getFullName } from '@/utils/v3/GetFullName/GetFullName.util';
import { formatComment } from './Comment.utils';

export const Comment: FC<CommentProps> = ({ comment, className, users, useSkeleton = true, isExpired = false }) => {
  const user = useMemo(() => {
    const user = users.find((user) => user.id === comment.userId);
    return user;
  }, [users, comment.userId]);
  const fullName = useMemo(() => {
    return getFullName(user?.firstName, user?.lastName)
  }, [user]);
  const formattedContent = useMemo(() => formatComment(comment.content), [comment.content]);
  return <StyledActivity className={className}>
    <Icon useSkeleton={useSkeleton} isExpired={isExpired} avatar={user?.avatar} />
    <div>
        <div>
            <StyledName useSkeleton={useSkeleton} isExpired={isExpired}>{fullName}</StyledName>
            <StyledChannel useSkeleton={useSkeleton} isExpired={isExpired}> commented internally</StyledChannel>
        </div>
        <StyledMassage useSkeleton={useSkeleton} isExpired={isExpired}>
          {parse(sanitizeHtml(cutLinks(formattedContent), {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
            allowedAttributes: {
              ...sanitizeHtml.defaults.allowedAttributes,
              'a': ['href', 'title', 'target', 'class']
            }
          }))}
        </StyledMassage>
        <StyledDate useSkeleton={useSkeleton} isExpired={isExpired}>{moment.utc(comment.createdAt).local().format('MMMM Do YYYY, h:mm a')}</StyledDate>
    </div>
  </StyledActivity>;
};

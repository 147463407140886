// @ts-nocheck

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { envUrl } from "../service/helpers";
import { clearCookies } from 'utils/ClearCookies/ClearCookies.util';
import { getCookie } from '@/utils/GetCookie/GetCookie.util';
import ChromeExtensionService from '@/providers/ChromeExtension/services/ChromeExtension/ChromeExtension.srvice';

export const HttpClient = axios.create();
export const HttpLoginClient  = axios.create();
HttpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response && error.response.status === 401 &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/v3/login' &&
      !window.location.pathname.includes('/two-factor-authentication')
    ) {
      clearCookies();
      delete HttpClient.defaults.headers.common['Authorization'];
      delete HttpClient.defaults.headers.common['X-CSRF-Token'];
      delete HttpClient.defaults.headers.common['X-Org-Id'];
      if (window.location.pathname.includes('/v3/')) {
        window.location.pathname = '/v3/login';
      } else {
        window.location.pathname = '/login';
      }
    }
    if (window.location.pathname.includes('public') && error.response && error.response.status === 403) {
      window.location.pathname = '/404';
    }
    return Promise.reject(error);
  }
);


type MapperType = ({ data }: AxiosResponse) => any;

const standardMapper: MapperType = ({ data }: AxiosResponse) => data;

export const get = <T>(
  url: string,
  queryParams?: object,
  config?: AxiosRequestConfig,
  mapper: MapperType = standardMapper
): Observable<T> => defer(() => HttpClient.get<T>(`${envUrl}` + url, { params: queryParams, ...config })).pipe(map(mapper));

export const post = <IN, OUT = IN>(
  url: string,
  body: IN,
  config?: AxiosRequestConfig,
  mapper: MapperType = standardMapper
): Observable<OUT> => defer(() => HttpClient.post<IN, AxiosResponse<OUT>>(url, body, config)).pipe(map(mapper));

class AuthHeaderInterceptorService {
  intercept = async (config: AxiosRequestConfig) => {
    let { headers } = config;
    headers = headers ?? {};
    const results = await ChromeExtensionService.getLocalStorage(['accessToken']);
    if (results.success && results.data) {
        const access_token = results.data['accessToken'];
        if (!!access_token) {
          headers.Authorization = `Bearer ${access_token}`;
        }
    }
    const csrf_token = getCookie('csrf_token');
    if (csrf_token) {
      headers['X-CSRF-Token'] = csrf_token;
    }
    return { ...config, headers };
  };
}

const AuthHeaderInterceptor = new AuthHeaderInterceptorService();
HttpClient.interceptors.request.use(AuthHeaderInterceptor.intercept);

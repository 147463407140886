import { Add2Icon } from "@/icons/v3/add2.icon";
import { styled } from "@mui/material";

export const StyledEmptyCardWrapper = styled('div')`
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
`
export const StyledAddIcon = styled(Add2Icon)`
    & path {
        fill: #202020;
    }
`
import { FC } from "react";
import { CalendarGroupProps } from "./CalendarGroup.type";
import { CalendarEvent } from "./components/CalendarCluster/components/CalendarEvent/CalendarEvent.component";
import { CalendarCluster } from "./components/CalendarCluster/CalendarCluster.component";

export const CalendarGroup: FC<CalendarGroupProps> = ({ group, startHour, groupWidth, groupLeft, baseWidth, useSkeleton=true }) => {
    if (group.clusters.length > 0) {
        const clusterWidth = groupWidth / group.clusters.length;
        const clustersJsx = group.clusters.map((cluster, i) => {
            const clusterLeft = clusterWidth * i + groupLeft;
            return (
                <CalendarCluster useSkeleton={useSkeleton} cluster={cluster} key={i} startHour={startHour} clusterWidth={clusterWidth} clusterLeft={clusterLeft} baseWidth={baseWidth} />
            )
        })
        return <>
            {clustersJsx}
        </>
    }
    const eventWidth = groupWidth / group.events.length;
    const eventsJsx = group.events.map((event, i) => {
        const eventLeft = eventWidth * i + groupLeft;
        return (
            <CalendarEvent useSkeleton={useSkeleton} event={event} key={i} events={group.events} startHour={startHour} eventWidth={eventWidth} eventLeft={eventLeft} baseWidth={baseWidth} />
        )
    })
    return (
        <>
            {eventsJsx}
        </>
    )
}
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { NodeCard } from "@/components/v3/List/NodeCard/NodeCard.component";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { FC } from "react";
import { StyledNodesWrapper, StyledScrollWrapper, StyledSectionHeader, StyledWrapper } from "./RecentlyUpdated.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

export const RecentlyUpdated: FC = () => {
    const { isFetchingListOfLastRecentActivities, listOfLastRecentActivities, setActiveTab, update } = useSpacePage();
    const isLargeScreen = useRenederAbove('large');
    const countOfRecentNodes = isLargeScreen ? 4 : 3;
    const recentNodes = listOfLastRecentActivities.slice(0, countOfRecentNodes);
    const nodesJsx = recentNodes.map((el, i) => {
        return <NodeCard key={i} node={el.node} onUpdate={update} onNewComment={update} />
    });
    return <StyledWrapper>
        <StyledSectionHeader title="Recently updated" >
            <Button onClick={() => setActiveTab('ACTIVITIES')} variant="tertiary">Show all</Button>
        </StyledSectionHeader>
        <LoaderLayout isLoading={isFetchingListOfLastRecentActivities} transparent={!!nodesJsx.length}>
            <StyledScrollWrapper>
                <StyledNodesWrapper countOfRecentNodes={countOfRecentNodes}>
                    {nodesJsx}
                </StyledNodesWrapper>
            </StyledScrollWrapper>
        </LoaderLayout>
    </StyledWrapper>
};
import { WalkthroughWrapperProps } from "./WalkthroughWrapper.type";
import { useWalkthrough } from "../../Walkthrough.provider";
import ReactDOM from 'react-dom';

import {
    StyledWalkthroughWrapper,
    StyledContainer,
    StyledArrow,
    StyledTitle,
    StyledDescription,
    StyledButtonContainer,
    StyledButton,
    StyledSkipButton,
} from "./WalkthroughWrapper.styled";
import { useEffect, useState, useRef } from "react";
import { usePortal as usePortalHook } from "hooks/UsePortal/UsePortal.hook";

export const WalkthroughWrapper: React.FC<WalkthroughWrapperProps> = ({
    children,
    variant,
    title,
    description,
    position: positionProp = 'right',
    targetId,
    minWidth = 320,
    maxWidth,
    topContainerMargin = 32,
    topMargin = 0,
    leftMargin = 0,
    usePortal = false
}: WalkthroughWrapperProps) => {
    const portalTarget = usePortalHook('walkthrough-portal');
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [top, setTop] = useState<number>(0);
    const [left, setLeft] = useState<number>(0);
    const [arrowTop, setArrowTop] = useState<number>(0);
    const [arrowLeft, setArrowLeft] = useState<number>(0);
    const { isOpened, onGotIt, onSkip } = useWalkthrough(variant);
    const boxRef = useRef<HTMLDivElement>(null);
    const padding = 16;
    useEffect(() => {
        const target = document.getElementById(targetId);
        if (target && boxRef.current) {
            const targetWidth = target.offsetWidth;
            const targetHeight = target.offsetHeight;
            const targetBounds = target.getBoundingClientRect();
            const targetTop = usePortal ? targetBounds.top : target.offsetTop;
            const targetLeft = usePortal ? targetBounds.left : target.offsetLeft;


            const boxWidth = boxRef.current.offsetWidth;
            const boxHeight = boxRef.current.offsetHeight;

            switch (positionProp) {
                case 'top':
                    setTop(targetTop - boxHeight - padding);
                    setLeft(targetLeft + targetWidth / 2 - boxWidth / 2);
                    setArrowTop(targetTop - padding - 8);
                    setArrowLeft(targetLeft + targetWidth / 2 - 2);
                    break;
                case 'bottom':
                    setTop(targetTop + targetHeight + padding);
                    setLeft(targetLeft + targetWidth / 2 - boxWidth / 2);
                    setArrowTop(targetTop + targetHeight);
                    setArrowLeft(targetLeft + targetWidth / 2 - 2);
                    break;
                case 'left':
                    setTop(targetTop + targetHeight / 2 - boxHeight / 2  + topContainerMargin);
                    setLeft(targetLeft - boxWidth - padding);
                    setArrowTop(targetTop + targetHeight / 2 - 10);
                    setArrowLeft(targetLeft - padding * 2);
                    break;
                case 'right':
                    setTop(targetTop + targetHeight / 2 - boxHeight / 2  + topContainerMargin);
                    setLeft(targetLeft + targetWidth + padding);
                    setArrowTop(targetTop + targetHeight / 2 - 10);
                    setArrowLeft(targetLeft + targetWidth + 4);
                    break;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetId, positionProp, isOpened, minWidth, maxWidth, topContainerMargin]);

    useEffect(() => {
        if (isOpened) {
            setTimeout(() => {
                setIsVisible(true);
            }, 200);
        } else {
            setIsVisible(false);
        }
    }, [isOpened]);
    const gotItHandle = () => {
        setIsVisible(false);
        setTimeout(() => {
            onGotIt();
        }, 200);
    }
    const skipHandle = () => {
        setIsVisible(false);
        setTimeout(() => {
            onSkip();
        }, 200);
    }

    const content =  <>
        <StyledContainer minWidth={minWidth} maxWidth={maxWidth} top={top + topMargin} left={left + leftMargin} isVisible={isVisible} ref={boxRef} usePortal={usePortal}>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
            <StyledButtonContainer>
                <StyledSkipButton onClick={skipHandle}>Skip tour</StyledSkipButton>
                <StyledButton onClick={gotItHandle}>Got it</StyledButton>
            </StyledButtonContainer>
        </StyledContainer>
        <StyledArrow isVisible={isVisible} position={positionProp} top={arrowTop + topMargin} left={arrowLeft + leftMargin}/>
    </>

    return <StyledWalkthroughWrapper>
        {isOpened && <>
            {usePortal
                ? ReactDOM.createPortal(
                    content,
                    portalTarget
                  )
                : content}
            
        </>}
        {children}
    </StyledWalkthroughWrapper>
}
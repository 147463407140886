import { useMemo, useState } from "react";
import { SelectProps } from "./Select.type";
import { Option } from "./components/Option/Option.component";
import { DropdownField } from "../DropdownField/DropdownField.component";
import { SelectChangeEvent, MenuItem, Select as MuiSelect, InputLabel, FormControl } from "@mui/material";
import { StyledFormControl } from "./Select.styled";

export const Select = <T,>(props: SelectProps<T>) => {
    const {
        value,
        options,
        onChange,
        label: labelProp,
        className,
        size = "small",
    } = props;
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const isMultiple = Array.isArray(value);
    const optionsJsx = options.map((option, i) => {
        return (
            <Option value={value} key={i} option={option} onChange={(val) => {
                if (isMultiple) {
                    const newVal = [...value as T[]];
                    const index = newVal.indexOf(val);
                    if (index === -1) {
                        newVal.push(val);
                    } else {
                        newVal.splice(index, 1);
                    }
                    (onChange as (newValue: T[]) => void)(newVal);
                } else {
                    (onChange as (newValue: T) => void)(val);
                    setIsOpened(false);
                }
            }} />
        );
    });
    const label = useMemo(() => {
        return options.find((option) => option.value === value)?.label || labelProp;
    }, [labelProp, options, value]);
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const val = event.target.value as T;
        (onChange as (newValue: T) => void)(val);
      };
    return (
        <>
            {size === "small" && <DropdownField 
                {...props}
                label={label} 
                onChange={setIsOpened}
                value={isOpened}
                className={className}
            >
                {optionsJsx}
            </DropdownField>}
            {size === 'large' && !isMultiple && <StyledFormControl variant="outlined" className={className}>
                <InputLabel>{labelProp}</InputLabel>
                <MuiSelect
                    label={labelProp}
                    value={value as T}
                    onChange={handleChange}
                    
                >
                    {options.map((option, i) => {
                        return <MenuItem key={i} value={option.value as string}>{option.label}</MenuItem>
                    })}
                </MuiSelect>
            </StyledFormControl>}
            </>
    );
};

import { FC } from "react";
import { MenuItemProps } from "./MenuItem.type";
import { StyledIcon, StyledMenuItem } from "./MenuItem.styled";

export const MenuItem: FC<MenuItemProps> = ({
    children,
    onClick,
    className,
    icon,
    variant = 'primary'
}) => {
    return <StyledMenuItem onClick={onClick} className={className} variant={variant}>
        {!!icon && <StyledIcon variant={variant}>{icon}</StyledIcon>}
        {children}
    </StyledMenuItem>
};
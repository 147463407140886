import { styled } from "@mui/material";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledTitle = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`
export const StyledSubTitle = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
`
export const StyledPageHeaderWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;]
`
export const StyledContentNodeTitleWrapper = styled('div')`
    & img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }
    & .skeleton-img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }
`
export const StyledContentNodeTitleContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`
export const StyledImageContainer = styled('div')`
    margin-top: 8px;
`
export const StyledLineButtons = styled('div')`
    display: flex;
`
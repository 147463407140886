import { FC, useState } from "react";
import { ImportantProps } from "./Important.type";
import {
    StyledNodeImportant,
    StyledNodeImportantIcon,
    StyledNodeImportantPeople,
    StyledNodeImportantPerson,
    StyledText
} from "./Important.styled";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { ToolpipContent } from "./components/ToolpipContent/ToolpipContent.component";

export const Important: FC<ImportantProps> = ({ size, users, useSkeleton = true, isExpired = false }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleTooltipClose = () => {
        setAnchorEl(null);
    };

    const handleTooltipOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'popper' : undefined;
    const isMobile = useRenederBelow('tablet');
    const hasImportantUsers = users.length > 0;
    const listOfUsersJsx = users.map((user, i) => {
        return <StyledNodeImportantPerson useSkeleton={useSkeleton} isExpired={isExpired} avatar={user.avatar} size="small" key={i} />
    })
    return <StyledNodeImportant
        hasImportantUsers={hasImportantUsers}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
    >
        <StyledNodeImportantIcon/>
        {size==='large' && !isMobile && <StyledText>Important</StyledText>}
        {hasImportantUsers && <StyledNodeImportantPeople>
            {listOfUsersJsx}
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Popper id={id} open={open} anchorEl={anchorEl} placement="top" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <ToolpipContent users={users} />
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        </StyledNodeImportantPeople>}
    </StyledNodeImportant>
};
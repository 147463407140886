import { useMemo } from "react";
import { PesonWrapper, PesonName, PesonEmail } from "./Person.styled";
import { PersonProps } from "./Person.type";
import { getFullName } from "@/utils/GetFullName/GetFullName.util";
import { UserAvatar } from "@/components/v3/Other/UserAvatar/UserAvatar.component";

export const Person = ({ user }: PersonProps) => {
    const fullName = useMemo(() => {
        return getFullName(user.firstName, user.lastName);
    }, [user.firstName, user.lastName]);
    return (
        <PesonWrapper>
            <UserAvatar avatar={user.avatar} />
            <div>
                <PesonName>{fullName}</PesonName>
                <PesonEmail>{user.email}</PesonEmail>
            </div>
        </PesonWrapper>
    )
}
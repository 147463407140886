import { Box, Button, Typography, styled } from "@mui/material";

export const StyledBanner = styled(Box)`
    padding: 12px 24px;
    width: 100%;
    height: 56px;
    background: #202020;
    z-index: 200;
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
`;
export const StyledNote = styled(Typography)`
    padding-top: 7px;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;
export const StyledButton = styled(Button)`
    width: 204px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 6px;
    &:hover {
        background: #FFFFFF;
    }
`;
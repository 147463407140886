import { useRouter } from "@/providers/Router/Router.provider";
import { StyledCreateSpaceButton, StyledCreateSpaceIcon, StyledText } from './CreateSpaceButton.styled';

export const CreateSpaceButton = () => {
  const router = useRouter()

  return (
      <>
        <StyledCreateSpaceButton
          onClick={() => {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                dialog: 'create-space',
              }
            })
          }}
        >
          <StyledCreateSpaceIcon /><StyledText>Create spaces</StyledText>
        </StyledCreateSpaceButton>
      </>

  );
};

import { FC, useEffect, useMemo, useState } from "react";
import { GroupNodeType, RecentActivitiesProps } from "./RecentActivities.type";
import { getAppOptionsFromNodeWrappers } from "@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import useInfiniteScroll from "@/hooks/v3/UseInfiniteScroll/UseInfiniteScroll.hook";
import { StyledFiltersContainer, StyledFiltersWrapper, StyledRelationsFiltersWrapper, StyledSelect, StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { ActivityGroup } from "@/components/v3/List/ActivityGroup/ActivityGroup.component";
import moment from "moment";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";
import { GetActivitiesDetailsFetch } from "@/axios/SenseFacade/ActivitiesDetails/ActivitiesDetails.api";
import { ActivitiesDetailsAxiosResponseSuccessType } from "@/axios/SenseFacade/ActivitiesDetails/Types/Get/Get.type";
import { addUsersToRelations } from "@/utils/AddUsersToRelations/AddUsersToRelations.util";
import { RelationCheckboxButton } from "@/pages/v3/Space/components/Activities/components/RelationCheckboxButton/RelationCheckboxButton.component";
import { RelationActionType } from "@/types/Relations/Relations.type";
import { getActivityRelations, getActivityValue, listOfActivityOptions } from "@/utils/v3/ActivityRelations/ActivityRelations.utils";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";

export const RecentActivities: FC<RecentActivitiesProps> = ({
    onUpdate,
    user
}) => {
    const router = useRouter();
    const { useNewActivityUI } = useFeatureFlags();
    const { users } = useMe();
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const [selectedRelations, setSelectedRelations] = useState<RelationActionType[]>([]);
    const onUpdateHandler = () => {
        refresh();
        onUpdate();
    }
    async function fetchRecentActivities(offset: number, limit: number) {
        return await GetActivitiesDetailsFetch({
            limit: limit,
            offset: offset,
            userId: router.params.fromUserId as string,
            relations: selectedRelations,
        }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
            const nodeIds = res.data.activities.map((activity) => activity.node.id);
            const importantUsers = await fetchImportantUsers(nodeIds);
            const wrappers = res.data.activities.map((activity) => {
                const listOfRelationships = addUsersToRelations(users, [activity.relationships]);
                const importantInfo = importantUsers.find(info => info.nodeId === activity.node.id)!
                return {
                    node: activity.node,
                    messages: activity.message,
                    relationships: listOfRelationships[0],
                    importantUserIds: importantInfo.userIds
                }
            });
            return wrappers;
        })
    };

    const { items, isFetching, refresh } = useInfiniteScroll(fetchRecentActivities);
    const appsOptions = useMemo((): OptionType<string>[] => {
        return getAppOptionsFromNodeWrappers(items);
    }, [items]);
    
    const groupedNodes = useMemo((): GroupNodeType[][] => {
        const groupsMap = new Map();
        items.forEach(wrapper => {
            const lastActor = findLastActor(wrapper.relationships?.actors || []);
            const happenedAtDate = lastActor?.properties.happenedAt || wrapper.node.createdAt;
            const momentDate = moment(happenedAtDate).format('YYYY-MM-DD');
            if (!groupsMap.has(momentDate)) {
                groupsMap.set(momentDate, []);
            }
            groupsMap.get(momentDate).push({
                ...wrapper,
                lastActor
            });
        });
        return Array.from(groupsMap.values());
    }, [items]);
    const groupsJsx = groupedNodes.map((group, i) => {
        return <ActivityGroup
            key={i}
            date={group[0].node.createdAt}
            group={group.map((wrapper) => {
                return {
                    node: wrapper.node,
                    activities: wrapper.messages,
                    relationships: wrapper.relationships,
                    users: users,
                    onUpdate: onUpdateHandler,
                    importantUserIds: wrapper.importantUserIds,
                    lastActor: wrapper.lastActor
                }
            })}
        />
    })
    useEffect(() => {
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApps, selectedRelations]);
    const setSelectedRelationsHandler = (val: string[]) => {
        const newVal = getActivityRelations(val)
        setSelectedRelations(newVal);
    };
    const activityValue = useMemo(() => {
        return getActivityValue(selectedRelations)
    }, [selectedRelations]);
    return <LoaderLayout isLoading={isFetching && !items.length} transparent={!!items.length}>
        <StyledWrapper>
            {!useNewActivityUI && <StyledRelationsFiltersWrapper>
                <RelationCheckboxButton val={[RelationActionType.MENTIONS, RelationActionType.CC, RelationActionType.BCC]} value={selectedRelations} onChange={setSelectedRelations}>Mentioned you</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.ASSIGNEE]} value={selectedRelations} onChange={setSelectedRelations}>Assigned to you</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.UPDATED]} value={selectedRelations} onChange={setSelectedRelations}>Task status changed</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.COMMENTED_BY, RelationActionType.COMMENTER, RelationActionType.POSTED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Commented</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.CREATED_BY, RelationActionType.CREATOR]} value={selectedRelations} onChange={setSelectedRelations}>Created</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.EDITED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Edited</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.SHARED_TO, RelationActionType.SHARED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Shared</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.FROM]} value={selectedRelations} onChange={setSelectedRelations}>Sent to you</RelationCheckboxButton>
                <RelationCheckboxButton val={[RelationActionType.ADDED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Added manually</RelationCheckboxButton>
            </StyledRelationsFiltersWrapper>}
            <StyledFiltersWrapper>
                <StyledFiltersContainer>
                    {useNewActivityUI && <StyledSelect
                        label="Activity"
                        value={activityValue}
                        onChange={(val: any) => setSelectedRelationsHandler(val)}
                        options={listOfActivityOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />}
                    <StyledSelect
                        label="Apps"
                        value={selectedApps}
                        onChange={(val: any) => setSelectedApps(val)}
                        options={appsOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                </StyledFiltersContainer>
            </StyledFiltersWrapper>
            {groupsJsx}
        </StyledWrapper>
        <CreateNoteDrawer onUpdate={onUpdateHandler} />
    </LoaderLayout>
};
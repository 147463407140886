import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { CommonGetQueryParams } from "./Types/GetCommon/GetCommon.type";
import { UpdatesGetQueryParams } from "./Types/GetUpdates/GetUpdates.type";
import { AllResourcesGetQueryParams } from "./Types/GetAllResources/GetAllResources.type";
import { NodeGetQueryParams } from "./Types/GetNode/GetNode.type";

export const GetCommonFetch = (query: CommonGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/public/spaces/common`, {
        params: query,
    });
}
export const GetUpdatesFetch = (query: UpdatesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/public/spaces/updates`, {
        params: {
            ...query,
            limit: query.limit || 30,
            users: !!query?.users?.length ? query.users.join(',') : undefined,
            apps: !!query?.apps?.length ? query.apps.join(',') : undefined,
        },
    });
}
export const GetAllResourcesFetch = (query: AllResourcesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/public/spaces/resources`, {
        params: query,
    });
}
export const GetSpaceNodeFetch = (nodeId: number, query: NodeGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/public/spaces/nodes/${nodeId}`, {
        params: query,
    });
}
export const GetNodeFetch = (query: NodeGetQueryParams) => {
    return HttpClient.get(`${envUrl}/sense-facade/v1/public/nodes`, {
        params: query,
    });
}
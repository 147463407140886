import { HOUR_HEIGHT, MIN_HEIGHT } from "../../MeetingsTimeline.const";
import { CalendarClusterType, CalendarGroupType } from "./components/CalendarGroup/CalendarGroup.type";
import { CalendarEventType } from "./components/CalendarGroup/components/CalendarCluster/components/CalendarEvent/CalendarEvent.type";

const minutePx = HOUR_HEIGHT / 60;
const minEventTime = MIN_HEIGHT * minutePx;

export const getVisibleEndTime = (start: Date, end: Date): Date => {
    if ((end.getTime() - start.getTime()) / (1000 * 60) < minEventTime) {
        return new Date(start.getTime() + minEventTime * 60 * 1000);
    }
    return end;
};
export const doEventsOverlap = (eventA: CalendarEventType, eventB: CalendarEventType) => {
    const visibleEndTimeA = getVisibleEndTime(eventA.start, eventA.end || new Date());
    const visibleEndTimeB = getVisibleEndTime(eventB.start, eventB.end || new Date());
    return eventA.start < (visibleEndTimeB || Infinity) && (visibleEndTimeA || Infinity) > eventB.start;
};
function dfs(adjacencyList: Map<number, number[]>, visited: boolean[], index: number): void {
    visited[index] = true;

    for (let neighbor of adjacencyList.get(index) || []) {
        if (!visited[neighbor]) {
            dfs(adjacencyList, visited, neighbor);
        }
    }
}

export function areAllEventsIntersecting(events: CalendarEventType[]): boolean {
    const adjacencyList = new Map<number, number[]>();
    events.forEach((event, index) => {
        adjacencyList.set(index, []);
        events.forEach((otherEvent, otherIndex) => {
            if (index !== otherIndex && doEventsOverlap(event, otherEvent)) {
                adjacencyList.get(index)!.push(otherIndex);
            }
        });
    });
    let visited = new Array(events.length).fill(false);
    dfs(adjacencyList, visited, 0);
    return visited.every(v => v);
}



export function clusterEvents(events: CalendarEventType[]): CalendarClusterType[] {
    let allIntersectingEvents: CalendarEventType[] = [];
    let someNonIntersectingEvents: CalendarEventType[] = [];

    for (let event of events) {
        if (events.every(otherEvent => doEventsOverlap(event, otherEvent) || event.id === otherEvent.id)) {
            allIntersectingEvents.push(event);
        } else {
            someNonIntersectingEvents.push(event);
        }
    }

    const generateGroupFromEvents = (eventsList: CalendarEventType[]): CalendarClusterType => {
        if (eventsList.length === 0) return { start: new Date(), end: new Date(), events: [], groups: [] };

        const start = new Date(Math.min(...eventsList.map(e => e.start.getTime())));
        const end = new Date(Math.max(...eventsList.map(e => e.end.getTime())));

        let groups: CalendarGroupType[] = [];
        const allEventsIntersecting = areAllEventsIntersecting(eventsList);
        if (eventsList.length > 2 && !allEventsIntersecting) {
            groups = groupEvents(eventsList);
        }

        return { start, end, events: eventsList, groups: groups };
    };

    return [allIntersectingEvents, someNonIntersectingEvents]
        .filter(events => events.length > 0)
        .map(events => generateGroupFromEvents(events));
}


export function groupEvents(events: CalendarEventType[]): CalendarGroupType[] {
    const groups: CalendarGroupType[] = [];
    events.forEach(event => {
        let foundGroup = false;

        for (let group of groups) {
            if (group.events.some(e => doEventsOverlap(e, event))) {
                group.events.push(event);
                if (event.start.getTime() < group.start.getTime()) {
                    group.start = event.start;
                }
                if (event.end.getTime() > group.end.getTime()) {
                    group.end = event.end;
                }
                foundGroup = true;
                break;
            }
        }

        if (!foundGroup) {
            groups.push({
                start: event.start,
                end: event.end,
                events: [event],
                clusters: [],
            });
        }
    });
    for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        const clusters = clusterEvents(group.events);
        if (group.events.length > 2) {
            groups[i].clusters = clusters;
        }
    }

    return groups;

}


import { FC } from "react";
// import { KeyResources } from "./components/KeyResources/KeyResources.component";
import { Channels } from "./components/Channels/Channels.component";
import { People } from "./components/People/People.component";
import { SidebarProps } from "./Sidebar.type";

export const Sidebar: FC<SidebarProps> = ({
    onUpdate
}) => {
    return <>
        {/* <KeyResources onUpdate={onUpdate} /> */}
        <Channels />
        <People />
    </>
};
import { styled } from "@mui/material";

export const StyledIconWrapper = styled('div')`
    position: relative;
    margin-right: 8px;
`;
export const StyledSenseIcon = styled('img')`
    border-radius: 4px;
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
`;
import React, { createContext, useState, ReactNode, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface NodeDataType {
    nodeId: number | null;
    name: string
}
type ModalContextProps = {
    isStartIntegrationModalOpen: boolean;
    openStartIntegrationModal: () => void;
    closeStartIntegrationModal: () => void;
    isRenameNodeModalOpen: boolean;
    openRenameNodeModal: () => void;
    closeRenameNodeModal: () => void;
    nodeData: NodeDataType;
    setNodeData: any;
    isEditSpaceModalOpen: boolean;
    openEditSpaceModal: () => void;
    closeEditSpaceModal: () => void;
    isAddPeopleToSpaceModalOpen: boolean;
    openAddPeopleToSpaceModal: () => void;
    closeAddPeopleToSpaceModal: () => void;
};

const ModalContext = createContext<ModalContextProps>({
    isStartIntegrationModalOpen: false,
    openStartIntegrationModal: () => {},
    closeStartIntegrationModal: () => {},
    isRenameNodeModalOpen: false,
    openRenameNodeModal: () => {},
    closeRenameNodeModal: () => {},
    nodeData: {nodeId: 0, name: ''},
    setNodeData: () => {},
    isEditSpaceModalOpen: false,
    openEditSpaceModal: () => {},
    closeEditSpaceModal: () => {},
    isAddPeopleToSpaceModalOpen: false,
    openAddPeopleToSpaceModal: () => {},
    closeAddPeopleToSpaceModal: () => {},
});
export const useModal = () => useContext<ModalContextProps>(ModalContext);

type ModalProviderProps = {
    children: ReactNode;
};

const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isStartIntegrationModalOpen, setIsStartIntegrationModalOpen] = useState(false);
    const [isRenameNodeModalOpen, setIsRenameNodeModalOpen] = useState(false);
    const [nodeData, setNodeData] = useState({nodeId: null, name: ''});
    const [isEditSpaceModalOpen, setIsEditSpaceModalOpen] = useState(false);
    const [isAddPeopleToSpaceModalOpen, setIsAddPeopleToSpaceModalOpen ] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const removeDialogAndTypeFromUrl = () => {
        const currentUrl = new URL(location.pathname + location.search, window.location.origin);
        currentUrl.searchParams.delete('type');
        history.push(currentUrl.pathname + currentUrl.search);
    };
    const openStartIntegrationModal = () => {
        setIsStartIntegrationModalOpen(true)
    }
    const closeStartIntegrationModal = () => {
        setIsStartIntegrationModalOpen(false);
        removeDialogAndTypeFromUrl();
    }

    const openRenameNodeModal = () => {
        setIsRenameNodeModalOpen(true);
    }
    const closeRenameNodeModal = () => {
        setNodeData({nodeId: null, name: ''})
        setIsRenameNodeModalOpen(false);
    };

    const openEditSpaceModal = () => {
        setIsEditSpaceModalOpen(true);
    }
    const closeEditSpaceModal = () => {
        setNodeData({nodeId: null, name: ''})
        setIsEditSpaceModalOpen(false);
    };
    const openAddPeopleToSpaceModal = () => {
        setIsAddPeopleToSpaceModalOpen(true);
    }
    const closeAddPeopleToSpaceModal = () => {
        setIsAddPeopleToSpaceModalOpen(false);
    };

    const contextValue: ModalContextProps = {
        isStartIntegrationModalOpen,
        openStartIntegrationModal,
        closeStartIntegrationModal,
        isRenameNodeModalOpen,
        closeRenameNodeModal,
        openRenameNodeModal,
        setNodeData,
        nodeData,
        isEditSpaceModalOpen,
        closeEditSpaceModal,
        openEditSpaceModal,
        isAddPeopleToSpaceModalOpen,
        openAddPeopleToSpaceModal,
        closeAddPeopleToSpaceModal,
    };

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };

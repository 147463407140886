import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { FC } from "react";
import { StyledGrid, StyledRelationshipsIcon, StyledRelationshipsIconWrapper, StyledTitle, StyledWrapper } from "./EmptyState.styled";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { EmptyListCard } from "@/components/v3/Other/EmptyListCard/EmptyListCard.component";

export const EmptyState: FC = () => {
    const { addInSpace } = useAddInSpace();
    const router = useRouter();
    return <StyledWrapper>
        <FilledCard>
            <StyledTitle>This tag is empty.<br/>Start filling it by connecting relevant app sources or add things manually</StyledTitle>
            <StyledGrid>
                <EmptyListCard variant="shadow" title="Add resources" onClick={() => addInSpace()}>
                    From Sense, links, tabs, and apps
                </EmptyListCard>
                <EmptyListCard variant="shadow" title="Add notes" onClick={() => router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'create-note'
                    }
                })}>
                    Take a note and connect resources
                </EmptyListCard>
            </StyledGrid>
        </FilledCard>
    </StyledWrapper>
};
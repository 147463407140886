import { Person } from "@/components/v3/List/Person/Person.component";
import { styled } from "@mui/material";

export const StyledPerson = styled(Person)`
    padding: 12px 8px;
    cursor: pointer;
    border-radius: 6px;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
`;

export const StyledList = styled('div')`
    padding: 0px 16px 16px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
import { FC } from "react";
import { Menu as MenuComponent } from "@/components/v3/Other/Menu/Menu.component";
import { MenuItem } from "@/components/v3/Other/MenuItem/MenuItem.component";
import { MenuProps } from "./Menu.type";
import { EditIcon } from "@/icons/v3/edit.icon";
import { DeleteIcon } from "@/icons/v3/delete.icon";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";
import { CreateSpaceRelationFetch, DeleteSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { ToastService } from "@/service/ToastService";
import { useRouter } from "@/providers/Router/Router.provider";
import { AddToIcon } from "@/icons/v3/add-to.icon";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";

export const Menu: FC<MenuProps> = ({
    space,
}) => {
    const router = useRouter();
    const { user } = useMe();
    const { loadSpaces, mySpaces } = useSpaces();
    const { confirm, component } = useConfirm({
        submitButtonProps: {
            children: "Delete",
        }
    });
    const isMySpaceManual = mySpaces.some(mySpace => mySpace.id === space.id);
    const isMySpaceNative = space.creatorId === user?.id || (user?.id && space.userIds.includes(user?.id));
    const deleteHandler = () => {
        confirm(async (result) => {
            if (result) {
                await DeleteSpaceFetch(space.id).then(data => {
                    ToastService.showToast('success', 'Space was deleted!');
                    router.push({
                        name: 'forYou'
                    })
                    window.location.reload();
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred!');
                });
            }
        });
    };
    const addToMySpacesHandler = async () => {
        if (!user?.id) return;
        await CreateSpaceRelationFetch([{
            spaceId: space.id,
            actors: [{
                userId: user.id,
                actionType: 'FOLLOW_BY',
                properties: {}
            }]
        }], {
            action: 'CREATE'
        }).then(data => {
            ToastService.showToast('success', 'Space was added to your spaces!');
            loadSpaces();
        })
    };
    const removeFromMySpacesHandler = async () => {
        if (!user?.id) return;
        await CreateSpaceRelationFetch([{
            spaceId: space.id,
            actors: [{
                userId: user.id,
                actionType: 'FOLLOW_BY',
                properties: {}
            }]
        }], {
            action: 'DELETE'
        }).then(data => {
            ToastService.showToast('success', 'Space was removed from your spaces');
            loadSpaces();
        })
    };
    return <>
        <MenuComponent
            buttonProps={{
                variant: 'secondary'
            }}
        >
            <MenuItem icon={<EditIcon />} onClick={() => {
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'edit-space'
                    }
                })
            }}>Edit</MenuItem>
            {!isMySpaceNative && <>
                {!isMySpaceManual && <MenuItem icon={<AddToIcon />} onClick={addToMySpacesHandler}>Add to My Spaces</MenuItem>}
                {isMySpaceManual && <MenuItem icon={<DeleteIcon />} onClick={removeFromMySpacesHandler} variant="error">Remove from My Spaces</MenuItem>}
            </>}
            <MenuItem icon={<DeleteIcon />} onClick={deleteHandler} variant="error">Delete</MenuItem>
        </MenuComponent>
        {component}
    </>
};
import { FC } from "react";
import { StyledPageHeader } from "./Header.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRouter } from "@/providers/Router/Router.provider";

export const Header: FC = () => {
    const { users } = useMe();
    const router = useRouter();
    return <StyledPageHeader
        title="People"
        subtitle={users?.length === 1 ? `${users?.length} member` : `${users?.length || 0} members`}
        icon="/icons/people-active-icon.svg"
        useSkeleton={false}
    >
        <Button variant="secondary" onClick={() => router.push({
            name: router.name,
            params: router.params,
            query: {
                ...router.query,
                dialog: 'manage-users',
            }
        })}>Manager users</Button>
    </StyledPageHeader>
};
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { NodeType } from "./GetPeopleOptionsFromNodes.type";

export const getPeopleOptionsFromNodes = (listOfNodes: NodeType[]): OptionType<string>[] => {
    const actors = listOfNodes.map((node) => {
        return node.relationships?.actors || [];
    }).flat();
    const seen = new Set<string>();
    return actors.filter((actor) => {
        const isDuplicate = seen.has(actor.userId);
        seen.add(actor.userId);
        return !isDuplicate;
    }).map((actor) => {
        return {
            label: `${actor.user?.firstName} ${actor.user?.lastName}`,
            value: actor.userId,
            img: actor.user?.avatar || "/icons/no_avatar.svg"
        };
    });
};
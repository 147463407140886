export const getIconPath = (application: string, isActive?: boolean): string => {
  if (application === 'Slack') {
    return '/icons/apps/slack.png'
  } else if (application === 'Sense') {
    if (isActive) {
      return '/icons/space.svg'
    }
    return '/icons/space-white.svg'
  } else if (application === 'Jira') {
    return '/icons/jira.svg'
  } else if (application === 'Asana') {
    return '/icons/icon-asana.png'
  } else if (application === 'Github') {
    return '/icons/icon-github.png'
  } else if (application === 'ClickUp') {
    return '/icons/icon-click_up.png'
  } else if (application === 'Trello') {
    return '/icons/icon-trello.png'
  } else if (application === 'Figma') {
    return '/icons/icon-figma.png'
  } else if (application === 'Confluence') {
    return '/icons/confluence.svg'
  } else if (application === 'MicrosoftTeams') {
    return '/icons/teams.svg'
  } else if (application === 'Notion') {
    return '/icons/icon-notion.svg'
  }
  return ''
};

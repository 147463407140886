import { FC, useCallback, useMemo, useState } from "react";
import { StyledAdd2Icon, StyledAddIcon, StyledButton, StyledDefaultEmojiIcon, StyledEmojiContainer, StyledEmojiIcon, StyledEmojiSelect, StyledEmojiWrapper, StyledMainIcon, StyledPageHeader } from "./Header.styled";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Menu } from "./components/Menu/Menu.component";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useSpaceTagPage } from "@/providers/v3/SpaceTagPage/SpaceTagPage.provider";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { UpdateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { ToastService } from "@/service/ToastService";
import { AddButton } from "@/pages/v3/Space/components/Header/components/AddButton/AddButton.component";

export const Header: FC = () => {
  const {
      update,
      space,
      spaceTag,
      listOfNodes,
  } = useSpaceTagPage();
    const shouldRenederButtonText = useRenederAbove('tablet');
    const { addInSpace } = useAddInSpace();
    const { loadSpaces } = useSpaces();
    const [isOpenedSelect, setIsOpenedSelect] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const { isSharedSpace } = useIsShared();
    const isActive = isHover || isOpenedSelect;
    const icon = "/icons/space-tag.svg";
    const updateEmoji = useCallback((emoji: string | null) => {
      !!space && !!spaceTag && UpdateSpaceTagsFetch(space.id, spaceTag.id, {
          ...spaceTag,
          emoji: emoji,
      }).then(() => {
          update();
          loadSpaces();
          ToastService.showToast('success', 'Tag emoji has been changed!');
      }).catch((err) => {
          ToastService.showToast('error', 'Some error occurred');
      });
    }, [loadSpaces, space, spaceTag, update]);
    const iconJsx = useMemo(() => {
      if (isSharedSpace) {
        return <StyledEmojiContainer>
          {!!spaceTag?.emoji && <StyledEmojiIcon >{spaceTag.emoji}</StyledEmojiIcon>}
          {!spaceTag?.emoji && <StyledMainIcon src={icon}/>}
        </StyledEmojiContainer>
      }
      return <StyledEmojiSelect isEmoji={!!spaceTag?.emoji} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onChange={(e) => updateEmoji(e.native)} onChangeIsOpened={setIsOpenedSelect}>
        {!!spaceTag?.emoji && <StyledEmojiIcon >{spaceTag.emoji}</StyledEmojiIcon>}
        {!spaceTag?.emoji && <StyledMainIcon src={icon}/>}
        <StyledEmojiWrapper isActive={isActive} >
          <StyledDefaultEmojiIcon isActive={isActive} />
          <StyledAdd2Icon isActive={isActive} />
        </StyledEmojiWrapper>
      </StyledEmojiSelect>
    }, [spaceTag?.emoji, icon, isActive, isSharedSpace, updateEmoji]);
    return <StyledPageHeader title={spaceTag?.name || ''} subtitle="Tag" icon={iconJsx} >
      {!isSharedSpace && <>
      {!!spaceTag && <Menu spaceTag={spaceTag} onUpdate={update} />}
      <AddButton />
      </>}
    </StyledPageHeader>
};
import { ActivityMessageType } from "@/types/ActivityMessage/ActivityMessage.type";

export const getChannelName = (activity: ActivityMessageType) => {
    const msgBelongsToAsana = activity.permalink?.includes('asana');
    const msgBelongsToGitHub = activity.permalink?.includes('github');
    const msgBelongsToClickUp = activity.permalink?.includes('click_up');
    const msgBelongsTotrello = activity.permalink?.includes('trello');
    const msgBelongsToFigma = activity.permalink?.includes('figma');
    if ('channelName' in activity) {
        return `#${activity.channelName}`;
    }
    if (msgBelongsToFigma && 'fileName' in activity) {
        return activity.fileName;
    }
    if (msgBelongsToAsana && 'taskName' in activity) {
        return activity.taskName
    }
    if (msgBelongsToGitHub && 'pullName' in activity) {
        return activity.pullName
    }
    if (msgBelongsToGitHub && 'issueName' in activity) {
        return activity.issueName
    }
    if (msgBelongsToClickUp && 'taskName' in activity) {
        return activity.taskName
    }
    if (msgBelongsTotrello && 'taskName' in activity) {
        return activity.taskName
    }
    if ('taskKey' in activity) {
        return activity.taskKey
    }
    return 'Unknown channel';
}
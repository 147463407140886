import { styled } from "@mui/material";

export const StyledNotFound = styled('div')`
    display: flex;
    justify-content: center;
`
export const StyledNotFoundContainer = styled('div')`
    margin-top: 296px;
    width: 100%;
    max-width: 320px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`
export const StyledNotFoundTitle = styled('div')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
`
export const StyledNotFoundCaption = styled('div')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
`
export const StyledGoToAccountButton = styled('div')`
    display: flex;
    padding: 4px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 6px;
    background: #FFECE7;
    margin-top: 24px;
    cursor: pointer;
`
export const StyledLearnModeButton = styled('a')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    margin-top: 24px;
    cursor: pointer;
`
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0px;
    @media (max-width: 768px) {
        display: block;
        padding-bottom: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
`;
export const StyledNameWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledNodeWrapper = styled('div')`
    margin-left: 32px;
`;
export const StyledName = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 8px;
    @media (max-width: 768px) {
        margin-bottom: 4px;
    }
`;
export const StyledAction = styled('span')`
    color: #636363;
    font-weight: 400;
`;
export const StyledDate = styled('span')`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
    @media (max-width: 768px) {
        display: block;
        margin-top: 4px;
        margin-left: 0;
    }
`;
import { UserAvatar } from "@/components/v3/Other/UserAvatar/UserAvatar.component";
import { Box, DialogTitle, MenuItem, styled } from "@mui/material";

export const StyledMenuBox = styled(Box)`
    display: flex;
    flex-direction: row;
    font-weight: 500;
    align-items: center;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }
`;
export const StyledMenuName = styled(Box)`
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #2F2C2C;
`;
export const StyledArrow = styled('img')`
    margin-left: 4px;
    width: 16px;
`;
export const StyledUserAvatar = styled(UserAvatar)`
    margin-right: 8px;
`;
export const StyledDialogTitle = styled(DialogTitle)`
    border-bottom: 1px solid rgba(0,0,0,.08);
`;
export const StyledMenuItem = styled(MenuItem)`
    width: auto;
    overflow: hidden;
    font-size: 14px;
    box-sizing: border-box;
    font-family: Poppins;
    font-weight: 500;
    line-height: 20px;
    padding-top: 6px;
    white-space: nowrap;
    padding-bottom: 6px;
`;
export const StyledMobileName = styled('div')`
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #00CCC0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
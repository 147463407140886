import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import moment from "moment";
import { FC, useMemo, useState } from "react";
import { StyledWrapper } from "../../../Space/components/AllResources/AllResources.styled";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyState } from "../Sidebar/components/EmptyState/EmptyState.component";
import { StyledButton, StyledMeeting, StyledNoMeetings } from "../Sidebar/Sidebar.styled";
import { CalendarIcon } from "@/icons/v3/calendar.icon";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Popover } from "@mui/material";
import { MeetingsTimeline } from "../Sidebar/components/MeetingsTimeline/MeetingsTimeline.component";
import dayjs from "dayjs";
import { StyledSpaceWrapper } from "./Meedings.styled";

export const Meedings: FC = () => {
    
    const {
        dailyCalendarEvents,
        notDailyCalendarEvents,
        isLoadingGoogleCalendarEvents,
        selectedDate,
        setSelectedDate
    } = useForYouPage();
    const { user } = useMe()
    const isCalendarConnected = user && user.integrations && (user.integrations.google_calendar.active || user.integrations.outlook.active);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const buttonText = useMemo((): string => {
        const today = moment();
        const selected = moment(selectedDate);
        
        if (selected.isSame(today, 'day')) {
            return "Today";
        } else if (selected.isSame(today.clone().add(1, 'day'), 'day')) {
            return "Tomorrow";
        } else if (selected.isSame(today.clone().subtract(1, 'day'), 'day')) {
            return "Yesterday";
        } else {
            return selected.format("MMMM D, YYYY");
        }
    }, [selectedDate]);
    const isEmpty = dailyCalendarEvents.length === 0 && notDailyCalendarEvents.length === 0;
    return <LoaderLayout isLoading={isLoadingGoogleCalendarEvents} transparent={!isEmpty}>
    <StyledWrapper>
        <StyledSpaceWrapper>
            <StyledButton disabled={isEmpty} center variant="secondary" onClick={handleButtonClick} icon={<CalendarIcon />}>{buttonText}</StyledButton>
        </StyledSpaceWrapper>
        <StyledSpaceWrapper>
            <SectionHeader title='Meetings' />
        </StyledSpaceWrapper>
        <EmptyStateLayoyt
            emptyNode={<EmptyState />} value={!isCalendarConnected}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <DateCalendar
                        value={dayjs(selectedDate)}
                        onChange={(newValue) => {
                            newValue && setSelectedDate(newValue.toDate());
                            handleClose();
                        }}
                    />
                </Popover>
            </LocalizationProvider>
            {!!dailyCalendarEvents.length && <StyledSpaceWrapper>
                {dailyCalendarEvents.map(calendarEvent => (<>
                    <StyledMeeting>{calendarEvent.title} — All day</StyledMeeting>
                </>))}
            </StyledSpaceWrapper>}
            {isEmpty && <StyledNoMeetings>No meetings on this day</StyledNoMeetings>}
        </EmptyStateLayoyt>
        <div>
            {notDailyCalendarEvents.length > 0 && <MeetingsTimeline meetings={notDailyCalendarEvents} />}
        </div>
    </StyledWrapper>
</LoaderLayout>
};
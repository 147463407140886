import { FC } from "react";
import { NodeProps } from "./Node.type";
import { NodeTypeType } from "@/types/Node/Node.type";
import { StyledInfoWrapper, StyledName, StyledNodeIcon, StyledNoteContent, StyledRemoveButton, StyledWrapper } from "./Node.styled";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { DeleteIcon } from "@/icons/v3/delete.icon";

export const Node: FC<NodeProps> = ({ node, onRemove }) => {
    const isNote = node.nodeType === NodeTypeType.NOTE;
    return <StyledWrapper>
        <StyledInfoWrapper>
            {!isNote && <>
                <StyledNodeIcon node={node} />
                <StyledName>{node.name}</StyledName>
            </>}
            {isNote && <StyledNoteContent>
                {node.content && parse(sanitizeHtml(node.content, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                    allowedAttributes: {
                        ...sanitizeHtml.defaults.allowedAttributes,
                        'a': ['href', 'title', 'target']
                    }
                }))}    
            </StyledNoteContent>}
        </StyledInfoWrapper>
        <StyledRemoveButton onClick={() => onRemove(node)} variant="tertiary" icon={<DeleteIcon className="icon" />} />
    </StyledWrapper>
};
import { AddInSpaceModalProps } from './AddInSpaceModal.type';
import { useRouter } from '@/providers/Router/Router.provider';
import { StyledDialogLayout } from './AddInSpaceModal.styled';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@/components/v3/Other/Dialog/Dialog.component';
import { useIsModalOpened } from '@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook';
import { AddResourcesForm } from '@/components/v3/Common/AddResourcesForm/AddResourcesForm.component';
import { NodeType } from '@/components/v3/Common/AddResourcesForm/components/AddNodeItem/AddNodeItem.type';
import { AddNodeToSpaceFetch, DeleteNodesFetch, GetSpacesNodesFetch } from '@/axios/AiService/SpacesNodes/SpacesNodes.api';
import { GetSpaceTagNodesFetch, GetSpaceTagsFetch, UpdateSpaceTagsFetch } from '@/axios/AiService/SpaceTags/SpaceTags.api';
import { UpdateSpaceTagsAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/Put/Put.type';
import { SpaceTagsAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/Get/Get.type';
import { EventTypes, useAmplitude } from '@/service/TrackingService';
import { ToastService } from '@/service/ToastService';
import { CreateSpaceKeyResourcesFetch, DeleteSpaceKeyResourcesFetch, DeleteSpaceKeyResourcesSuggestionsFetch } from '@/axios/AiService/Spaces/Spaces.api';
import { useSpacePageCommon } from '@/providers/v3/SpacePageCommon/SpacePageCommon.provider';
import { SpacesNodesAxiosResponseSuccessType } from '@/axios/AiService/SpacesNodes/Types/Get/Get.type';
import { GetTagNodesAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/GetTagNodes/GetTahNodes.type';

export const AddInSpaceModal: FC<AddInSpaceModalProps> = ({ onUpdate }) => {
  const { value: isOpen, onChange: setIsOpen } = useIsModalOpened({ name: 'add-to-space' });
  const [listOfAddedNodes, setListOfAddedNodes] = useState<NodeType[]>([]);
  const router = useRouter();
  const asKeyResource = router.query.type === 'key-resources';
  const spaceId = router.params.spaceId as string | undefined;
  const tagId = router.params.tagId as string | undefined;
  const [isFetchingLocal, setIsFetchingLocal] = useState<boolean>(false);
  const trackEvent = useAmplitude();
  const title = useMemo(() => {
    if (asKeyResource) {
      return 'Add key resources';
    }
    return tagId ? 'Add to Tag' : 'Add to Space'
  }, [asKeyResource, tagId]);
  const closeHandler = () => {
    setIsOpen(false, {
      type: undefined,
      tab: undefined,
    });
  };
  
  const {
    listOfKeyResources,
    listOfKeyResourcesSuggestions
  } = useSpacePageCommon();
  const isKeyResourceCallback = useCallback((nodeId: number) => {
    return listOfKeyResources.map((keyResource) => keyResource.node.id).includes(nodeId);
  }, [listOfKeyResources]);
  const isKeyResourceSuggestionCallback = useCallback((nodeId: number) => {
    return listOfKeyResourcesSuggestions.map((keyResource) => keyResource.node.id).includes(nodeId);
  }, [listOfKeyResourcesSuggestions]);
  
  const loadSpaceNodes = useCallback(async (): Promise<NodeType[]> => {
    if (!spaceId) {
      return []
    }
    return await GetSpacesNodesFetch(spaceId).then((res: SpacesNodesAxiosResponseSuccessType) => {
      return res.data;
    }).catch(() => {
      return [];
    });
  }, [spaceId]);
  
  const loadSpaceTagNodes = useCallback(async (): Promise<NodeType[]> => {
    if (!spaceId) {
      return [];
    }
    if (!tagId) {
      return [];
    }
    setIsFetchingLocal(true);
    return await GetSpaceTagNodesFetch(spaceId, tagId, {}).then((res: GetTagNodesAxiosResponseSuccessType) => {
      return res.data
    })
  }, [setIsFetchingLocal, spaceId, tagId]);
  const loadAddedNodeIds = useCallback(async () => {
    setIsFetchingLocal(true);
    if (!!tagId) {
      const spaceTagNodes = await loadSpaceTagNodes();
      if (asKeyResource) {
        const keyResourcesNodes = spaceTagNodes.filter((node) => isKeyResourceCallback(node.id));
        const keyResourcesSuggestionNodes = spaceTagNodes.filter((node) => isKeyResourceSuggestionCallback(node.id));
        setListOfAddedNodes([...keyResourcesNodes, ...keyResourcesSuggestionNodes])
      } else {
        setListOfAddedNodes(spaceTagNodes)
      }
    } else {
      const spaceNodes = await loadSpaceNodes();
      if (asKeyResource) {
        const keyResourcesNodes = spaceNodes.filter((node) => isKeyResourceCallback(node.id));
        const keyResourcesSuggestionNodes = spaceNodes.filter((node) => isKeyResourceSuggestionCallback(node.id));
        setListOfAddedNodes([...keyResourcesNodes, ...keyResourcesSuggestionNodes])
      } else {
        setListOfAddedNodes(spaceNodes);
      }
    }
    setIsFetchingLocal(false);
  }, [asKeyResource, isKeyResourceCallback, isKeyResourceSuggestionCallback, loadSpaceNodes, loadSpaceTagNodes, tagId]);
  const addToSpaceTagHandler = async (spaceId: string, spaceTagId: string, node: NodeType) => {
    await GetSpaceTagsFetch(spaceId).then(async (res: SpaceTagsAxiosResponseSuccessType) => {
      const spaceTag = res.data.find((spaceTag) => spaceTag.id === spaceTagId);
      if (!spaceTag) {
        console.error('Tag not found', res.data, spaceTagId);
        return;
      }
      await UpdateSpaceTagsFetch(spaceId, spaceTagId, {
        ...spaceTag,
        emoji: spaceTag.emoji || null,
        nodeIds: [...spaceTag.nodeIds, node.id],
      }).then(async (res: UpdateSpaceTagsAxiosResponseSuccessType) => {
        trackEvent(EventTypes.NODE_WAS_MANUALLY_ADDED_TO_SPACE);
        // const newListOfAddedNodes = [...listOfAddedNodes, node];
        // setListOfAddedNodes(newListOfAddedNodes);
        ToastService.showToast('success', '1 resource was added to the space!');
        onUpdate && await onUpdate();
      }).catch(error => {
        ToastService.showToast('error', 'Some error occurred! Please try again later!');
      });
    })
    .catch(error => {
      ToastService.showToast('error', 'Some error occurred! Please try again later!');
    });
  }
  const addToKeyResources = async (spaceId: string, node: NodeType) => {
    await CreateSpaceKeyResourcesFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was added!');
    })
  };
  const addToSpaceHandler = async (spaceId: string, node: NodeType) => {
    await AddNodeToSpaceFetch({
      spaceId: spaceId,
      nodeIds: [node.id],
    }).then(async () => {
      trackEvent(EventTypes.NODE_WAS_MANUALLY_ADDED_TO_SPACE);
      // const newListOfAddedNodes = [...listOfAddedNodes, node];
      // setListOfAddedNodes(newListOfAddedNodes);
      ToastService.showToast('success', '1 resource was added to the space!');
      if (asKeyResource) {
        await addToKeyResources(spaceId, node);
      }
      onUpdate && await onUpdate()
    })
    .catch(() => {
      ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
    });
  }
  const addHandler = async (node: NodeType) => {
    if (!spaceId) {
      return;
    }
    setIsFetchingLocal(true);
    if (!!tagId) {
      await addToSpaceTagHandler(spaceId, tagId, node);
    } else {
      await addToSpaceHandler(spaceId, node);
    }
  };
  const removeFromSpaceHandler = async (spaceId: string, node: NodeType) => {
    await DeleteNodesFetch({
      spaceId,
      nodeIds: [node.id],
    }).then(() => {
      // const newListOfAddedNodes = listOfAddedNodes.filter((addedNode) => addedNode.id !== node.id);
      // setListOfAddedNodes(newListOfAddedNodes);
      ToastService.showToast('success', '1 resources was removed from the space!');
    })
    .catch(error => {
      ToastService.showToast('error', 'Some error occurred! Please try again later!');
    });
  }
  const removeFromSpaceTagHandler = async (spaceId: string, spaceTagId: string, node: NodeType) => {
    await GetSpaceTagsFetch(spaceId).then(async (res: SpaceTagsAxiosResponseSuccessType) => {
      const spaceTag = res.data.find((spaceTag) => spaceTag.id === spaceTagId);
      if (!spaceTag) {
        console.error('Tag not found');
        return;
      }
      await UpdateSpaceTagsFetch(spaceId, spaceTagId, {
        ...spaceTag,
        emoji: spaceTag.emoji || null,
        nodeIds: spaceTag.nodeIds.filter((id) => id !== node.id),
      }).then(async (res: UpdateSpaceTagsAxiosResponseSuccessType) => {
        // const newListOfAddedNodes = listOfAddedNodes.filter((addedNode) => addedNode.id !== node.id);
        // setListOfAddedNodes(newListOfAddedNodes);
        ToastService.showToast('success', '1 resources was removed from the tag!');
      })
      .catch(error => {
        ToastService.showToast('error', 'Some error occurred! Please try again later!');
      });
    })
    .catch(error => {
      ToastService.showToast('error', 'Some error occurred! Please try again later!');
    });
  }
  const removeFromKeyResourcesSuggestion = async (spaceId: string, node: NodeType) => {
    await DeleteSpaceKeyResourcesSuggestionsFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      // const newListOfAddedNodes = listOfAddedNodes.filter((addedNode) => addedNode.id !== node.id);
      // setListOfAddedNodes(newListOfAddedNodes);
      ToastService.showToast('success', '1 key resource was removed!');
    })
  };
  const removeFromKeyResources = async (spaceId: string, node: NodeType) => {
    await DeleteSpaceKeyResourcesFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      // const newListOfAddedNodes = listOfAddedNodes.filter((addedNode) => addedNode.id !== node.id);
      // setListOfAddedNodes(newListOfAddedNodes);
      ToastService.showToast('success', '1 key resource was removed!');
    })
  };
  const removeHandler = async (node: NodeType) => {
    if (!spaceId) {
      return;
    }
    setIsFetchingLocal(true);
    if (asKeyResource) {
      const isKeyResource = isKeyResourceCallback(node.id);
      const isKeyResourceSuggestion = isKeyResourceSuggestionCallback(node.id);
      if (isKeyResourceSuggestion) {
        await removeFromKeyResourcesSuggestion(spaceId, node);
      } else if (isKeyResource) {
        await removeFromKeyResources(spaceId, node);
      }
    } else {
      if (!!tagId) {
        await removeFromSpaceTagHandler(spaceId, tagId, node);
      } else {
        await removeFromSpaceHandler(spaceId, node);
      }
    }
    onUpdate && await onUpdate();
  };
  useEffect(() => {
    loadAddedNodeIds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAddedNodeIds]);
  return (
    <Dialog value={isOpen} onChange={closeHandler}>
      <StyledDialogLayout
        title={title}
        onCloseClick={closeHandler}
        useContetPaddings={false}
        isLoading={isFetchingLocal}
      >
        <AddResourcesForm
          onAdd={addHandler}
          onRemove={removeHandler}
          asKeyResource={asKeyResource}
          isFetchingLocal={isFetchingLocal}
          setIsFetchingLocal={setIsFetchingLocal}
          listOfAddedNodes={listOfAddedNodes}
        />
      </StyledDialogLayout>
    </Dialog>
  );
};

import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { RelationshipsIcon } from "@/icons/v3/relationships.icon";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 24px 32px 40px 32px;
`;
export const StyledTitle = styled('div')`
    color: #636363;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 8px;
`;
export const StyledGrid = styled('div')`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    margin-top: 24px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
export const StyledRelationshipsIconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #202020;
`;
export const StyledRelationshipsIcon = styled(RelationshipsIcon)`
    width: 24px;
    height: 24px;
    & path {
        fill: #fff;
    }
`;
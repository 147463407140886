import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";
import { PersonActivities } from "@/components/v3/List/PersonActivities/PersonActivities.component";

export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
    @media (max-width: 1024px) {
        padding-right: 32px;
        padding-left: 32px;
    }
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledExternalSectionHeader = styled(StyledSectionHeader)`
    margin-top: 32px;
`;
export const StyledPersonActivities = styled(PersonActivities)`
    margin-bottom: 4px;
    &:last-child {
        margin-bottom: 0;
    }
`;
import { FC } from "react";
import { SpaceTypeProps } from "./SpaceType.type";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { StyledRadioWrapper } from "./SpaceType.styled";
import { RadioItem } from "@/components/v3/Fields/RadioItem/RadioItem.component";

export const SpaceType: FC<SpaceTypeProps> = ({
    spaceType,
    setSpaceType
}) => {
    return <FilledCard>
    <SectionHeader title="Type of space" />
    <StyledRadioWrapper>
        <RadioItem
            label="Public space"
            caption="Visible for everyone"
            val="Public" value={spaceType} onChange={setSpaceType}
        />
        <RadioItem
            label="Private space"
            caption="Visible only for users invited to the space"
            val="Private" value={spaceType} onChange={setSpaceType}
        />
    </StyledRadioWrapper>
</FilledCard>
};
import { SpaceType } from "@/axios/AiService/Spaces/Types/List/List.type";

export const compare = (a: SpaceType, b: SpaceType) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}


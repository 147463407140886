import { FC } from "react";
import { StyledWrapper } from "./CheckboxButton.styled";
import { CheckboxButtonProps } from "./CheckboxButton.type";

export const CheckboxButton:FC<CheckboxButtonProps> = ({
    children,
    value,
    onChange,
    className
}) => {
    return <StyledWrapper active={value} onClick={() => onChange(!value)} className={className}>
        {children}
    </StyledWrapper>
}
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { ReposGetQueryParams } from './Types/Get/Get.type';
import { ReposSynchronizeBodyParams } from './Types/Synchronize/Synchronize.type';

export const GetReposFetch = (query: ReposGetQueryParams) => {
    return HttpClient.get(`${envUrl}/github-service/v1/repos`, {
        params: query,
    });
}
export const SynchronizeReposFetch = (body: ReposSynchronizeBodyParams) => {
    return HttpClient.post(`${envUrl}/github-service/v1/grants`, body);
}
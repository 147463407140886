import { useRouter } from "providers/Router/Router.provider";
import {
    StyledGoToAccountButton,
    StyledLearnModeButton,
    StyledNotFound,
    StyledNotFoundCaption,
    StyledNotFoundContainer,
    StyledNotFoundTitle
} from "./NotFound.styled"
import { useMe } from "providers/MeProvider/Me.provider";

export const NotFound: React.FC = () => {
    const { user } = useMe();
    const router = useRouter();
    const goToSenceAccount = () => {
        if (user?.id) {
            router.push({ name: 'forYou' });
        } else {
            router.push({ name: 'login' });
        }
    }
    return <StyledNotFound>
        <StyledNotFoundContainer>
            <StyledNotFoundTitle>Page is not found</StyledNotFoundTitle>
            <StyledNotFoundCaption>
                <div>It seems like this page doesn’t exist.</div>
                <div>You can choose one of following options</div>
            </StyledNotFoundCaption>
            <StyledGoToAccountButton onClick={goToSenceAccount}>Go to my Sense account</StyledGoToAccountButton>
            <StyledLearnModeButton href="https://senseapp.ai">Learn more about Sense</StyledLearnModeButton>
        </StyledNotFoundContainer>
    </StyledNotFound>
}
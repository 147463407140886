import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { ChannelsGetQueryParams } from './Types/GetTeams/GetTeams.type';
import { ProjectsSynchronizeBodyParams } from './Types/Synchronize/Synchronize.type';

export const GetChannelsFetch = (query: ChannelsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/azure-service/v1/teams`, {
        params: query,
    });
}
export const SynchronizeProjectsFetch = (body: ProjectsSynchronizeBodyParams) => {
    return HttpClient.post(`${envUrl}/azure-service/v1/grants`, body);
}
import { useResponsive } from '@/providers/v3/Responsive/Responsive.provider';
import { BreakPointsTypeType, BREAKPOINTS } from './BreackPoints';


export const useRenederBelow = (point: BreakPointsTypeType | number): boolean => {
  const { innerWidth } = useResponsive();
  const p = typeof point === 'number' ? point : BREAKPOINTS[point]
  return innerWidth <= p;
}
export const useRenederAbove = (point: BreakPointsTypeType | number): boolean => {
  const { innerWidth } = useResponsive();
  const p = typeof point === 'number' ? point : BREAKPOINTS[point];
  return innerWidth > p;
}

export default useResponsive;

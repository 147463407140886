import { FC } from "react";
import { DesctiptionItemProps } from "./DesctiptionItem.type";
import { StyledDescription, StyledWrapper } from "./DesctiptionItem.styled";
import { DoneIcon } from "./components/DoneIcon/Doneicon";

export const DesctiptionItem: FC<DesctiptionItemProps> = ({
    description
}) => {
    return <StyledWrapper>
        <DoneIcon />
        <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
};
import { FC, useState } from "react";
import { Menu as MenuComponent } from "@/components/v3/Other/Menu/Menu.component";
import { MenuItem } from "@/components/v3/Other/MenuItem/MenuItem.component";
import { EditSpaceTagModal } from "./components/EditSpaceTagModal/EditSpaceTagModal.component";
import { MenuProps } from "./Menu.type";
import { EditIcon } from "@/icons/v3/edit.icon";
import { DeleteIcon } from "@/icons/v3/delete.icon";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";
import { ToastService } from "@/service/ToastService";
import { useRouter } from "@/providers/Router/Router.provider";
import { DeleteSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";

export const Menu: FC<MenuProps> = ({
    spaceTag,
    onUpdate
}) => {
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const router = useRouter();
    const { loadSpaces } = useSpaces();
    const spaceId = router.params.spaceId as string;
    const { confirm, component } = useConfirm({
        submitButtonProps: {
            children: "Delete",
        }
    });
    const deleteHandler = () => {
        confirm(async (result) => {
            if (result) {
                await DeleteSpaceTagsFetch(spaceId, spaceTag.id).then(async () => {
                    loadSpaces();
                    ToastService.showToast('success', 'Tag was deleted!');
                    setIsOpenEditModal(false);
                    router.push({
                        name: 'space',
                        params: {
                            spaceId: spaceId
                        }
                    });
                })
                .catch(() => {
                    ToastService.showToast('error', 'Some error occurred!');
                });
            }
        });
    };
    return <>
        <MenuComponent
            buttonProps={{
                variant: 'secondary'
            }}
        >
            <MenuItem icon={<EditIcon />} onClick={() => setIsOpenEditModal(true)}>Edit</MenuItem>
            <MenuItem icon={<DeleteIcon />} onClick={deleteHandler} variant="error">Delete</MenuItem>
        </MenuComponent>
        <EditSpaceTagModal spaceId={spaceId} spaceTag={spaceTag} value={isOpenEditModal} onChange={setIsOpenEditModal} onUpdate={onUpdate} />
        {component}
    </>
};
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SpaceProps } from "./Space.type";
import { SpaceCategory } from './components/SpaceCategory/SpaceCategory.component';
import { useRouter } from "@/providers/Router/Router.provider";
import { getIconPath } from "./Space.utils";
import { GetSpaceCategoriesFetch } from "@/axios/AiService/SpaceCategories/SpaceCategories.api";
import { SpaceCategoriesAxiosResponseSuccessType } from "@/axios/AiService/SpaceCategories/Types/Get/Get.type";
import { SpaceTagsAxiosResponseSuccessType, SpaceTagType } from "@/axios/AiService/SpaceTags/Types/Get/Get.type";
import { SpaceTagInput } from "./components/SpaceTagInput/SpaceTagInput.component";
import { GetSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { SpaceTag } from "./components/SpaceTag/SpaceTag.component";
import { StyledArrow, StyledEmoji, StyledEmojiWrapper, StyledSpaceContainer, StyledSpaceImage, StyledSpaceName, StyledSpaceNotify, StyledSpaceWrapper } from "./Space.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Space: FC<SpaceProps> = ({ space }) => {
  const router = useRouter();
  const { getMetadata } = useMe();
  const [categories, setCategories] = useState<string[]>([]);
  const [tags, setTags] = useState<SpaceTagType[]>([]);
  const isPrivate = space.private;
  const isActive = router.params.spaceId === space.id;
  const imgSrc = useMemo(() => {
    if (isPrivate) {
      return isActive ? '/icons/private-space-active-icon.svg' : '/icons/private-space-icon.svg';
    }
    return getIconPath(space.application, isActive);
  }, [isPrivate, isActive, space.application]);
  const imgJsx = useMemo(() => {
    if (!!space.emoji) {
      return <StyledEmojiWrapper>
        <StyledEmoji>{space.emoji}</StyledEmoji>
      </StyledEmojiWrapper>
    }
    return <StyledSpaceImage src={imgSrc} />
  }, [imgSrc, space.emoji]);
  const isNativeSpace = space.application==='Sense';
  const loadCategories = useCallback(async () => {
    await GetSpaceCategoriesFetch(space.id).then((res: SpaceCategoriesAxiosResponseSuccessType) => {
      setCategories(res.data);
    });
  }, [space])
  const loadTags = useCallback(async () => {
    await GetSpaceTagsFetch(space.id).then((res: SpaceTagsAxiosResponseSuccessType) => {
      setTags(res.data);
    });
  }, [space])
  useEffect(() => {
    if (isActive) {
      if (isNativeSpace) {
        loadTags();
      } else {
        loadCategories();
      }
    }
  }, [isActive, isNativeSpace, loadTags, loadCategories])
  const listOfSpaceCategoriesJsx = categories.map((category, i) => {
    return <SpaceCategory category={category} useWalkthrough={i===0} key={i} spaceId={space.id} />
  })
  const listOfSpaceTagsJsx = tags.map((tag, i) => {
    return <SpaceTag tag={tag} key={i} spaceId={space.id} />
  })
  const dispalayNotify = useMemo(() => {
    const spaceReadTime = getMetadata(`space-${space.id}-read-time`);
    if (!spaceReadTime) {
      return true;
    }
    return new Date(spaceReadTime).getTime() < new Date(space.updatedAt).getTime();
  }, [getMetadata, space.id, space.updatedAt])
  return <>
    <StyledSpaceWrapper
      onClick={() => {
        router.push({
          name: 'space',
          params: {
            spaceId: space.id
          }
        })
      }}
      active={router.name==='space' && isActive}
      id="walkthrough-spaces"
    >
      {/* <StyledArrow isActive={isActive} /> */}
      {imgJsx}
      <StyledSpaceContainer>
        <StyledSpaceName title={space.name}>
          {space.name}
        </StyledSpaceName>
        {!!dispalayNotify && <StyledSpaceNotify />}
      </StyledSpaceContainer>
    </StyledSpaceWrapper>
    {/* <Collapse in={isActive} timeout="auto" unmountOnExit>
      <List component="nav" disablePadding>
        {listOfSpaceCategoriesJsx}
        {listOfSpaceTagsJsx}
        {isNativeSpace && <SpaceTagInput spaceId={space.id} onUpdate={loadTags} />}
      </List>
    </Collapse> */}
  </>
}
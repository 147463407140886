import { FC, useEffect, useState } from "react";
import { ImgProps } from "./Img.type";

export const Img: FC<ImgProps> = (props) => {
    const [error, setError] = useState<boolean>(false);
  
    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setError(false);
      };
      img.onerror = () => {
        setError(true);
      };
      img.src = props.src;
    }, [props.src]);
  
    if (error) {
      return <>{!!props.default ? <img alt={props.alt} {...props} src={props.default}/> : props.children}</>;
    }
    return <img alt={props.alt} {...props}/>;
};
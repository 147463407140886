import { Button, Dialog, DialogContent, DialogTitle, TextField, styled } from "@mui/material";

export const StyledDialog = styled(Dialog) `
    & .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.1);
    }
    & .MuiDialog-paper {
        width: 560px;
    }
`;
export const StyledDialogTitle = styled(DialogTitle) `
    padding-bottom: 0px;
    display: flex;
    align-items: center;
`;
export const StyledDialogContent = styled(DialogContent) `
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.08);
    }
`;
export const StyledCancel = styled(Button) `
    height: 32px;
    border-radius: 6px;
    font-weight: 500;
    box-shadow: none;
    text-transform: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 0px 8px 0px 8px;
    align-items: center;
    color: #202020;
    background-color: #FFECE7;
    &:hover {
        background-color: #FFECE7;
    }
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 16px;
    padding-right: 16px;
`;
export const StyledSubmitButton = styled(Button) `
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    cursor: pointer;
    padding: 0px 8px 0px 8px;
    font-weight: 500;
    height: 32px;
    align-items: center;
    color: #FFFFFF;
    background-color: #202020;
    text-transform: none;
    padding-left: 16px;
    padding-right: 16px;
    &:hover {
        background-color: #202020;
    }
    & img {
        width: 16px;
        height: 16px;
    }
    & span {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
        text-align: center;
        flex-grow: 1;
    }
`;
export const StyledTextField = styled(TextField) `
    margin-bottom: 16px;
    & .MuiOutlinedInput-root {
        & fieldset {
            border: none;
        }
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.08);
    }
    & .MuiFormLabel-root {
        color: rgba(32, 32, 32, 0.5);
        font-size: 14px;
        font-weight: 400;
    }
    & .MuiInputBase-input {
        // padding: 10px 0px 10px 12px;
    }
    & .MuiInputLabel-formControl {
        position: relative;
        top: 13px;
    }
`;
export const StyledDescription = styled('div') `
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
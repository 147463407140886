import { NodeType, RelationshipsType, UserType } from "@/components/v3/List/Activity/Activity.type";
import { RelationshipsActorType } from "@/components/v3/List/Activity/components/Header/Header.type";
import { ActivityMessageType } from "@/types/ActivityMessage/ActivityMessage.type";
import { NodeTypeType } from "@/types/Node/Node.type";
const date = new Date()
date.setHours(8);
date.setMinutes(43);
export const users: UserType[] = [
    {
        "id": "1",
        "firstName": "Derk",
        "lastName": "Steemers",
        "avatar": "/users/2.png",
    }, 
    {
        "id": "2",
        "firstName": "Anastasia",
        "lastName": "Reich",
        "avatar": "/users/3.png",
    },
]
const activities: ActivityMessageType[] = [
    {
        source: 'Slack',
        avatar: users[1].avatar as string,
        channelName: 'marketing',
        messageTs: '1699373457.405269',
        permalink: '',
        resourceId: 1,
        text: 'Budget for the marketing Q4 might not be sufficient.',
        userName: `${users[1].firstName} ${users[1].lastName}`,
    },
]
export const relationships: RelationshipsType = {
    "actors": [
        {
            "actionType": "ASSIGNEE",
            "user": users[0],
            properties: {happenedAt: date.toString()},
            "userId": "1"
        },
        {
            "actionType": "COMMENTED_BY",
            "user": users[1],
            properties: {happenedAt: date.toString()},
            "userId": "2"
        },
    ],
  }
export const listOfActivities: {
    node: NodeType;
    relationships: RelationshipsType;
    activities: ActivityMessageType[];
    lastActor: RelationshipsActorType | null;
}[] = [
    {
        node: {
            "id": 1,
            "nodeType": NodeTypeType.TASK,
            "name": "Frontend",
            "originalURI": "",
            "application": 'Jira',
            "relationshipNumber": 0,
            "activitiesNumber": 0,
            "createdAt": "2023-11-09T14:35:10",
            "categories": [
                "task"
            ],
        },
        relationships: {
            actors: [
                relationships.actors[0],
            ]
        },
        activities: [],
        lastActor: relationships.actors[0]
    },
    {
        node: {
            "id": -1,
            "nodeType": NodeTypeType.TASK,
            "name": "Budget Plan",
            "originalURI": "",
            "application": "Asana",
            "relationshipNumber": 4,
            "activitiesNumber": 0,
            "createdAt": "2023-11-09T14:35:10",
            "categories": [
                "email"
            ],
        },
        relationships: {
            actors: [
                relationships.actors[1]
            ]
        },
        activities: activities,
        lastActor: relationships.actors[1]
    }
];
import {useState, useEffect, useCallback, useRef, useLayoutEffect} from 'react';
import { useParams } from 'react-router-dom';
import {ToastService} from "../../../service/ToastService";

type FetchFunctionType<T> = (offset: number, limit: number, userId?: string | undefined) => Promise<T[] | false>;

type UseInfiniteScrollOptions = {
    limit?: number;
    disabled?: boolean;
    querySelector?: string;
};


export function useInfiniteScroll<T>(fetchFunction: FetchFunctionType<T>, options: UseInfiniteScrollOptions = { limit: 10, disabled: false }) {
    const { limit = 10, disabled = false, querySelector = '#main-content' } = options;

    const [items, setItems] = useState<T[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentItemCount, setCurrentItemCount] = useState<number>(0);
    const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);

    const isMounted = useRef(true);
    const isLocked = useRef(false);

    const { spaceId } = useParams<{ spaceId: string }>();
    const { fromUserId } = useParams<{ fromUserId: string }>();

    const fetchItems = useCallback(async () => {
        if (isLocked.current) {
            return;
        }
        isLocked.current = true;
        setIsFetching(true);

        try {
            const newItems = await fetchFunction(currentItemCount, limit, fromUserId);
            if (!newItems) {
                return;
            }
            if (!isMounted.current) return;
            setItems(prev => {
                return [...prev, ...newItems]
            });
            setCurrentItemCount(prev => prev + newItems.length);
            if (newItems.length < limit) {
                setHasMore(false);
            }
        } catch (error) {
            ToastService.showToast('error', 'Some error occurred')
        } finally {
            setIsFetching(false);
            isLocked.current = false;
        }
    }, [fetchFunction, currentItemCount, limit, fromUserId]);

    const scrollContainerRef = useRef<Element | null>(null);

    const handleScroll = useCallback(() => {
        if (isLocked.current) {
            return;
        }

        const scrollContainer = querySelector ? scrollContainerRef.current : document.documentElement;
        if (!scrollContainer) return;

        const containerBottom = scrollContainer.getBoundingClientRect().bottom;
        const windowBottom = window.innerHeight;

        if (containerBottom <= windowBottom + 2000 && !isFetching && hasMore) {
            fetchItems();
        }
    }, [hasMore, isFetching, fetchItems, querySelector]);

    const refetchItems = useCallback(async () => {
        try {
            const newItems = await fetchFunction(currentItemCount, limit, fromUserId);
            if (!newItems) {
                return;
            }
            if (!isMounted.current) return;

            // Append new items to the existing items list
            setItems(prev => [...prev, ...newItems]);

            // Update the current item count
            setCurrentItemCount(prev => prev + newItems.length);

            // If fewer new items are fetched than the limit, set hasMore to false
            if (newItems.length < limit) {
                setHasMore(false);
            }
        } catch (error) {
            ToastService.showToast('error', 'Some error occurred');
        }
    }, [currentItemCount, fetchFunction, limit, fromUserId]);

    useEffect(() => {
        return () => { isMounted.current = false; };
    }, []);

    useLayoutEffect(() => {
        setItems([]);
        setCurrentItemCount(0);
        setHasMore(true);
        setInitialLoadComplete(false);
    }, [spaceId, fromUserId]);

    useEffect(() => {
        if (initialLoadComplete || disabled) {
            return;
        }
        fetchItems().then(() => {
            if (!isMounted.current) return;
            setInitialLoadComplete(true);
        });
    }, [initialLoadComplete, fetchItems, disabled]);

    useEffect(() => {
        const scrollContainer = document.querySelector(querySelector) || document.documentElement;
        scrollContainerRef.current = scrollContainer;

        if (initialLoadComplete && !disabled) {
            scrollContainer.addEventListener('scroll', handleScroll);
            return () => scrollContainer.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll, initialLoadComplete, disabled, querySelector]);

    const refreshHandle = () => {
        setItems([]);
        setCurrentItemCount(0);
        setHasMore(true);
        setInitialLoadComplete(false);
    }

    return {
        items,
        setItems,
        isFetching,
        hasMore,
        refetchItems,
        refresh: refreshHandle
    }
};

export default useInfiniteScroll;

import { FC } from "react";
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { AddInSpaceProvider } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { Header } from "./components/Header/Header.component";
import { Content } from "./components/Content/Content.component";
import { useSpaceTagPage } from "@/providers/v3/SpaceTagPage/SpaceTagPage.provider";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Sidebar } from "../Space/components/Sidebar/Sidebar.component";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";

export const SpaceTagContent: FC = () => {
    const { update } = useSpaceTagPage();
    const shoudRenederMainSidebar = useRenederAbove('medium');
    return <AddInSpaceProvider onUpdate={update}>
        <MainLayout sidebar={shoudRenederMainSidebar ? <Sidebar onUpdate={update} /> : undefined}>
            <Header/>
            <Content/>
        </MainLayout>
        <CreateNoteDrawer onUpdate={update} />
    </AddInSpaceProvider>
};
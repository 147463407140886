import { FC } from "react";

export const ChromeIcon: FC = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10161_10411)">
        <path d="M12 17.4976C15.0376 17.4976 17.5 15.0351 17.5 11.9976C17.5 8.95999 15.0376 6.49756 12 6.49756C8.96243 6.49756 6.5 8.95999 6.5 11.9976C6.5 15.0351 8.96243 17.4976 12 17.4976Z" fill="white"/>
        <path d="M11.9981 6.50002H21.5228C20.5576 4.82774 19.1692 3.43904 17.4971 2.47356C15.825 1.50809 13.9282 0.999865 11.9973 1C10.0665 1.00013 8.16976 1.50862 6.49779 2.47433C4.82583 3.44004 3.43758 4.82893 2.47266 6.50135L7.23501 14.75L7.23928 14.7489C6.75482 13.9135 6.49916 12.9651 6.49808 11.9994C6.49699 11.0337 6.75051 10.0847 7.23309 9.24823C7.71567 8.41173 8.41025 7.71723 9.24681 7.23474C10.0834 6.75225 11.0323 6.49883 11.9981 6.50002Z" fill="#636363"/>
        <path d="M11.9987 16.3541C14.4034 16.3541 16.3529 14.4047 16.3529 12C16.3529 9.59524 14.4034 7.64581 11.9987 7.64581C9.59396 7.64581 7.64453 9.59524 7.64453 12C7.64453 14.4047 9.59396 16.3541 11.9987 16.3541Z" fill="#636363"/>
        <path d="M16.7643 14.7513L12.002 23C13.9328 23.0002 15.8296 22.4922 17.5018 21.5268C19.174 20.5615 20.5625 19.1729 21.5278 17.5007C22.4931 15.8285 23.0011 13.9316 23.0007 12.0008C23.0004 10.07 22.4917 8.17326 21.5258 6.5014H12.0011L12 6.50567C12.9657 6.5038 13.9149 6.75657 14.7518 7.23849C15.5887 7.7204 16.2837 8.41443 16.7669 9.25062C17.25 10.0868 17.5042 11.0356 17.5037 12.0013C17.5033 12.967 17.2482 13.9156 16.7643 14.7513Z" fill="#636363" fillOpacity="0.3"/>
        <path d="M7.23651 14.7514L2.47415 6.50275C1.50849 8.17475 1.00007 10.0715 1 12.0023C0.999928 13.9332 1.50821 15.83 2.47374 17.502C3.43926 19.1741 4.828 20.5625 6.50031 21.5276C8.17262 22.4927 10.0696 23.0005 12.0004 23L16.7627 14.7513L16.7597 14.7482C16.2784 15.5855 15.5849 16.2811 14.7491 16.7649C13.9133 17.2487 12.9647 17.5036 11.999 17.5039C11.0333 17.5042 10.0845 17.25 9.24841 16.7667C8.41229 16.2835 7.71833 15.5884 7.23651 14.7514Z" fill="#636363" fillOpacity="0.7"/>
        </g>
        <defs>
        <clipPath id="clip0_10161_10411">
        <rect width="22" height="22" fill="white" transform="translate(1 1)"/>
        </clipPath>
        </defs>
    </svg>
};
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    display: flex;
    align-items: center;
`;
export const StyledPro = styled('div')`
    width: 33px;
    height: 20px;
    background: linear-gradient(248.17deg, #D251FF 9.36%, #2F2C2C 100%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 1px 4px;
`;
import { FC } from "react";
import { StyledConnectApps, StyledContriner, StyledGradientWrapper, StyledWrapper } from "./ExternalEmptyState.styled";
import { GradientLayout } from "@/components/v3/Layouts/GradientLayout/GradientLayout.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { externalUsersMock } from "./ExternalEmptyState.mock";
import { StyledPersonActivities } from "../All/All.styled";

export const ExternalEmptyState: FC = () => {
    const externalUsersJsx = externalUsersMock.map((el) => {
        return <StyledPersonActivities person={el.person} nodes={el.activities} type="external" key={el.userId} />
    });
    return <StyledWrapper>
        <StyledContriner>
            <StyledGradientWrapper>
                <GradientLayout>
                    <FilledCard>
                        {externalUsersJsx}
                    </FilledCard>
                </GradientLayout>
            </StyledGradientWrapper>
            <StyledConnectApps
                title="Get resources related to users without inviting them"
                description="Connect apps to automatically get all users from your emails, Slack, Asana, ClickUp, etc and all resources related to them. They will not be invited to your workspace."
                applications={['Slack', 'Asana', 'Notion', 'GoogleDrive']}
            />
        </StyledContriner>
    </StyledWrapper>
};
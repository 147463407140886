import { IconProps } from "./icon.type";

export const RemoveFromTagIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.72656 6H14.0016" stroke="#202020" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 2.5L10 7.5" stroke="#202020" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 2.5L5 13.5" stroke="#202020" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2 10H7" stroke="#202020" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 12C8 14.1896 9.81045 16 12 16C14.1934 16 16.0039 14.1896 16.0039 12C16.0039 9.81044 14.1896 8 11.9961 8C9.80658 8 8 9.81044 8 12ZM9.58065 11.2C9.25996 11.2 9 11.4686 9 11.8C9 12.1314 9.25996 12.4 9.58065 12.4H14.4194C14.74 12.4 15 12.1314 15 11.8C15 11.4686 14.74 11.2 14.4194 11.2H9.58065Z" fill="#202020"/>
    </svg>
  );
};

import React, { FC, useContext, useEffect } from "react";
import { ResponsiveContextType, ResponsiveProps } from "./Responsive.type";
import { useState } from "react";
import useWindowResizeEvent from "@/hooks/v3/UseWindowResizeEvent/UseWindowResizeEvent.hook";
import mobileCheck from "./utils/mobileCheck.util";
import mobileAndTabletCheck from "./utils/mobileAndTabletCheck.util";


const defaultValue: ResponsiveContextType = {
    isMobile: false,
    innerWidth: -0,
    isMobileOrTablet: false,
};

const ResponsiveContext = React.createContext<ResponsiveContextType>(defaultValue);
export const useResponsive = () => useContext<ResponsiveContextType>(ResponsiveContext);

export const ResponsiveProvider: FC<ResponsiveProps> = ({
    children
}) => {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [isMobile] = useState(mobileCheck(window.navigator.userAgent));
    const [isMobileOrTablet] = useState(mobileAndTabletCheck(window.navigator.userAgent));

    useWindowResizeEvent(() => {
        setInnerWidth(document.body.clientWidth)
    }, []);
    useEffect(() => {
        setInnerWidth(document.body.clientWidth)
    }, []);

    return (
        <ResponsiveContext.Provider value={{ isMobile, isMobileOrTablet, innerWidth }}>
            {children}
        </ResponsiveContext.Provider>
    );
};

export default ResponsiveContext;
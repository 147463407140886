import { ActionTypeType } from "@/components/ActivityDetail/ActivityDetail.type";

export function actionTypeToText(actionType?: ActionTypeType): string {
    switch (actionType) {
        case 'CREATED_BY':
        case 'CREATOR':
            return 'created';
        case 'COMMENTED_BY':
        case 'COMMENTER':
        case 'POSTED_BY':
            return 'commented';
        case 'EDITED_BY':
            return 'edited';
        case 'SHARED_BY':
        case 'SHARED_TO':
            return 'shared';
        case 'WATCHED_BY':
            return 'watched';
        case 'ASSIGNEE':
            return 'was assigned to';
        case 'WATCHER':
            return 'is a watcher of';
        case 'MENTIONS':
        case 'CC':
        case 'BCC':
        case 'TO':
            return 'mentioned';
        case 'FROM':
            return 'sent';
        case 'ADDED_BY':
            return 'added manually';
        case 'UPDATED':
            return 'updated status';
        default:
            return 'created';
    }
}
import { styled } from "@mui/material";
import { AppIcon } from "../../../../Other/AppIcon/AppIcon.component";

export const StyledIconWrapper = styled('div')`
    position: relative;
    margin-right: 8px;
`;
export const StyledAppContainer = styled('img')`
    border-radius: 4px;
    width: 16px;
    height: 16px;
    display: block;
    overflow: hidden;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
`;
export const StyledAppIcon = styled('div')`
    border-radius: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledAppIconImg = styled(AppIcon)`
    width: 14px;
    height: 14px;
`;
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    padding: 8px 16px;
    margin-bottom: 8px;
    &::last-child {
        margin-bottom: 0;
    }
`;
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    gridWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: '#FFECE7',
      [theme.breakpoints.up('xs')]: {
        minHeight: '100vh',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '320px',
        width: '320px',
      },
    },
}));
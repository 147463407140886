import { GetChannelsFetch } from "@/axios/AiService/Channels/Channels.api";
import { SpaceChannelType, SpaceChannelsAxiosResponseSuccessType } from "@/axios/AiService/Channels/Types/Get/Get.type";
import { createContext, useState, useContext, useEffect, useCallback } from "react";
import { AppChannels, ChannelsContextType, ChannelsProviderProps } from "./Channels.type";
import { AppTypesKeys } from "@/types/AppTypes/AppTypes.type";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

const initialChannelContext: ChannelsContextType = {
    appChannels: {
        Slack: [],
        Jira: [],
        Asana: [],
        Github: [],
        ClickUp: [],
        Trello: [],
        Figma: [],
        Confluence: [],
        MicrosoftTeams: [],
        Notion: [],
        TeamSpaces: [],
        GoogleDrive: [],
        Gmail: [],
        Sharepoint: [],
        Dropbox: [],
        Outlook: [],
        GoogleCalendar: [],
    },
    update: () => new Promise(() => undefined),
    isLoading: false,
}

const ChannelsContext = createContext<ChannelsContextType>(initialChannelContext);
export const useChannels = () => useContext<ChannelsContextType>(ChannelsContext);

export const APPS: AppTypesKeys[] = ['Slack', 'Jira', 'Asana', 'Github', 'ClickUp', 'Trello', 'Figma', 'Confluence', 'MicrosoftTeams', 'Notion', 'TeamSpaces'];
const ChannelsProvider = ({ children }: ChannelsProviderProps) => {
    const { isSharedSpace, isSharedNode } = useIsShared();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [appChannels, setAppChannels] = useState<AppChannels>({
      Slack: [],
      Jira: [],
      Asana: [],
      Github: [],
      ClickUp: [],
      Trello: [],
      Figma: [],
      Confluence: [],
      MicrosoftTeams: [],
      Notion: [],
      TeamSpaces: [],
      GoogleDrive: [],
      Gmail: [],
      Sharepoint: [],
      Dropbox: [],
      Outlook: [],
      GoogleCalendar: [],
    });
    const loadChannels = useCallback(async (app?: AppTypesKeys): Promise<SpaceChannelType[]> => {
      if (!isSharedSpace && !isSharedNode) {
        if (!!app) {
          setIsLoading(true);
          return await GetChannelsFetch({
            application: app
          }).then((res: SpaceChannelsAxiosResponseSuccessType) => {
              const application = res.data[0] && res.data[0].application;
              application && setAppChannels && setAppChannels((prevChannels) => ({
                ...prevChannels,
                [application]: res.data,
              }));
              return res.data;
            }).finally(() => {
              setIsLoading(false);
            });
        } else {
          APPS.map(app => {
            GetChannelsFetch({
              application: app
            }).then((res: SpaceChannelsAxiosResponseSuccessType) => {
                const application = res.data[0] && res.data[0].application;
                application && setAppChannels && setAppChannels((prevChannels) => ({
                  ...prevChannels,
                  [application]: res.data,
                }));
              });
            }, [appChannels])
        }
      }
      return []
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSharedSpace, isSharedNode]);
    useEffect(() => {
      loadChannels();
    }, [loadChannels])
    
    const contextValue: ChannelsContextType = {
        appChannels,
        update: loadChannels,
        isLoading
    };

    return (
        <ChannelsContext.Provider value={contextValue}>
            {children}
        </ChannelsContext.Provider>
    );
};

export { ChannelsContext, ChannelsProvider };
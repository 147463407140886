import { FC, useMemo } from "react";
import { SpaceTagProps } from "./SpaceTag.type";
import { StyledEmoji, StyledEmojiWrapper, StyledSpaceTag, StyledSpaceTagContainer, StyledSpaceTagText, StyledWhiteTagIcon } from "./SpaceTag.styled";
import { UpdateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { ToastService } from "@/service/ToastService";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { Button } from "@/components/v3/Fields/Button/Button.component";

export const SpaceTag: FC<SpaceTagProps> = ({ tag, nodeId, spaceId, onUpdate }) => {
    const isAdded = tag.nodeIds.includes(nodeId);
    const trackEvent = useAmplitude();
    const addToSpaceTagHandler = async () => {
        await UpdateSpaceTagsFetch(spaceId, tag.id, {
            ...tag,
            emoji: tag.emoji || null,
            nodeIds: [...tag.nodeIds, nodeId]
        }).then(() => {
            onUpdate();
            trackEvent(EventTypes.NODE_WAS_MANUALLY_ADDED_TO_SPACE);
            ToastService.showToast('success', '1 resource was added to the tag!');
        })
        .catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
    }
    const removeFromSpaceTagHandler = async () => {
        await UpdateSpaceTagsFetch(spaceId, tag.id, {
            ...tag,
            emoji: tag.emoji || null,
            nodeIds: tag.nodeIds.filter((id) => id !== nodeId)
        }).then(() => {
            onUpdate();
            trackEvent(EventTypes.NODE_WAS_MANUALLY_ADDED_TO_SPACE);
            ToastService.showToast('success', '1 resource was removed from the tag!');
        })
        .catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
        
    }
    const imgJsx = useMemo(() => {
        if (!!tag.emoji) {
          return <StyledEmojiWrapper>
            <StyledEmoji>{tag.emoji}</StyledEmoji>
          </StyledEmojiWrapper>
        }
        return <>
            <StyledWhiteTagIcon />
        </>
      }, [tag.emoji]);
    return <StyledSpaceTag>
            <StyledSpaceTagContainer>
                {imgJsx}
                <StyledSpaceTagText >{tag.name}</StyledSpaceTagText>
            </StyledSpaceTagContainer>
            {!isAdded && <Button center onClick={() => addToSpaceTagHandler()}>Add here</Button>}
            {isAdded && <Button center onClick={() => removeFromSpaceTagHandler()} variant="tertiary">Remove</Button>}
        </StyledSpaceTag>
}
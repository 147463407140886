import { IconButton } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { WalkthroughWrapper } from 'providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component';
import { StyledTextField } from './SearchTextField.styled';
import { SearchTextFieldProps } from './SearchTextField.type';

export const SearchTextField = ({ id, type, value, onChange, error, ...rest }: SearchTextFieldProps) => {
  return (
    <WalkthroughWrapper
      variant="walkthrough-search"
      title="Search across all your apps"
      description="Your can find any resource by name, app, people, type. "
      targetId="walkthrough-search"
      position="bottom"
      maxWidth={320}
      topMargin={-12}
    >
      <StyledTextField
        id="walkthrough-search"
        placeholder="Search in Sense"
        variant="outlined"
        onChange={onChange}
        value={value}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchOutlined />
            </IconButton>
          ),
          // endAdornment: (
          //     <IconButton>
          //       <img src="/icons/powered-by-swirl.svg" />
          //     </IconButton>
          // ),
        }}
        {...rest}
      />
    </WalkthroughWrapper>

  );
};

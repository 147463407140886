import { useState, FC, useEffect } from "react";
import { Popover } from "@mui/material";
import { Emoji, EmojiSelectProps } from "./EmojiSelect.type";
import { StyledButton, StyledDropdownContainer } from "./EmojiSelect.styled";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react'

export const EmojiSelect: FC<EmojiSelectProps> = ({
    children,
    className,
    onChange,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    onChangeIsOpened,
    onMouseEnter,
    onMouseLeave
}: EmojiSelectProps) => {
    const defaultPath = '/icons/default-emoji.svg';
    const custom = [
        {
            id: 'default',
            name: 'Default',
            emojis: [
                {
                    id: 'default',
                    name: 'Default',
                    keywords: ['default'],
                    skins: [{ src: defaultPath }],
                },
            ],
        },
    ];
      
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        onChangeIsOpened && onChangeIsOpened(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onChangeIsOpened && onChangeIsOpened(false);
    };
    useEffect(() => {
        onChangeIsOpened && onChangeIsOpened(Boolean(anchorEl));
    }, [anchorEl, onChangeIsOpened]);
    const open = Boolean(anchorEl);
    return (
        <>
            <StyledButton onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handleButtonClick} className={className}>
                {children}
            </StyledButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <StyledDropdownContainer>
                <Picker data={data} custom={custom} onEmojiSelect={(e: Emoji) => {
                    onChange(e);
                    handleClose();
                }} />
                </StyledDropdownContainer>
            </Popover>
        </>
    );
};

import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { Drawer, styled } from "@mui/material";

export const StyledWrappper = styled('div')`
    display: flex;
    width: 70%;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        width: 100%;
        padding-right: 8px;
    }
`;
export const StyledSearchWrappper = styled('div')`
    width: 100%;
`;
export const StyledHeaderSearch = styled('div')`
    display: flex;
    width: 70%;
`;
export const StyledHeaderAppMenu = styled('div')`
    display: flex;
`;
export const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        width: 75%;
    }
`;
import { SpaceTagType } from "@/components/v3/Common/EditSpaceForm/components/Tags/components/Tag/Tag.type";

export function groupTags(original: SpaceTagType[], edited: SpaceTagType[]) {
    const needUpdate: SpaceTagType[] = [];
    const needCreate: SpaceTagType[] = [];
    const needDelete: SpaceTagType[] = [];

    const originalMap = new Map(original.map(tag => [tag.id, tag]));
    const editedMap = new Map(edited.map(tag => [tag.id, tag]));

    for (const editedTag of edited) {
        if (originalMap.has(editedTag.id)) {
            const originalTag = originalMap.get(editedTag.id);
            if (JSON.stringify(editedTag) !== JSON.stringify(originalTag)) {
                needUpdate.push(editedTag);
            }
        } else {
            needCreate.push(editedTag);
        }
    }

    for (const originalTag of original) {
        if (!editedMap.has(originalTag.id)) {
            needDelete.push(originalTag);
        }
    }

    return { needUpdate, needCreate, needDelete };
}

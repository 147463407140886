import { NodeRelationTypeWithUser } from "./AddUsersToRelations.type";
import { UserType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";
import { NodeRelationType } from "@/axios/AiService/NodeRelations/Types/Get/Get.type";

export const addUsersToRelations = (users: UserType[], relations: NodeRelationType[]): NodeRelationTypeWithUser[] => {
    return relations.map((relation) => {
        return {
            ...relation,
            actors: relation.actors.map((actor) => {
                return {
                    ...actor,
                    user: users.find((user) => user.id === actor.userId)!
                }
            })
        };
    });
};
import { FC } from "react";
import { ImportantLabelProps } from "./ImportantLabel.type";
import { StyledImportantLabel, StyledImportnatIcon } from "./ImportantLabel.styled";

export const ImportantLabel: FC<ImportantLabelProps> = ({ value, className }) => {
    return <>
        {value && <StyledImportantLabel className={className}>
            <StyledImportnatIcon />
            Important for me
        </StyledImportantLabel>}
    </>
}
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Arrow } from "@/components/v3/Fields/DropdownField/components/Arow/Arow.component";
import { AddIcon } from "@/icons/v3/add.icon";
import { styled } from "@mui/material";

export const StyledButton = styled(Button)`
    margin-left: 8px;
`
export const StyledAddIcon = styled(AddIcon)`
    width: 18px;
    height: 18px;
    & path {
        fill: #fff;
    }
`
export const StyledArrow = styled(Arrow, {
    shouldForwardProp: (prop) => prop !== 'active'
})<{active: boolean}>`
    transition: .2s ease-in-out;
    transform-origin: center;
    width: 24px;
    height: 24px;
    transform: ${({active}) => active ? 'rotate(180deg)' : 'rotate(0deg)'};
    fill: #ffffff;
    margin-left: 8px;
    path {
        fill: #ffffff;
    }
`;
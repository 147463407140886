import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { CreateBodyParams } from "./Types/Post/Post.type";
import { UpdateBodyParams } from "./Types/Put/Put.type";
import { GetTagNodesGetBodyParams } from "./Types/GetTagNodes/GetTahNodes.type";
import { AddNodeToSpaceTagBodyParams } from "./Types/AddNodeToSpaceTag/AddNodeToSpaceTag.type";

export const GetSpaceTagsFetch = (spaceId: string) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/tags`);
}
export const GetSpaceTagNodesFetch = (spaceId: string, tagId: string, body: GetTagNodesGetBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/${spaceId}/tags/${tagId}/nodes`, body);
}
export const AddNodeToSpaceTagFetch = (spaceId: string, body: AddNodeToSpaceTagBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/${spaceId}/tags/nodes`, body);
}
export const CreateSpaceTagsFetch = (spaceId: string, body: CreateBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/${spaceId}/tags`, body);
}
export const UpdateSpaceTagsFetch = (spaceId: string, tagId: string, body: UpdateBodyParams) => {
    return HttpClient.put(`${envUrl}/ai-service/v1/spaces/${spaceId}/tags/${tagId}`, body);
}
export const DeleteSpaceTagsFetch = (spaceId: string, tagId: string) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/spaces/${spaceId}/tags/${tagId}`);
}
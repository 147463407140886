import { DataService } from './DataService';
import { Observable } from 'rxjs';
import { get, HttpClient } from '../axios/HttpClient';
import { GraphNode } from '../components/Nodes/NodeService';
import { envUrl } from "./helpers";

export interface Space {
  id?: string;
  nodeType?: string;
  name: string;
  application: string;
  userIds: string[];
}

class SpaceDataService extends DataService<GraphNode[]> {
  protected getFetchUrl(_arg?: string): string {
    return super.getFetchUrl() + _arg;
  }
}

//TODO: prod
export const SpacesService = new DataService<Space[] | undefined>(`${envUrl}/ai-service/v1/spaces`, undefined);

export const fetchSpaceById$ = (id: string): Observable<Space> => get<Space>(`/ai-service/v1/spaces/${id}`);

export const NodesForSpaceService = new SpaceDataService(`${envUrl}/ai-service/v1/spaces/`, []);

export const fetchRelatedNodesBySpaceById$ = (spaceId: string): Observable<GraphNode[]> =>
  get<GraphNode[]>(`/ai-service/v1/spaces/${spaceId}/nodes`);

export const getUsersAddedToSpace = (spaceId: string) => {
  return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/users`);
}

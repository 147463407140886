import { FC, useEffect, useState } from "react";
import {
    StyledContent,
    StyledStepTitle,
    StyledStep3Title,
    StyledStep3,
    StyledActions,
    StyledSubmit,
} from "./Content.styled";
import { ContentProps } from "./Content.type";
import { SenseTextField } from "@/mui/SenseTextField/SenseTextField.component"
import { OtpDisableFetch } from "@/axios/OrganizationService/Otp/Otp.api";
import { useMe } from "providers/MeProvider/Me.provider";
import { OtpDisableAxiosResponseSuccessType } from "@/axios/OrganizationService/Otp/Types/Disable/Disable.type";

export const Content: FC<ContentProps> = ({ onClose }) => {
    const { loadUser } = useMe();
    const [code, setCode] = useState<string>('')
    const [error, setError] = useState<string>('')
    useEffect(() => {
        if (code) {
            setError('');
        }
    }, [code]);
    const submitHandle = async () => {
        if (!code) {
            setError('Invalid code');
            return;
        }
        await OtpDisableFetch({
            code
        }).then(async (res: OtpDisableAxiosResponseSuccessType) => {
            if (res.data.disabled === true) {
                await loadUser();
                onClose();
            } else {
                setError('Invalid code');
            }
        });
    }
    return <StyledContent>
        <StyledStep3>
            <StyledStepTitle>Enter Validation code</StyledStepTitle>
            <StyledStep3Title>Enter code generated by Authenticator App</StyledStep3Title>
            <SenseTextField
                id="code"
                type="code"
                label="Enter authentication code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                error={!!error}
                helperText={error || ""}
            />
        </StyledStep3>
        <StyledActions>
            <StyledSubmit onClick={submitHandle}>Submit</StyledSubmit>
        </StyledActions>
    </StyledContent>
}
import { FC } from "react";
import { AddNodeItemProps } from "./AddNodeItem.type";
import { StyledInfoWrapper, StyledName, StyledNodeIcon, StyledNoteContent, StyledWrapper } from "./AddNodeItem.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { NodeTypeType } from "@/types/Node/Node.type";

export const AddNodeItem: FC<AddNodeItemProps> = ({
    node,
    listOfAddedNodes,
    onAdd,
    onRemove
}) => {
    const isAddedById = listOfAddedNodes.map(el => el.id).includes(node.id);
    const isAddedByUrl = listOfAddedNodes.map(el => el.originalURI).includes(node.originalURI);
    const isAdded = isAddedById || isAddedByUrl;
    const isNote = node.nodeType === NodeTypeType.NOTE;
    
    return <StyledWrapper>
        <StyledInfoWrapper>
            {!isNote && <>
                <StyledNodeIcon node={node} />
                <StyledName>{node.name}</StyledName>
            </>}
            {isNote && <StyledNoteContent>
                {node.content && parse(sanitizeHtml(node.content, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                    allowedAttributes: {
                        ...sanitizeHtml.defaults.allowedAttributes,
                        'a': ['href', 'title', 'target']
                    }
                }))}    
            </StyledNoteContent>}
        </StyledInfoWrapper>
        {!isAdded && <Button onClick={() => onAdd(node)}>Add</Button>}
        {isAdded && <Button onClick={() => onRemove(node)} variant="tertiary">Remove</Button>}
    </StyledWrapper>
};
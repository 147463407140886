import { Button } from "@/components/v3/Fields/Button/Button.component";
import { NodeCard } from "@/components/v3/List/NodeCard/NodeCard.component";
import { FC } from "react";
import { StyledNodesWrapper, StyledScrollWrapper, StyledSectionHeader, StyledWrapper } from "./Important.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";

export const Important: FC = () => {
    const router = useRouter();
    const { isFetchingImportant, listOfImportant, update } = useForYouPage();
    const isLargeScreen = useRenederAbove('large');
    const countOfRecentNodes = isLargeScreen ? 4 : 3;
    const recentNodes = listOfImportant.slice(0, countOfRecentNodes);
    const nodesJsx = recentNodes.map((el, i) => {
        return <NodeCard key={i} node={el.node} onUpdate={update} onNewComment={update} />
    });

    return <StyledWrapper>
        <EmptyStateLayoyt
            value={!listOfImportant.length && !isFetchingImportant}
            emptyNode={<></>}
        >
            <StyledSectionHeader title="Important" >
                <Button onClick={() => router.push({
                    name: 'important'
                })} variant="tertiary">Show all</Button>
            </StyledSectionHeader>
            <LoaderLayout isLoading={isFetchingImportant} transparent={!!nodesJsx.length}>
                <StyledScrollWrapper>
                    <StyledNodesWrapper countOfRecentNodes={countOfRecentNodes}>
                        {nodesJsx}
                    </StyledNodesWrapper>
                </StyledScrollWrapper>
            </LoaderLayout>
        </EmptyStateLayoyt>
    </StyledWrapper>
};
import { FC } from "react";
import { MyWorkspaceProps } from "./MyWorkspace.type";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { StyledPersonActivities, StyledSectionHeader } from "../All/All.styled";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";

export const MyWorkspace: FC<MyWorkspaceProps> = ({
    isFetching,
    myWorkspaceUsers,
}) => {
    const myWorkspaceUsersJsx = myWorkspaceUsers.map((el) => {
        return <StyledPersonActivities person={el.person} nodes={[]} type="workspace" key={el.userId} />
    });
    const isEmpty = !myWorkspaceUsers.length && !isFetching;
    return <LoaderLayout isLoading={isFetching} transparent={!!myWorkspaceUsers.length}>
        <EmptyStateLayoyt
            value={isEmpty}
            emptyNode={<></>}
        >
            <StyledWrapper>
                <StyledSectionHeader title="My workspace" />
                <FilledCard>
                    {myWorkspaceUsersJsx}
                </FilledCard>
            </StyledWrapper>
        </EmptyStateLayoyt>
    </LoaderLayout>
};
import { styled } from "@mui/material";
import { VariantType } from "./EmptyCard.type";

export const StyledWarapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'variant'
})<{variant: VariantType}>`
    background-color: #fff;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    ${({variant}) => variant === 'dashed' && `
        border: 2px dashed rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        padding: 16px;
        &:hover {
            border: 2px dashed #202020;
        }
    `};
    ${({variant}) => variant === 'shadow' && `
        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
        padding: 24px;
        &:hover {
            border: 1px solid #202020;
        }
    `};
`;
export const StyledIconWrapper = styled('div')`
    display: flex;
    justify-content: center;
`;
export const StyledAddIconWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.06);
`;
export const StyledTitle = styled('div')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 16px;
`;
export const StyledDescription = styled('div')`
    color: #636363;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;
`;
import { FC, useEffect, useState } from "react";
import { AddCommentDialogProps } from "./AddCommentDialog.type";
import { StyledCancel, StyledDescription, StyledDialog, StyledDialogContent, StyledDialogTitle, StyledSubmitButton, StyledTextField } from "./AddCommentDialog.styled";
import { DialogActions, Typography } from "@mui/material";
import { CreateCommentFetch } from "@/axios/AiService/Comments/Comments.api";
import { ToastService } from "@/service/ToastService";

export const AddCommentDialog: FC<AddCommentDialogProps> = ({ nodeId, value, onChange, onNewComment }) => {
    const [comment, setComment] = useState<string>('');
    useEffect(() => {
        setComment('');
    }, [value]);
    const addHandler = async () => {
        await CreateCommentFetch({nodeId}, {
            content: comment
        }).then((res) => {
            ToastService.showToast('success', 'Comment was successfully added');
            onNewComment(res.data);
        });
        onChange(false);
    };
    return <StyledDialog open={value} aria-labelledby="form-dialog-title" >
        <StyledDialogTitle id="form-dialog-title">
            <Typography variant="h1" component="h1" style={{ fontSize: '24px', fontWeight: 600}}>
                Add comment
            </Typography>
        </StyledDialogTitle>
        <StyledDialogContent>
            <StyledTextField multiline rows={4} style={{ width: '100%' }} label="Comment" placeholder="Type your comment here" variant="outlined" value={comment} onChange={(e) => setComment(e.target.value)} />
            <StyledDescription>
                Your comment will be visible to everyone in your workspace. It will not be visible in shared spaces and resources. 
            </StyledDescription>
        </StyledDialogContent>
        <DialogActions>
            <StyledCancel onClick={() => onChange(false)} color="primary" variant="contained">
                Cancel
            </StyledCancel>
            <StyledSubmitButton
                onClick={() => addHandler()}
                variant="contained"
            >
                Add
            </StyledSubmitButton>
        </DialogActions>
    </StyledDialog>
};
import { Drawer } from '@mui/material';
import { DrawerContent } from "./components/DrawerContent/DrawerContent.component";
import { StyledCloseButtonWrapper, StyledEditButton, StyledLineButtons } from "./NodeNeighboursDrawer.styled";
import { useChromeExtension } from "@/providers/ChromeExtension/ChromeExtension.provider";
import useOnClickOutside from "@/hooks/UseOnClickOutside/UseOnClickOutside.hook";
import { useEffect, useMemo, useRef, useState } from "react";
import { ShareButton } from "./components/DrawerContent/components/ShareButton/ShareButton.component";
import { OpenButton } from "./components/OpenButton/OpenButton.component";
import { Menu } from "./components/Menu/Menu.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { ScaledCloseIcon } from "@/icons/v3/close.icon";
import { AddComment } from "./components/AddComment/AddComment.component";
import { useNode } from '../../Node.provider';
import { NodeTypeType } from '@/types/Node/Node.type';
import { EditIcon } from '@/icons/v3/edit.icon';
import { useRouter } from '@/providers/Router/Router.provider';
import { useMe } from '@/providers/MeProvider/Me.provider';

export const NodeNeighboursDrawer = () => {
    const { isSharedSpace, isSharedNode } = useIsShared();
    const router = useRouter();
    const [commentHeight, setCommentHeight] = useState<number>(0);
    const chromeExtension = useChromeExtension();
    let contentRef = useRef<HTMLDivElement>(null);
    const { user, invitedUsers, confirmedInvitedUsers } = useMe();
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
    const { isOpened, onClose, node } = useNode();
    const handleClickOutside = (): void => {
        onClose();
    };
    useOnClickOutside(contentRef, handleClickOutside, ['.disable-node-drawer-close', '.MuiDialog-container', '.MuiMenu-root', '#mentions']);
    const isMobile = useRenederBelow(560);
    const fullWidth = isMobile || chromeExtension.isIframe;
    useEffect(() => {
        if (isSubscriptionExpired && isOpened) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                    node: undefined,
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountOfPaidUsers, isOpened, isSubscriptionExpired]);
    return (
        <Drawer
            anchor="right"
            open={isOpened}
            onClose={onClose}
            style={{ pointerEvents: 'none', }}
            PaperProps={{
                style: {
                    boxShadow: '-8px 16px 40px 2px rgba(0, 0, 0, 0.24)',
                    pointerEvents: 'auto',
                    width: fullWidth ? '100%' : 'auto',
                }
            }}
            ModalProps={{
                hideBackdrop: true,
                disableScrollLock: true,
            }}
            ref={contentRef}
        >
            <StyledCloseButtonWrapper>
                <Button onClick={onClose} icon={<ScaledCloseIcon />} variant="tertiary" />
                <StyledLineButtons>
                    <Menu />
                    {node?.nodeType !== NodeTypeType.NOTE && <>
                        {!isSharedSpace && <ShareButton />}
                        <OpenButton />
                    </>}
                    {node?.nodeType === NodeTypeType.NOTE && <StyledEditButton variant="secondary" icon={<EditIcon />} onClick={() => router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            node: undefined,
                            dialog: 'edit-note',
                            note: node.id
                        }
                    })}>Edit</StyledEditButton>}
                </StyledLineButtons>
            </StyledCloseButtonWrapper>
            <DrawerContent commentHeight={commentHeight} />
            {!isSharedNode && !isSharedSpace && <AddComment setHeight={setCommentHeight} />}
        </Drawer>
    )
}
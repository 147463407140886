import { useMe } from "@/providers/MeProvider/Me.provider";
import {HttpClient} from "../axios/HttpClient";
import {envUrl} from "./helpers";
import { useCallback, useEffect } from "react";

export enum EventTypes {
    LAST_LOGIN = 'Last Login',
    SEARCH_CLICK = 'Search Click',
    FOLLOW_NODE = 'Follow Node',
    UNFOLLOW_NODE = 'Unfollow Node',
    SEARCH_EXTERNAL_CLICK = 'Search External Click',
    MENU_CLICK = 'Menu Click',
    FORYOU_TRENDING_NODE_OPEN = 'ForYou Trending Node Open',
    FORYOU_NODE_OPEN = 'ForYou Node Open',
    FORYOU_FOLLOWED_NODE_OPEN = 'ForYou Followed Node Open',
    RECENT_ACTIVITIES_NODE_OPEN = 'Recent Activities Node Open',
    COMMON_RELATIONSHIP_NODE_OPEN = 'Common Relationship Node Click',
    RELATIONSHIP_CLICK = 'Relationship Click',
    SPACE_CREATED = 'Space was manually created',
    RESOURCE_ADDED = 'Resource Added',
    CHANNEL_ADDED = 'Channel Added',
    ORIGIN_NODE_LINK_OPENED = 'Original node link opened',
    SEARCH_RESULT_CLICKED = 'Search result clicked',
    NODE_WAS_MANUALLY_ADDED_TO_SPACE = 'Node was manually added to a space',
    APP_WAS_CONNECTED_TO_SPACE = 'App was connected to a space',
    USER_VOTED = 'User voted',
    SHARED_SPACE_VISITED = 'Shared space visited',
    SHARED_SPACE_CATEGORY_VISITED = 'Shared space category visited',
    SHARED_SPACE_TAG_VISITED = 'Shared space tag visited',
    SHARED_NODE_VISITED = 'Shared node visited',
    FIRST_LOGIN = 'Onboarding: First login',
    ONBOARDING_SELECT_PLAN = 'Onboarding: Plan selected',
    ONBOARDING_CONNECT_APPS = 'Onboarding: Connect app step passed',
    ONBOARDING_INVITE_USERS = 'Onboarding: Invite users step passed',
    SUGGESTION_ACCEPTED = 'Suggestion accepted',
    SUGGESTION_DECLINED = 'Suggestion declined',
    ALL_SUGGESTIONS_ACCEPTED = 'All suggestions accepted',
    ALL_SUGGESTIONS_DECLINED = 'All suggestions declined',
}

export const useAmplitude = () => {
    const { user } = useMe();
    const callback = useCallback((eventName: string, args?: any) => {
        !!user?.id && HttpClient.post(`${envUrl}/amplitude-service/v1/events`, [{
            eventType: eventName,
            userId: user?.id,
            timestamp: new Date().getTime(),
            userProperties: {
                organizationId: user?.organizationId
            },
            eventProperties: args ?? {...args}
        }]).catch(error => {
            console.log(error)
        });
    }, [user?.id, user?.organizationId]);
    return callback
};

import { useContext, createContext } from 'react';
import { TabsContextType, TabsProviderProps } from './Tabs.type';
import { StyledContainer, StyledLine, StyledWrapper } from './Tabs.styled';

const defaultValue: TabsContextType<any> = {
    value: null,
    onChange: () => undefined,
};

export const TabsContext = createContext<TabsContextType<any>>(defaultValue);

export const useTabs = <T,>() => useContext<TabsContextType<T>>(TabsContext);

export const Tabs = <T,>(props: TabsProviderProps<T>) => {
    return (
        <TabsContext.Provider value={{ value: props.value, onChange: props.onChange }}>
            <StyledContainer className={props.className}>
                <StyledWrapper>{props.children}</StyledWrapper>
                <StyledLine />
            </StyledContainer>
        </TabsContext.Provider>
    );
};
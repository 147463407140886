import { styled } from "@mui/material";

export const StyledSectionDescription = styled('div')`
    width: 50%;
    height: 100%;
    background: #fff;
    padding: 48px 60px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledLogo = styled("div")`
    position: absolute;
    left: 60px;
    top: 48px;
    width: 120px;
    height: 32px;
`;
export const StyledTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 32px;
`;
export const StyledCard = styled('div')`
    max-width: 475px;
`;
import { FC } from "react";
import { NodeLinkActionsProps } from "./NodeLinkActions.type";
import { StyledCopyLinkIcon, StyledIcon, StyledOpenLinkIcon } from "./NodeLinkActions.styled";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import useCopyToClipboard from "@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook";
import { IconButton } from "@mui/material";

export const NodeLinkActions: FC<NodeLinkActionsProps> = ({ value, hideCopy }) => {
    const { copyToClipboard } = useCopyToClipboard();
    const trackEvent = useAmplitude();
    const openHandle = () => {
        trackEvent(EventTypes.ORIGIN_NODE_LINK_OPENED);
        window.open(value, '_blank');
    };
    const copyHandle = () => {
        copyToClipboard(value, 'Link copied to clipboard');
    };
    return <>
        {!hideCopy && <IconButton onClick={copyHandle}>
            <StyledIcon >
                <StyledCopyLinkIcon />
            </StyledIcon>
        </IconButton>}
        
        <IconButton onClick={openHandle}>
            <StyledIcon >
                <StyledOpenLinkIcon  />
            </StyledIcon>
        </IconButton>
    </>
};
export const availablePlans = [
    {
        planName: 'Individual',
        price: 'Free',
        users: 'No',
        availableFeatures: 'All',
        includedIntegrations: 'Unlimited'
    },
    {
        planName: 'Team',
        price: '$8',
        users: 'Unlimited',
        availableFeatures: 'All',
        includedIntegrations: 'Unlimited'
    },
];
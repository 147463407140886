import { FC, createContext, useContext } from "react";
import { AddInSpaceProviderProps, AddInSpaceContextType } from "./AddInSpace.type";
import { AddInSpaceModal } from "./components/AddInSpaceModal/AddInSpaceModal.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

const defaultConfig: AddInSpaceContextType = {
    addInSpace: () => undefined,
};

export const AddInSpaceContext = createContext<AddInSpaceContextType>(defaultConfig);
export const useAddInSpace = () => useContext<AddInSpaceContextType>(AddInSpaceContext);

export const AddInSpaceProvider: FC<AddInSpaceProviderProps> = (
  { children, onUpdate }: AddInSpaceProviderProps
) => {
    const router = useRouter();
    const isMobile = useRenederBelow('tablet');
    const addInSpaceHandler = (asKeyResource?: boolean) => {
        router.push({
            name: router.name,
            params: router.params,
            query: {
                ...router.query,
                dialog: 'add-to-space',
                type: asKeyResource ? 'key-resources' : undefined,
                tab: isMobile ? '' : 'sense',
            }
        })
    };
    const config: AddInSpaceContextType = {
        addInSpace: addInSpaceHandler,
    }
    return (
        <>
            <AddInSpaceContext.Provider value={config}>
                {children}
                <AddInSpaceModal onUpdate={onUpdate} />
            </AddInSpaceContext.Provider>
        </>
    );
};
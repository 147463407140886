import {HttpClient} from "../../HttpClient";
import {envUrl} from "../../../service/helpers";
import { GetCommentsQueryParams } from "./Types/GetComments/GetComments.type";

export const GetTaskComments = (query: GetCommentsQueryParams) => {
    return HttpClient.get(`${envUrl}/asana-service/v1/tasks/comments`, {
        params: {
            ...query,
            nodeIds: query.nodeIds.join(',')
        }
    });
}
import { styled } from "@mui/material";

export const StyledAppIcon = styled('img')`
    width: 32px;
    height: 32px;
    object-fit: cover;
`;
export const StyledMaskedImg = styled('div')`
    width: 32px;
    height: 32px;
    display: block;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 6px;
`;
import { SvgIcon, SvgIconProps } from '@mui/material';

export const OpenLinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 14L14 2" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.38464 2H14V6.61538" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};

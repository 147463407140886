import { Button } from "@/components/v3/Fields/Button/Button.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledCloseButtonWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        border-bottom: 1px solid rgba(0,0,0,.08);
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 1;
    }
`
export const StyledLineButtons = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const StyledEditButton = styled(Button)`
  margin-left: 8px;
`
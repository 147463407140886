import ChromeExtensionService from "@/providers/ChromeExtension/services/ChromeExtension/ChromeExtension.srvice";

export const clearCookies = () => {
    localStorage.clear();
    sessionStorage.clear();
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    const version = document.documentElement.dataset.senseai_extension_version ? document.documentElement.dataset.senseai_extension_version : '0';
    const versionNumber = Number(version.replace(/\./g, ''))
    if (versionNumber >= Number('1.4.1.44'.replace(/\./g, ''))) {
      ChromeExtensionService.setLocalStorage({
        accessToken: null
      });
    }
    
}
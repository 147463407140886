import { styled } from "@mui/material";
import { FilledCard } from "../../Other/FilledCard/FilledCard.component";

export const StyledWrapper = styled('div')`
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledTitle = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #636363;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
export const StyledFilledCard = styled(FilledCard)`
    margin-top: 8px;
    padding-top: 8px;
`;
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Input } from "@/components/v3/Fields/Input/Input.component";
import { MentionArea } from "@/components/v3/Fields/MentionArea/MentionArea.component";
import { styled } from "@mui/material";

export const StyledCancelButton = styled(Button)`
    margin-right: 8px;
`;
export const StyledTextField = styled(Input) `
    margin-bottom: 16px;
    margin-top: -20px;
    & .MuiOutlinedInput-root {
        & fieldset {
            border: none;
        }
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.08);
    }
    & .MuiFormLabel-root {
        color: rgba(32, 32, 32, 0.5);
        font-size: 14px;
        font-weight: 400;
    }
    & .MuiInputBase-input {
        // padding: 10px 0px 10px 12px;
    }
    & .MuiInputLabel-formControl {
        position: relative;
        top: 13px;
    }
`;
export const StyledMentionArea = styled(MentionArea)`
    margin-bottom: 16px;
`;
export const StyledDescription = styled('div') `
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
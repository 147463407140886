import { SvgIcon, SvgIconProps } from '@mui/material';

export const Add2Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" {...props}>
      <g clipPath="url(#clip0_9479_29380)">
      <path d="M3 0.429688V5.57254" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.428589 3H5.57145" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_9479_29380">
      <rect width="6" height="6" fill="white"/>
      </clipPath>
      </defs>
    </SvgIcon>
  );
};

import { HOUR_HEIGHT, MIN_HEIGHT } from "../../../../../../../../MeetingsTimeline.const";

export const calculateHeight = (start: Date, end: Date): number => {
    const durationMinutes = (end.getTime() - start.getTime()) / (1000 * 60);
    const calculatedHeight = (durationMinutes / 60) * HOUR_HEIGHT;
    return Math.max(calculatedHeight, MIN_HEIGHT);
};

export const calculateTop = (start: Date, startHour: number): number => {
    return (start.getHours() * HOUR_HEIGHT + (start.getMinutes() / 60) * HOUR_HEIGHT) - (startHour * HOUR_HEIGHT);
};

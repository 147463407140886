import { FC } from 'react';
import { StyledAppIcon, StyledAppIconWrapper, StyledAppIconsWrapper, StyledAppsTitle, StyledAppsWrapper, StyledContent, StyledDescription, StyledFooter, StyledTitle, StyledWrapper } from './ConnectApps.styled';
import { Button } from '@/components/v3/Fields/Button/Button.component';
import { ConnectAppsProps } from './ConnectApps.type';
import { useRouter } from '@/providers/Router/Router.provider';

export const ConnectApps: FC<ConnectAppsProps> = ({
    title,
    description,
    applications,
    className
}) => {
    const appsJsx = applications.map((app, i) => <StyledAppIconWrapper key={i}><StyledAppIcon application={app} /></StyledAppIconWrapper>)
    const router = useRouter()
    return (
        <>
            <StyledWrapper className={className} onClick={() => router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'manage-integrations'
                }
            })}>
                <StyledContent>
                    <StyledTitle>
                        {title}
                    </StyledTitle>
                    <StyledDescription>
                        {description}
                    </StyledDescription>
                    <StyledFooter>
                        <StyledAppsWrapper>
                            <StyledAppIconsWrapper>
                                {appsJsx}
                            </StyledAppIconsWrapper>
                            <StyledAppsTitle>15 integrations available</StyledAppsTitle>
                        </StyledAppsWrapper>
                        <Button variant="secondary" onClick={() => undefined}>Connect apps</Button>
                    </StyledFooter>
                </StyledContent>
            </StyledWrapper>
        </>
    )
}
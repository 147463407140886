import { AppTypes, AppTypesKeys } from "@/types/AppTypes/AppTypes.type";
import { AppType } from "./App.type";
import { UserType } from "@/axios/SenseFacade/Users/Types/Get/Get.type";

type AppListType = {
    [Key in AppTypesKeys]: AppType
  }
export const getAppsCongigs = (user: UserType | null): AppListType => {
    return {
        Asana: { application: 'Asana', title: AppTypes.Asana, isConnected: !!user?.integrations?.asana.active },
        Github: { application: 'Github', title: AppTypes.Github, isConnected: !!user?.integrations?.github.active },
        ClickUp: { application: 'ClickUp', title: AppTypes.ClickUp, isConnected: !!user?.integrations?.trello.active },
        Trello: { application: 'Trello', title: AppTypes.Trello, isConnected: !!user?.integrations?.click_up.active },
        Confluence: { application: 'Confluence', title: AppTypes.Confluence, isConnected: !!user?.integrations?.atlassian.active },
        Jira: { application: 'Jira', title: AppTypes.Jira, isConnected: !!user?.integrations?.atlassian.active },
        Slack: { application: 'Slack', title: AppTypes.Slack, isConnected: !!user?.integrations?.slack.active },
        MicrosoftTeams: { application: 'MicrosoftTeams', title: AppTypes.MicrosoftTeams, isConnected: !!user?.integrations?.microsoft_teams.active },
        Notion: { application: 'Notion', title: AppTypes.Notion, isConnected: !!user?.integrations?.notion.active },
        GoogleDrive: { application: 'GoogleDrive', title: AppTypes.GoogleDrive, isConnected: !!user?.integrations?.google_drive.active },
        Figma: { application: 'Figma', title: AppTypes.Figma, isConnected: !!user?.integrations?.figma.active },
        TeamSpaces: { application: 'TeamSpaces', title: AppTypes.TeamSpaces, isConnected: !!user?.integrations?.microsoft_teams.active },
        Gmail: { application: 'Gmail', title: AppTypes.Gmail, isConnected: !!user?.integrations?.gmail.active },
        Sharepoint: { application: 'Sharepoint', title: AppTypes.Sharepoint, isConnected: !!user?.integrations?.microsoft_teams.active },
        Dropbox: { application: 'Dropbox', title: AppTypes.Dropbox, isConnected: !!user?.integrations?.dropbox_business?.active},
        Outlook: { application: 'Outlook', title: AppTypes.Outlook, isConnected: false },
        GoogleCalendar: { application: 'GoogleCalendar', title: AppTypes.GoogleCalendar, isConnected: !!user?.integrations?.google_calendar.active },
    }
};
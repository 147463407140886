import { FC, useState } from "react";
import { StyledContainer, StyledWrapper, StyledDescription, StyledButton } from "./Tooltip.styled";
import { StyledImage, StyledContent, StyledDescriptionWrapper } from "@/components/v3/Layouts/FirstExperienceLayout/FirstExperienceLayout.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { UpdateFetch } from "@/axios/OrganizationService/Users/Users.api";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";

export const Tooltip: FC = () => {
    const { user, loadUser } = useMe();
    const [isFething, setIsFething] = useState<boolean>(false);
    const gotItHandler = async () => {
        if (!user?.id) return;
        setIsFething(true);
        await UpdateFetch(user.id, { skipImportantTooltip: true });
        await loadUser();
        setIsFething(false);
    };
    return <>
        {!user?.skipImportantTooltip && <StyledWrapper>
            <LoaderLayout isLoading={isFething} transparent>
                <StyledContainer>
                    <StyledContent>
                        <StyledImage />
                        <StyledDescriptionWrapper>
                            <StyledDescription>
                                You can mark any resource as important for you using button on the resource card. You can also mark something as important for other users in your workspace.
                            </StyledDescription>
                        </StyledDescriptionWrapper>
                    </StyledContent>
                    <StyledButton size="large" variant="tertiary" onClick={gotItHandler}>Got it</StyledButton>
                </StyledContainer>
            </LoaderLayout>
        </StyledWrapper>}
    </>
};
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { FC, useMemo } from "react";
import { StyledSectionHeader, StyledWrapper } from "./RecentActivities.styled";
import { RecentActivitiesProps } from "./RecentActivities.type";
import moment from "moment";
import { ActivityGroup } from "@/components/v3/List/ActivityGroup/ActivityGroup.component";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { SpacesNodeWrapperType } from "@/providers/v3/SpacePage/SpacePage.type";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";

export const RecentActivities: FC<RecentActivitiesProps> = ({
    onUpdate
}) => {
  const { listOfSpaceUsers } = useSpacePageCommon();
  const { isFetchingListOfLastRecentActivities, listOfLastRecentActivities, setActiveTab } = useSpacePage();
  const groupedNodes = useMemo((): SpacesNodeWrapperType[][] => {
    const groupsMap = new Map();
    listOfLastRecentActivities.forEach(wrapper => {
      const lastActor = findLastActor(wrapper.relationships?.actors || []);
      const happenedAtDate = lastActor?.properties.happenedAt || wrapper.node.createdAt;
      const momentDate = moment(happenedAtDate).format('YYYY-MM-DD');
      if (!groupsMap.has(momentDate)) {
        groupsMap.set(momentDate, []);
      }
      groupsMap.get(momentDate).push({
        ...wrapper,
        lastActor
      });
    });
    return Array.from(groupsMap.values());
  }, [listOfLastRecentActivities]);
  
  const groupsJsx = groupedNodes.map((group, i) => {
    return <ActivityGroup
      key={i}
      date={group[0].node.createdAt}
      group={group.map((wrapper) => {
        return {
          node: wrapper.node,
          activities: wrapper.activities,
          relationships: wrapper.relationships,
          users: listOfSpaceUsers,
          onUpdate: onUpdate,
          importantUserIds: wrapper.importantUserIds,
          lastActor: wrapper.lastActor
        }
      })}
    />
  })
  return <StyledWrapper>
    <StyledSectionHeader title="Recent activities" >
      <Button onClick={() => setActiveTab('ACTIVITIES')} variant="tertiary">Show all</Button>
    </StyledSectionHeader>
        <LoaderLayout isLoading={isFetchingListOfLastRecentActivities} transparent={!!groupedNodes.length}>
            {groupsJsx}
        </LoaderLayout>
  </StyledWrapper>
};
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { SharingSpaceGeneratePostBodyParams } from './Types/SpaceGenerate/SpaceGenerate.type';
import { SharingSpaceRevokePostBodyParams } from './Types/SpaceRevoke/SpaceRevoke.type';
import { SharingSpaceGetPostQueryParams } from './Types/SpaceGet/SpaceGet.type';
import { SharingNodeGeneratePostBodyParams } from './Types/NodeGenerate/NodeGenerate.type';
import { SharingNodeRevokePostBodyParams } from './Types/NodeRevoke/NodeRevoke.type';
import { SharingNodeGetPostQueryParams } from './Types/NodeGet/NodeGet.type';

export const SharingSpaceGenerateFetch = (body: SharingSpaceGeneratePostBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/sharing/spaces/generate`, body);
}
export const SharingSpaceRevokeFetch = (body: SharingSpaceRevokePostBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/sharing/spaces/revoke`, body);
}
export const SharingSpaceGetFetch = (query: SharingSpaceGetPostQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/sharing/spaces`, {
        params: query
    });
}
export const SharingNodeGenerateFetch = (body: SharingNodeGeneratePostBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/sharing/nodes/generate`, body);
}
export const SharingNodeRevokeFetch = (body: SharingNodeRevokePostBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/sharing/nodes/revoke`, body);
}
export const SharingNodeGetFetch = (query: SharingNodeGetPostQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/sharing/nodes`, {
        params: query
    });
}
import { useState, useEffect, useMemo } from 'react';
import { AddLinksToSpaceProps } from './AddLinksToSpace.type';
import { StyledButton, StyledFilledCard, StyledInputWrapper, StyledSectionHeader } from './AddLinksToSpace.styled';
import { SetNodeRelationsFetch } from '@/axios/AiService/NodeRelations/NodeRelations.api';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { Input } from '@/components/v3/Fields/Input/Input.component';
import { AddNodeItem } from '../AddNodeItem/AddNodeItem.component';
import { NodeType } from '../AddNodeItem/AddNodeItem.type';
import { CreateNodesFetch } from '@/axios/AiService/Nodes/Nodes.api';
import { NodeTypeType } from '@/types/Node/Node.type';
import { NodesCreateResponseSuccessType } from '@/axios/AiService/Nodes/Types/Create/Create.type';
function isValidUrl(url: string): boolean {
  try {
      new URL(url);
      return true;
  } catch (e) {
      return false;
  }
}


export const AddLinksToSpace = ({ setIsFetchingLocal, listOfAddedNodes, onAdd, onRemove }: AddLinksToSpaceProps) => {
  const [link, setLink] = useState<string>('');
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [links, setLinks] = useState<NodeType[]>([]);
  const { user } = useMe();

  useEffect(() => {
    return () => {
      setLinks([]);
    }
  }, []);
  const error = useMemo(() => {
    const isUrlValid = isValidUrl(link);
    if (!isUrlValid && isSubmited) {
      return 'Please enter a valid URL';
    }
    return undefined;
  }, [isSubmited, link]);
  const createNodeHandler = async (url: string) => {
    setIsFetchingLocal(true);
    await CreateNodesFetch([{
      name: url,
      originalURI: url,
      nodeType: NodeTypeType.RESOURCE_NODE,
    }]).then((res: NodesCreateResponseSuccessType) => {
      for (let i = 0; i < res.data.length; i++) {
        const node = res.data[i];
        user?.id && SetNodeRelationsFetch([{
          nodeId: node.id,
          actors: [{
            userId: user.id,
            actionType: 'ADDED_BY',
            properties: {
              happenedAt: new Date().toISOString()
            }
          },]
        }], {
          action: 'CREATE',
        });
        onAdd(node);
        setLinks(links => links.concat(res.data));
      }
    }).catch((e) => {
      setIsFetchingLocal(false);
    })
  };
  const addHandler = async () => {
    const isUrlValid = isValidUrl(link);
    if (!isUrlValid) {
      setIsSubmited(true);
      return;
    }
    await createNodeHandler(link);
    setLink('');
    setIsSubmited(false);
  };
  const linksJsx = links.map(link => {
    return <AddNodeItem
    listOfAddedNodes={listOfAddedNodes}
      node={link}
      onAdd={onAdd}
      onRemove={onRemove}
      key={link.id}
    />
  });
  return (
    <>
      <StyledInputWrapper>
        <Input
          placeholder="Past link here"
          variant='outlined'
          size='medium'
          onChange={(e) => setLink(e.target.value)}
          value={link}
          error={error}
        />
        <StyledButton disabled={!link} onClick={addHandler} size="large">Add</StyledButton>
      </StyledInputWrapper>
      {!!links.length && <StyledFilledCard>
        <StyledSectionHeader title="Added links"  />
        {linksJsx}
      </StyledFilledCard>}
    </>
  );
};

import { FC } from "react";
import { EmptyListCardProps } from "./EmptyListCard.type";
import { StyledAddIconWrapper, StyledDescription, StyledIconWrapper, StyledText, StyledTitle, StyledWarapper } from "./EmptyListCard.styled";
import { Add2Icon } from "@/icons/v3/add2.icon";

export const EmptyListCard:FC<EmptyListCardProps> = ({
    title,
    children,
    variant = 'dashed',
    icon: iconProp,
    onClick
}) => {
    const icon = iconProp ? iconProp : <StyledAddIconWrapper><Add2Icon /></StyledAddIconWrapper>;

    return <StyledWarapper onClick={onClick} variant={variant}>
        <StyledIconWrapper>{icon}</StyledIconWrapper>
        <StyledText>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{children}</StyledDescription>
        </StyledText>
    </StyledWarapper>
};
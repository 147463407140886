import { IconProps } from "./icon.type"

export const InfoIcon = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0_11767_5678)">
                <path d="M8 16C3.63137 16 0 12.3686 0 8C0 3.62353 3.62353 0 7.99216 0C12.3686 0 16 3.62353 16 8C16 12.3686 12.3765 16 8 16ZM8 14.6667C11.702 14.6667 14.6745 11.702 14.6667 8C14.6588 4.29804 11.6941 1.33333 7.99216 1.33333C4.2902 1.33333 1.34118 4.29804 1.34118 8C1.34118 11.702 4.29804 14.6667 8 14.6667ZM7.92941 5.20784C7.36471 5.20784 6.9098 4.75294 6.9098 4.18824C6.9098 3.61569 7.36471 3.16078 7.92941 3.16078C8.50196 3.16078 8.94902 3.61569 8.94902 4.18824C8.94902 4.75294 8.50196 5.20784 7.92941 5.20784ZM6.62745 12.3137C6.30588 12.3137 6.0549 12.0863 6.0549 11.7647C6.0549 11.4588 6.30588 11.2157 6.62745 11.2157H7.6V7.61569H6.76078C6.43922 7.61569 6.18824 7.38824 6.18824 7.06667C6.18824 6.76078 6.43922 6.51765 6.76078 6.51765H8.23529C8.63529 6.51765 8.84706 6.8 8.84706 7.22353V11.2157H9.81961C10.1412 11.2157 10.3922 11.4588 10.3922 11.7647C10.3922 12.0863 10.1412 12.3137 9.81961 12.3137H6.62745Z" fill="#202020"/>
            </g>
            <defs>
                <clipPath id="clip0_11767_5678">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
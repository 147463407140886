import { FC } from "react";
import { ActivityMessageOrCommentProps } from "./ActivityMessageOrComment.type";
import { Comment } from "../Comment/Comment.component";
import { StyledWrapper } from "./ActivityMessageOrComment.styled";
import { ActivityMessage } from "../ActivityMessage/ActivityMessage.component";

export const ActivityMessageOrComment: FC<ActivityMessageOrCommentProps> = ({
    activityMessage,
    comment,
    users,
    useSkeleton = true,
    isExpired = false,
    className
}) => {
    return <StyledWrapper className={className}>
        {!!activityMessage && <ActivityMessage useSkeleton={useSkeleton} isExpired={isExpired} activityMessage={activityMessage} />}
        {!!comment && <Comment useSkeleton={useSkeleton} isExpired={isExpired} comment={comment} users={users} />}
    </StyledWrapper>
};
import { createContext, useContext, useEffect, useState, ReactNode, useCallback, useMemo } from "react";
import {
    NodeKeyResourceSuggestionWrapperType,
    NodeKeyResourceWrapperType,
    SpacePageCommonContextType,
} from "./SpacePageCommon.type";
import { GetChannelsFetch } from "@/axios/AiService/Channels/Channels.api";
import { SpaceChannelType, SpaceChannelsAxiosResponseSuccessType } from "@/axios/AiService/Channels/Types/Get/Get.type";
import { GetSpaceFetch, GetSpaceKeyResourcesSuggestionsFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { SpaceAxiosResponseSuccessType, SpaceType } from "@/axios/AiService/Spaces/Types/Get/Get.type";
import { useMe } from "providers/MeProvider/Me.provider";
import { GetSpaceApplicationsFetch } from "@/axios/AiService/SpaceApplications/SpaceApplications.api";
import { SpaceApplicationsAxiosResponseSuccessType } from "@/axios/AiService/SpaceApplications/Types/Get/Get.type";
import { UsersAxiosResponseSuccessType } from "@/axios/AiService/Users/Types/Get/Get.type";
import { GetUsersFetch } from "@/axios/AiService/Users/Users.api";
import { useRouter } from "@/providers/Router/Router.provider";
import { GetCommonFetch } from "@/axios/SenseFacade/Public/Public.api";
import { CommonAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetCommon/GetCommon.type";
import { GetSpaceKeyResourcesFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { GetKeyResourcesAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/GetKeyResources/GetKeyResources.type";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { GetKeyResourcesSuggestionsAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/GetKeyResourcesSuggestions/GetKeyResourcesSuggestions.type";
import { UserType } from "axios/AiService/Users/Types/Get/Get.type";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { SpaceApplicationType } from "axios/AiService/SpaceApplications/Types/Get/Get.type";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";

const defaultValue: SpacePageCommonContextType = {
    listOfChannels: [],
    listOfKeyResources: [],
    listOfKeyResourcesSuggestions: [],
    space: null,
    update: () => undefined,
    listOfSpaceApplications: [],
    listOfSpaceUsers: [],
    isFetchingKeyResources: true,
    isFetchingChannels: true,
    isFetchingListOfSpaceUsers: true,
};

const SpacePageCommonContext = createContext<SpacePageCommonContextType>(defaultValue);
export const useSpacePageCommon = () => useContext<SpacePageCommonContextType>(SpacePageCommonContext);

export const SpacePageCommonProvider = (props: { children: ReactNode }) => {
    const router = useRouter();
    const { isSharedSpace } = useIsShared();
    
    
    const [isFetchingKeyResources, setIsFetchingKeyResources] = useState<boolean>(true);
    const [isFetchingKeyResourcesSuggestions, setIsFetchingKeyResourcesSuggestions] = useState<boolean>(true);
    const [isFetchingChannels, setIsFetchingChannels] = useState<boolean>(true);
    const [isFetchingListOfSpaceUsers, setIsFetchingListOfSpaceUsers] = useState<boolean>(true);

    const [listOfSpaceApplications, setListOfSpaceApplications] = useState<SpaceApplicationType[]>([]);
    const [listOfSpaceUsers, setListOfSpaceUsers] = useState<UserType[]>([]);
    const { users, setUsers, setMetadata, getMetadata } = useMe();
    const spaceId = router.params.spaceId as string | undefined;
    const code = router.params.code as string | undefined;
    const [space, setSpace] = useState<SpaceType | null>(null);
    const [listOfChannels, setListOfChannels] = useState<SpaceChannelType[]>([]);
    const [listOfKeyResources, setListOfKeyResources] = useState<NodeKeyResourceWrapperType[]>([]);
    const [listOfKeyResourcesSuggestions, setListOfKeyResourcesSuggestions] = useState<NodeKeyResourceSuggestionWrapperType[]>([]);
    const loadSpace = useCallback(async () => {
        if (isSharedSpace) {
            if (!code) { return }
            setIsFetchingKeyResources(true);
            setIsFetchingKeyResourcesSuggestions(true);
            setIsFetchingChannels(true);
            setIsFetchingListOfSpaceUsers(true);
            await GetCommonFetch({
                code
            }).then((res: CommonAxiosResponseSuccessType) => {
                setSpace(res.data.space);
                setListOfChannels(res.data.channels);
                setListOfSpaceApplications(res.data.applications.map(application => ({ ...application, checked: false })));
                setListOfSpaceUsers(res.data.users.map(user => ({ ...user, checked: false })));
                const oldUsers = JSON.stringify(users);
                const newUsers = JSON.stringify(res.data.users);
                if (oldUsers !== newUsers) {
                    setUsers(res.data.users);
                }
                setListOfKeyResources(res.data.keyResources.map((node) => {
                    return {
                        node: node,
                        importantUserIds: []
                    }
                }));
            });
            setIsFetchingKeyResources(false);
            setIsFetchingKeyResourcesSuggestions(false);
            setIsFetchingChannels(false);
            setIsFetchingListOfSpaceUsers(false);
        } else {
            if (!spaceId) { return }
            await GetSpaceFetch(spaceId).then((res: SpaceAxiosResponseSuccessType) => {
                const spaceReadTime = getMetadata(`space-${spaceId}-read-time`);
                if (!spaceReadTime || (spaceReadTime && new Date().getTime() - new Date(spaceReadTime).getTime() > 1000 * 60 * 5)) {
                    setMetadata(`space-${spaceId}-read-time`, new Date().toISOString());
                }
                setSpace(res.data);
            });
        }
    }, [isSharedSpace, code, users, setUsers, spaceId, getMetadata, setMetadata])
    const loadChannels = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) { return }
            setIsFetchingChannels(true);
            await GetChannelsFetch({
                spaceId: spaceId
            }).then((res: SpaceChannelsAxiosResponseSuccessType) => {
                setListOfChannels(res.data);
            });
            setIsFetchingChannels(false);
        }
    }, [isSharedSpace, spaceId])
    const loadKeyResources = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) { return }
            setIsFetchingKeyResources(true)
            await GetSpaceKeyResourcesFetch(spaceId).then(async (res: GetKeyResourcesAxiosResponseSuccessType) => {
                const nodeIds = res.data.map(suggestion => suggestion.id);
                const importantUsers = await fetchImportantUsers(nodeIds);
                setListOfKeyResources(res.data.map((node) => {
                    const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                    return {
                        node: node,
                        importantUserIds: importantInfo.userIds
                    }
                }));
            });
            setIsFetchingKeyResources(false)
        }
    }, [isSharedSpace, spaceId])
    const loadKeyResourcesSuggestions = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) { return }
            setIsFetchingKeyResourcesSuggestions(true)
            await GetSpaceKeyResourcesSuggestionsFetch(spaceId).then(async (res: GetKeyResourcesSuggestionsAxiosResponseSuccessType) => {
                const nodeIds = res.data.map(suggestion => suggestion.id);
                const importantUsers = await fetchImportantUsers(nodeIds);
                setListOfKeyResourcesSuggestions(res.data.map((node) => {
                    const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                    return {
                        node: node,
                        importantUserIds: importantInfo.userIds
                    }
                }));
            });
            setIsFetchingKeyResourcesSuggestions(false)
        }
    }, [isSharedSpace, spaceId])

    
    const loadSpaceApplications = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) { return }
            await GetSpaceApplicationsFetch(spaceId).then((res: SpaceApplicationsAxiosResponseSuccessType) => {
                setListOfSpaceApplications(res.data.map(application => ({ ...application, checked: false })));
            });
        }
    }, [isSharedSpace, spaceId])
    const loadSpaceUsers = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) { return }
            setIsFetchingListOfSpaceUsers(true);
            await GetUsersFetch({
                spaceId: spaceId
            }).then((res: UsersAxiosResponseSuccessType) => {
                setListOfSpaceUsers(res.data.map(user => ({ ...user, checked: false })));
            });
            setIsFetchingListOfSpaceUsers(false);
        }
    }, [isSharedSpace, spaceId])
    const updateHandler = useCallback(async () => {
        loadSpace();
        loadChannels();
        loadKeyResources();
        loadKeyResourcesSuggestions();
    }, [loadSpace, loadChannels, loadKeyResources, loadKeyResourcesSuggestions]);
    useEffect(() => {
        loadSpace();
    }, [loadSpace]);
    useEffect(() => {
        loadChannels();
    }, [loadChannels]);
    useEffect(() => {
        loadKeyResources();
    }, [loadKeyResources]);
    useEffect(() => {
        loadKeyResourcesSuggestions();
    }, [loadKeyResourcesSuggestions]);
    useEffect(() => {
        loadSpaceApplications();
    }, [loadSpaceApplications]);
    useEffect(() => {
        loadSpaceUsers();
    }, [loadSpaceUsers]);
    const trackEvent = useAmplitude();
    useEffect(() => {
        if (router.name === 'publicSpace') {
            trackEvent(EventTypes.SHARED_SPACE_VISITED)
        }
    }, [router.name, trackEvent]);

    const contextValue: SpacePageCommonContextType = {
        listOfChannels,
        listOfKeyResources,
        listOfKeyResourcesSuggestions,
        space,
        update: updateHandler,
        listOfSpaceApplications,
        listOfSpaceUsers,
        isFetchingKeyResources: isFetchingKeyResources || isFetchingKeyResourcesSuggestions,
        isFetchingChannels,
        isFetchingListOfSpaceUsers,
    };

    return <SpacePageCommonContext.Provider value={contextValue}>
        {props.children}
    </SpacePageCommonContext.Provider>;
};

import {
  RoutePathType,
  ParamNameType,
  RouteParamType,
} from "../Router.type";
import { addQueryParam } from "./addQueryParam.util";
export const replaceParam = (path: RoutePathType, paramName: ParamNameType, value: RouteParamType): RoutePathType => {
    const val = typeof value==='string' ? value : value?.toString();
    const index = path.indexOf(`[${paramName}]`);
    if (val && index > -1) {
        return path.replace(`[${paramName}]`, val)
    }
    return addQueryParam(path, paramName, value);
}
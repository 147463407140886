import { FC, useMemo } from "react";
import { ChannelProps } from "./Channel.type";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";
import { StyledContainer, StyledImg, StyledWrapper } from "./Channel.styled";

export const Channel: FC<ChannelProps> = ({ channel, className }) => {
    const image = useMemo(() => {
        return getIconPath(channel.application, false);
    }, [channel]);
    return <StyledWrapper className={className}>
        <StyledContainer>
            <StyledImg avatar={image} size="large" />
            {channel.name}
        </StyledContainer>
        {/* <Button variant="tertiary" icon={<OpenLinkIcon />} onClick={() => undefined} /> */}
    </StyledWrapper>
};
import { FC, useCallback, useEffect, useState } from "react";
import { EditSpaceModalProps } from "./EditSpaceModal.type";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { EditSpaceForm } from "@/components/v3/Common/EditSpaceForm/EditSpaceForm.component";
import { ErrorsType } from "@/components/v3/Common/EditSpaceForm/EditSpaceForm.type";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledCancelButton, StyledDialogLayout } from "./EditSpaceModal.styled";
import { SpaceTypeType } from "@/components/v3/Common/EditSpaceForm/components/SpaceType/SpaceType.type";
import { SpaceTagType } from "@/components/v3/Common/EditSpaceForm/components/Tags/components/Tag/Tag.type";
import { UpdateSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { ToastService } from "@/service/ToastService";
import { CreateSpaceTagsFetch, DeleteSpaceTagsFetch, GetSpaceTagsFetch, UpdateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { SpaceTagsAxiosResponseSuccessType } from "@/axios/AiService/SpaceTags/Types/Get/Get.type";
import { groupTags } from "./EditSpaceModal.utils";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { defaultEmoji } from "@/const/emoji.const";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

export const EditSpaceModal: FC<EditSpaceModalProps> = ({
    space,
    onUpdate: onUpdateProp
}) => {
    const [errors, setErrors] = useState<ErrorsType>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFetchingSpaceTags, setIsFetchingSpaceTags] = useState<boolean>(true);
    const [name, setName] = useState<string>(space.name);
    const [emoji, setEmoji] = useState<string | null>(space.emoji || defaultEmoji);
    const [spaceType, setSpaceType] = useState<SpaceTypeType>(space.private ? 'Private' : 'Public');
    const [originalTags, setOriginalTags] = useState<SpaceTagType[]>([]);
    const [tags, setTags] = useState<SpaceTagType[]>([]);
    const [users, setUsers] = useState<string[]>(space.userIds);
    const [channels, setChannels] = useState<string[]>(space.channelIds);
    const { value, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'edit-space' });
    const { isShared } = useIsShared();
    const onChange = (value: boolean) => {
        if (value) {
            onChangeIsOpened(value);
        } else {
            onChangeIsOpened(value, {
                spaceApp: undefined,
                scroll: undefined
            });
        }
    };
    useEffect(() => {
        setName(space.name);
        setEmoji(space.emoji || defaultEmoji);
        setSpaceType(space.private ? 'Private' : 'Public');
        setUsers(space.userIds);
        setChannels(space.channelIds);
        setErrors({});
    }, [space]);

    const { loadSpaces } = useSpaces();
    const onUpdate = () => {
        loadSpaceTags();
        loadSpaces();
        onUpdateProp();
    }
    const loadSpaceTags = useCallback(async () => {
        if (isShared) {
            return;
        }
        setIsFetchingSpaceTags(true)
        await GetSpaceTagsFetch(space.id).then((res: SpaceTagsAxiosResponseSuccessType) => {
            setTags(res.data);
            setOriginalTags(res.data);
        });
        setIsFetchingSpaceTags(false)
    }, [isShared, space.id]);
    useEffect(() => {
        loadSpaceTags();
    }, [loadSpaceTags]);
    const deleteTag = async (spaceId: string, tag: SpaceTagType) => {
        await DeleteSpaceTagsFetch(spaceId, tag.id as string).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
    }
    const deleteTags = async (spaceId: string, tagsNeedDelete: SpaceTagType[]) => {
        for (let i = 0; i < tagsNeedDelete.length; i++) {
            const tag = tagsNeedDelete[i];
            await deleteTag(spaceId, tag);
        }
    }
    const updateTag = async (spaceId: string, tag: SpaceTagType) => {
        await UpdateSpaceTagsFetch(spaceId, tag.id as string, {
            ...tag,
            emoji: tag.emoji || null,
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred');
        });
    }
    const updateTags = async (spaceId: string, tagNeedUpdate: SpaceTagType[]) => {
        for (let i = 0; i < tagNeedUpdate.length; i++) {
            const tag = tagNeedUpdate[i];
            await updateTag(spaceId, tag);
        }
    }
    const createTag = async (spaceId: string, tag: SpaceTagType) => {
        await CreateSpaceTagsFetch(spaceId, {
            name: tag.name,
            emoji: tag.emoji
        }).catch((err) => {
            ToastService.showToast('error', 'Some error occurred');
        });
    }
    const createTags = async (spaceId: string, tagsNeedCreate: SpaceTagType[]) => {
        for (let i = 0; i < tagsNeedCreate.length; i++) {
            const tag = tagsNeedCreate[i];
            await createTag(spaceId, tag);
        }
    };
    const validate = (name: string) => {
        let tempErrors: ErrorsType = {};
        if (!name) tempErrors.name = "Name is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    const saveHandler = async () => {
        if (validate(name)) {
            setIsLoading(true);
            await UpdateSpaceFetch(space.id, {
                name: name,
                userIds: users,
                channelIds: channels,
                emoji: emoji,
            }).then(() => {
                ToastService.showToast('success', 'Space has been changed!');
            }).catch((err) => {
                ToastService.showToast('error', 'Some error occurred');
            });
            const { needCreate, needUpdate, needDelete } = groupTags(originalTags, tags);
            await createTags(space.id, needCreate);
            await updateTags(space.id, needUpdate);
            await deleteTags(space.id, needDelete);
            ToastService.showToast('success', 'Space tags has been updated!');
            onUpdate();
            onChange(false);
            setIsLoading(false);
        }
    }
  const isNativeSpace = space.application==='Sense';
    return <Dialog value={value} onChange={onChange} disableEscapeKeyDown>
        <StyledDialogLayout
            title="Edit space"
            actions={<>
                <StyledCancelButton size="large" variant="error" onClick={() => onChange(false)}>
                    Cancel
                </StyledCancelButton>
                <Button size="large" onClick={saveHandler}>
                    Save
                </Button>
            </>}
            isLoading={isLoading}
        >
            <EditSpaceForm
                isFetchingSpaceTags={isFetchingSpaceTags}
                name={name} setName={setName}
                emoji={emoji} setEmoji={setEmoji}
                spaceType={spaceType} setSpaceType={setSpaceType}
                tags={tags} setTags={setTags}
                users={users} setUsers={setUsers}
                channels={channels} setChannels={setChannels}
                errors={errors}
                setErrors={setErrors}
                isNativeSpace={isNativeSpace}
            />
        </StyledDialogLayout>
    </Dialog>
};
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    margin-top: 16px;
    padding: 0 32px 0 32px;
    @media (max-width: 768px) {
        padding: 0 16px 0 16px;
    }
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
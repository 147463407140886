import { FC, useMemo, useState } from "react";
import { CommonRelationsProps } from "./CommonRelations.type";
import { StyledFiltersContainer, StyledFiltersWrapper, StyledSelect, StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { getAppOptionsFromNodeWrappers } from "@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { groupNodeWrappersByCategory } from "@/utils/v3/GroupNodeByCategory/GroupNodeByCategory.util";

export const CommonRelations: FC<CommonRelationsProps> = ({
    isFetchingCommonNodes,
    commonNodes,
    onUpdate
}) => {
    const { users } = useMe();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const appsOptions = useMemo((): OptionType<string>[] => {
        return getAppOptionsFromNodeWrappers(commonNodes);
    }, [commonNodes]);
    const allGroups = useMemo(() => {
        return groupNodeWrappersByCategory(commonNodes);
    }, [commonNodes]);
    const filteredListOfNodes = useMemo(() => {
        return commonNodes.filter((wrapper) => {
            const selectedAppsLabels = appsOptions.filter((option) => selectedApps.includes(option.value)).map((option) => option.label);
            if (selectedApps.length && !selectedAppsLabels.includes(wrapper.node.application)) {
                return false;
            }
            return true;
        });
    }, [appsOptions, commonNodes, selectedApps]);
    const groups = useMemo(() => {
        return groupNodeWrappersByCategory(filteredListOfNodes);
    }, [filteredListOfNodes]);
    const filteredGroups = useMemo(() => {
        if (!selectedCategories.length) {
            return groups;
        }
        return groups.filter((group) => {
            return selectedCategories.includes(group.category);
        });
    }, [groups, selectedCategories]);
    const listOfCategoriesJsx = filteredGroups.map((group, i) => {
        return <CategoryGroup useShowAll title={group.category} nodes={group.wrappers.map((wrapper) => {
            return {
                node: wrapper.node,
                users: users,
                onUpdate: onUpdate,
                importantUserIds: wrapper.importantUserIds
            }
        })} key={i} />
    });
    return <StyledWrapper>
        <LoaderLayout isLoading={isFetchingCommonNodes} transparent={!!listOfCategoriesJsx.length}>
            <StyledFiltersWrapper>
                <StyledFiltersContainer>
                    <StyledSelect
                        label="Categories"
                        value={selectedCategories}
                        onChange={(val: any) => setSelectedCategories(val)}
                        options={allGroups.map((group) => {
                            return { label: group.category, value: group.category}
                        })}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                    <StyledSelect
                        label="Apps"
                        value={selectedApps}
                        onChange={(val: any) => setSelectedApps(val)}
                        options={appsOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                </StyledFiltersContainer>
            </StyledFiltersWrapper>
            {listOfCategoriesJsx}
        </LoaderLayout>
    </StyledWrapper>
};
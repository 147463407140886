import { FC } from "react";
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { AddInSpaceProvider } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { Header } from "./components/Header/Header.component";
import { Content } from "./components/Content/Content.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Sidebar } from "../Space/components/Sidebar/Sidebar.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { EditSpaceModal } from "../Space/components/EditSpaceModal/EditSpaceModal.component";
import { useSpaceCategoryPage } from "@/providers/v3/SpaceCategoryPage/SpaceCategoryPage.provider";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";

export const SpaceCategoryContent: FC = () => {
    const {
        space,
    } = useSpacePageCommon();
    const {
        update,
    } = useSpaceCategoryPage();
    const shoudRenederMainSidebar = useRenederAbove('medium');
    return <AddInSpaceProvider onUpdate={update}>
        <MainLayout sidebar={shoudRenederMainSidebar ? <Sidebar onUpdate={update} /> : undefined}>
            <Header/>
            <Content/>
        </MainLayout>
        {!!space &&  <EditSpaceModal space={space} onUpdate={update} />}
        <CreateNoteDrawer onUpdate={update} />
    </AddInSpaceProvider>
};
import { FilledCard } from '@/components/v3/Other/FilledCard/FilledCard.component';
import { SectionHeader } from '@/components/v3/Sections/SectionHeader/SectionHeader.component';
import { Box } from '@mui/material';
import { styled } from "@mui/material";

export const StyledOnboardingContent = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
`;
export const StyledOnboardingStep = styled(Box)`
    display: flex;
    flex-direction: column;
    max-width: 720px;
    width: 100%;
`;
export const StyledRelationships = styled(Box)`
    padding: 0;
`;
export const StyledListBlock = styled(Box)`
    margin-top: 16px;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
export const StyledFilledCard = styled(FilledCard)`
    margin-bottom: 24px;
`;
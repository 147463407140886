import { FC } from "react";
import { StyledNodesWrapper, StyledScrollWrapper, StyledSectionHeader, StyledWrapper } from "./RecentSpaces.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { compareCreatedAt } from "@/helpers/helpers";
import { SpaceCard } from "@/components/v3/List/SpaceCard/SpaceCard.component";
import { useRouter } from "@/providers/Router/Router.provider";

export const RecentSpaces: FC = () => {
    const router = useRouter();
    const isLargeScreen = useRenederAbove('large');
    const { spaces, isFetching } = useSpaces();
    const sortedSenseSpaces = spaces.sort(compareCreatedAt);
    const countOfRecentSpaces = isLargeScreen ? 4 : 3;
    const recentlyCreatedSpaces = sortedSenseSpaces.slice(0, countOfRecentSpaces);
    
    const spacesJsx = recentlyCreatedSpaces.map((space, i) => {
        return <SpaceCard key={i} space={space} />
    });
    return <StyledWrapper>
        <StyledSectionHeader title="Recent spaces" >
            <Button onClick={() => {
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'create-space'
                    }
                });
            }} variant="tertiary">Create space</Button>
        </StyledSectionHeader>
        <LoaderLayout isLoading={isFetching} transparent={!!spaces.length}>
            <StyledScrollWrapper>
                <StyledNodesWrapper countOfRecentSpaces={countOfRecentSpaces}>
                    {spacesJsx}
                </StyledNodesWrapper>
            </StyledScrollWrapper>
        </LoaderLayout>
    </StyledWrapper>
};
import { Box } from '@mui/material';
import { styled } from "@mui/material";
import { Input } from '../../Fields/Input/Input.component';
import { Button } from '../../Fields/Button/Button.component';

export const StyledNodeInfo = styled('div')`
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    margin-bottom: 24px;
    & img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        margin-top: 4px;
    }
`
export const StyledNodeTitle = styled(Box)`
  color: #202020;
  font-weight: 500;
  padding-right: 16px;
  line-height: 20px;
  & p {
    color: #636363;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
`
export const StyledName = styled('h1')<{
  long: boolean;
}>`
  ${props => props.long && `
    display: inline-block;
    word-break: break-word;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  `}
`
export const StyledDescription = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const StyledLinkWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
`
export const StyledActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
export const StyledInput = styled(Input)`
    width: 100%;
    margin-bottom: 0;
`
export const StyledCreateButton = styled(Button)`
    margin-left: 16px;
`
import { Drawer, IconButton } from '@mui/material';
import { useState } from 'react';
import { MenuRounded } from '@mui/icons-material';
import { SideBarMenu } from '../components/SideBarMenu/SideBarMenu.component';

export const SideDrawerMainLayout = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <SideBarMenu handleDrawerClosing={() => setOpen(false)} />
      </Drawer>
      <IconButton onClick={() => setOpen(!open)}>
        <MenuRounded />
      </IconButton>
    </>
  );
};

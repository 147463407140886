export const getLinkName = (url: string): string => {
    if (!url.includes('http://') && !url.includes('https://')) {
        return url;
    }
    let parsedUrl;
    try {
        parsedUrl = new URL(url);
    } catch (e) {
        return url;
    }

    const { hostname } = parsedUrl;

    const parts = hostname.split('.');
    const domainName = parts.length > 1 ? parts[parts.length - 2] : hostname;

    const capitalizedDomainName = domainName.charAt(0).toUpperCase() + domainName.slice(1);

    return `Untitled ${capitalizedDomainName}`;
}
import { FC } from "react";
import { OnboardingLayoutProps } from "./OnboardingLayout.type";
import { StyledContainer, StyledTitle, StyledWrapper } from "./OnboardingLayout.styled";

export const OnboardingLayout: FC<OnboardingLayoutProps> = ({ title, children, className }) => {
    return (
        <StyledWrapper className={className}>
            <StyledContainer>
                <StyledTitle>{title}</StyledTitle>
                {children}
            </StyledContainer>
        </StyledWrapper>
    );
};
import { useState, useEffect, useMemo } from 'react';
import { HttpClient } from "@/axios/HttpClient";
import { ToastService } from 'service/ToastService';
import { Typography } from '@mui/material';
import { AddFromSenseProps } from "./AddFromSense.type";
import { envUrl } from '@/service/helpers';
import { Input } from '@/components/v3/Fields/Input/Input.component';
import { StyledSearchIcon, StyledSearchIconWrapper } from '@/components/v3/Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled';
import { StyledFilledCard, StyledSectionHeader } from './AddFromSense.styled';
import { AddNodeItem } from '../AddNodeItem/AddNodeItem.component';
import { useDebounce } from '@/hooks/UseDebounce/UseDebounce.hook';
import { EmptyStateLayoyt } from '@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component';
import { OptionType } from '@/components/v3/Fields/Select/components/Option/Option.type';
import { getAppOptionsFromNodes } from '@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util';
import { Select } from '@/components/v3/Fields/Select/Select.component';
import { NodeType } from '../AddNodeItem/AddNodeItem.type';
import { FilterIcon } from '@/icons/v3/filter.icon';
import { StyledCloseIcon } from '@/components/v3/Layouts/MainLayout/components/Header/components/Search/Search.styled';
import { GetNodeRecentActionsFetch } from '@/axios/AiService/NodeRecentActions/NodeRecentActions.api';
import { NodeRecentActionsAxiosResponseSuccessType } from '@/axios/AiService/NodeRecentActions/Types/Get/Get.type';

export const AddFromSense = ({
  setIsFetchingLocal,
  listOfAddedNodes,
  onAdd,
  onRemove
}: AddFromSenseProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [listOfNodes, setListOfNodes] = useState<NodeType[]>([]);
  const appsOptions = useMemo((): OptionType<string>[] => {
    return getAppOptionsFromNodes(listOfNodes);
}, [listOfNodes]);
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const filteredListOfNodes = useMemo(() => {
    return listOfNodes.filter((node) => {
        if (selectedApps.length && !selectedApps.includes(node.application)) {
            return false;
        }
        return true;
    });
}, [listOfNodes, selectedApps]);
  const [foundCount, setFoundCount] = useState<number>(0);

  const loadRecentActivitiesNodes = async () => {
    setIsFetchingLocal(true);
    await GetNodeRecentActionsFetch({
      limit: 30,
    }).then((res: NodeRecentActionsAxiosResponseSuccessType) => {
      setSelectedApps([]);
      setListOfNodes(res.data);
      setFoundCount(0);
    })
    setIsFetchingLocal(false);
  };

  useEffect(() => {
    loadRecentActivitiesNodes();
  }, []);

  const handler = useDebounce((query: string) => {
    setIsFetchingLocal(true);
    HttpClient.get(`${envUrl}/ai-service/v1/search`, {
      params: { query: query, filtered: true },
    })
      .then(response => response.data)
      .then(data => {
        setListOfNodes(data.nodes);
        setFoundCount(data.nodes.length);
      })
      .catch(error => {
        ToastService.showToast('error', 'Some error occurred! Please try again later');
      })
    setIsFetchingLocal(false);
  })
  useEffect(() => {
    if (!!searchValue) {
      handler(false, searchValue);
    } else {
      loadRecentActivitiesNodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const filteredListOfNodesJsx = filteredListOfNodes.map(node => {
    return (
      <AddNodeItem
        listOfAddedNodes={listOfAddedNodes}
        node={node}
        onAdd={onAdd}
        onRemove={onRemove}
        key={node.id}
      />
    )
  });
  return (
    <>
      <Input
        placeholder="Search in Sense"
        variant="filled"
        size='medium'
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <StyledSearchIconWrapper>
              <StyledSearchIcon />
            </StyledSearchIconWrapper>
          ),
          endAdornment: <>
            {!!searchValue && <StyledCloseIcon onClick={() => setSearchValue('')} />}
          </>,
        }}
      />
      <StyledFilledCard>
        <EmptyStateLayoyt
          value={!listOfNodes.length}
          emptyNode={<Typography variant="body1" style={{ padding: '0 24px' }}>
            No nodes found
          </Typography>}
        >
          <StyledSectionHeader title={foundCount ? `${foundCount} found` : "Recent"} >
            {!!foundCount && appsOptions.length && <Select
              label="Apps"
              value={selectedApps}
              onChange={setSelectedApps}
              options={appsOptions}
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              icon={<FilterIcon />}
            />}
          </StyledSectionHeader>
          {filteredListOfNodesJsx}
        </EmptyStateLayoyt>
      </StyledFilledCard>
    </>
  );
};

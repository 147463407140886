import { GradientLayout } from "@/components/v3/Layouts/GradientLayout/GradientLayout.component";
import { FC } from "react";
import { MeetingsTimeline } from "../MeetingsTimeline/MeetingsTimeline.component";
import { meetingsTimelineMock } from "./EmptyState.mock";
import { StyledAppsSectionWrapper, StyledAppsWrapper, StyledMeetingsTimlineWrapper } from "./EmptyState.styled";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { App } from "@/components/v3/List/App/App.component";
import { getAppsCongigs } from "@/components/v3/List/App/App.const";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const EmptyState: FC = () => {
    const { user } = useMe();
    const appsPages = getAppsCongigs(user);
    const listOfApps = [appsPages.GoogleCalendar, appsPages.Outlook]
    const listOfAppsJsx = listOfApps.map((app, i) => {
        return <App key={app.title} app={app} buttonProps={{variant: 'secondary'}} />
    });
    return <>
        <GradientLayout>
            <StyledMeetingsTimlineWrapper>
                <MeetingsTimeline meetings={meetingsTimelineMock} useSkeleton={false} />
            </StyledMeetingsTimlineWrapper>
        </GradientLayout>
        <StyledAppsSectionWrapper>
            <StyledAppsWrapper>
                <SectionHeader title="Connect calendars" caption="You can sync multiple calendars" />
                {listOfAppsJsx}
            </StyledAppsWrapper>
        </StyledAppsSectionWrapper>
    </>
};
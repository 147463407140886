
export type BreakPointsTypeType = 'mobile320' | 'mobile' | 'mobileRotate' | 'tablet' | 'small' | 'medium' | "large";

export type BreakPointsType = {
    [key in BreakPointsTypeType]: number;
}
export const BREAKPOINTS: BreakPointsType = {
    mobile320: 100,
    mobile: 320,
    mobileRotate: 568,
    tablet: 768,
    small: 1024,
    medium: 1232,
    large: 1680,
};

import moment from "moment";

export const formatGroupDate = (date: string | Date) => {
    const today = moment();
    const selected = moment.utc(date);
    
    if (selected.isSame(today, 'day')) {
        return "Today";
    } else if (selected.isSame(today.clone().add(1, 'day'), 'day')) {
        return "Tomorrow";
    } else if (selected.isSame(today.clone().subtract(1, 'day'), 'day')) {
        return "Yesterday";
    } else {
        return selected.format("MMMM D, YYYY");
    }
};
import { FC } from "react";
import { StyledConnectApps, StyledGradientWrapper, StyledWrapper } from "./EmptyState.styled";
import { GradientLayout } from "@/components/v3/Layouts/GradientLayout/GradientLayout.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { listOfActivities, users } from "./EmptyState.const";
import { Activity } from "@/components/v3/List/Activity/Activity.component";

export const EmptyState: FC = () => {
    const listOfActivitiesJsx = listOfActivities.map((el) => {
        return <Activity
            key={el.node.id}
            node={el.node}
            activities={el.activities}
            onUpdate={() => {}}
            users={users}
            relationships={el.relationships}
            lastActor={el.lastActor}
            importantUserIds={[]}
            useSkeleton={false}
        />;
    });
    return <StyledWrapper>
        <StyledGradientWrapper>
            <GradientLayout>
                <FilledCard>
                    {listOfActivitiesJsx}
                </FilledCard>
            </GradientLayout>
        </StyledGradientWrapper>
        <StyledConnectApps
            title="Get latest activities for all resources"
            description="Connect apps to automatically get resources’ activities, such as comments, discussions, editing, sharing, mentions, etc. "
            applications={['Slack', 'Asana', 'Notion', 'GoogleDrive']}
        />
    </StyledWrapper>
};
import { FC, createContext, useContext, useState, useEffect } from "react";
import { AddToSpaceProviderProps, AddToSpaceContextType } from "./AddToSpace.type";
import { SpaceType } from "components/Nodes/NodeService";
import { AddToSpaceModal } from "./components/AddToSpaceModal/AddToSpaceModal.component";

const defaultConfig: AddToSpaceContextType = {
    spaces: [],
    setSpaces: () => undefined,
    addToSpace: () => undefined,
};

export const AddToSpaceContext = createContext<AddToSpaceContextType>(defaultConfig);
export const useAddToSpace = () => useContext<AddToSpaceContextType>(AddToSpaceContext);

export const AddToSpaceProvider: FC<AddToSpaceProviderProps> = (
  { children }: AddToSpaceProviderProps
) => {
    const [spaces, setSpaces] = useState<SpaceType[]>([]); 
    const [nodeId, setNodeId] = useState<number | null>(null);
    const addToSpaceHandler = (nodeId: number) => {
        setNodeId(nodeId);
    };
    const config: AddToSpaceContextType = {
        spaces,
        setSpaces,
        addToSpace: addToSpaceHandler,
    }
    useEffect(() => {
        setTimeout(() => {
            const isVisible = !!nodeId;
            if (isVisible) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'visible';
            }
        }, 300);
    }, [nodeId]);
    return (
        <>
            <AddToSpaceContext.Provider value={config}>
                {children}
                <AddToSpaceModal nodeId={nodeId} onClose={() => setNodeId(null)} />
            </AddToSpaceContext.Provider>
        </>
    );
};
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";

export const APP_OPTIONS: OptionType<string>[] = [
    {
        label: 'Team Spaces',
        value: 'Sense'
    },
    {
        label: 'Confluence',
        value: 'Confluence'
    },
    {
        label: 'Jira',
        value: 'Jira'
    },
    {
        label: 'Slack',
        value: 'Slack'
    },
    {
        label: 'Microsoft Teams',
        value: 'MicrosoftTeams'
    },
    {
        label: 'Notion',
        value: 'Notion'
    },
]
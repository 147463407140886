import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastService } from 'service/ToastService';
import { ConnectionNotificationDialog } from '@/components/ConnectionNotificatiionDialog/ConnectionNotificatiionDialog.component';
import { HttpClient } from "@/axios/HttpClient";
import { Integration } from "./components/Integration/Integration.component";
import { useMe } from 'providers/MeProvider/Me.provider';
import { ManageIntegrationsContentProps } from './ManageIntegrationsContent.type';
import { generateAppNameFromType } from './ManageIntegrationsContent.utils';
import { STAGE_PARAM_ERROR_VALUE, STAGE_PARAM_NAME } from './ManageIntegrationsContent.const';
import { CommingSoon } from './components/CommingSoon/CommingSoon.conponent';
import { StyledFilledCard, StyledListBlock, StyledOnboardingContent, StyledOnboardingStep, StyledRelationships, StyledSectionHeader } from './ManageIntegrationsContent.styled';
import { useChannels } from '@/providers/v3/Channels/Channels.provider';
import { FilledCard } from '@/components/v3/Other/FilledCard/FilledCard.component';
import { DeleteData } from './components/DeleteData/DeleteData.component';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';
import { envUrl } from '@/service/helpers';

export const ManageIntegrationsContent: FC<ManageIntegrationsContentProps> = ({
  isLoading,
  setIsLoading
}) => {
  const search = useLocation().search;
  const stage = new URLSearchParams(search).get(STAGE_PARAM_NAME);
  const { user, loadUser } = useMe();
  const servicesIntegrationStatus = useMemo(() => {
    return {
      isSlackActive: !!user?.integrations?.slack.active,
      isGoogleDriveActive: !!user?.integrations?.google_drive.active,
      googleDriveAccounts: user?.integrations?.google_drive.accounts,
      isGmailActive: !!user?.integrations?.gmail.active,
      gmailAccounts: user?.integrations?.gmail.accounts,
      isAtlassianActive: !!user?.integrations?.atlassian.active,
      isAsanaActive: !!user?.integrations?.asana.active,
      isGitHubActive: !!user?.integrations?.github.active,
      isClickUpActive: !!user?.integrations?.click_up.active,
      isTrelloActive: !!user?.integrations?.trello.active,
      isFigmaActive: !!user?.integrations?.figma.active,
      isDropboxActive: !!user?.integrations?.dropbox_business.active,
      isTeamsActive: !!user?.integrations?.microsoft_teams.active,
      isAzureMailActive: !!user?.integrations?.outlook.active,
      outlookAccounts: user?.integrations?.outlook.accounts,
      isNotionActive: !!user?.integrations?.notion.active,
      isGoogleCalendarActive: !!user?.integrations?.google_calendar.active,
      googleCalendarAccounts: user?.integrations?.google_calendar.accounts,
      isAtLeastOneServiceConnected:
        user?.integrations?.slack.active ||
        user?.integrations?.google_drive.active ||
        user?.integrations?.gmail.active ||
        user?.integrations?.atlassian.active ||
        user?.integrations?.asana.active ||
        user?.integrations?.github.active ||
        user?.integrations?.click_up.active ||
        user?.integrations?.trello.active ||
        user?.integrations?.figma.active ||
        user?.integrations?.dropbox_business.active ||
        user?.integrations?.microsoft_teams.active ||
        user?.integrations?.outlook.active ||
        user?.integrations?.notion.active ||
        user?.integrations?.google_calendar.active
    }
  }, [user?.integrations]);

  useEffect(() => {
    if (stage === STAGE_PARAM_ERROR_VALUE) {
      ToastService.showToast(
        'error',
        'Some error occurred while connecting the service, please try again or contact us if error occurred more than once'
      );
    }
  }, [stage]);
  const { update: updateChannels } = useChannels();
  const { component, confirm } = useConfirm({
    title: 'Disconnect integration',
    content: <>
      <StyledSectionHeader title="Are you sure you want to disconnect this integration?" />
      <div>
        Synchronisation of all the resources and updates from this app will be stopped for all users. The data that was already synchronised will remain. You can delete all integrations data using the block at the bottom of the “Manage integrations” modal. 
      </div>
    </>,
    submitButtonProps: {
      children: 'Disconnect',
      variant: 'error'
    },
    cancelButtonProps: {
      children: 'Cancel',
      variant: 'primary'
    }
  });
  const disconnectHandler = (type: string | undefined, email?: string) => {
    if (!user?.isAdmin) {
      return;
    }
    confirm(async (
      result
    ) => {
      if (result) {
        let url = `${envUrl}/sense-facade/v1/integrations?userId=${user?.id}&type=${type}`;
        if (email) {
          url += `&email=${email}`;
        }
        setIsLoading(true);
        await HttpClient.delete(url)
          .then(response => response.data)
          .then(data => {
            HttpClient.get(`${envUrl}/sense-facade/v1/users/${user?.id}/info`)
              .then(response => response.data)
              .then(async data => {
                updateChannels();
                await loadUser();
                setIsLoading(false);
              })
            ToastService.showToast('success', `${generateAppNameFromType(type)} was disconnected`);
          })
          .catch(error => {
            ToastService.showToast('error', 'Some error occurred! Please try again later!');
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <>
      <StyledOnboardingContent>
        <StyledOnboardingStep>
          <StyledRelationships>
            {servicesIntegrationStatus.isAtLeastOneServiceConnected && <StyledFilledCard>
              <StyledSectionHeader title="Connected" />
              <StyledListBlock>
                {servicesIntegrationStatus.isAsanaActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isAsanaActive}
                    icon="/icons/icon-asana.png"
                    appName='Asana'
                    disconnectHandler={disconnectHandler}
                    type='asana'
                  />
                )}
                {servicesIntegrationStatus.isGitHubActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isGitHubActive}
                    icon="/icons/icon-github.png"
                    appName='GitHub'
                    disconnectHandler={disconnectHandler}
                    type='github'
                  />
                )}
                {servicesIntegrationStatus.isClickUpActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isClickUpActive}
                    icon="/icons/icon-click_up.png"
                    appName='ClickUp'
                    disconnectHandler={disconnectHandler}
                    type='click_up'
                  />
                )}
                {servicesIntegrationStatus.isTrelloActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isTrelloActive}
                    icon="/icons/icon-trello.png"
                    appName='Trello'
                    disconnectHandler={disconnectHandler}
                    type='trello'
                  />
                )}
                {servicesIntegrationStatus.isGmailActive && (
                  <Integration
                    icon={"/icons/icon-gmail.png"}
                    servicesIntegrationStatus={servicesIntegrationStatus.isGmailActive}
                    type={'gmail'}
                    appName='Gmail'
                    accounts={servicesIntegrationStatus.gmailAccounts}
                    disconnectHandler={disconnectHandler}
                  />
                )}
                {servicesIntegrationStatus.isGoogleDriveActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isGoogleDriveActive}
                    icon="/icons/icon-drive.png"
                    appName='Google Drive'
                    type={'google_drive'}
                    disconnectHandler={disconnectHandler}
                    accounts={servicesIntegrationStatus.googleDriveAccounts}
                  />
                )}
                {servicesIntegrationStatus.isAzureMailActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isAzureMailActive}
                    icon='/icons/icon-outlook.png'
                    appName='Outlook'
                    disconnectHandler={disconnectHandler}
                    accounts={servicesIntegrationStatus.outlookAccounts}
                    type='outlook'
                  />
                )}
                {servicesIntegrationStatus.isSlackActive && (
                  <Integration
                    icon='/icons/icon-slack.png'
                    appName='Slack'
                    servicesIntegrationStatus={servicesIntegrationStatus.isSlackActive}
                    disconnectHandler={disconnectHandler}
                    type='slack'
                  />
                )}
                {servicesIntegrationStatus.isAtlassianActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isAtlassianActive}
                    icon="/icons/icon-atlassian.png"
                    appName='Atlassian (Jira, Confluence)'
                    disconnectHandler={disconnectHandler}
                    type='atlassian'
                  />
                )}
                {servicesIntegrationStatus.isTeamsActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isTeamsActive}
                    icon='/icons/icon-microsoft.png'
                    appName='Microsoft (Teams, SharePoint)'
                    disconnectHandler={disconnectHandler}
                    type='microsoft_teams'
                  />
                )}
                {servicesIntegrationStatus.isDropboxActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isDropboxActive}
                    icon='/icons/icon_dropbox.png'
                    appName='Dropbox for Business'
                    disconnectHandler={disconnectHandler}
                    type='dropbox_business'
                  />
                )}
                {servicesIntegrationStatus.isNotionActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isNotionActive}
                    icon={'/icons/icon-notion.svg'}
                    appName='Notion'
                    disconnectHandler={disconnectHandler}
                    type='notion'
                  />
                )}
                {servicesIntegrationStatus.isGoogleCalendarActive && (
                  <Integration
                    icon='/icons/meeting-icon.svg'
                    appName='Google Calendar'
                    servicesIntegrationStatus={servicesIntegrationStatus.isGoogleCalendarActive}
                    disconnectHandler={disconnectHandler}
                    type='google_calendar'
                    accounts={servicesIntegrationStatus.googleCalendarAccounts}
                  />
                )}
                {servicesIntegrationStatus.isFigmaActive && (
                  <Integration
                    servicesIntegrationStatus={servicesIntegrationStatus.isFigmaActive}
                    icon="/icons/icon-figma.png"
                    appName='Figma'
                    disconnectHandler={disconnectHandler}
                    type='figma'
                  />
                )}
              </StyledListBlock>
            </StyledFilledCard>}
            <StyledFilledCard>
              <StyledSectionHeader title="To be Connected" />
              {!servicesIntegrationStatus.isAsanaActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isAsanaActive}
                  icon="/icons/icon-asana.png"
                  appName='Asana'
                  disconnectHandler={disconnectHandler}
                  type='asana'
                  caption='Projects, Tasks, Links, Files, Comments, People'
                />
              )}
              {!servicesIntegrationStatus.isGitHubActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isGitHubActive}
                  icon="/icons/icon-github.png"
                  appName='GitHub'
                  disconnectHandler={disconnectHandler}
                  type='github'
                  caption='Repositories, Pull requests, Issues, Links, Comments, Files, People'
                />
              )}
              {!servicesIntegrationStatus.isClickUpActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isClickUpActive}
                  icon="/icons/icon-click_up.png"
                  appName='ClickUp'
                  disconnectHandler={disconnectHandler}
                  type='click_up'
                  caption='Spaces, Tasks, Documents, Links, Files, Comments, People'
                />
              )}
              {!servicesIntegrationStatus.isTrelloActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isTrelloActive}
                  icon="/icons/icon-trello.png"
                  appName='Trello'
                  disconnectHandler={disconnectHandler}
                  type='trello'
                  caption='Boards, Tasks, Links, Documents, Files, People'
                />
              )}
              {!servicesIntegrationStatus.isSlackActive && (
                <Integration
                  icon='/icons/icon-slack.png'
                  appName='Slack'
                  servicesIntegrationStatus={servicesIntegrationStatus.isSlackActive}
                  disconnectHandler={disconnectHandler}
                  type='slack'
                  caption='Channels, Discussions, Links, Files, People'
                />
              )}
              {!servicesIntegrationStatus.isNotionActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isNotionActive}
                  icon={'/icons/icon-notion.svg'}
                  appName={'Notion'}
                  disconnectHandler={disconnectHandler}
                  type='notion'
                  caption='Pages, Documents, Links, Comments, Tasks, People'
                />
              )}
              {!servicesIntegrationStatus.isGmailActive && (
                <Integration
                  icon={"/icons/icon-gmail.png"}
                  servicesIntegrationStatus={servicesIntegrationStatus.isGmailActive}
                  appName='Gmail'
                  disconnectHandler={disconnectHandler}
                  type='gmail'
                  caption='Emails, Contacts, Links, Attachments'
                />
              )}
              {!servicesIntegrationStatus.isAtlassianActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isAtlassianActive}
                  icon="/icons/icon-atlassian.png"
                  appName='Atlassian (Jira, Confluence)'
                  disconnectHandler={disconnectHandler}
                  type='atlassian'
                  caption='Projects, Spaces, Tasks, Documents, Pages, Links, Comments, People'
                />
              )}
              {!servicesIntegrationStatus.isGoogleDriveActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isGoogleDriveActive}
                  icon="/icons/icon-drive.png"
                  appName='Google Drive'
                  disconnectHandler={disconnectHandler}
                  type='google_drive'
                  caption='Files, Documents'
                />
              )}
              {!servicesIntegrationStatus.isTeamsActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isTeamsActive}
                  icon='/icons/icon-microsoft.png'
                  appName='Microsoft (Teams, SharePoint)'
                  disconnectHandler={disconnectHandler}
                  type='microsoft_teams'
                  caption='Teams, Discussions, Links, Files, Documents, Comments, People'
                />
              )}
              {!servicesIntegrationStatus.isAzureMailActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isAzureMailActive}
                  icon='/icons/icon-outlook.png'
                  appName='Outlook'
                  disconnectHandler={disconnectHandler}
                  accounts={servicesIntegrationStatus.outlookAccounts}
                  type='outlook'
                  caption='Emails, Events, Contacts, Links, Attachments'
                />
              )}
              {!servicesIntegrationStatus.isDropboxActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isDropboxActive}
                  icon='/icons/icon_dropbox.png'
                  appName='Dropbox for Business'
                  disconnectHandler={disconnectHandler}
                  type='dropbox_business'
                  caption='Files, Documents'
                />
              )}
              {!servicesIntegrationStatus.isGoogleCalendarActive && (
                <Integration
                  icon='/icons/meeting-icon.svg'
                  appName='Google Calendar'
                  servicesIntegrationStatus={servicesIntegrationStatus.isGoogleCalendarActive}
                  disconnectHandler={disconnectHandler}
                  type='google_calendar'
                  accounts={servicesIntegrationStatus.googleCalendarAccounts}
                  caption='Events, Links, Contacts'
                />
              )}
              {!servicesIntegrationStatus.isFigmaActive && (
                <Integration
                  servicesIntegrationStatus={servicesIntegrationStatus.isFigmaActive}
                  icon="/icons/icon-figma.png"
                  appName='Figma'
                  disconnectHandler={disconnectHandler}
                  type='figma'
                  caption='Designs, People'
                />
              )}
            </StyledFilledCard>
            <StyledFilledCard>
              <StyledSectionHeader title="Coming soon" />
              <CommingSoon />
            </StyledFilledCard>
            <FilledCard>
              <StyledSectionHeader title="Delete integrations data" />
              <DeleteData isLoading={isLoading} setIsLoading={setIsLoading} />
            </FilledCard>
          </StyledRelationships>
        </StyledOnboardingStep>
      </StyledOnboardingContent>
      <ConnectionNotificationDialog />
      {component}
    </>
  );
};

import { MentionArea } from "@/components/v3/Fields/MentionArea/MentionArea.component";
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    background: #EBEBEB;
`;
export const StyledMentionArea = styled(MentionArea)`
    width: 100%;
`;
import { StyledBanner, StyledNote } from "./TrialOverBanner.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { FC, useMemo } from "react";

export const TrialOverBanner: FC = () => {
    const router = useRouter();
    const { invitedUsers, confirmedInvitedUsers } = useMe();
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const addHandler = () => {
        router.push({
            name: router.name,
            params: router.params,
            query: {
                ...router.query,
                dialog: 'payment',
                plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
            }
        });
    }
    return <StyledBanner>
        <StyledNote>Your trial period is over. No worries, your data is still automatically synced and stored. Add subscription plan to continue using Sense. </StyledNote>
        <Button variant="secondary" onClick={addHandler}>Add subscription plan</Button>
    </StyledBanner>
}
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Box, Typography, styled } from "@mui/material";

export const StyledEmptyTabWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
`;
export const StyledTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 600;
    text-align: center;
`;
export const StyledDescription = styled(Typography)`
    padding-top: 8px;
    font-weight: 400;
    text-align: center;
    color: #636363;
`;
export const StyledButton = styled(Button)`
    margin-top: 16px;
`;
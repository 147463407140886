import { useCallback, useEffect, useMemo, useState } from "react";
import { ShareNodeDialogProps } from "./ShareNodeDialog.type";
import { StyledActions, StyledCreateButton, StyledDescription, StyledInput, StyledLinkWrapper, StyledName, StyledNodeInfo, StyledNodeTitle } from "./ShareNodeDialog.styled";
import useCopyToClipboard from "@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook";
import { SharingNodeGenerateFetch, SharingNodeGetFetch, SharingNodeRevokeFetch } from "@/axios/AiService/Sharing/Sharing.api";
import { SharingNodeGenerateAxiosResponseSuccessType } from "@/axios/AiService/Sharing/Types/NodeGenerate/NodeGenerate.type";
import { SharingNodeGetAxiosResponseSuccessType } from "@/axios/AiService/Sharing/Types/NodeGet/NodeGet.type";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { getLinkName } from "@/utils/GetLinkName/GetLinkName.util";
import { NodeIcon } from "@/components/v3/Other/NodeIcon/NodeIcon.component";
import { AppName } from "@/components/v3/Other/AppName/AppName.component";
import { DialogLayout } from "../../Layouts/DialogLayout/DialogLayout.component";
import { Button } from "../../Fields/Button/Button.component";
import { useConfirm } from "@/hooks/v3/UseConfirm/UseConfirm.hook";
import { Dialog } from "../../Other/Dialog/Dialog.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

export const ShareNodeDialog = ({ isOpened, setIsOpened, node: nodeProps }: ShareNodeDialogProps) => {
    const { node: nodeProvider } = useNode();
    const node = nodeProps || nodeProvider;
    const nodeIdNumber = node?.id;
    const [code, setCode] = useState<string>('');
    const { copyToClipboard } = useCopyToClipboard();
    const isMobile = useRenederBelow('tablet');
    const { confirm, component } = useConfirm({
        title: 'Confirm revoke access',
        content: "Everybody who received this sharing link will never be able to access this shared space anymore.",
        submitButtonProps: {
            children: 'Revoke',
        },
    });
    const submitHandle = useCallback(async () => {
        nodeIdNumber && await SharingNodeGenerateFetch({
            nodeId: nodeIdNumber,
        }).then((res: SharingNodeGenerateAxiosResponseSuccessType) => {
            setCode(res.data.code);
        });
    }, [nodeIdNumber]);
    const loadCode = useCallback(async () => {
        nodeIdNumber && await SharingNodeGetFetch({
            nodeId: nodeIdNumber,
        }).then((res: SharingNodeGetAxiosResponseSuccessType) => {
            setCode(!!res.data.length ? res.data[0].code : '');

        });
    }, [nodeIdNumber]);
    const revokeHandle = useCallback(async () => {
        if (!code) {
           return; 
        }
        confirm(async (result) => {
            result && await SharingNodeRevokeFetch({
                code: code,
            }).then((res: SharingNodeGenerateAxiosResponseSuccessType) => {
                setCode('');
            });
        })
        
    }, [code, confirm]);
    useEffect(() => {
        if (isOpened) {
            loadCode();
        }
    }, [isOpened, loadCode]);
    useEffect(() => {
        setCode('');
    }, [nodeIdNumber]);
    const link = useMemo(() => {
        if (!code) {
            return '';
        }
        return `${window.location.protocol}//${window.location.host}/public/node/${code}`;
    }, [code]);
    const copyHandle = () => {
        copyToClipboard(link, 'Link copied to clipboard');
    }
    const isShared = !!link;
    const name = getLinkName(node?.name || '');
    const buttonsJsx = <>
        {!isShared && <StyledCreateButton size="large" onClick={submitHandle}>Create sharing link</StyledCreateButton>}
        {isShared && <>
            <Button size="large" variant='tertiary' onClick={revokeHandle}>Revoke</Button>
            <Button size="large" onClick={copyHandle}>Copy link</Button>
        </>}
    </>
    return <Dialog value={isOpened} onChange={setIsOpened} size="small">
        <DialogLayout
            title="Share node"
            actions={isMobile ? buttonsJsx : undefined}
            onCloseClick={() => setIsOpened(false)}
        >
            <StyledNodeInfo>
                {node && <NodeIcon node={node} />}
                <StyledNodeTitle>
                    <StyledName long={!!node?.name} title={node?.name}>{name}</StyledName>
                    <p>{node && <AppName node={node} />}</p>
                </StyledNodeTitle>
            </StyledNodeInfo>
            <StyledDescription>
                Anyone with the link will be able to browse all relationships of this node, except emails and discussions. They might not have access to the resources from your apps, if you didn’t grant them this access via your apps.
            </StyledDescription>
            <StyledLinkWrapper>
                <StyledInput
                    placeholder="Sharing link"
                    value={link}
                    disabled={!link}
                />
                {!isMobile && <StyledActions>
                    {buttonsJsx}
                </StyledActions>}
            </StyledLinkWrapper>
        </DialogLayout>
        {component}
    </Dialog>
}
import { FC } from "react";
import { TagsProps } from "./Tags.type";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { StyledFilledCard, StyledWrapper } from "./Tags.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Tag } from "./components/Tag/Tag.component";
import { defaultEmoji } from "@/const/emoji.const";

export const Tags: FC<TagsProps> = ({
    tags,
    setTags
}) => {
    const tagsJax = tags.map((tag, i) => {
        return <Tag
            key={i}
            tag={tag}
            setTag={(tag) => {
                const newTags = [...tags];
                newTags[i] = tag;
                setTags(newTags);
            }}
            onRemove={() => {
                const newTags = [...tags];
                newTags.splice(i, 1);
                setTags(newTags);
            }}
        />
    });
    const addTagHandler = () => {
        setTags([...tags, { name: '', emoji: defaultEmoji }]);
    };
    return <StyledFilledCard>
        <SectionHeader title="Tags" caption="Visible only for users invited to the space" />
        <StyledWrapper>
            {tagsJax}
        </StyledWrapper>
        <Button variant="secondary" onClick={addTagHandler}>Create tag</Button>
    </StyledFilledCard>
};
import { NodeTypeType } from "types/Node/Node.type";
import { NodeIconProps } from "./NodeIcon.type";

export const NodeIcon = ({ node, height, width, className }: NodeIconProps) => {
  let imgSrc = node && node.application === 'Sense' ? '/icons/space.svg' : '/icons/google_chrome.svg';
  if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.favicon !== undefined) {
    imgSrc = node.favicon;
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.TASK && node.application === 'Asana') {
    imgSrc = '/icons/icon-asana.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.TASK && node.application === 'Github') {
    imgSrc = '/icons/icon-github.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.TASK && node.application === 'ClickUp') {
    imgSrc = '/icons/icon-click_up.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.TASK && node.application === 'Trello') {
    imgSrc = '/icons/icon-trello.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.TASK && node.application === 'Figma') {
    imgSrc = '/icons/icon-figma.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.TASK) {
    imgSrc = '/icons/jira.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.WIKI && node.originalURI && node.originalURI.includes('wiki')) {
    imgSrc = '/icons/confluence.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI && node.originalURI.includes('sharepoint')) {
    imgSrc = '/icons/sharepoint.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI && node.originalURI.includes('spreadsheets')) {
    imgSrc = '/icons/google_sheets.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI && node.originalURI.includes('presentation')) {
    imgSrc = '/icons/google_slides.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI && node.originalURI.includes('docs')) {
    imgSrc = '/icons/google_docs.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI && node.originalURI.includes('drive')) {
    imgSrc = '/icons/google_drive.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.EMAIL && node.originalURI && node.originalURI.includes('outlook')) {
    imgSrc = '/icons/outlook.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI && node.originalURI.includes('dropbox')) {
    imgSrc = '/icons/icon_dropbox.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.EMAIL && node.originalURI && node.originalURI.includes('mail.google.com')) {
    imgSrc = '/icons/gmail.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.WIKI && node.originalURI && node.originalURI.includes('notion')) {
    imgSrc = '/icons/icon-notion.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.BASE_NODE && node.originalURI && node.originalURI.includes('atlassian')) {
    imgSrc = '/icons/icon-jira.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.categories?.some((category) => category === 'file')) {
    imgSrc = '/icons/icon_file.svg';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI?.includes('linkedin')) {
    imgSrc = '/icons/icon_linkedin.png';
  } else if (node && "nodeType" in node && node.nodeType === NodeTypeType.EVENT) {
    if (node.originalURI.includes('outlook')) {
      imgSrc = '/icons/calendar/outlook_calendar.png';
    } else if (node.originalURI.includes('google')) {
      imgSrc = '/icons/calendar/google_calendar.png';
    }
  }
  return <img className={className} width={width ? width : "32"} height={height ? height : "32"} src={imgSrc} alt="" />;
};

import { Button } from "@/components/v3/Fields/Button/Button.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";

export const StyledSwitcherContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const StyledEmail = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;
`
export const StyledDeleteAccount = styled(Button)`
    margin-top: 32px;
    padding: 8px;
`
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
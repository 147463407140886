import { FC } from "react";
import { StyledMainIcon, StyledPageHeader } from "./Header.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Header: FC = () => {
    const { followedNodes } = useMe();
    return <StyledPageHeader
        title="Important"
        subtitle={followedNodes.length===1 ? `${followedNodes.length} resource` : `${followedNodes.length} resources`}
        icon={<StyledMainIcon src={'/icons/important-active-icon.svg'} />}
        useSkeleton={false}
    />
};
import { styled } from "@mui/material";

export const StyledStepTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`
export const StyledInstruction = styled('div')`
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 16px;
    padding-top: 16px;
`
export const StyledInstructionItem = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 0;
`
export const StyledInstructionItemBold = styled('span')`
    font-weight: 600;
`
export const StyledStep2 = styled('div')`
    padding-top: 8px;
    padding-bottom: 24px;
    margin-top: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
`
export const StyledStep2Wrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const StyledScanQrCodeContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -14px;
`
export const StyledSetupKey = styled('div')`
    width: 100%;
`
export const StyledSetupKeyTitle = styled('div')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const StyledSetupKeyContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledSetupKeyText = styled('div')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`
export const StyledStep3Title = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
`
import { styled } from "@mui/material";
import { Node } from "./components/Node/Node.component";
import { CaptionDot } from "../Node/components/CaptionDot/CaptionDot.component";
import { Person } from "../Person/Person.component";

export const StyledWrapper = styled('div')`
    padding: 16px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
`;
export const StyledUserWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
`;
export const StyledNodesWrapper = styled('div')`
    margin-top: 16px;
`;
export const StyledNodesTitle = styled('div')`
    color: #636363;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
`;
export const StyledNode = styled(Node)`
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledPersonCaption = styled('div')`
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
`;
export const StyledCaptionDot = styled(CaptionDot)`
    display: inline-block;
`;
export const StyledPerson = styled(Person, {
    shouldForwardProp: (prop) => prop !== 'hasButton'
})<{hasButton: boolean}>`
    width: ${({ hasButton }) => hasButton ? 'calc(100% - 100px)' : '100%'};
`;
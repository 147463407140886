import { styled } from "@mui/material";

export const CheckboxContainer = styled("div")`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 4px 16px;
    padding-left: 0;
    user-select: none;
`;
export const StyledImg = styled('img')`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 4px;
`
export const StyledCheckboxWrapper = styled("div")`
    margin-left: 16px;
`;
export const StyledCheckboxLabel = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
export const StyledCheckboxCaption = styled("div")`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
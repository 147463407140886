import { styled } from "@mui/material";
import { EmojiSelect } from "../../Fields/EmojiSelect/EmojiSelect.component";
import { Input } from "../../Fields/Input/Input.component";
import { EmojiIcon } from "@/icons/v3/emoji.icon";

export const StyledInputWrapper = styled('div')`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
`;
export const StyledEmojiSelect = styled(EmojiSelect)`
    width: 56px;
    height: 56px;
    background-color: #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 40px;
    margin-right: 12px;
`
export const StyledEmojiIcon = styled(EmojiIcon)`
    width: 40px;
    height: 40px;
`
export const StyledInput = styled(Input)`
    margin-bottom: 0;
    width: calc( 100% - 56px - 12px );
`
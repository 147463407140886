import { GoogleCalendarEventType } from "axios/SenseFacade/CalendarEvents/Types/Get/Get.type";

export const meetingsTimelineMock: GoogleCalendarEventType[] = [
    {
        "id": 1,
        "title": "Team Sync",
        "originalURI": "",
        "type": "google_calendar",
        "startTime": new Date().setHours(9, 0, 0, 0),
        "endTime": new Date().setHours(10, 0, 0, 0),
        "users": [],
        "meetings": [],
        "attachments": [],
        "daily": false
    },
    {
        "id": 2,
        "title": "Project Kick-off",
        "originalURI": "",
        "type": "google_calendar",
        "startTime": new Date().setHours(10, 0, 0, 0),
        "endTime": new Date().setHours(10, 30, 0, 0),
        "users": [],
        "meetings": [],
        "attachments": [],
        "daily": false
    },
    {
        "id": 3,
        "title": "Workshop. Day 2",
        "originalURI": "",
        "type": "outlook_calendar",
        "startTime": new Date().setHours(10, 45, 0, 0),
        "endTime": new Date().setHours(14, 0, 0, 0),
        "users": [],
        "meetings": [],
        "attachments": [],
        "daily": false
    },
]
import { FC, useMemo } from "react";
import { AppIconProps } from "./AppIcon.type";
import { StyledAppIcon, StyledMaskedImg } from "./AppIcon.styled";
import { AppTypesKeys } from "@/types/AppTypes/AppTypes.type";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const AppIcon: FC<AppIconProps> = ({ application, src, className, useSkeleton = false, isExpired = false }) => {
  const { user } = useMe();
  const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
  const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
  const skeletonState = useSkeleton && (isSubscriptionExpired || (isExpired && isIndividual));
    let imgSrc = useMemo((): string => {
      if (!!src) {
        return src;
      }
      switch (application as AppTypesKeys) {
        case 'MicrosoftTeams':
          return '/icons/teams.svg';
        case 'Slack':
          return '/icons/icon-slack.png';
        case 'Asana':
          return '/icons/icon-asana.png';
        case 'Github':
          return '/icons/icon-github.png';
        case 'ClickUp':
          return '/icons/icon-click_up.png';
        case 'Trello':
          return '/icons/icon-trello.png';
        case 'Figma':
          return '/icons/icon-figma.png';
        case 'Jira':
          return '/icons/icon-jira.png';
        case 'Notion':
          return '/icons/icon-notion.svg';
        case 'Confluence':
          return '/icons/apps/confluence.png';
        case 'TeamSpaces':
          return '/icons/teams.svg';
        case 'GoogleDrive':
          return '/icons/icon-drive.png';
        case 'Gmail':
          return '/icons/icon-gmail.png';
        case 'Dropbox':
          return '/icons/icon_dropbox.png';
        case 'Outlook':
          return '/icons/icon-outlook.png';
        case 'Sharepoint':
          return '/icons/sharepoint.png';
        case 'GoogleCalendar':
          return '/icons/calendar/google_calendar.png';
      }
    }, [application, src])
    return <>
      {!skeletonState && <StyledAppIcon src={imgSrc} className={className}/>}
      {skeletonState && <StyledMaskedImg className={`${className} skeleton-img`} />}
    </>
}
import { Box, Typography } from '@mui/material';
import { styled } from "@mui/material";

export const StyledContainer = styled(Box)<{
    checked: boolean;
}>`
    border: 1px solid ${({ checked }) => (checked ? '#202020' : '#EBEBEB')};
    height: 272px;
    width: 352px;
    padding: 16px;
    border-radius: 8px;
`;
export const StyledPrice = styled(Typography)`
    font-weight: 600;
`;
export const StyledTitleContainer = styled(Box)`
    padding-bottom: 16px;
    border-bottom: 1px solid #ebebeb;
    height: 90px;
`;
export const StyledPlanItem = styled(Box)`
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
`;
import moment from "moment";

interface SortedObject {
    createdAt: string;
}
export const compareCreatedAt = (a: SortedObject, b: SortedObject) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
}
export const formatDate = (date: Date | string): string => {
    moment.locale('en');
    return moment(date).format('MMMM Do YYYY, h:mm a');
}
export const formatDateTimeAgo = (date: Date | string): string => {
    const time = moment(date);
    const currentTime = moment();

    const diffInMinutes = currentTime.diff(time, 'minutes');
    const diffInHours = currentTime.diff(time, 'hours');

    if (diffInMinutes < 1) return 'Just now';

    if (diffInMinutes < 60) return `${diffInMinutes} min ago`;

    if (diffInHours < 24) return `${diffInHours}h ago`;

    if (diffInHours < 48) return `Yesterday, at ${time.format('HH:mm')}`;

    return formatDate(date);
}
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { DrawerContent } from "providers/v3/NodeProvider/components/NodeNeighboursDrawer/components/DrawerContent/DrawerContent.component";
import { FC } from "react";
import { StyledWrapper } from "./NodeNeighbours.styled";
import { SharedLayout } from "@/components/v3/Layouts/SharedLayout/SharedLayout.component";

export const NodeNeighboursContent: FC = () => {
    const { isShared } = useIsShared();
    const contentJsx = isShared ? (
        <SharedLayout>
            <StyledWrapper>
                <DrawerContent fullWidth={!isShared} />
            </StyledWrapper>
        </SharedLayout>
    ) : (
        <MainLayout>
            <DrawerContent fullWidth={!isShared} />
        </MainLayout>
    )
    return <>
        {contentJsx}
    </>
};

import { useState, useCallback, useRef } from 'react';
import { ConfirmationDialog } from './components/ConfirmationDialog/ConfirmationDialog.component';
import { UseConfirmProps } from './UseConfirm.type';

export const useConfirm = (props?: UseConfirmProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const callbackRef = useRef<(result: boolean) => void >(() => undefined);
    const submitHandler = useCallback(() => {
        setIsOpen(false);
        callbackRef.current(true);
    }, []);
    const cancelHandler = useCallback(() => {
        setIsOpen(false);
        callbackRef.current(false);
    }, []);

    const confirm = (callback: (value: boolean) => void) => {
        setIsOpen(true);
        callbackRef.current = callback;
    }

    const component = <ConfirmationDialog
        value={isOpen}
        onChange={cancelHandler}
        onSubmit={submitHandler}
        submitButtonProps={props?.submitButtonProps}
        cancelButtonProps={props?.cancelButtonProps}
        title={props?.title}
        content={props?.content}
    />;

    return { confirm, component };
}

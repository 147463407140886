import { styled } from "@mui/material";
import { Tabs } from "@/components/v3/Fields/Tabs/Tabs.component";
import { Tab } from "@/components/v3/Fields/Tabs/Tab.component";

export const StyledWrapper = styled('div')`
    margin-top: 32px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`;
export const StyledTabs = styled(Tabs)`
    padding-left: 32px;
    padding-right: 32px;
    min-width: fit-content;
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledTab = styled(Tab)`
    white-space: nowrap;
`;
import { Box } from '@mui/material';
import { StyledButton, StyledDescription, StyledEmptyTabWrapper, StyledTitle } from './EmptyTabs.styled';

export const EmptyTabs = () => {

  const installChromeExtensionHandler = () => {
    window.open('https://chrome.google.com/webstore/detail/sense/obemfgmikdfkejnfioiodicdhjnnojla', '_blank');
  }

  return (
    <Box style={{padding: 24}}>
      <StyledEmptyTabWrapper>
        <StyledTitle>Install Sense Chrome Extension</StyledTitle>
        <StyledDescription>
          With Sense Chrome Extension you can add your tabs to spaces, search across all your apps right from browser and get all necessary data right from the context.
        </StyledDescription>
        <Box style={{display: 'flex', justifyContent: 'center', }}>
          <StyledButton onClick={installChromeExtensionHandler}>
            Install Chrome Extension
          </StyledButton>
        </Box>
      </StyledEmptyTabWrapper>
    </Box>
  )
}

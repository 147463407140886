import { FC, useEffect } from "react";
import { EditSpaceFormProps } from "./EditSpaceForm.type";
import { StyledEmojiIcon, StyledEmojiSelect, StyledInput, StyledInputWrapper } from "./EditSpaceForm.styled";
import { SpaceType } from "./components/SpaceType/SpaceType.component";
import { Tags } from "./components/Tags/Tags.component";
import { InviteUsers } from "./components/InviteUsers/InviteUsers.component";
import { ConnectApps } from "./components/ConnectApps/ConnectApps.component";
import { LoaderLayout } from "../../Layouts/LoaderLayout/LoaderLayout.component";
import { useRouter } from "@/providers/Router/Router.provider";

export const EditSpaceForm: FC<EditSpaceFormProps> = ({
    name, setName,
    emoji, setEmoji,
    spaceType, setSpaceType,
    tags, setTags,
    users, setUsers,
    channels, setChannels,
    isFetchingSpaceTags = false,
    errors,
    setErrors,
    isNativeSpace
}) => {
    const router = useRouter();
    const isCreateSpace = router.query.isCreateSpace === 'true';
    const isEditSpace = router.query.isEditSpace === 'true';
    useEffect(() => {
        if ((router.query.dialog === 'create-space' || router.query.dialog === 'edit-space') && !router.query.invite) {
          if (isCreateSpace || isEditSpace) {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                ...router.query,
                dialog: [isCreateSpace ? 'create-space' : 'edit-space', 'manage-users'],
                isCreateSpace: undefined,
                isEditSpace: undefined
              }
            })
          }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [router.query.dialog, router.query.invite, isCreateSpace, isEditSpace]);
    return <>
        <StyledInputWrapper>
            <StyledEmojiSelect onChange={(e) => {
              setEmoji(e.native)
            }} >
              {!!emoji ? emoji : <StyledEmojiIcon />}
            </StyledEmojiSelect>
            <StyledInput onFocus={() => {
                setErrors({
                    ...errors,
                    name: undefined
                })
            }} error={errors.name} label="Space name" value={name} onChange={(e) => setName(e.target.value)} />
        </StyledInputWrapper>
        {router.query.dialog === 'create-space' && <SpaceType spaceType={spaceType} setSpaceType={setSpaceType} />}
        {isNativeSpace && <LoaderLayout isLoading={isFetchingSpaceTags} transparent>
            <Tags tags={tags} setTags={setTags} />
        </LoaderLayout>}
        <InviteUsers users={users} setUsers={setUsers} />
        <ConnectApps channels={channels} setChannels={setChannels} />
    </>
};
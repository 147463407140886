import { NodeIcon } from "@/components/v3/Other/NodeIcon/NodeIcon.component";
import { PageHeader } from "@/components/v3/Sections/PageHeader/PageHeader.component";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { FC } from "react";
import { StyledWrapper } from "./Header.styled";
import { AppName } from "@/components/v3/Other/AppName/AppName.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { ShareButton } from "../ShareButton/ShareButton.component";
import { OpenButton } from "../../../OpenButton/OpenButton.component";
import { getLinkName } from "@/utils/v3/GetLinkName/GetLinkName.util";
import { Menu } from "../../../Menu/Menu.component";

export const Header: FC = () => {
    const { node } = useNode();
    const router = useRouter();
    const { isSharedSpace, isSharedNode } = useIsShared();
    const name = getLinkName(node?.name || '');
    return (
        <StyledWrapper>
            <PageHeader title={name} subtitle={node && <AppName node={node} />} icon={node && <NodeIcon node={node} />} >
                {(router.name === 'nodeNeighbours' || router.name === 'publicNode') &&  <>
                    {!isSharedSpace && !isSharedNode && <>
                        <Menu />
                        <ShareButton />
                    </>}
                    <OpenButton />
                </>}
            </PageHeader>
        </StyledWrapper>
    )
};
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from "@/service/helpers";
import { CreateNoteBodyParams } from "./Types/Create/Create.type";
import { UpdateNoteBodyParams } from "./Types/Update/Update.type";

export const CreateNoteFetch = (body: CreateNoteBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/notes`, body);
}
export const UpdateNoteFetch = (nodeId: number, body: UpdateNoteBodyParams) => {
    return HttpClient.patch(`${envUrl}/ai-service/v1/notes/${nodeId}`, body);
}
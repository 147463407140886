import { FC, useEffect, useMemo, useState } from "react";
import { SelectUsersDialogProps } from "./SelectUsersDialog.type";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { StyledCancelButton, StyledInput } from "./SelectUsersDialog.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { User } from "./components/User/User.component";

export const SelectUsersDialog: FC<SelectUsersDialogProps> = ({
    title,
    isOpened, setIsOpened,
    users: usersProp, setUsers: setUsersProp,
    listOfUsers,
    isLoading
}) => {
    const [search, setSearch] = useState<string>('');
    const [users, setUsers] = useState<string[]>(usersProp);
    useEffect(() => {
        setUsers(usersProp);
    }, [usersProp, isOpened]);
    const filteredListOfUsers = useMemo(() => {
        const searchTrim = search.toLowerCase().trim();
        return listOfUsers.filter((user) => {
            return user.firstName?.toLowerCase().includes(searchTrim) || user.lastName?.toLowerCase().includes(searchTrim) || user.email?.toLowerCase().includes(searchTrim);
        })
    }, [listOfUsers, search]);
    const listOfUsersJsx = filteredListOfUsers.map((user, i) => {
        return <User key={i} user={user} value={users} onChange={setUsers} />
    });
    const doneHandler = () => {
        setUsersProp(users);
    };
    return <Dialog value={isOpened} onChange={setIsOpened}>
        <DialogLayout
            title={title}
            isLoading={isLoading}
            actions={<>
                <StyledCancelButton size="large" variant="error" onClick={() => setIsOpened(false)}>
                    Cancel
                </StyledCancelButton>
                <Button size="large" onClick={doneHandler}>
                    Done
                </Button>
            </>}
        >
            <StyledInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Find users" />
            {listOfUsersJsx}
        </DialogLayout>
    </Dialog>
};
import { FC, useMemo } from "react";
import { StyledSectionHeader, StyledWrapper } from "./Relationships.styled";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { ConnectApps } from "@/components/v3/Sections/ConnectApps/ConnectApps.component";
import { groupNodeWrappersByCategory } from "@/utils/v3/GroupNodeByCategory/GroupNodeByCategory.util";
import { NodeTypeType } from "@/types/Node/Node.type";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

export const Relationships: FC = () => {
    const {
        nodes,
        nodeUsers,
        node
    } = useNode();
    const { isShared } = useIsShared();
    const groups = useMemo(() => {
        return groupNodeWrappersByCategory(nodes);
    }, [nodes]);
    const groupsJsx = useMemo(() => {
        return groups.map((group, i) => {
            const nodes = group.wrappers.map(wrapper => {
                return {
                    node: wrapper.node,
                    onUpdate: () => {},
                    users: nodeUsers,
                    importantUserIds: wrapper.importantUserIds
                    // relationships: {
                    //     actors,
                    // },
                }
            })
            return <CategoryGroup useShowAll title={group.category} nodes={nodes} key={i} />
        })
    }, [groups, nodeUsers]);
    const isEmpty = !nodes.length;
    const isNote = node?.nodeType === NodeTypeType.NOTE;
    return <>
        <EmptyStateLayoyt value={isEmpty} emptyNode={!isNote && !isShared && <ConnectApps
            title="Get related resources automatically"
            description="Connect apps to automatically get previous versions, tasks, documents, discussions, files, links related to this resource."
            applications={['Slack', 'Asana', 'Notion', 'GoogleDrive']}
        />}>
            <StyledWrapper>
                <StyledSectionHeader title="Relationships" />
                {groupsJsx}
            </StyledWrapper>
        </EmptyStateLayoyt>
    </>
};
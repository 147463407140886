import { SenseTextField } from '@/mui/SenseTextField/SenseTextField.component';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from "@mui/material";

export const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        max-width: 560px;
        width: 560px;
        @ media (max-width: 600px) {
            width: 80%;
            minWidth: 0;
        }
    }
`
export const StyledDialogTitle = styled(DialogTitle)`
    border-bottom: 1px solid rgba(0,0,0,.08);
`
export const StyledDialogContent = styled(DialogContent)`
    padding: 24px;
    padding-top: 24px !important;
`
export const StyledDescription = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const StyledLinkWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
`
export const StyledActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 12px;
`
export const StyledSenseTextField = styled(SenseTextField)`
    width: 100%;
    margin-right: 16px;
`
export const StyledSubmit = styled('div')`
    display: flex;
    height: 32px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #1D1A1A;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    white-space: nowrap;
`
export const StyledCancel = styled('div')`
    display: flex;
    height: 32px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    color: #F00;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: 8px;
    cursor: pointer;
`
import { CopyLinkIcon } from "@/icons/copy-link-icon";
import { OpenLinkIcon } from "@/icons/open-link-icon";
import { styled } from "@mui/material";

export const StyledIcon = styled('div')`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledCopyLinkIcon = styled(CopyLinkIcon)`
    width: 18px;
    height: 18px;
`;
export const StyledOpenLinkIcon = styled(OpenLinkIcon)`
    width: 16px;
    height: 16px;
`;
export enum RelationActionType {
    CREATOR = 'CREATOR',
    CREATED_BY = 'CREATED_BY',
    ASSIGNEE = 'ASSIGNEE',
    WATCHER = 'WATCHER',
    ATTENDEE = 'ATTENDEE',
    FROM = 'FROM',
    CC = 'CC',
    BCC = 'BCC',
    TO = 'TO',
    ADDED_BY = 'ADDED_BY',
    COMMENTED_BY = 'COMMENTED_BY',
    COMMENTER = 'COMMENTER',
    POSTED_BY = 'POSTED_BY',
    EDITED_BY = 'EDITED_BY',
    SHARED_BY = 'SHARED_BY',
    SHARED_TO = 'SHARED_TO',
    WATCHED_BY = 'WATCHED_BY',
    MENTIONS = 'MENTIONS',
    UPDATED = 'UPDATED'
}
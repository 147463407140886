import { FC } from "react";
import { CategoryGroupProps } from "./CategoryGroup.type";
import { StyledNodesWrapper } from "./CategoryGroup.styled";
import { GroupLayout } from "../../Layouts/GroupLayout/GroupLayout.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { Button } from "../../Fields/Button/Button.component";
import { Node } from "../Node/Node.component";

export const CategoryGroup: FC<CategoryGroupProps> = ({
    title,
    actions,
    nodes,
    useShowAll = false,
    cols = 1,
    nodeSize = 'large'
}) => {
    const { items: itemsMore, showMore, setShowMore } = useShowMore({ items: nodes, limit: 5 });
    const items = useShowAll ? itemsMore : nodes;
    const listOfNodesJsx = items.map((item, i) => {
        return <Node {...item} size={nodeSize} key={i} />
    });
    return <GroupLayout title={title} actions={actions}>
        <StyledNodesWrapper cols={cols}>
            {listOfNodesJsx}
        </StyledNodesWrapper>
        {useShowAll && !showMore && nodes.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
    </GroupLayout>
};
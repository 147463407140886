// @ts-nocheck

import { Box } from "@mui/material";
import { User } from 'components/Nodes/SearchService';
import { GraphNode } from 'components/Nodes/NodeService';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppName } from 'components/AppName/AppName.component';
import { useMe } from 'providers/MeProvider/Me.provider';
import { EventTypes, useAmplitude } from "service/TrackingService";
import { SearchResultProps } from './SearchResultView.type';
import { NodeMenu } from 'components/NodeMenu/NodeMenu.component';
import { StyledLineButtons, StyledListBlock, StyledListItemNode, StyledListNodeTitleWrapper, StyledNodeTitle, StyledTitle } from "./SearchResultView.styled";
import { ImportantLabel } from "@/components/ImportantLabel/ImportantLabel.component";
import { NodeIcon } from "@/components/NodeIcon/NodeIcon.component";
import { NodeLinkActions } from "@/components/NodeLinkActions/NodeLinkActions.component";
import { getLinkName } from "@/utils/GetLinkName/GetLinkName.util";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";

export const SearchResultView: React.FC<SearchResultProps> = ({
  search,
  setSearchResult,
  refSearchField,
  onNavigate,
  onUpdate
}: SearchResultProps) => {
  const history = useHistory();
  const { setActiveNodeId } = useNode();

  const { followedNodes } = useMe();
  const trackEvent = useAmplitude();

  const navigate = (url: string) => {
    setSearchResult({
      users: [],
      nodes: [],
    });
    onNavigate();
    refSearchField.current!.value = '';
    history.replace(url);
  };

  const nodeClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, nodeId: number) => {
    const menuElements = document.querySelectorAll(".MuiMenu-root");
    for (let i = 0; i < menuElements.length; i++) {
        if (menuElements[i].contains(e.target as Node)) {
            return;
        }
    }
    const dialogElements = document.querySelectorAll(".MuiDialog-root");
    for (let i = 0; i < dialogElements.length; i++) {
        if (dialogElements[i].contains(e.target as Node)) {
            return;
        }
    }
    navigate('/node-neighbours/' + nodeId);
    setSearchResult({
      users: [],
      nodes: [],
    });
    onNavigate();
    refSearchField.current!.value = '';
    setActiveNodeId(nodeId)
    trackEvent(EventTypes.SEARCH_RESULT_CLICKED)
  };
  return (
    <Box>
      {/*{isSubscriptionExpired ? (<MaskedPage rowsCount={20})}*/}
      {search.users?.map((user: User) => {
        return (
          <StyledListBlock key={user.id}>
            <StyledListItemNode onClick={() => navigate(`/common-relations/${user.id}`)}>
              <StyledListNodeTitleWrapper>
                <img width="32" height="32" style={{ borderRadius: "50%" }} src={`${user.avatar || "/icons/no_avatar.svg"}`} alt="" />
                <StyledNodeTitle>
                  {user.firstName} {user.lastName} {user.email}
                </StyledNodeTitle>
              </StyledListNodeTitleWrapper>
            </StyledListItemNode>
          </StyledListBlock>
        );
      })}
      {search.nodes?.map((node: GraphNode) => {
        const important = !!followedNodes.find((followedNode) => followedNode.id === node?.id);
        const name = getLinkName(node.name);
        return (
          <StyledListBlock key={node.id}>
            <StyledListItemNode>
              <StyledListNodeTitleWrapper onClick={(e) => nodeClickHandler(e, node.id)} className="node">
                <NodeIcon node={node} />
                <StyledNodeTitle>
                  <StyledTitle>{name}</StyledTitle>
                  <p style={{ color: 'rgba(32,32,32,.7)' }}>
                    <AppName node={node} />
                  </p>
                  <ImportantLabel value={important} />
                </StyledNodeTitle>
              </StyledListNodeTitleWrapper>
              <StyledLineButtons>
                {!!node?.originalURI && <NodeLinkActions value={node.originalURI} />}
                {node && <NodeMenu node={node} onUpdate={onUpdate} />}
              </StyledLineButtons>
            </StyledListItemNode>
          </StyledListBlock>
        );
      })}
    </Box>
  );
};


import { styled } from "@mui/material";

export const PesonContainer = styled('div')`
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    margin-left: -8px;
    border-radius: 50%;
`
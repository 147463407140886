import { styled } from "@mui/material";
import { HOUR_HEIGHT } from "../../../../MeetingsTimeline.const";

export const StyledHourContainer = styled('div')`
    height: ${HOUR_HEIGHT}px;
`
export const StyledHourWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateY(-10px);
`
export const StyledHour = styled('div')`
    color: #B5B5B5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
`
export const StyledHourLine = styled('div')`
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    margin-left: 4px;
`
import { FC } from "react";
import { StyledWrapper } from "../AllResources/AllResources.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { StyledAddIcon } from "../Sidebar/Sidebar.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { StyledChannel, StyledEmptyCardWrapper, StyledFilledCard } from "./Channels.styled";
import { useRouter } from "@/providers/Router/Router.provider";

export const Channels: FC = () => {
    const {
        isFetchingChannels,
        listOfChannels,
    } = useSpacePageCommon();
    const router = useRouter();
    const { items, showMore, setShowMore } = useShowMore({ items: listOfChannels, limit: 5 });
    const listOfChannelsJsx = items.map((channel, i) => {
        return <StyledChannel channel={channel} key={i} />
    });
    return <LoaderLayout isLoading={isFetchingChannels} transparent={!!listOfChannels.length}>
        <StyledWrapper>
            <SectionHeader title="Channels">
                {!!listOfChannels.length && <Button 
                    onClick={() => {
                        router.push({
                            name: router.name,
                            params: router.params,
                            query: {
                                ...router.query,
                                dialog: 'edit-space',
                                scroll: 'apps'
                            }
                        })
                    }}
                    icon={<StyledAddIcon />}
                    variant="tertiary"
                >Add</Button>}
            </SectionHeader>
            <LoaderLayout isLoading={isFetchingChannels} transparent={!!listOfChannels.length}>
                <EmptyStateLayoyt
                    value={!listOfChannels.length && !isFetchingChannels}
                    emptyNode={<StyledEmptyCardWrapper>
                        <EmptyCard title="Connect apps to space" onClick={() => {
                            router.push({
                                name: router.name,
                                params: router.params,
                                query: {
                                    ...router.query,
                                    dialog: 'edit-space',
                                    scroll: 'apps'
                                }
                            })
                        }}>
                            All resources from your alls will be automatically organized in the space
                        </EmptyCard>
                    </StyledEmptyCardWrapper>}
                >
                    <StyledFilledCard>
                        {listOfChannelsJsx}
                        {!showMore && listOfChannels.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
                    </StyledFilledCard>
                </EmptyStateLayoyt>
            </LoaderLayout>
        </StyledWrapper>
    </LoaderLayout>
};
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
export const StyledActions = styled("div")`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;
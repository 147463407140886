import { FC, useState, useEffect, useMemo } from "react";
import { SpaceProps } from "./Space.type";
import { StyledDropdownIcon, StyledNoTags, StyledApplicationIcon, StyledWrapper, StyledSpaceContant, StyledSpaceName, StyledTagsContainer, StyledContent, StyledSpaceImage, StyledEmojiWrapper } from "./Space.styled";
import { Collapse } from '@mui/material';
import { AddNodeToSpaceFetch, DeleteNodesFetch } from "@/axios/AiService/SpacesNodes/SpacesNodes.api";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { ToastService } from "@/service/ToastService";
import { GetSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { SpaceTagType, SpaceTagsAxiosResponseSuccessType } from "@/axios/AiService/SpaceTags/Types/Get/Get.type";
import { SpaceTag } from "./components/SpaceTag/SpaceTag.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledEmoji } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.styled";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";

export const Space: FC<SpaceProps> = ({ space, nodeId, isOpen, setIsOpen }) => {
    const useCollapse = space.application ==='Sense';
    const [isAdded, setIsAdded] = useState<boolean>(false);
    const [tags, setTags] = useState<SpaceTagType[]>([]);
    const trackEvent = useAmplitude();
    const loadTags = async () => {
        await GetSpaceTagsFetch(space.id).then((res: SpaceTagsAxiosResponseSuccessType) => {
            setTags(res.data);
        });
    };
    useEffect(() => {
        if (tags.find((tag) => tag.nodeIds.includes(nodeId))) {
            setIsAdded(true);
        } else {
            setIsAdded(false);
        }
    }, [nodeId, tags]);
    const addToSpaceHandler = async () => {
        await AddNodeToSpaceFetch({
            spaceId: space.id,
            nodeIds: [nodeId],
        }).then(() => {
            setIsAdded(true);
            trackEvent(EventTypes.NODE_WAS_MANUALLY_ADDED_TO_SPACE);
            ToastService.showToast('success', '1 resource was added to the space!');
        })
        .catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
    };
    const removeFromSpaceTagHandler = async () => {
        await DeleteNodesFetch({
            spaceId: space.id,
            nodeIds: [nodeId],
          }).then(() => {
            setIsAdded(false);
            ToastService.showToast('success', '1 resources was removed from the space!');
            loadTags()
          })
          .catch(error => {
            ToastService.showToast('error', 'Some error occurred! Please try again later!');
          });
    }
    useEffect(() => {
        if (isOpen) {
            loadTags();
        }
      }, [isOpen]);
    const listOfTagsJsx = tags.map((tag) => {
        return <SpaceTag onUpdate={loadTags} spaceId={space.id} nodeId={nodeId} tag={tag} key={tag.id} />
    });
    const onToggleHandler = async () => {
        if (useCollapse) {
            if (isOpen) {
                setIsOpen(false)
            } else {
                if (tags.length>0) {
                    setIsOpen(true);
                } else {
                    await loadTags();
                    setIsOpen(true)
                }
            }
        }
    };
    const imgSrc = useMemo(() => {
        if (space.private) {
          return '/icons/private-space-active-icon.svg';
        }
        return getIconPath(space.application, true);
      }, [space.private, space.application]);
    const imgJsx = useMemo(() => {
        if (!!space.emoji) {
          return <StyledEmojiWrapper>
            <StyledEmoji>{space.emoji}</StyledEmoji>
          </StyledEmojiWrapper>
        }
        return <StyledSpaceImage src={imgSrc} />
      }, [imgSrc, space.emoji]);
    return <StyledWrapper>
        <StyledContent>
            <StyledSpaceContant useCollapse={useCollapse} onClick={onToggleHandler}>
                {useCollapse && <StyledDropdownIcon isOpened={isOpen} />}
                {imgJsx}
                {/* <StyledApplicationIcon application={space.application} /> */}
                <StyledSpaceName>{space.name}</StyledSpaceName>
            </StyledSpaceContant>
            {isAdded && <Button center onClick={() => removeFromSpaceTagHandler()} variant="tertiary">Remove</Button>}
            {!isAdded && <Button center onClick={() => addToSpaceHandler()}>Add here</Button>}
        </StyledContent>
        {useCollapse && <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <StyledTagsContainer>
                {listOfTagsJsx}
            </StyledTagsContainer>
            {tags.length===0 && <StyledNoTags>No tags</StyledNoTags>}
        </Collapse>}
    </StyledWrapper>
};
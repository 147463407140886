export const getLocalStorage = async (keys: string[]): Promise<{
    success: boolean;
    error_code?: string;
    data?: { [key: string]: string | null | undefined };
}> => {
    let request_id = Date.now();
    return new Promise((resolve) => {
        if (document.documentElement.dataset.senseai_extension_installed !== "1") {
            resolve({ success: false, error_code: "extension_not_installed" });
        } else {
            let listener = function (event: any) {
                if (event.data.name === "exec_result" && event.data.meta.request_id === request_id) {
                    resolve(event.data.data.result);
                    window.removeEventListener("message", listener);
                }
            };
            window.addEventListener("message", listener);
            window.postMessage(
                {
                    name: "get_storage_local",
                    meta: { request: true, request_id },
                    data: { _origin: true, data: keys },
                },
                "https://memory.senseapp.ai"
            );
        }
    });
};

import { Box, styled } from "@mui/material";

export const StyledListBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    align-content: stretch;
    &:last-child $listItemNode {
        border-bottom: none;
    }
`;
export const StyledListItemNode = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.08);
    justify-content: space-between;
`;
export const StyledListNodeTitleWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 12px 0px 12px 0px;
    & img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
`;
export const StyledNodeTitle = styled(Box)`
    font-weight: 500;
    & h1 {
        margin: 0;
        font-weight: bold;
    }
    & p {
        margin: 0;
    }
`;
export const StyledLineButtons = styled(Box)`
    display: flex;
    align-items: center;
`;
export const StyledTitle = styled('p')`
    font-weight: 500;
    color: #202020;
`;
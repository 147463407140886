import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { FC } from "react";
import { StyledGrid, StyledTitle, StyledWrapper } from "./EmptyState.styled";
import { useRouter } from "@/providers/Router/Router.provider";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";

export const EmptyState: FC = () => {
    const { update } = useForYouPage();
    const router = useRouter();
    return <StyledWrapper>
        <FilledCard>
            <StyledTitle>This tag is empty.<br/>Start filling it by connecting relevant app sources or add things manually</StyledTitle>
            <StyledGrid>
                <EmptyCard variant="shadow" title="Add note" onClick={() => router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        dialog: "create-note"
                    }
                })}>
                    Take a note and connect resources to it
                </EmptyCard>
            </StyledGrid>
        </FilledCard>
        <CreateNoteDrawer onUpdate={update} />
    </StyledWrapper>
};
import { FC } from "react";
import { PageHeaderProps } from "./PageHeader.type";
import {
  StyledPageHeaderWrapper,
  StyledContentNodeTitleContainer,
  StyledContentNodeTitleWrapper,
  StyledLineButtons,
  StyledSubTitle,
  StyledTitle,
  StyledImageContainer
} from "./PageHeader.styled";

export const PageHeader: FC<PageHeaderProps> = ({
    title, subtitle, icon, children, breadcrumbs, className, useSkeleton = true
}) => {
  const iconJsx = typeof icon === 'string' ? <img src={icon} alt='' /> : icon;
  return <div className={className}>
    {breadcrumbs}
    <StyledPageHeaderWrapper>
      <StyledContentNodeTitleWrapper>
        <StyledContentNodeTitleContainer>
          <StyledImageContainer>
            {iconJsx}
          </StyledImageContainer>
          <div>
            <StyledTitle useSkeleton={useSkeleton}>{title}</StyledTitle>
            <StyledSubTitle useSkeleton={useSkeleton}>
              {subtitle}
            </StyledSubTitle>
          </div>
        </StyledContentNodeTitleContainer>
      </StyledContentNodeTitleWrapper>
      {!!children && <StyledLineButtons>
        {children}
      </StyledLineButtons>}
    </StyledPageHeaderWrapper>
  </div>
}
import { NodeTypeType } from 'types/Node/Node.type';
import { AppNameProps } from './AppName.type';

export const AppName = ({ node }: AppNameProps) => {
  let name;
  if (node.nodeType === NodeTypeType.TASK && node.application === 'Asana') {
    name = 'Asana';
  } else if (node.nodeType === NodeTypeType.TASK && node.application === 'Github') {
    name = 'GitHub';
  } else if (node.nodeType === NodeTypeType.TASK && node.application === 'ClickUp') {
    name = 'ClickUp';
  } else if (node.nodeType === NodeTypeType.TASK && node.application === 'Trello') {
    name = 'Trello';
  } else if (node.nodeType === NodeTypeType.TASK && node.application === 'Figma') {
    name = 'Figma';
  } else if (node.nodeType === NodeTypeType.TASK) {
    name = 'Atlassian Jira';
  } else if (node.nodeType === NodeTypeType.WIKI && node.originalURI.includes('atlassian')) {
    name = 'Atlassian Confluence';
  } else if (node.nodeType === NodeTypeType.RESOURCE_NODE && node.originalURI.includes('sharepoint')) {
    name = 'SharePoint';
  } else if (
    node.nodeType === NodeTypeType.RESOURCE_NODE &&
    node.application === 'Google Drive' &&
    node.originalURI?.includes('spreadsheets')
  ) {
    name = 'Google Sheets';
  } else if (
    node.nodeType === NodeTypeType.RESOURCE_NODE &&
    node.application === 'Google Drive' &&
    node.originalURI?.includes('docs')
  ) {
    name = 'Google Docs';
  } else if (
    node.nodeType === NodeTypeType.RESOURCE_NODE &&
    node.application === 'Google Drive' &&
    node.originalURI?.includes('drive')
  ) {
    name = 'Google Drive';
  } else if (node.nodeType === NodeTypeType.EMAIL && node.originalURI?.includes('outlook')) {
    name = 'Microsoft Exchange';
  } else {
    name = node?.application;
  }
  return <>{name}</>;
};

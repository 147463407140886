import { CommingSoonIntegration } from "./components/CommingSoonIntegration/CommingSoonIntegration.component";
import { listOfComingSoon } from "./CommingSoon.const";

export const CommingSoon = () => {
    const listOfComingSoonJsx = listOfComingSoon.map((item, index) => {
        return <CommingSoonIntegration key={index} icon={item.icon} appName={item.appName} />
    })
    return (
        <>
            {listOfComingSoonJsx}
        </>
    )
}
import { SvgIconProps } from '@mui/material';

export const GoogleSlideIcon = (props: SvgIconProps) => {
    return (
        <svg width="36" height="36" viewBox="0 0 73 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_1:2)">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L56.029 15.6881L44.8232 0Z" fill="#F4B400"/>
            </g>
            <mask id="mask1_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_1:2)">
                <path d="M50.4261 44.8232H21.291C19.4421 44.8232 17.9293 46.336 17.9293 48.185V77.3201C17.9293 79.169 19.4421 80.6818 21.291 80.6818H50.4261C52.2751 80.6818 53.7879 79.169 53.7879 77.3201V48.185C53.7879 46.336 52.2751 44.8232 50.4261 44.8232ZM49.3056 70.5966H22.4116V54.9085H49.3056V70.5966Z" fill="#F1F1F1"/>
            </g>
            <mask id="mask2_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask2_1:2)">
                <path d="M46.7899 24.9273L71.7172 49.849V26.8939L46.7899 24.9273Z" fill="url(#paint0_linear_1:2)"/>
            </g>
            <mask id="mask3_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask3_1:2)">
                <path d="M44.8232 0V20.1705C44.8232 23.8852 47.832 26.8939 51.5467 26.8939H71.7172L44.8232 0Z" fill="#FADA80"/>
            </g>
            <mask id="mask4_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask4_1:2)">
                <path d="M44.8232 0V0.56029L71.1569 26.8939H71.7172L44.8232 0Z" fill="white" fillOpacity="0.1"/>
            </g>
            <mask id="mask5_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask5_1:2)">
                <path d="M6.72348 0C3.02557 0 0 3.02557 0 6.72348V7.28377C0 3.58586 3.02557 0.56029 6.72348 0.56029H44.8232V0H6.72348Z" fill="white" fillOpacity="0.2"/>
            </g>
            <mask id="mask6_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask6_1:2)">
                <path d="M64.9937 98.0508H6.72348C3.02557 98.0508 0 95.0253 0 91.3273V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V91.3273C71.7172 95.0253 68.6916 98.0508 64.9937 98.0508Z" fill="#BF360C" fillOpacity="0.2"/>
            </g>
            <mask id="mask7_1:2" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="99">
                <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="white"/>
            </mask>
            <g mask="url(#mask7_1:2)">
                <path d="M51.5467 26.8939C47.832 26.8939 44.8232 23.8852 44.8232 20.1705V20.7307C44.8232 24.4455 47.832 27.4542 51.5467 27.4542H71.7172V26.8939H51.5467Z" fill="#BF360C" fillOpacity="0.1"/>
            </g>
            <path d="M44.8232 0H6.72348C3.02557 0 0 3.02557 0 6.72348V91.8876C0 95.5855 3.02557 98.6111 6.72348 98.6111H64.9937C68.6916 98.6111 71.7172 95.5855 71.7172 91.8876V26.8939L44.8232 0Z" fill="url(#paint1_radial_1:2)"/>
            <defs>
                <linearGradient id="paint0_linear_1:2" x1="59.2549" y1="27.0671" x2="59.2549" y2="49.8525" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#BF360C" stopOpacity="0.2"/>
                    <stop offset="1" stop-color="#BF360C" stopOpacity="0.02"/>
                </linearGradient>
                <radialGradient id="paint1_radial_1:2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.27204 1.9601) scale(115.643)">
                    <stop stop-color="white" stopOpacity="0.1"/>
                    <stop offset="1" stop-color="white" stopOpacity="0"/>
                </radialGradient>
            </defs>
        </svg>
    );
};
import { styled } from "@mui/material";

export const StyledSpacesWrapper = styled('div')`
    padding: 0px 16px;
    max-height: calc( 100vh - 307px );
    overflow: auto;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.06);
    }
`;
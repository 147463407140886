import { FC } from "react";
import { EmptyCardProps } from "./EmptyCard.type";
import { StyledAddIconWrapper, StyledDescription, StyledIconWrapper, StyledTitle, StyledWarapper } from "./EmptyCard.styled";
import { Add2Icon } from "@/icons/v3/add2.icon";

export const EmptyCard:FC<EmptyCardProps> = ({
    title,
    children,
    variant = 'dashed',
    icon: iconProp,
    onClick
}) => {
    const icon = iconProp ? iconProp : <StyledAddIconWrapper><Add2Icon /></StyledAddIconWrapper>;
    return <StyledWarapper onClick={onClick} variant={variant}>
        <StyledIconWrapper>{icon}</StyledIconWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{children}</StyledDescription>
    </StyledWarapper>
};
import { Box, Typography, Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { TeamSpacesDropdownProps } from './TeamSpacesDropdown.type';
import { StyledArrow, StyledConnect, StyledConnectBtn, StyledDropDown, StyledTeamSpaces, StyledToggleBtn } from './TeamSpacesDropdown.styled';

export const TeamSpacesDropdown = (
    {teamSpaces,
      jiraSpaces,
      asanaSpaces,
      githubSpaces,
      clickUpSpaces,
      trelloSpaces,
      microsoftTeamSpaces,
      confluenceSpaces,
      slackSpaces,
      notionSpaces,
      setNode
    }: TeamSpacesDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [title, setTitle] = useState('All Spaces');
  const titleFromLocalStorage = localStorage.getItem('selectedSpace');
  const history = useHistory();

  const nodes = [
    { id: 1, iconPath: '/icons/confluence.svg', title: 'Confluence', count: confluenceSpaces?.length },
    { id: 2, iconPath: '/icons/icon-asana.png', title: 'Asana', count: asanaSpaces?.length },
    { id: 2, iconPath: '/icons/icon-github.png', title: 'GitHub', count: githubSpaces?.length },
    { id: 2, iconPath: '/icons/icon-click_up.png', title: 'ClickUp', count: clickUpSpaces?.length },
    { id: 2, iconPath: '/icons/icon-trello.png', title: 'Trello', count: trelloSpaces?.length },
    { id: 2, iconPath: '/icons/jira.svg', title: 'Jira', count: jiraSpaces?.length },
    { id: 3, iconPath: '/icons/slack.svg', title: 'Slack', count: slackSpaces?.length },
    { id: 4, iconPath: '/icons/teams.svg', title: 'MicrosoftTeams', count: microsoftTeamSpaces?.length },
    { id: 5, iconPath: '/icons/icon-notion.svg', title: 'Notion', count: notionSpaces?.length },
  ];

  const isEmptySpace = confluenceSpaces?.length || asanaSpaces?.length || githubSpaces?.length || clickUpSpaces?.length || trelloSpaces?.length || jiraSpaces?.length || slackSpaces?.length || microsoftTeamSpaces?.length || notionSpaces?.length;

  const setNodeHandlerClick = (nodeTitle: string) => {
    setNode(nodeTitle);
    if (nodeTitle === 'Sense') {
      setTitle('Team Spaces');
      localStorage.setItem('selectedSpace','Sense');
    } else {
      setTitle(nodeTitle);
      localStorage.setItem('selectedSpace', nodeTitle);
    }
    setAnchorEl(null);
  }

  useEffect(() => {
    if (titleFromLocalStorage === 'Sense') {
      setTitle('Team Spaces')
    } else {
      titleFromLocalStorage && setTitle(titleFromLocalStorage)
    }
  }, [titleFromLocalStorage])
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
};
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <StyledToggleBtn
        role="button"
        aria-controls="space-menu-dropdown"
        aria-haspopup="true"
        onClick={handleButtonClick}
      >
        <Typography component="span" variant="body1" style={{ paddingRight: '7px' }}>
          {title}
        </Typography>
        <StyledArrow
          src="/icons/icon-disclose.svg"
          width="16px"
          height="16px"
          alt=""
          active={open}
        />
      </StyledToggleBtn>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledDropDown>
          <Typography
              onClick={() => {setNodeHandlerClick('All Spaces')}}
              variant="body1"
              style={{ padding: '16px', cursor: 'pointer' }}>
            All spaces
          </Typography>
          <StyledTeamSpaces onClick={() => {setNodeHandlerClick('Sense')}}>
            <Box style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins' }}>
              <img src="/icons/space.svg" alt="space" width="24" height="24" />
              <Typography
                variant="body1"
                style={{
                  cursor: 'pointer',
                  paddingLeft: '12px',
                }}
              >
                Team Spaces
              </Typography>
            </Box>
            <Box component="span" style={{ fontSize: '14px', color: '#636363' }}>
              {teamSpaces?.length}
            </Box>
          </StyledTeamSpaces>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {nodes.map(node => {
              return (
                node.count ? <Box
                    onClick={
                  () => {
                      setNodeHandlerClick(node.title)
                    }}
                    key={node.id}
                    style={{ padding: '16px', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
                  <Box style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins' }}>
                    <img src={node.iconPath} alt="space" width="24" height="24" />
                    {<Typography
                        style={{
                          paddingLeft: '12px',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          color: '#202020',
                          fontWeight: 500,
                        }}
                    >
                      {node.title}
                    </Typography>}
                  </Box>
                  <Box component="span">{node?.count}</Box>
                </Box> : ''
              );
            })}
          </Box>
          {!isEmptySpace && <Box>
            <StyledConnect>Connect apps to import your existing projects or spaces to Sense</StyledConnect>
            <StyledConnectBtn  onClick={() => history.push(`${window.location.pathname}?dialog=manage-integrations`)}>Connect apps</StyledConnectBtn>
          </Box>}
        </StyledDropDown>
      </Popover>
    </>
  );
};

import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)`
    width: 320px;
    height: 48px;
    margin-bottom: 16px;
    border-radius: 6px;
    & .MuiFilledInput-root {
        background: rgb(0,0,0,0.08);
    }
`;
import { FC, useMemo } from "react";
import { PersonLabelProps } from "./PersonLabel.type";
import { StyledUserAvatar, StyledWrapper } from "./PersonLabel.styled";
import { getFullName } from "@/utils/getFullName";

export const PersonLabel: FC<PersonLabelProps> = ({ person, onClick, className, title }) => {
    const fullName = useMemo(() => {
        return  getFullName(person);
    }, [person]);
    return <StyledWrapper className={className} title={title} onClick={onClick}>
        <StyledUserAvatar
            avatar={person.avatar}
            title={fullName}
        />
        {fullName}
    </StyledWrapper>
};
import { DialogLayout } from '@/components/v3/Layouts/DialogLayout/DialogLayout.component';
import { BREAKPOINTS } from '@/hooks/v3/UseResponsive/BreackPoints';
import { styled } from "@mui/material";

export const StyledDialogLayout = styled(DialogLayout)`
    width: 800px;
    height: 680px;
    position: relative;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        width: 100%;
        height: 100%;
    }
`;
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { FC } from "react";
import { Header } from "./components/Header/Header.component";
import { ListOfNodes } from "./components/ListOfNodes/ListOfNodes.component";
import { Tooltip } from "./components/Tooltip/Tooltip.component";

export const ImportantContent: FC = () => {
    return <MainLayout>
    <Header/>
    <Tooltip/>
    <ListOfNodes />
</MainLayout>
};
import { useState } from "react";
import { EditSpaceModalProps } from "./EditSpaceTagModal.type";
import { ToastService } from "@/service/ToastService";
import { UpdateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { StyledCancelButton, StyledInputWrapper } from "./EditSpaceTagModal.styled";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledEmojiIcon, StyledEmojiSelect, StyledInput } from "@/components/v3/Common/EditSpaceForm/EditSpaceForm.styled";

export const EditSpaceTagModal = ({
    spaceId,
    spaceTag,
    value,
    onChange,
    onUpdate
}: EditSpaceModalProps) => {
    const { loadSpaces } = useSpaces();
    const [tagName, setTagName] = useState(spaceTag.name);
    const [emoji, setEmoji] = useState<string | null>(spaceTag.emoji || null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isSpaceNameEmpty = tagName.trim() === '';

    const saveHandler = async () => {
        if (isSpaceNameEmpty) return
        setIsLoading(true)
        await UpdateSpaceTagsFetch(spaceId, spaceTag.id, {
            ...spaceTag,
            emoji: emoji,
            name: tagName,
        }).then(async () => {
            loadSpaces();
            onUpdate();
            onChange(false);
            ToastService.showToast('success', 'Tag renamed!');
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred');
        });
        setIsLoading(false)
    }
    return (
        <Dialog value={value} onChange={onChange} disableEscapeKeyDown>
            <DialogLayout
                title="Edit Tag"
                actions={<>
                    <StyledCancelButton size="large" variant="error" onClick={() => onChange(false)}>
                        Cancel
                    </StyledCancelButton>
                    <Button size="large" onClick={saveHandler}>
                        Save
                    </Button>
                </>}
                isLoading={isLoading}
            >
                <StyledInputWrapper>
                    <StyledEmojiSelect onChange={(e) => {
                        setEmoji(e.native)
                    }} >
                    {!!emoji ? emoji : <StyledEmojiIcon />}
                    </StyledEmojiSelect>
                    <StyledInput label="Tag name" value={tagName} onChange={(e) => setTagName(e.target.value)} />
                </StyledInputWrapper>
            </DialogLayout>
        </Dialog>
    )
}
import { FC } from "react";

import { SpaceContent } from "./Space.content";
import { SpacePageProvider } from "@/providers/v3/SpacePage/SpacePage.provider";
import { SpacePageCommonProvider } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { NodeNeighboursDrawer } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/NodeNeighboursDrawer.component";


export const SpacePage: FC = () => {
    return <SpacePageCommonProvider>
        <SpacePageProvider>
            <SpaceContent />
            <NodeNeighboursDrawer/>
        </SpacePageProvider>
    </SpacePageCommonProvider>
};
import { styled } from "@mui/material";
import { VariantType } from "./MenuItem.type";

const getColor = (variant: VariantType) => {
    return variant === "primary" ? "#202020" : "#F00";
};

export const StyledMenuItem = styled("div", {
    shouldForwardProp: (prop) => prop !== "variant"
})<{variant: VariantType}>`
    padding: 12px 16px;
    color: #202020;
    color: ${({ variant }) => getColor(variant)};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
`;

export const StyledIcon = styled("div", {
    shouldForwardProp: (prop) => prop !== "variant"
})<{variant: VariantType}>`
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
    }
`;
import { FC } from "react";
import { RecentlyUpdated } from "./components/RecentlyUpdated/RecentlyUpdated.component";
import { RecentActivities } from "./components/RecentActivities/RecentActivities.component";
import { StyledWrapper } from "./Updates.styled";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { SuggestedToBeAdded } from "./components/SuggestedToBeAdded/SuggestedToBeAdded.component";
import { KeyResources } from "./components/KeyResources/KeyResources.component";

export const Updates: FC = () => {
    const { update } = useSpacePage();
    return <StyledWrapper>
        <SuggestedToBeAdded />
        <KeyResources onUpdate={update} />
        <RecentlyUpdated />
        <RecentActivities onUpdate={update} />
    </StyledWrapper>
};
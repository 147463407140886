import { TwoFAModalProps } from "./TwoFAModal.type";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import {
    StyledInstruction,
    StyledInstructionItem,
    StyledInstructionItemBold,
    StyledScanQrCodeContainer,
    StyledSetupKey,
    StyledSetupKeyContainer,
    StyledSetupKeyTitle,
    StyledSetupKeyText,
    StyledStep2,
    StyledStep2Wrapper,
    StyledStepTitle,
    StyledStep3Title,
} from "./TwoFAModal.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useEffect, useState } from "react";
import QRCode from "qrcode";
import { OtpVerifyFetch } from "@/axios/OrganizationService/Otp/Otp.api";
import { OtpVerifyAxiosResponseSuccessType } from "@/axios/OrganizationService/Otp/Types/Verify/Verify.type";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Input } from "@/components/v3/Fields/Input/Input.component";

export const TwoFAModal = ({ secret, onClose }: TwoFAModalProps) => {
  const { loadUser } = useMe();
  const [qrcodeUrl, setqrCodeUrl] = useState<string>("");
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')
  useEffect(() => {
      secret.otpAuthURL && QRCode.toDataURL(secret.otpAuthURL).then(setqrCodeUrl);
  }, [secret]);
  useEffect(() => {
      if (code) {
          setError('');
      }
  }, [code]);
  const submitHandle = async () => {
      if (!code) {
          setError('Invalid code');
          return;
      }
      await OtpVerifyFetch({
          code
      }).then(async (res: OtpVerifyAxiosResponseSuccessType) => {
          if (res.data === true) {
              await loadUser();
              onClose();
          } else {
              setError('Invalid code');
          }
      });
  }
  return <Dialog
    value={!!secret.otpAuthURL}
    onChange={() => onClose()}
  >
    <DialogLayout
      title="Enable Two-Factor Authentication"
      onCloseClick={() => onClose()}
      actions={<>
        <Button size="large" onClick={submitHandle}>Submit</Button>
      </>}
    >
      <StyledStepTitle>Step 1. Set up Authenticator</StyledStepTitle>
      <StyledInstruction>
        <StyledInstructionItem>1. Install <StyledInstructionItemBold>Google Authenticator</StyledInstructionItemBold> or <StyledInstructionItemBold>Microsoft Authenticator App</StyledInstructionItemBold> via Appstore or Google Play</StyledInstructionItem>
        <StyledInstructionItem>2. Open the app and press “+” button.</StyledInstructionItem>
        <StyledInstructionItem>3. Select Scan code or Enter a setup key</StyledInstructionItem>
      </StyledInstruction>
      <StyledStep2>
        <StyledStepTitle>Step 2. Scan QR Code</StyledStepTitle>
        <StyledStep2Wrapper>
          <StyledScanQrCodeContainer>
            <img
                src={qrcodeUrl}
                alt="qrcode url"
            />
          </StyledScanQrCodeContainer>
          <StyledSetupKey>
            <StyledSetupKeyTitle>or enter a Setup Key</StyledSetupKeyTitle>
            <StyledSetupKeyContainer>
              <StyledSetupKeyText>
                {secret.secretKey}
              </StyledSetupKeyText>
            </StyledSetupKeyContainer>
          </StyledSetupKey>
        </StyledStep2Wrapper>
      </StyledStep2>
      <StyledStepTitle>Step 3. Enter Validation code</StyledStepTitle>
      <StyledStep3Title>Enter code generated by Authenticator App</StyledStep3Title>
      <Input
        label="Enter authentication code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        error={error}
      />
    </DialogLayout>
  </Dialog>
}
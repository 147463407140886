import {useEffect} from "react";
import './App.css';
import { CssBaseline } from '@mui/material';
import { LayoutResolver } from './Layouts/LayoutResolver/LayoutResolver.component';
import { ErrorBoundary } from './components/ErrorBoundary';
import FreshChat from 'react-freshchat';
import { NodeProvider } from 'providers/v3/NodeProvider/Node.provider';
import { useMe } from "./providers/MeProvider/Me.provider";
import { AddToSpaceProvider } from 'providers/AddToSpace/AddToSpace.provider';

const App = () => {
  const { user } = useMe()
  useEffect(() => {
    if (user && user.email) {
      (window as any).smartlook('identify', user.id, {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email
      });
    }
  }, [user]);

  return (
    <>
      {user && !!user.email.length && <FreshChat
        token={'b47fc581-b57c-4723-b6a6-72d172842e75'}
        host={'https://wchat.eu.freshchat.com'}
        externalId={user?.id}
        firstName={user?.firstName}
        lastName={user?.lastName}
        email={user?.email}
      />}
      <CssBaseline />
      <ErrorBoundary>
        <NodeProvider>
          <AddToSpaceProvider>
            <LayoutResolver />
          </AddToSpaceProvider>
        </NodeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { HttpClient } from '../../axios/HttpClient';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { envUrl } from "../../service/helpers";
import { CheckoutSession, PaymentInterval } from 'components/Payments';
import { useMe } from 'providers/MeProvider/Me.provider';
import { StyledButton, StyledFeatureBox, StyledLineButtons } from './Payment.styled';
import { ToastService } from '@/service/ToastService';

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
});

export const Payment = () => {
  const classes = useStyles();

  const { user } = useMe();
  const userId = user?.id;
  const [paymentInterval, setPaymentInterval] = useState('YEARLY');
  const [pricePerSeat, setPricePerSeat] = React.useState<number | 8>(8);
  const isTeam = user?.paymentPlan.name === 'TEAM'

  const getRedirectLinkHandlerClick = () => {
    HttpClient.get<CheckoutSession>(`${envUrl}/organization-service/v1/payment?userId=${userId}&paymentInterval=${paymentInterval}`)
      .then(response => response.data.checkoutSessionUrl)
      .then(data => {
        window.location.href = data
      });
  }

  // select
  const [type, setType] = React.useState<PaymentInterval | 'yearly'>('yearly');

  const [amountOfPaidUsers, setAmountOfPaidUsers] = useState<number>(1);
  const [priceFactor, setPricefactor] = useState<number | 12>(12);

  const handleChange = (event: SelectChangeEvent<PaymentInterval | 'yearly'>) => {
    const paymentIntervalSelected = event.target.value;
    setType(paymentIntervalSelected as PaymentInterval | 'yearly');
    setPaymentInterval(paymentIntervalSelected.toUpperCase());
    if (paymentIntervalSelected === 'yearly') {
      setPricePerSeat(8)
      setPricefactor(12)
    }
    if (paymentIntervalSelected === 'monthly') {
      setPricePerSeat(9)
      setPricefactor(1)
    }
  };

  useEffect(() => {
    HttpClient.get(`${envUrl}/organization-service/v1/users/invites`, {
      params: {
        organizationId: user?.organizationId ?? '',
      }
    }).then(response => response.data)
      .then(data => {
        setAmountOfPaidUsers(data.users.length + data.emails.length + 1);
      })
      .catch(error => {
        ToastService.showToast('error', 'Some error occurred!');
      });
  }, [user?.organizationId]);

  return (
    <Box style={{ width: '100%' }}>
      {!isTeam && <Box style={{ marginTop: '40px' }}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="subscription-plan-label">Subscription plan</InputLabel>
          <Select label="Subscription plan" labelId="subscription-plan-label" id="subscription-plan" value="team">
            <MenuItem value="team">Team</MenuItem>
          </Select>
        </FormControl>
      </Box>}
      <Box>
        <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: '28px' }}>
          <InputLabel id="subscription-plan-label">Billing period</InputLabel>
          <Select label="Billing period"
            labelId="billing-plan-label"
            id="billing-period"
            value={type}
            onChange={handleChange}
          >
            <MenuItem value="yearly">Yearly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <StyledFeatureBox style={{ marginTop: '28px' }}>
        <Box>Seat price</Box>
        <Box>${pricePerSeat}</Box>
      </StyledFeatureBox>
      <StyledFeatureBox style={{ marginTop: '28px' }}>
        <Box>Seats</Box>
        <Box>{amountOfPaidUsers}</Box>
      </StyledFeatureBox>
      <StyledFeatureBox style={{ marginTop: '28px' }}>
        <Box>Total</Box>
        <Box>${amountOfPaidUsers * pricePerSeat * priceFactor}</Box>
      </StyledFeatureBox>
      <Divider />
      <Box style={{ color: '#636363', marginBottom: '12px', marginTop: '28px' }}>Plan details</Box>
      <StyledFeatureBox>
        <Box>Available features</Box>
        <Box>All</Box>
      </StyledFeatureBox>
      <StyledFeatureBox>
        <Box>Users to be invited</Box>
        <Box>Unlimited</Box>
      </StyledFeatureBox>
      <StyledFeatureBox style={{ marginBottom: '56px' }}>
        <Box>Included integration</Box>
        <Box>Unlimited</Box>
      </StyledFeatureBox>
      <StyledLineButtons>
        <StyledButton
          onClick={getRedirectLinkHandlerClick}
        >
          <span>Proceed to payment</span>
        </StyledButton>
      </StyledLineButtons>
    </Box>
  );
};

import { FC } from "react";
import { StyledPro, StyledWrapper } from "./User.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { AppMenu } from "./components/AppMenu/AppMenu.component";

export const User: FC = () => {
    const { user } = useMe();
    const isPro = user && user.paymentPlan.name === 'PRO';
    return <StyledWrapper>
        {isPro && <StyledPro>PRO</StyledPro>}
        <AppMenu />
    </StyledWrapper>
};
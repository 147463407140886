import { IconProps } from "./icon.type";

export const ImportantForIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M7.60691 7.00756C5.80778 7.00756 4.34881 5.41253 4.34125 3.46976C4.34125 1.54968 5.81533 0 7.60691 0C9.41361 0 10.8726 1.51944 10.8726 3.45464C10.8726 5.40497 9.40605 7.00756 7.60691 7.00756ZM7.60691 5.86609C8.71814 5.86609 9.65551 4.79266 9.65551 3.45464C9.65551 2.13931 8.73326 1.14147 7.60691 1.14147C6.48812 1.14147 5.55832 2.15443 5.55832 3.46976C5.55832 4.80778 6.50324 5.86609 7.60691 5.86609ZM2.85205 14C1.59719 14 1 13.622 1 12.7905C1 10.8099 3.50216 7.94492 7.60691 7.94492C11.7041 7.94492 14.2063 10.8099 14.2063 12.7905C14.2063 13.622 13.6091 14 12.3542 14H2.85205ZM2.49676 12.8585H12.7171C12.9136 12.8585 12.9892 12.8056 12.9892 12.6469C12.9892 11.4071 11.0691 9.08639 7.60691 9.08639C4.13715 9.08639 2.21706 11.4071 2.21706 12.6469C2.21706 12.8056 2.30022 12.8585 2.49676 12.8585Z" fill="#202020"/>
      <circle cx="12" cy="12" r="4" fill="#202020"/>
      <path d="M12 9L12.8817 10.7865L14.8532 11.0729L13.4266 12.4635L13.7634 14.4271L12 13.5L10.2366 14.4271L10.5734 12.4635L9.14683 11.0729L11.1183 10.7865L12 9Z" fill="white"/>
    </svg>
  );
};

import { Button } from "@/components/v3/Fields/Button/Button.component";
import { styled } from "@mui/material";

export const StyledDescription = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
export const StyledLinkWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
`
export const StyledActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 12px;
`
export const StyledCreateButton = styled(Button)`
    margin-left: 16px;
`
import { AppIcon } from '@/components/v3/Other/AppIcon/AppIcon.component';
import { BREAKPOINTS } from '@/hooks/v3/UseResponsive/BreackPoints';
import { styled } from '@mui/material';

export const StyledWrapper = styled('div')`
    padding: 24px 24px 0px 24px;
`
export const StyledContent = styled('div')`
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed rgba(0, 0, 0, 0.16);
    transition: all 0.1s ease-in-out;
    &:hover {
        cursor: pointer;
        border: 2px dashed #202020;
    }
`
export const StyledTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
`
export const StyledDescription = styled('div')`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
`
export const StyledFooter = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    padding: 12px 0px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        display: block;
    }
`
export const StyledAppsWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        display: block;
    }
`
export const StyledAppsTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 12px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        margin-left: 0;
        margin-top: 12px;
        margin-bottom: 12px;
    }
`
export const StyledAppIconsWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;
`
export const StyledAppIconWrapper = styled('div')`
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 50%;
    border: 2px solid #EBEBEB;
    background: #FFF;
    margin-left: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledAppIcon = styled(AppIcon)`
    width: 24px;
    height: 24px;
`
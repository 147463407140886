import { useRouter } from '@/providers/Router/Router.provider';

export const useIsShared = (): {
    isSharedSpace: boolean;
    isSharedNode: boolean;
    isShared: boolean;
} => {
    const router = useRouter();
    const isSharedSpace = router.type.includes('public') && (router.name === 'publicSpace' || router.name === 'publicSpaceCategory'  || router.name === 'publicSpaceTag');
    const isSharedNode = router.type.includes('public') && router.name === 'publicNode';
    return { isSharedSpace, isSharedNode, isShared: isSharedSpace || isSharedNode};
}

import { FC, memo, useMemo, useState } from 'react';
import {
  IconButton,
  Typography,
} from '@mui/material';
import { HttpClient } from "@/axios/HttpClient";
import { ToastService } from 'service/ToastService';
import { envUrl } from "../../service/helpers";
import { NodesForSpaceService } from "../../service/SpaceService";
import { useModal } from "../../store/ModalContext";
import { NodeMenuProps } from './NodeMenu.type';
import { StyledMenu, StyledMenuItem } from './NodeMenu.styled';
import { useAddToSpace } from 'providers/AddToSpace/AddToSpace.provider';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { DeleteNodesFetch } from '@/axios/AiService/SpacesNodes/SpacesNodes.api';
import { useRouter } from '@/providers/Router/Router.provider';
import { useConfirm } from '@/hooks/UseConfirm/UseConfirm.hook';
import { GetSpaceTagsFetch, UpdateSpaceTagsFetch } from '@/axios/AiService/SpaceTags/SpaceTags.api';
import { SpaceTagsAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/Get/Get.type';
import { UpdateSpaceTagsAxiosResponseSuccessType } from '@/axios/AiService/SpaceTags/Types/Put/Put.type';
import { ImportantForDialog } from './components/ImportantForDialog/ImportantForDialog.component';
import useCopyToClipboard from '@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook';
import { AddCommentDialog } from './components/AddCommentDialog/AddCommentDialog.component';
import { CreateSpaceKeyResourcesFetch, DeleteSpaceKeyResourcesFetch, DeleteSpaceKeyResourcesSuggestionsFetch } from '@/axios/AiService/Spaces/Spaces.api';
import { ShareNodeDialog } from '@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/components/DrawerContent/components/ShareButton/components/ShareNodeDialog/ShareNodeDialog.component';
import { useSpacePageCommon } from '@/providers/v3/SpacePageCommon/SpacePageCommon.provider';

export const NodeMenu: FC<NodeMenuProps> = memo(({ node, spaceId: spaceIdProp, onUpdate, showCopy, onNewComment }) => {
  const { confirm, component } = useConfirm();

  const router = useRouter();
  const spaceIdParam = router.params.spaceId as string | undefined;
  const spaceTagId = router.params.tagId as string | undefined;
  
  const { followedNodes, follow, unfollow } = useMe();
  const {
    listOfKeyResources,
    listOfKeyResourcesSuggestions
  } = useSpacePageCommon();
  const showKeyResources = useMemo(() => {
    return router.name === 'space' || router.name === 'spaceCategory' || router.name === 'spaceTag';
  }, [router.name]);
  const isKeyResource = useMemo(() => {
    return listOfKeyResources.map((keyResource) => keyResource.node.id).includes(node.id);
  }, [listOfKeyResources, node.id]);
  const isKeyResourceSuggestion = useMemo(() => {
    return listOfKeyResourcesSuggestions.map((keyResource) => keyResource.node.id).includes(node?.id);
  }, [listOfKeyResourcesSuggestions, node?.id]);
  const isFollowed = useMemo(() => {
    return !!followedNodes.find((followedNode) => followedNode.id === node?.id);
  }, [followedNodes, node]);
  const { addToSpace } = useAddToSpace();
  const { id, name } = node;
  const spaceId = spaceIdProp || spaceIdParam;
  const [isOpenedImportantUsersDialog, setIsOpenedImportantUsersDialog] = useState<boolean>(false);
  const [isOpenedAddCommentDialog, setIsOpenedAddCommentDialog] = useState<boolean>(false);
  const [isOpenedShareDialog, setIsOpenedShareDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { openRenameNodeModal, setNodeData } = useModal();

  const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addToSpaceHandler = () => {
    addToSpace(id);
    handleClose();
  };

  const renameNodeHandler = () => {
    setNodeData({
      name,
      nodeId: id
    })
    openRenameNodeModal()
    handleClose();
  }
  const removeFromTagHandler = () => {
    confirm(async (newVal) => {
      if (newVal) {
        if (!spaceId || !spaceTagId) {
          return;
        }
        await GetSpaceTagsFetch(spaceId).then(async (res: SpaceTagsAxiosResponseSuccessType) => {
          const spaceTag = res.data.find((spaceTag) => spaceTag.id === spaceTagId);
          if (!spaceTag) {
            console.error('Tag not found');
            return;
          }
          await UpdateSpaceTagsFetch(spaceId, spaceTagId, {
            ...spaceTag,
            emoji: spaceTag.emoji || null,
            nodeIds: spaceTag.nodeIds.filter((nodeId) => nodeId !== id),
          }).then(async (res: UpdateSpaceTagsAxiosResponseSuccessType) => {
              onUpdate && onUpdate()
              ToastService.showToast('success', '1 resources was removed from the space!');
            })
            .catch(error => {
              ToastService.showToast('error', 'Some error occurred! Please try again later!');
            });
        })
        .catch(error => {
          ToastService.showToast('error', 'Some error occurred! Please try again later!');
        });
      }
    })
  };
  const removeFromSpaceHandler = () => {
    confirm(async (newVal) => {
      if (newVal) {
        if (!spaceId) {
          return;
        }
        await DeleteNodesFetch({
          spaceId,
          nodeIds: [id],
        }).then(() => {
          ToastService.showToast('success', '1 resources was removed from the space!');
          NodesForSpaceService.fetch(true, {}, `${spaceId}/nodes`);
          HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/nodes`).then(response => response.data)
            .then(data => {
              onUpdate && onUpdate()
            })
            .catch(error => {
              ToastService.showToast('error', 'Some error occurred!');
            });
        })
        .catch(error => {
          ToastService.showToast('error', 'Some error occurred! Please try again later!');
        });
      }
    })
  };
  const { copyToClipboard } = useCopyToClipboard();
  const copyHandle = () => {
    copyToClipboard(node.originalURI, 'Link copied to clipboard');
  };
  if (router.type.includes('public')) {
    return null;
  }
  const removeFromKeyResourcesSuggestion = () => {
    !!spaceId && DeleteSpaceKeyResourcesSuggestionsFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was removed!');
      onUpdate && onUpdate();
    })
  };
  const removeFromKeyResources = () => {
    !!spaceId && DeleteSpaceKeyResourcesFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was removed!');
      onUpdate && onUpdate()
    })
  };
  const addToKeyResources = () => {
    !!spaceId && CreateSpaceKeyResourcesFetch(spaceId, {
      nodeId: node.id,
    }).then(() => {
      ToastService.showToast('success', '1 key resource was added!');
      onUpdate && onUpdate()
    })
  };
  return (
    <>
      <IconButton aria-controls="customized-menu" aria-haspopup="true" onClick={menuClickHandler}>
        <img src="/icons/three_dots.svg" alt="" width="16px" height="16px" />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        disableScrollLock
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={renameNodeHandler}>
          <Typography variant="button">Rename...</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
          setIsOpenedShareDialog(true);
          handleClose();
        }}>
          <Typography variant="button">Share...</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
          setIsOpenedAddCommentDialog(true);
          handleClose();
        }}>
          <Typography variant="button">Add comment...</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={addToSpaceHandler}>
          <Typography variant="button">Add to...</Typography>
        </StyledMenuItem>
        {!!spaceId && <StyledMenuItem onClick={removeFromSpaceHandler}>
          <Typography variant="button">Remove from space</Typography>
        </StyledMenuItem>}
        {!!spaceTagId && <StyledMenuItem onClick={removeFromTagHandler}>
          <Typography variant="button">Remove from tag</Typography>
        </StyledMenuItem>}
        {showKeyResources && <>
          {isKeyResourceSuggestion && <StyledMenuItem onClick={() => {
            removeFromKeyResourcesSuggestion();
            handleClose();
          }}>
            <Typography variant="button">Remove from Key resources</Typography>
          </StyledMenuItem>}

          {!isKeyResourceSuggestion && <>
            {!isKeyResource && <StyledMenuItem onClick={() => {
              addToKeyResources();
              handleClose();
            }}>
              <Typography variant="button">Add to Key resources</Typography>
            </StyledMenuItem>}
            {isKeyResource && <StyledMenuItem onClick={() => {
              removeFromKeyResources();
              handleClose();
            }}>
              <Typography variant="button">Remove from Key resources</Typography>
            </StyledMenuItem>}
          </>}
        </>}
        {isFollowed && <StyledMenuItem onClick={() => {
          unfollow(node.id);
          handleClose();
        }}>
          <Typography variant="button">Not important for me</Typography>
        </StyledMenuItem>}
        {!isFollowed && <StyledMenuItem onClick={() => {
          follow(node.id);
          handleClose();
        }}>
          <Typography variant="button">Important for me</Typography>
        </StyledMenuItem>}
        <StyledMenuItem onClick={() => {
          setIsOpenedImportantUsersDialog(true);
          handleClose();
        }}>
          <Typography variant="button">Important for...</Typography>
        </StyledMenuItem>
        {showCopy && <StyledMenuItem onClick={() => {
          copyHandle();
          handleClose();
        }}>
          <Typography variant="button">Copy link</Typography>
        </StyledMenuItem>}
      </StyledMenu>
      {component}
      <ImportantForDialog nodeId={node.id} value={isOpenedImportantUsersDialog} onChange={setIsOpenedImportantUsersDialog} />
      <AddCommentDialog nodeId={node.id} value={isOpenedAddCommentDialog} onChange={setIsOpenedAddCommentDialog} onNewComment={onNewComment} />
      <ShareNodeDialog node={node} isOpened={isOpenedShareDialog} setIsOpened={setIsOpenedShareDialog} />
    </>
  );
});

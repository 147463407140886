import { Person } from "./components/Person/Person.components";
import { StyledWrapper } from "./ToolpipContent.styled";
import { ToolpipContentProps } from "./ToolpipContent.type";

export const ToolpipContent = ({ people }: ToolpipContentProps) => {
    const peopleJsx = people.map((person, i) => {
        return <Person key={i} user={person.user} actionType={person.actionType} />
    })
    return (
        <StyledWrapper>
            {peopleJsx}
        </StyledWrapper>
    )
}
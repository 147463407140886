import { FC } from "react";
import { SpaceCardProps } from "./SpaceCard.type";
import { StyledApplication, StyledContentWrapper, StyledImageWrapper, StyledName, StyledWrapper } from "./SpaceCard.styled";
import { getLinkName } from "@/utils/v3/GetLinkName/GetLinkName.util";
import { useRouter } from "@/providers/Router/Router.provider";
import { SpaceIcon } from "../../Other/SpaceIcon/SpaceIcon.component";

export const SpaceCard: FC<SpaceCardProps> = ({ space, className }) => {
    const name = getLinkName(space.name);
    const router = useRouter();
    const onClickHandler = () => {
        router.push({
            name: 'space',
            params: { spaceId: space.id }
        })
    };
    return <StyledWrapper className={`${className} disable-node-drawer-close`} onClick={onClickHandler}>
        <StyledImageWrapper>
            <SpaceIcon space={space} />
        </StyledImageWrapper>
        <StyledContentWrapper>
            <StyledName>{name}</StyledName>
            <StyledApplication>{space.application}</StyledApplication>
        </StyledContentWrapper>
    </StyledWrapper>
};
import { FC, useMemo } from "react";
import { NodeProps } from "./Node.type";
import {
    StyledNodeContainer,
    StyledNodeContent,
    StyledNodeContentName,
    StyledNodeIcon,
    StyledNodeWrapper,
    StyledTextContent,
    StyledPeople,
    StyledNodeContentNote
} from "../../../Node/Node.styled";
import { getLinkName } from "@/utils/v3/GetLinkName/GetLinkName.util";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Actions } from "../../../Node/components/Actions/Actions.component";
import { StyledStyledNodeContainer, StyledStyledNodeContent } from "./Node.styled";
import { NodeTypeType } from "@/types/Node/Node.type";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

export const Node: FC<NodeProps> = (props) => {
    const {
        node,
        className,
        relationships
    } = props;
    const { setActiveNodeId } = useNode();
    const isMobile = useRenederBelow('tablet');
    const size = 'large';
    const name = getLinkName(node.name);
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const menuElements = document.querySelectorAll(".MuiMenu-root");
        for (let i = 0; i < menuElements.length; i++) {
            if (menuElements[i].contains(e.target as Node)) {
                return;
            }
        }
        const dialogElements = document.querySelectorAll(".MuiDialog-root");
        for (let i = 0; i < dialogElements.length; i++) {
            if (dialogElements[i].contains(e.target as Node)) {
                return;
            }
        }
        const confirmationElement = document.getElementById("confirmation-dialog");
        if (confirmationElement && confirmationElement.contains(e.target as Node)) {
            return;
        }
        if ((e.target as Element).closest('.ignore--node-actions')) {
            return;
        }
        setActiveNodeId(node.id)
    };
    const people = useMemo(() => {
        if (!relationships) {
            return []
        }
        return relationships.actors
            .filter(actor => !!actor.user && !!actor.user.avatar)
            .map(actor => {
                const user = actor.user!;
                return {
                    user,
                    actionType: actor.actionType,
                };
            });
    }, [relationships]);
    const isNote = node.nodeType === NodeTypeType.NOTE;
    return <StyledNodeWrapper size={size} className={`${className} disable-node-drawer-close`} onClick={onClick}>
        <StyledStyledNodeContainer size={size} isNote={isNote} >
            <StyledStyledNodeContent>
                {!isNote && <StyledNodeIcon node={node} />}
                <StyledTextContent>
                    {!isNote && <StyledNodeContentName>{name}</StyledNodeContentName>}
                    {isNote && <StyledNodeContentNote>
                        {node.content && parse(sanitizeHtml(node.content, {
                            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                            allowedAttributes: {
                                ...sanitizeHtml.defaults.allowedAttributes,
                                'a': ['href', 'title', 'target']
                            }
                        }))}    
                    </StyledNodeContentNote>}
                    
                    {size==='large' && isMobile && <StyledPeople people={people} />}
                </StyledTextContent>
            </StyledStyledNodeContent>
            <Actions {...props} people={people}  />
        </StyledStyledNodeContainer>
    </StyledNodeWrapper>
}
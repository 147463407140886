import { Typography, Box } from "@mui/material";
import { PlanProps } from "./Plan.type";
import {
    StyledContainer,
    StyledPlanItem,
    StyledPrice,
    StyledTitleContainer
} from "./Plan.styled";

export const Plan = ({users, features, integrations, name, price, selectedValue, value, onChange }: PlanProps ) => {
    const checked = value === selectedValue;
    const isTeam = name === 'Team'
    return (
        <StyledContainer onClick={() => onChange(value)} checked={checked}>
            <StyledTitleContainer>
                <Typography variant='subtitle2' >{name}</Typography>
                <StyledPrice variant='h5'>{price}
                    {
                        isTeam && <>
                              <span
                                  style={{color: '#636363', fontSize: 14, fontWeight: 400}}>/month per seat paying annually</span>
                            <Box style={{color: '#636363', fontSize: 14, fontWeight: 400}}>$9/month per seat paying monthly</Box>
                        </>
                      }
                </StyledPrice>
            </StyledTitleContainer>
            <StyledPlanItem>
                <Typography style={{fontWeight: 400}} variant='body1'>Users</Typography>
                <Typography variant='body1'>{users}</Typography>
            </StyledPlanItem>
            <StyledPlanItem>
                <Typography style={{fontWeight: 400}} variant='body1'>Available features</Typography>
                <Typography variant='body1'>{features}</Typography>
            </StyledPlanItem>
            <StyledPlanItem>
                <Typography style={{fontWeight: 400}} variant='body1'>Included integrations</Typography>
                <Typography variant='body1'>{integrations}</Typography>
            </StyledPlanItem>
        </StyledContainer>
    )
}
import { FC } from "react";
import { MenuProps } from "./Menu.type";
import { Menu as MenuComponent } from "@/components/v3/Other/Menu/Menu.component";
import { MenuItem } from "@/components/v3/Other/MenuItem/MenuItem.component";
import { EditIcon } from "@/icons/v3/edit.icon";
import { DeleteIcon } from "@/icons/v3/delete.icon";

export const Menu: FC<MenuProps> = ({
    onEdit,
    onDelete
}) => {
    return <MenuComponent>
        <MenuItem icon={<EditIcon />} onClick={onEdit}>Edit</MenuItem>
        <MenuItem icon={<DeleteIcon />} onClick={onDelete} variant="error">Delete</MenuItem>
    </MenuComponent>
};
import { WalkthroughWrapper } from 'providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component';
import { StyledEndIconWrapper, StyledIconsWrapper, StyledSearchIcon, StyledSearchIconWrapper } from './SearchTextField.styled';
import { SearchTextFieldProps } from './SearchTextField.type';
import { EndIcon1, EndIcon2, EndIcon3 } from './components/EndIcons/EndIcons.components';
import { useRenederAbove } from '@/hooks/v3/UseResponsive/UseResponsive.hook';
import { Input } from '@/components/v3/Fields/Input/Input.component';

export const SearchTextField = ({ type, value, onChange, error, ...rest }: SearchTextFieldProps) => {
  const shoudRenederIcons = useRenederAbove('tablet') && !value;
  return (
    <WalkthroughWrapper
      variant="walkthrough-search"
      title="Search across all your apps"
      description="Your can find any resource by name, app, people, type. "
      targetId="walkthrough-search"
      position="bottom"
      maxWidth={320}
      topMargin={-12}
    >
      <Input
        id="walkthrough-search"
        placeholder="Search in Sense"
        variant="filled"
        size='medium'
        onChange={onChange}
        value={value}
        InputProps={{
          startAdornment: (
            <StyledSearchIconWrapper>
              <StyledSearchIcon />
            </StyledSearchIconWrapper>
          ),
          endAdornment: <>
            {shoudRenederIcons && (
              <StyledIconsWrapper>
                <StyledEndIconWrapper><EndIcon1 /></StyledEndIconWrapper>
                <StyledEndIconWrapper><EndIcon2 /></StyledEndIconWrapper>
                <StyledEndIconWrapper><EndIcon3 /></StyledEndIconWrapper>
              </StyledIconsWrapper>
            )}
          </>,
        }}
        {...rest}
      />
    </WalkthroughWrapper>

  );
};

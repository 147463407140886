import { FC } from "react";
import { BackgroundProps } from "./Backgroundtype";
import { Hour } from "./components/Hour/Hour.component";

export const Background: FC<BackgroundProps> = ({ startHour }) => {
    const hours = Array.from({ length: 24 - startHour }).map((_, i) => i + startHour);
    return (
        <div>
            {hours.map(hour => (
                <Hour key={hour} hour={hour} />
            ))}
        </div>
    )
}
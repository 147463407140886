import { styled } from "@mui/material";

export const StyledSectionHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;
export const StyledSectionTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
export const StyledSectionCaption = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
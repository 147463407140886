import { styled } from "@mui/material";
import { TagIcon as TagDarkIcon } from "@/icons/tag-dark.icon";
import { TagIcon as TagWhiteIcon } from "@/icons/tag-white.icon";

export const StyledSpaceTagContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'isActive'
})<{isActive: boolean}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 8px 56px;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;
    background-color: ${props => props.isActive ? 'rgba(0,0,0,.06);' : 'tarnsparant;'};
    &:hover {
        background: rgba(0,0,0,.06);
    }
`
export const StyledSpaceTagText = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 12px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
`
export const StyledDarkTagIcon = styled(TagDarkIcon)`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
`
export const StyledWhiteTagIcon = styled(TagWhiteIcon)`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
`
export const StyledEmojiWrapper = styled('div')`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledEmoji = styled('div')`
    padding-top: 2px;
    font-size: 16px;
`;
import { FC } from "react";
import { PublicSpaceProps } from "./PublicSpace.type";
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { SpaceCategory } from './components/SpaceCategory/SpaceCategory.component';
import { useRouter } from "@/providers/Router/Router.provider";
import {
  Box,
  Collapse,
  List,
} from '@mui/material';
import { getIconPath } from "../Space/Space.utils";
import { SpaceTag } from "./components/SpaceTag/SpaceTag.component";
import { StyledListItemButton } from "../Space/Space.styled";

export const PublicSpace: FC<PublicSpaceProps> = ({ space, categories, tags }) => {
  const router = useRouter();
  const isSpaceItemOpen = true;
  const isNativeSpace = space.application==='Sense';
  const listOfSpaceCategoriesJsx = categories.map((category, i) => {
    return <SpaceCategory category={category} key={i} />
  })
  const listOfSpaceTagsJsx = tags.map((tag, i) => {
    return <SpaceTag tag={tag} key={i} />
  })
  return <>
    <StyledListItemButton
      onClick={() => {
        router.push({
          name: 'publicSpace',
          params: {
            code: router.params.code as string,
          }
        })
      }}
      selected={router.name==='publicSpace' && isSpaceItemOpen}
      id="walkthrough-spaces"
    >
      {isSpaceItemOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      <img src={getIconPath(space.application, isSpaceItemOpen)} alt="" />
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {space.name}
      </Box>
    </StyledListItemButton>
    <Collapse in={isSpaceItemOpen} timeout="auto" unmountOnExit>
      <List component="nav" disablePadding>
        {!isNativeSpace && listOfSpaceCategoriesJsx}
        {isNativeSpace && listOfSpaceTagsJsx}
      </List>
    </Collapse>
  </>
}
import { truncateString } from "./truncateString.util";

export function cutLinks(inputHtml: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(inputHtml, 'text/html');

    const links = doc.querySelectorAll('a');
    links.forEach(link => {
        const originalText = link.textContent || '';
        link.textContent = truncateString(originalText, 50);
        link.setAttribute('title', originalText);
        link.setAttribute('target', '_blank');
        link.classList.add('ignore--node-actions');
    });

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
}

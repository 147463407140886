import { styled } from "@mui/material";

export const StyledContent = styled('div')`
    position: relative;
`;
export const StyledWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'transparent'
})<{transparent: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    ${({transparent}) => transparent ? `
        background: rgba(255,255,255,0.7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    ` : `
        background: #fff;
    `}
`;
import { styled } from "@mui/material";

export const StyledTitle = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
`;
export const StyledDescription = styled("div")`
    color: #636363;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 4px;
`;
export const StyledAppsWrapper = styled("div")`
    margin-top: 24px;
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed rgba(0, 0, 0, 0.16);
`;
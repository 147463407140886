import { FC } from "react";
import { RecentSpaces } from "./components/RecentSpaces/RecentSpaces.component";
import { StyledWrapper } from "./Updates.styled";
import { RecentUpdates } from "./components/RecentUpdates/RecentUpdates.component";
import { RecentNotes } from "./components/RecentNotes/RecentNotes.component";
import { Important } from "./components/Important/Important.component";

export const Updates: FC = () => {
    return <StyledWrapper>
        <Important />
        <RecentNotes />
        <RecentSpaces />
        <RecentUpdates />
    </StyledWrapper>
};
export const getAppIcon = (source: string | undefined) => {
    if (source === 'SLACK') {
        return '/icons/icon-slack.png'
    } else if (source === 'ASANA') {
        return '/icons/icon-asana.png'
    } else if (source === 'GITHUB') {
        return '/icons/icon-github.png'
    } else if (source === 'CLICKUP') {
        return '/icons/icon-click_up.png'
    } else if (source === 'TRELLO') {
        return '/icons/icon-trello.png'
    } else if (source === 'FIGMA') {
        return '/icons/icon-figma.png'
    } else if (source === 'JIRA') {
        return '/icons/icon-jira.png'
    }

}
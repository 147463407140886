import { SvgIcon, SvgIconProps } from '@mui/material';

export const SenseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect width="40" height="40" rx="10" fill="#202020"/>
      <path d="M16.0868 16.3679C17.8226 16.3679 19.281 14.93 19.281 13.1942C19.281 11.4585 17.8226 10 16.0868 10C14.3511 10 12.9132 11.4585 12.9132 13.1942C12.9132 14.8992 14.3408 16.3679 16.0868 16.3679ZM23.9132 16.3679C25.6489 16.3679 27.0868 14.93 27.0868 13.1942C27.0868 11.4585 25.6489 10 23.9132 10C22.1774 10 20.7292 11.4585 20.7292 13.1942C20.7292 14.93 22.1774 16.3679 23.9132 16.3679ZM20 23.3623C21.7358 23.3623 23.1737 21.9244 23.1737 20.1886C23.1737 18.4528 21.7358 17.0149 20 17.0149C18.2642 17.0149 16.8263 18.4528 16.8263 20.1886C16.8263 21.9244 18.2642 23.3623 20 23.3623ZM12.1839 23.3623C13.93 23.3623 15.3782 21.9244 15.3782 20.1886C15.3782 18.4528 13.93 17.0149 12.1839 17.0149C10.4482 17.0149 9 18.4528 9 20.1886C9 21.9141 10.4276 23.3623 12.1839 23.3623ZM27.8161 23.3623C29.5518 23.3623 31 21.9244 31 20.1886C31 18.4528 29.5518 17.0149 27.8161 17.0149C26.0803 17.0149 24.6321 18.4528 24.6321 20.1886C24.6321 21.9244 26.0803 23.3623 27.8161 23.3623ZM16.0868 30.3772C17.8226 30.3772 19.281 28.9188 19.281 27.183C19.281 25.4472 17.8226 24.0093 16.0868 24.0093C14.3511 24.0093 12.9132 25.4472 12.9132 27.183C12.9132 28.9085 14.3408 30.3772 16.0868 30.3772ZM23.9132 30.3772C25.6489 30.3772 27.0868 28.9188 27.0868 27.183C27.0868 25.4472 25.6489 24.0093 23.9132 24.0093C22.1774 24.0093 20.7292 25.4472 20.7292 27.183C20.7292 28.9188 22.1774 30.3772 23.9132 30.3772Z" fill="white"/>
    </SvgIcon>
  );
};

import { FC } from "react";
import { PersonProps } from "./Person.type";
import { StyledPersonLabel } from "./Person.styled";
import { actorActionToString } from "@/utils/ActorActionToString/ActorActionToString.util";
import { useRouter } from "@/providers/Router/Router.provider";

export const Person: FC<PersonProps> = ({
    actor
}) => {
    const action = actorActionToString(actor.actionType);
    const router = useRouter();
    return (
        <StyledPersonLabel person={actor.user || {}} title={action} onClick={() => router.push({name: 'commonRelations', params: {fromUserId: actor.userId}})} />
    )
};
import { useEffect } from 'react';
import { HttpClient } from '../axios/HttpClient';
import { envUrl } from "../service/helpers";
import { useMe } from 'providers/MeProvider/Me.provider';
import { useIsShared } from '@/hooks/v3/UseIsShared/UseIsShared.hook';
import { EventTypes, useAmplitude } from '@/service/TrackingService';

export const TrackRecentVisit = () => {
  const { user } = useMe();
  const { isSharedSpace, isSharedNode } = useIsShared();
  const trackEvent = useAmplitude();
  useEffect(() => {
    !isSharedSpace && !isSharedNode && HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
      lastLogin: new Date().getTime(),
    }).then(() => {
      trackEvent(EventTypes.LAST_LOGIN);
    });
  }, [isSharedSpace, isSharedNode, user?.id, user?.organizationId, trackEvent]);

  return <></>;
};

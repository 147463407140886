const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const getFullName = (user: {
    firstName?: string;
    lastName?: string;
}) => {
    const firstName = user.firstName ? capitalizeFirstLetter(user.firstName) : "";
    const lastName = user.lastName ? capitalizeFirstLetter(user.lastName) : "";

    return firstName && lastName
        ? `${firstName} ${lastName}`
        : firstName || lastName || "Someone";
}
import { Box, styled } from "@mui/material";

export const StyledLineButtons = styled(Box)`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
`;
export const StyledButton = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 200px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0px 8px 0px 8px;
    font-weight: 500;
    height: 32px;
    align-items: center;
    color: #FFFFFF;
    background-color: #202020;
    & img {
        width: 16px;
        height: 16px;
    }
    & span {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
        text-align: center;
        flex-grow: 1;
    }
`;
export const StyledFeatureBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`;
import { FC } from "react";
import { Person } from "./components/Person/Person.components";
import { StyledWrapper } from "./ToolpipContent.styled";
import { ToolpipContentProps } from "./ToolpipContent.type";

export const ToolpipContent: FC<ToolpipContentProps> = ({ users }) => {
    const peopleJsx = users.map((user, i) => {
        return <Person key={i} user={user} />
    })
    return (
        <StyledWrapper>
            {peopleJsx}
        </StyledWrapper>
    )
}
import { styled } from "@mui/material";

export const StyledWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "isActive",
})<{isActive: boolean}>`
    padding: 12px 16px;
    background-color: ${({isActive}) => isActive ? "rgba(0, 0, 0, 0.06)" : "#FFFFFF"};
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
`;
export const StyledIconWrapper = styled("div")`
    margin-right: 12px;
    width: 24px;
    height: 24px;
`;
export const StyledText = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
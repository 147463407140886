import { Route, Switch } from 'react-router-dom';
import { mainLayoutRoutesConfig } from './RoutesConfig';

export const MainContent = () => {
  return (
    <>
      <Switch>
        {mainLayoutRoutesConfig.map(route => {
          return  <Route key={route.path} path={route.path} exact={route?.exact ?? true} component={route.component}/>
        })}
      </Switch>
    </>
  );
};

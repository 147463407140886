import { Button, DialogTitle } from "@mui/material";
import { ConfirmationDialogProps } from "./ConfirmationDialog.type";
import { StyledDialog, StyledDialogActions } from "./ConfirmationDialog.styled";


export const ConfirmationDialog = ({open, closeDialog, submitHandler}: ConfirmationDialogProps) => {
    return (
        <StyledDialog
            open={open}
            onClose={closeDialog}
            aria-labelledby="confirmation-dialog-title"
            id="confirmation-dialog"
        >
            <DialogTitle style={{ cursor: 'move' }} id="confirmation-dialog-title">
                Are you sure?
            </DialogTitle>
            <StyledDialogActions>
                <Button autoFocus onClick={closeDialog}>
                    Cancel
                </Button>
                <Button style={{ backgroundColor: '#FF0000', color: '#fff' }} onClick={submitHandler}>
                    Remove
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    )
}
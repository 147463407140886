import { FC } from "react";
import { MenuItemProps } from "./MenuItem.type";
import { EventTypes, useAmplitude } from 'service/TrackingService';
import { useRouter } from "@/providers/Router/Router.provider";
import { WalkthroughWrapper } from "@/providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component";
import { StyledNavigationItem } from "./MenuItem.styled";

export const MenuItem: FC<MenuItemProps> = ({
    routeName,
    title,
    icon,
    handleDrawerClosing,
    walkthrough
}) => {
    const trackEvent = useAmplitude();
    const router = useRouter();
    const item = <StyledNavigationItem
      active={routeName === router.name}
      id={walkthrough?.targetId}
      onClick={() => {
        if (handleDrawerClosing) {
          handleDrawerClosing();
        }
        trackEvent(EventTypes.MENU_CLICK, { uri: router.getPathWithParams(routeName) });
        router.push({
          name: routeName
        });
      }}
    >
      <img src={icon} className={routeName === 'forYou' ? 'avatar' : ''} alt='' />
      {title}
    </StyledNavigationItem>
      return (<>{walkthrough ? <WalkthroughWrapper {...walkthrough}>
        {item}
      </WalkthroughWrapper> : item}</>
      );
}
import { FC } from "react";
import { NodeProps } from "./Node.type";
import { StyledAfterWrapper, StyledButton, StyledDoneIcon, StyledNode } from "./Node.styled";
import { ScaledCloseIcon } from "@/icons/v3/close.icon";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

export const Node: FC<NodeProps> = (props) => {
    const { node, onAdd, onDecline } = props;
    const isMobile = useRenederBelow('tablet');
    const buttonsHsx = <>
        <StyledButton center={isMobile} onClick={() => onAdd(node.id)} icon={<StyledDoneIcon />}>Add</StyledButton>
        <StyledButton center={isMobile} onClick={() => onDecline(node.id)} variant="secondary" icon={<ScaledCloseIcon />} />
    </>
    return <StyledNode
        {...props}
        hadeCopyIcon hideMenuIcon
        actions={!isMobile && buttonsHsx}
        after={isMobile && <StyledAfterWrapper>
            {buttonsHsx}
        </StyledAfterWrapper>}
    />
};
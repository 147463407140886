import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { LoaderProps } from './Loader.type';
import { StyledLoaderWrapper } from './Loader,styled';

export const Loader:FC<LoaderProps> = ({size = 88, className}) => {
  return (
      <StyledLoaderWrapper className={className}>
        <CircularProgress color="inherit" size={size} />
      </StyledLoaderWrapper>
  );
};
import { FC } from 'react';
import { AddFromSense } from "./components/AddFromSense/AddFromSense.component";
import { AddLinksToSpace } from "./components/AddLinksToSpace/AddLinksToSpace.component.tsx";
import { EmptyTabs } from "./components/EmptyTabs/EmptyTabs.component.tsx";
import { AddFromTabs } from "./components/AddFromTabs/AddFromTabs.component";
import { useMe } from 'providers/MeProvider/Me.provider';
import { AddResourcesFormProps } from './AddResourcesForm.type';
import { TabPanel } from './components/TabPanel/TabPanel.component';
import { StyledBackIconWrapper, StyledBackWrapper, StyledRoot, StyledTabsWrapper } from './AddResourcesForm.styled';
import { Tab } from './components/Tab/Tab.component';
import { SenseIcon } from './icons/Sense/Sense.icon';
import { ChromeIcon } from './icons/Chrome/Chrome.icon';
import { LinkIcon } from './icons/Link/Link.icon';
import { Divider } from '@/components/v3/Other/Divider/Divider.component';
import { AddFromCategory } from './components/AddFromCategory/AddFromCategory.component';
import { useRouter } from '@/providers/Router/Router.provider';
import { CategoryType } from './components/AddFromCategory/AddFromCategory.type';
import { useRenederBelow } from '@/hooks/v3/UseResponsive/UseResponsive.hook';
import { BackIcon } from '@/icons/v3/back.icon';
import { getAppsCongigs } from '@/components/v3/List/App/App.const';


export const AddResourcesForm: FC<AddResourcesFormProps> = ({
  asKeyResource,
  isFetchingLocal,
  setIsFetchingLocal,
  onAdd,
  onRemove,
  listOfAddedNodes,
}) => {
  const isMobile = useRenederBelow('tablet');
  const {user} = useMe();
  const router = useRouter();
  const activeTab = router.query.tab as string;
  const setActiveTab = (tab: string) => {
    router.push({
      name: router.name,
      params: router.params,
      query: {
        ...router.query,
        tab: tab,
      }
    });
  };
  const appsPages = getAppsCongigs(user);
  const categories: CategoryType[] = [
    { name: 'Sense Notes', categories: ['note'], listOfApps: [] },
    { name: 'Tasks', categories: ['task'], listOfApps: [appsPages.Asana, appsPages.ClickUp, appsPages.Trello, appsPages.Jira] },
    { name: 'Documents', categories: ['document'], listOfApps: [appsPages.GoogleDrive, appsPages.ClickUp, appsPages.Trello, appsPages.Notion, appsPages.Confluence, appsPages.Sharepoint] },
    { name: 'Files', categories: ['file'], listOfApps: [appsPages.GoogleDrive, appsPages.Dropbox, appsPages.Sharepoint, appsPages.Slack] },
    { name: 'Links', categories: ['link'], listOfApps: [appsPages.Slack, appsPages.TeamSpaces, appsPages.Gmail, appsPages.Outlook, appsPages.ClickUp, appsPages.Trello, appsPages.Notion, appsPages.Asana, appsPages.Confluence, appsPages.Github] },
    { name: 'Emails', categories: ['email'], listOfApps: [appsPages.Gmail, appsPages.Outlook] },
    { name: 'Design', categories: ['design'], listOfApps: [appsPages.Figma] },
    { name: 'Pull requests', categories: ['pull_request'], listOfApps: [appsPages.Github] },
    { name: 'Videos', categories: ['video'], listOfApps: [appsPages.Slack, appsPages.TeamSpaces, appsPages.GoogleDrive, appsPages.Dropbox, appsPages.Sharepoint] },
  ];
  const categoriesTabsJsx = categories.map((category) => {
    return (
      <Tab key={category.name} val={category.name} value={activeTab} onChange={setActiveTab} >{category.name}</Tab>
    )
  });
  const categoriesTabPanelsJsx = categories.map((category) => {
    return (
      <TabPanel value={activeTab} key={category.name} index={category.name}>
          <AddFromCategory
            category={category}
            setIsFetchingLocal={setIsFetchingLocal}
            listOfAddedNodes={listOfAddedNodes}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </TabPanel>
    )
  });
  return (
    <>
      {(!isMobile || (isMobile && !activeTab)) && <StyledTabsWrapper isMobile={isMobile}>
        <Tab val='sense' value={activeTab} onChange={setActiveTab} icon={<SenseIcon />} >From Sense</Tab>
        <Tab val='chrome' value={activeTab} onChange={setActiveTab} icon={<ChromeIcon />} >From Google Chrome</Tab>
        <Tab val='link' value={activeTab} onChange={setActiveTab} icon={<LinkIcon />} >From link</Tab>
        <Divider />
        {categoriesTabsJsx}
      </StyledTabsWrapper>}
      {!!activeTab && isMobile && <StyledBackWrapper>
        <StyledBackIconWrapper onClick={() => setActiveTab('')}>
          <BackIcon />
        </StyledBackIconWrapper>
      </StyledBackWrapper>}
      <StyledRoot>
        <TabPanel value={activeTab} index="sense">
          <AddFromSense
            setIsFetchingLocal={setIsFetchingLocal}
            listOfAddedNodes={listOfAddedNodes}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </TabPanel>
        <TabPanel value={activeTab} index="chrome">
          {user?.installedChromeExtension && <AddFromTabs
            asKeyResource={asKeyResource}
            listOfAddedNodes={listOfAddedNodes}
            isFetchingLocal={isFetchingLocal}
            setIsFetchingLocal={setIsFetchingLocal}
            onAdd={onAdd}
            onRemove={onRemove}
          />}
          {!user?.installedChromeExtension && <EmptyTabs/>}
        </TabPanel>
        <TabPanel value={activeTab} index="link">
          <AddLinksToSpace
            asKeyResource={asKeyResource}
            listOfAddedNodes={listOfAddedNodes}
            isFetchingLocal={isFetchingLocal}
            setIsFetchingLocal={setIsFetchingLocal}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </TabPanel>
        
        {categoriesTabPanelsJsx}
      </StyledRoot>
    </>
  );
};
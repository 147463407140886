import { Observable } from 'rxjs';
import { GraphNode } from './NodeService';
import { get } from '../../axios/HttpClient';

export interface User {
  id?: string;
  email: string;
  organizationId: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  slackUserId?: string;
  slackTeamId?: string;
  googleUserId?: string;
  msTeamsTeamId?: string;
  msTeamsUserId?: string;
  isOnboarded?: boolean;
  isSlackAdmin?: boolean;
  isGhost?: boolean;
  installedChromeExtension?: boolean;
  invitedTeamMate?: boolean;
  connectedCommunicationApp?: boolean;
  connectedStorageApp?: boolean;
  subscriptionStatus?: string;
  trialExpiresAt?: number;
  emailConfirmed?: boolean;
  accountReady?: boolean;
  isAdmin?: boolean;
  paymentPlan: PaymentPlan;
  avatar: string;
  walkThroughCompleted: boolean;
  googleSsoUserId: string | null;
  activities?: GraphNode[];
}

export interface SearchResult {
  users: User[];
  nodes: GraphNode[];
}

interface PaymentPlan {
  name: string;
  status: string;
  expiresAt: number;
  seatsNumber: number
}

export const searchBy$ = (queryToSearchBy: string): Observable<SearchResult> =>
  get<SearchResult>(`/ai-service/v1/search?query=${queryToSearchBy}`);

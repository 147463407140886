import { styled } from "@mui/material";
import { Node } from "@/components/v3/List/Node/Node.component";

export const StyledWrapper = styled('div')`
    padding: 24px 32px 40px 32px;
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledNode = styled(Node)`
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;
import { UserType } from "@/axios/SenseFacade/Users/Types/Get/Get.type";
import { UserAxiosResponseSuccessType } from "@/axios/OrganizationService/Users/Types/GetUser/GetUser.type";
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { FC, useCallback, useEffect, useState } from "react";
import { Header } from "./components/Header/Header.component";
import { GetUserFetch } from "@/axios/OrganizationService/Users/Users.api";
import { Tabs } from "./components/Tabs/Tabs.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { TabValueType } from "./components/Tabs/Tabs.type";
import { CommonRelations } from "./components/CommonRelations/CommonRelations.component";
import { RecentActivities } from "./components/RecentActivities/RecentActivities.component";
import { AllRelationships } from "./components/AllRelationships/AllRelationships.component";
import { GetCommonRelationsFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { CommonRelationsNodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/CommonRelations/CommonRelations.type";
import { CommonNodeWrapperType } from "./CommonRelations.type";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";

export const CommonRelationsContent: FC = () => {
    const { user: userMe } = useMe();
    const router = useRouter();
    const { useNewActivityUI } = useFeatureFlags();
    const defaultActiveTab = useNewActivityUI ? 'RECENT_ACTIVITIES' : 'COMMON_RELATIONSHIPS';
    const [activeTab, setActiveTab] = useState<TabValueType>(defaultActiveTab);
    const [user, setUser] = useState<UserType | null>(null);
    const [isFetchingUser, setIsFetchingUser] = useState<boolean>(true);
    const [isFetchingCommonNodes, setIsFetchingCommonNodes] = useState<boolean>(true);
    const [commonNodes, setCommonNodes] = useState<CommonNodeWrapperType[]>([]);
    const loadUser = useCallback(async () => {
        if (!router.params.fromUserId) {
            return;
        }
        setIsFetchingUser(true);
        await GetUserFetch(router.params.fromUserId as string).then((user: UserAxiosResponseSuccessType) => {
            setUser(user.data);
        });
        setIsFetchingUser(false);
    }, [router.params.fromUserId]);
    const loadCommonNodes = useCallback(async () => {
        if (!router.params.fromUserId) {
            return;
        }
        if (!userMe?.id) {
            return;
        }
        setIsFetchingCommonNodes(true);
        await GetCommonRelationsFetch({
            fromUser: userMe?.id,
            toUser: router.params.fromUserId as string,
        }).then(async (res: CommonRelationsNodesAxiosResponseSuccessType) => {
            const nodeIds = res.data.map((el) => el.id)
            const importantUsers = await fetchImportantUsers(nodeIds);
            const wrappers = res.data.map((node) => {
                const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                return {
                    node,
                    importantUserIds: importantInfo.userIds
                }
            });
            setCommonNodes(wrappers);
        });
        setIsFetchingCommonNodes(false);
    }, [router.params.fromUserId, userMe?.id]);
    useEffect(() => {
        loadUser();
    }, [loadUser]);
    useEffect(() => {
        loadCommonNodes();
    }, [loadCommonNodes]);
    return <MainLayout>
        <LoaderLayout isLoading={isFetchingUser} transparent={!!user} >
            {!!user && <Header user={user} />}
            <Tabs value={activeTab} onChange={setActiveTab} />
            {activeTab === 'COMMON_RELATIONSHIPS' && <CommonRelations onUpdate={loadCommonNodes} isFetchingCommonNodes={isFetchingCommonNodes} commonNodes={commonNodes} />}
            {activeTab === 'RECENT_ACTIVITIES' && <RecentActivities onUpdate={loadCommonNodes} isFetchingUser={isFetchingUser} user={user} />}
            {activeTab === 'ALL_RELATIONSHIPS' && <AllRelationships onUpdate={loadCommonNodes} isFetchingCommonNodes={isFetchingCommonNodes} commonNodes={commonNodes} />}
        </LoaderLayout>
        <CreateNoteDrawer onUpdate={loadCommonNodes} />
    </MainLayout>
};
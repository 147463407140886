import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
} from '@mui/material';
import { GetStarted } from '@/components/SideBarMenu/components/GetStarted/GetStarted.component';
import { TeamSpacesDropdown } from './components/TeamSpacesDropdown/TeamSpacesDropdown.component';
import { NewChannelProvider } from "../Space/NewSpaceContext";
import { CreateSpaceButton } from "./components/CreateSpaceButton/CreateSpaceButton.component";
import { WalkthroughWrapper } from 'providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component';
import { useSpaces } from "../../providers/SpacesProvider/Spaces.provider";
import { SideBarMenuProps } from './SideBarMenu.type';
import { useStyles } from './SideBarMenu.classes';
import { compare } from './SideBarMenu.utils';
import { Space } from './components/Space/Space.component';
import { MenuItem } from './components/MenuItem/MenuItem.component';
import { StyledCreateAccountButton, StyledLogoWrapper, StyledMenuSpaceTitle, StyledNavigationItem, StyledPublicTitle, StyledSideMenuWrapper, StyledSpacesMenuList } from './SideBarMenu.styled';
import { useRouter } from '@/providers/Router/Router.provider';
import { GetCommonFetch } from '@/axios/SenseFacade/Public/Public.api';
import { CommonAxiosResponseSuccessType } from '@/axios/SenseFacade/Public/Types/GetCommon/GetCommon.type';
import { SpaceType } from '@/axios/AiService/Spaces/Types/List/List.type';
import { PublicSpace } from './components/PublicSpace/PublicSpace.component';
import { SpaceTagType } from '@/axios/AiService/SpaceTags/Types/Get/Get.type';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { isShared } from '@/utils/IsShared/IsShared.utils';

export const SideBarMenu = ({ handleDrawerClosing, user }: SideBarMenuProps) => {
  const{ user: userMe } = useMe();
  const classes = useStyles();
  const router = useRouter();
  const { isSharedSpace } = isShared(router);
  const code = router.params.code as string | undefined;
  const [space, setSpace] = useState<SpaceType | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [tags, setTags] = useState<SpaceTagType[]>([]);
  const { spaces } = useSpaces();
  const teamSpaces = spaces.filter(space => space.application === 'Sense').sort(compare);
  const slackSpaces = spaces.filter(space => space.application === 'Slack').sort(compare);
  const confluenceSpaces = spaces.filter(space => space.application === 'Confluence').sort(compare);
  const jiraSpaces = spaces.filter(space => space.application === 'Jira').sort(compare);
  const asanaSpaces = spaces.filter(space => space.application === 'Asana').sort(compare);
  const githubSpaces = spaces.filter(space => space.application === 'Github').sort(compare);
  const clickUpSpaces = spaces.filter(space => space.application === 'ClickUp').sort(compare);
  const trelloSpaces = spaces.filter(space => space.application === 'Trello').sort(compare);
  const microsoftTeamsSpaces = spaces.filter(space => space.application === 'MicrosoftTeams').sort(compare);
  const notionSpaces = spaces.filter(space => space.application === 'Notion').sort(compare);
  const [node, setNode] = useState('All Spaces');
  const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED'
  const paddingStyle = isSubscriptionExpired ? { paddingTop: 55 } : { paddingTop: 0 };
  const getStartedAllItemsResolved = useMemo(() => {
    const hasCommonItemsResolved = !!user?.connectedCommunicationApp && !!user?.connectedStorageApp && !!user?.installedChromeExtension;
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    return isIndividual ? hasCommonItemsResolved : hasCommonItemsResolved && !!user?.invitedTeamMate;
  }, [user?.connectedCommunicationApp, user?.connectedStorageApp, user?.installedChromeExtension, user?.invitedTeamMate, user?.paymentPlan.name]);
  
  const loadPublicSpace = useCallback(async () => {
    if (isSharedSpace) {
      if (!code) { return }
      await GetCommonFetch({
          code
      }).then((res: CommonAxiosResponseSuccessType) => {
          setSpace(res.data.space);
          setCategories(res.data.categories);
          setTags(res.data.tags);
      });
    }
  }, [code, isSharedSpace]);
  useEffect(() => {
    loadPublicSpace();
  }, [loadPublicSpace]);
  const selectedSpace = localStorage.getItem('selectedSpace');
  useEffect(() => {
    selectedSpace && setNode(selectedSpace)
  }, [selectedSpace])
  return (
    <Box className={classes.gridWrapper} style={paddingStyle}>
      <StyledSideMenuWrapper>
        <StyledNavigationItem>
          <StyledLogoWrapper onClick={() => router.push({
            name: 'forYou'
          })}>
            <a>
              <img src="/icons/sense-logo.svg" alt="" />
            </a>
          </StyledLogoWrapper>
        </StyledNavigationItem>
        {!isSharedSpace && <>
          <MenuItem
            title="For you"
            routeName="forYou"
            icon={user?.avatar || '/icons/no_avatar.svg'}
            walkthrough={{
              variant: 'walkthrough-for-you',
              title: 'What is important for you',
              description: 'Important discussions, resources, tasks, emails from your apps. ',
              targetId: 'for-you',
            }}
            handleDrawerClosing={handleDrawerClosing}
          />
          <MenuItem
            title="Important"
            routeName="important"
            icon={router.name==='important' ? '/icons/important-active-icon.svg' : '/icons/important-icon.svg'}
            handleDrawerClosing={handleDrawerClosing}
          />
          <MenuItem
            title="People"
            routeName="people"
            icon={router.name==='people' ? '/icons/people-active-icon.svg' : '/icons/people-icon.svg'}
            handleDrawerClosing={handleDrawerClosing}
          />
          <StyledMenuSpaceTitle>
            <NewChannelProvider>
              <CreateSpaceButton />
            </NewChannelProvider>
            <TeamSpacesDropdown
              setNode={setNode}
              teamSpaces={teamSpaces}
              slackSpaces={slackSpaces}
              jiraSpaces={jiraSpaces}
              asanaSpaces={asanaSpaces}
              githubSpaces={githubSpaces}
              clickUpSpaces={clickUpSpaces}
              trelloSpaces={trelloSpaces}
              confluenceSpaces={confluenceSpaces}
              microsoftTeamSpaces={microsoftTeamsSpaces}
              notionSpaces={notionSpaces}
            />
          </StyledMenuSpaceTitle>
          <WalkthroughWrapper
            variant="walkthrough-spaces"
            title={Array.isArray(spaces) && spaces.length > 1 ? 'All your projects will be here' : 'Self-organised spaces'}
            description={Array.isArray(spaces) && spaces.length > 1 ? 'Sense will look through all apps you connected and pack your projects in self-organised spaces. You can easily switch between your sources. ' : 'Discover what your projects might look like'}
            targetId="walkthrough-spaces"
          >
            <StyledSpacesMenuList getStartedAllItemsResolved={getStartedAllItemsResolved}>
              {node === 'All Spaces' && spaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'Sense' && teamSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'Slack' && slackSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'Confluence' && confluenceSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'Jira' && jiraSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'Asana' && asanaSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'GitHub' && githubSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'ClickUp' && clickUpSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'Trello' && trelloSpaces.map((borad) => <Space space={borad} key={borad.id} />)}
              {node === 'Notion' && notionSpaces.map((space) => <Space space={space} key={space.id} />)}
              {node === 'MicrosoftTeams' && microsoftTeamsSpaces.map((space) => <Space space={space} key={space.id} />)}
            </StyledSpacesMenuList>
          </WalkthroughWrapper>
        </>}
        {isSharedSpace && !!space && <>
          <StyledPublicTitle>Spaces shared with you</StyledPublicTitle>
          <PublicSpace space={space} categories={categories} tags={tags} />
        </>}
      </StyledSideMenuWrapper>
      {isSharedSpace && !userMe?.id && <StyledCreateAccountButton onClick={() => {
        router.push({
          name: 'signup',
        })
      }}>
        Create Sense account
      </StyledCreateAccountButton>}
      {!isSharedSpace && !getStartedAllItemsResolved && <GetStarted />}
    </Box>
  );
};

import { FC, useEffect, useRef } from "react";
import { ConnectAppsProps } from "./ConnectApps.type";
import { StyledFilledCard, StyledWrapper } from "./ConnectApps.styled";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { AppType } from "./components/App/App.type";
import { AppTypes } from "@/types/AppTypes/AppTypes.type";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { App } from "./components/App/App.component";
import { useRouter } from "@/providers/Router/Router.provider";

export const ConnectApps: FC<ConnectAppsProps> = ({
    channels, setChannels
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const router = useRouter();
    useEffect(() => {
        if (router.query.scroll === 'apps') {
            const current = ref.current;
            const scrollElement = document.querySelector('.scroll-dialog-wrapper');
            if (current && scrollElement) {
                const offsetTop = current.getBoundingClientRect().top + window.pageYOffset - 100; // Враховуємо sticky елемент
                scrollElement.scrollTo({ top: offsetTop, behavior: 'smooth' });
            }
        }
    }, [router.query.scroll]);
    
    const { user } = useMe();
    const listOfApps: AppType[] = [
        { channelsTitle: 'projects', application: 'Asana', title: AppTypes.Asana, isConnected: !!user?.integrations?.asana.active },
        { channelsTitle: 'projects', application: 'Github', title: AppTypes.Github, isConnected: !!user?.integrations?.github.active },
        { channelsTitle: 'projects', application: 'ClickUp', title: AppTypes.ClickUp, isConnected: !!user?.integrations?.click_up.active },
        { channelsTitle: 'projects', application: 'Trello', title: AppTypes.Trello, isConnected: !!user?.integrations?.trello.active },
        { channelsTitle: 'spaces', application: 'Confluence', title: AppTypes.Confluence, isConnected: !!user?.integrations?.atlassian.active },
        { channelsTitle: 'projects', application: 'Jira', title: AppTypes.Jira, isConnected: !!user?.integrations?.atlassian.active },
        { channelsTitle: 'channels', application: 'Slack', title: AppTypes.Slack, isConnected: !!user?.integrations?.slack.active },
        { channelsTitle: 'channels', application: 'MicrosoftTeams', title: AppTypes.MicrosoftTeams, isConnected: !!user?.integrations?.microsoft_teams.active },
        { channelsTitle: 'workspaces', application: 'Notion', title: AppTypes.Notion, isConnected: !!user?.integrations?.notion.active },
    ];
    const listOfAppsJsx = listOfApps.map((app, i) => {
        return <App key={i} app={app} channels={channels} setChannels={setChannels} />
    });
    return <>
        <div ref={ref}></div>
        <StyledFilledCard>
            <SectionHeader title="Connect apps" caption="Visible only for users invited to the space" />
            <StyledWrapper>
                {listOfAppsJsx}
            </StyledWrapper>
        </StyledFilledCard>
    </>
}
import { Observable } from 'rxjs';
import { get } from '../axios/HttpClient';
import { User } from '../components/Nodes/SearchService';
import { DataService } from './DataService';
import {envUrl} from "./helpers";

export interface Organization {
  id: string;
  name: string;
  email: string;
  microsoftOrganizationId: string;
}

export const fetchUserById$ = (userId: string): Observable<User> => {
  return get<User>(`/organization-service/v1/users/${userId}`);
};

export const fetchUsersByOrganizationId$ = (organizationId: string): Observable<User[]> => {
  return get<User[]>(`/organization-service/v1/users?organizationId=${organizationId}`);
};

export const fetchOrganizationById$ = (organizationId: string): Observable<Organization> =>
  get<Organization>(`/organization-service/v1/organizations/${organizationId}`);

class UserDataService extends DataService<User | undefined> {
  protected getFetchUrl(_arg?: string): string {
    return super.getFetchUrl() + _arg;
  }
}

class OrganizationDataService extends DataService<Organization | undefined> {
  protected getFetchUrl(_arg?: string): string {
    return super.getFetchUrl() + _arg;
  }
}

//TODO: prod
export const UserService = new UserDataService(`${envUrl}/organization-service/v1/users/`, undefined);
export const OrganizationService = new OrganizationDataService(`${envUrl}/organization-service/v1/organizations/`, undefined);

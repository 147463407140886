import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Input } from "@/components/v3/Fields/Input/Input.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { styled } from "@mui/material";

export const StyledLoginPage = styled(LoaderLayout)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #F4F4F4;
    position: relative;
`;
export const StyledCard = styled("div")`
    padding: 24px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
    margin-bottom: 16px;
`;
export const StyledTitle = styled("div")`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
`;
export const StyledDescription = styled("div")`
    color: rgb(32, 32, 32);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
`;
export const StyledInput = styled(Input)`
    width: 100%;
`;
export const StyledButton = styled(Button)`
    width: 100%;
    justify-content: center;
`;
export const StyledSubmitButton = styled(StyledButton)`
    margin-top: 8px;
`;
export const StyledDivider = styled('div')`
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.16);
    margin-top: 24px;
    margin-bottom: 24px;
`;
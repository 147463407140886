import { styled } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.24);
    padding: 0;
  }
`
export const StyledMenuItem = styled(MenuItem)`
  width: 320px;
  padding: 10px 16px;
`
import { ToastService } from "@/service/ToastService";

export const useCopyToClipboard = () => {
    const copyToClipboard = (value: string | null, notification?: string) => {
        if (value) {
            navigator.clipboard.writeText(value).then(() => {
                ToastService.showToast('success', notification || 'The value has been copied');
            })
        }
    }
    return {
        copyToClipboard
    }
};
export default useCopyToClipboard;
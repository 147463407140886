import { FC } from "react";
import { CheckboxProps } from "./Checkbox.type";
import { Checkbox as CheckboxMUI } from "@mui/material";
import { CheckboxContainer, StyledCheckboxCaption, StyledCheckboxLabel, StyledCheckboxWrapper, StyledImg } from "./Checkbox.styled";

export const Checkbox: FC<CheckboxProps> = ({ value, onChange, label, caption, img }) => {
    return <CheckboxContainer onClick={() => onChange(!value)}>
        <CheckboxMUI checked={value} />
        <StyledImg src={img} />
        <StyledCheckboxWrapper>
            <StyledCheckboxLabel>{label}</StyledCheckboxLabel>
            <StyledCheckboxCaption>{caption}</StyledCheckboxCaption>
        </StyledCheckboxWrapper>
    </CheckboxContainer>
};
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { StyledCancelBtn, StyledInviteBtn } from "./ConfirmInviteModal.styled";

export const ConfirmInviteModal = ({users, isOpen, setIsOpen, finishHandler }: any) => {
    return (
        <Dialog
            open={isOpen}
            onClose={() => {setIsOpen(false)}}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">
                {users.length} users will be invited
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    <p> We will send invitation email to {users.length} users we found in your apps. Do you <br/>  want to proceed?</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledInviteBtn autoFocus onClick={() => finishHandler() }>
                    Invite users
                </StyledInviteBtn>
                <StyledCancelBtn onClick={() => setIsOpen(false)}>
                    Cancel
                </StyledCancelBtn>
            </DialogActions>
        </Dialog>
    )
}
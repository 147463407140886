import { Box, Typography, styled } from "@mui/material";

export const StyledWrapper = styled(Box)`
    background: rgba(255, 255, 255, 0.32);
    padding: 16px 16px 0 16px;
    position: absolute;
    width: 320px;
    background-color: #FFECE7;
    bottom: 0;
`;
export const StyledTitle = styled(Box)`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 16px;
`;
export const StyledItem = styled(Box, {
    shouldForwardProp: prop => prop !== 'resolved'
})<{
    resolved: boolean;
}>`
    padding-bottom: 12px;
    word-break: break-word;
    display: flex;
    ${({ resolved }) => (resolved && 'text-decoration-line: line-through;')}
`;
export const StyledIcon = styled('img')`
    padding-right: 8px;
`;
export const StyledLink = styled('a')`
    color: #A921D9;
    text-decoration: none;
    cursor: pointer;
    word-break: break-word;
`;
export const StyledTrial = styled(Typography)`
    display: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #636363;
`;
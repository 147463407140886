import { SearchCloseIcon } from "components/Svg/index.svg";
import { styled } from "@mui/material";

export const StyledDropdownContainer = styled('div')`
  position: relative;
`;
export const StyledDropdown = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  max-height: 50vh;
  overflow-y: auto;
  box-shadow: 0px 0.5rem 1rem 0.125rem #00000029;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    height: 30px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(0,0,0,0.08);
  }
`;
export const StyledCloseIcon = styled(SearchCloseIcon)`
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 0.5rem;
  z-index: 1;
  cursor: pointer;
`;
export const StyledLoaderWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
`;
export const StyledEmptyWrapper = styled('div')`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #636363;
  line-height: 1.5rem;
  padding-bottom: 24px;
`;
import { FC } from "react";
import {
    StyledDoneIcon,
    StyledProgress,
    StyledProgressCaption,
    StyledProgressContainer,
    StyledProgressLoader,
    StyledProgressTitle,
    StyledReloadButtonContainer,
    StyledReloadIcon
} from "./Progress.styled";
import { ProgressProps } from "./Progress.type";

export const Progress: FC<ProgressProps> = ({ done }) => {
    return <StyledProgress>
        <StyledProgressContainer>
            {!done && <>
                <StyledProgressLoader size={32} />
                <div>
                    <StyledProgressTitle>
                        Sense is organising resources from your apps...
                    </StyledProgressTitle>
                    <StyledProgressCaption>
                        It might take up to 10 minutes
                    </StyledProgressCaption>
                </div>
            </>}
            {done && <>
                <StyledDoneIcon />
                <div>
                    <StyledProgressTitle>
                        {`It’s done`}
                    </StyledProgressTitle>
                    <StyledProgressCaption>
                        Sense finished organising data from your apps
                    </StyledProgressCaption>
                </div>
                <StyledReloadButtonContainer onClick={() => window.location.reload()}>
                    <StyledReloadIcon /> Reload page
                </StyledReloadButtonContainer>
            </>}
        </StyledProgressContainer>
    </StyledProgress>
};
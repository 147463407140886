import { IconProps } from "./icon.type";

export const ShareIcon = (props: IconProps) => {
  return (
    <svg  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="none" d="M12.8572 6.42969H14.1429C14.3134 6.42969 14.4769 6.49742 14.5974 6.61798C14.718 6.73854 14.7857 6.90205 14.7857 7.07254V16.0725C14.7857 16.243 14.718 16.4066 14.5974 16.5271C14.4769 16.6477 14.3134 16.7154 14.1429 16.7154H3.85715C3.68666 16.7154 3.52314 16.6477 3.40258 16.5271C3.28202 16.4066 3.21429 16.243 3.21429 16.0725V7.07254C3.21429 6.90205 3.28202 6.73854 3.40258 6.61798C3.52314 6.49742 3.68666 6.42969 3.85715 6.42969H5.14287" stroke="black" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 8.99944V1.28516" stroke="black" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.07141 3.21373L8.99998 1.28516L10.9286 3.21373" stroke="black" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

import { TextSkeleton } from "@/components/v3/Skeleton/TextSkeleton/TextSkeleton.component";
import { styled } from "@mui/material";
import { CSSProperties } from "@mui/styles";

export const StyledCalendarEvent = styled('div', {
    shouldForwardProp: prop => {
        return prop !== 'top' && prop !== 'height' && prop !== 'left' && prop !== 'width' && prop !== 'padding' && prop !== 'alignItems' && prop !== 'justifyContent'
    }
})<{
    top: number,
    height: number,
    left: number,
    width: number,
    padding: CSSProperties['padding'],
    alignItems: CSSProperties['alignItems'],
    justifyContent: CSSProperties['justifyContent'],
}>`
    display: flex;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    width: ${props => props.width}px;
    min-height: ${props => props.height}px;
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    padding: ${props => props.padding};
    background-color: #FFECE7;
    border-radius: 6px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    cursor: pointer;
`
export const StyledIcon = styled('img', {
    shouldForwardProp: prop => prop !== 'width' && prop !== 'height'
})<{
    width: CSSProperties['width'],
    height: CSSProperties['height'],
}>`
    margin-right: 8px;
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: ${props => props.width};
    min-height: ${props => props.height};
`
export const StyledMaskedIcon = styled('div', {
    shouldForwardProp: prop => prop !== 'width' && prop !== 'height'
})<{
    width: CSSProperties['width'],
    height: CSSProperties['height'],
}>`
    margin-right: 8px;
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: ${props => props.width};
    min-height: ${props => props.height};
    background: rgba(0, 0, 0, 0.06);
    border-radius: 6px;
`
export const StyledSmallIcon = styled('img', {
    shouldForwardProp: prop => prop !== 'width' && prop !== 'height'
})<{
    width: CSSProperties['width'],
    height: CSSProperties['height'],
}>`
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: ${props => props.width};
    min-height: ${props => props.height};
`
export const StyledSmallMaskedIcon = styled('div', {
    shouldForwardProp: prop => prop !== 'width' && prop !== 'height'
})<{
    width: CSSProperties['width'],
    height: CSSProperties['height'],
}>`
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: ${props => props.width};
    min-height: ${props => props.height};
    background: rgba(0, 0, 0, 0.06);
    border-radius: 6px;
`
export const StyledHeader = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`
export const StyledContent = styled('div', {
    shouldForwardProp: prop => prop !== 'width'
})<{
    width: CSSProperties['width'],
}>`
    width: ${props => props.width};
`
export const StyledTitle = styled(TextSkeleton, {
    shouldForwardProp: prop => prop !== 'lineHeight'
})<{
    lineHeight: CSSProperties['lineHeight'],
}>`
    color: #2F2C2C;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: ${props => props.lineHeight};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const StyledCaption = styled(TextSkeleton)<{ellipsis: boolean}>`
    color: rgba(32, 32, 32, 0.70);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    ${props => props.ellipsis && 'text-overflow: ellipsis;'}
    
`
import { FC, useState } from "react";
import { ButtonProps, MenuProps, MuiMenuProps } from "./Menu.type";
import { Button } from "../../Fields/Button/Button.component";
import { StyledMenu, StyledMenuIcon } from "./Menu.styled";

export const Menu: FC<MenuProps> = ({
    children,
    buttonProps = {},
    menuProps = {}
}) => {
    const defaultButtonProps: ButtonProps = {
        variant: 'tertiary',
        icon: <StyledMenuIcon />
    };
    const finalButtonProps = { ...defaultButtonProps, ...buttonProps };
    const defaultMenuProps: MuiMenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
        disableScrollLock: true,
        elevation: 0,
    };
    const finalMenuProps = { ...defaultMenuProps, ...menuProps };
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <Button onClick={menuClickHandler} {...finalButtonProps} />
        <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            {...finalMenuProps}
        >
            <div onClick={handleClose}>{children}</div>
        </StyledMenu>
    </>
};
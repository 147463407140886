import { IconProps } from "./icon.type";

export const Add2Icon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_10186_5775)">
            <path d="M9 1.28577V16.7143" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.28516 9H16.7137" stroke="#202020" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_10186_5775">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
};

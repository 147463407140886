import { FC } from "react";
import { StyledAppsWrapper, StyledGradientWrapper, StyledWrapper } from "./EmptyState.styled";
import { GradientLayout } from "@/components/v3/Layouts/GradientLayout/GradientLayout.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { Node } from "@/components/v3/List/Node/Node.component";
import { listOfNodes } from "./EmptyState.const";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { App } from "@/components/v3/List/App/App.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { getAppsCongigs } from "@/components/v3/List/App/App.const";

export const EmptyState: FC = () => {
    const { user } = useMe();
    const listOfNodesJsx = listOfNodes.map((el) => {
        return <Node
            key={el.node.id}
            node={el.node}
            onUpdate={() => {}}
            users={[]}
            relationships={el.relationships}
            importantUserIds={[]}
            useSkeleton={false}
        />;
    });
    const appsPages = getAppsCongigs(user);
    const listOfApps = [appsPages.Gmail, appsPages.Outlook]
    const listOfAppsJsx = listOfApps.map((app, i) => {
        return <App key={app.title} app={app} />
    });
    return <StyledWrapper>
        <StyledGradientWrapper>
            <GradientLayout>
                <FilledCard>
                    {listOfNodesJsx}
                </FilledCard>
            </GradientLayout>
        </StyledGradientWrapper>
        <StyledAppsWrapper>
            <SectionHeader title="Connect email accounts" caption="You can sync multiple email accounts" />
            {listOfAppsJsx}
        </StyledAppsWrapper>
    </StyledWrapper>
};
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { NodesGetQueryParams } from './Types/Get/Get.type';
import { PostImportantQueryParams } from "./Types/PostImportant/PostImportant.type";
import { DeleteImportantQueryParams } from "./Types/DeleteImportant/DeleteImportant.type";
import { NodesPatchBodyParams } from "./Types/Patch/Patch.type";
import { GetPeopleRecentActivitiesQueryParams } from "./Types/GetPeopleRecentActivities/GetPeopleRecentActivities.type";
import { CommonRelationsNodesGetQueryParams } from "./Types/CommonRelations/CommonRelations.type";
import { GetImportantUsersQueryParams } from "./Types/GetImportantUsers/GetImportantUsers.type";
import { NodesApplicationsQueryParams } from "./Types/Applications/Applications.type";
import { NodesCreateBodyParams } from "./Types/Create/Create.type";

export const GetNodesFetch = (query: NodesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/nodes`, {
        params: {
            ...query,
            nodeIds: query.nodeIds?.join(','),
            categories: query.categories?.join(','),
        },
    });
}
export const PatchNodesFetch = (nodeId: number, body: NodesPatchBodyParams) => {
    return HttpClient.patch(`${envUrl}/ai-service/v1/nodes/${nodeId}`, body);
}
export const CreateNodesFetch = (body: NodesCreateBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/nodes`, body);
}
export const PostImportantFetch = (query: PostImportantQueryParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/nodes/important`, null, {
        params: {
            ...query,
            users: query.users.join(','),
        }
    });
}
export const DeleteImportantFetch = (query: DeleteImportantQueryParams) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/nodes/important`, {
        params: query
    });
}
export const GetPeopleRecentActivitiesFetch = (query: GetPeopleRecentActivitiesQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/nodes/people-recent-activities`, {
        params: query,
    });
}
export const GetCommonRelationsFetch = (query: CommonRelationsNodesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/nodes/common-relations`, {
        params: query,
    });
}
export const GetImportantUsersFetch = (query: GetImportantUsersQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/nodes/important/users`, {
        params: {
            ...query,
            nodeIds: query.nodeIds?.join(','),
        },
    });
}
export const GetNodesApplicationsFetch = (query: NodesApplicationsQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/nodes/applications`, {
        params: query,
    });
}
export const DeleteNodeFetch = (nodeId: number) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/nodes/${nodeId}`);
}
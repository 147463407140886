import { styled } from "@mui/material";
import { Checkbox } from "../../../Checkbox/Checkbox.component";

export const StyledOption = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isChecked',
})<{isChecked: boolean}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
    cursor: pointer;
    ${({ isChecked }) => isChecked && `
        background-color: rgba(0, 0, 0, 0.06);
    `}
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
`;
export const StyledCheckbox = styled(Checkbox)`
    padding: 0;
    margin-right: 8px;
`;
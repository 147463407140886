import { FC } from "react";
import { StyledWrapper } from "./People.styled";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { Person } from "./components/Person/Person.component";

export const People: FC = () => {
    const { relationships } = useNode();
    const creatorIds = new Set();
    relationships?.actors.forEach(user => {
        if (user.actionType === 'CREATOR' || user.actionType === 'CREATED_BY') {
            creatorIds.add(user.userId);
        }
    });
    const filteredActors = relationships?.actors.filter(user => {
        return user.actionType === 'CREATOR' || user.actionType === 'CREATED_BY' || !creatorIds.has(user.userId);
    });
    const filteredActorsJsx = filteredActors?.map((actor, i) => {
        return <Person actor={actor} key={i} />
    });
    
    return (
        <>
            {!!filteredActors?.length && <StyledWrapper>
                {filteredActorsJsx}
            </StyledWrapper>}
        </>
    )
};
import { useRouter } from "@/providers/Router/Router.provider";
import { FC, useEffect, useState } from "react";
import { Dialog } from "../../Other/Dialog/Dialog.component";
import { StyledDialogLayout } from "../CreateSpaceModal/CreateSpaceModal.styled";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { ManageIntegrationsContent } from "./components/ManageIntegrationsContent/ManageIntegrationsContent.component";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const ManageIntegrationsDialog: FC = () => {
    const router = useRouter();
    const { loadUser } = useMe();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { value: valueIsOpened, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'manage-integrations' });
    const value = valueIsOpened && !router.query.spaceApp;
    const onChange = (value: boolean) => {
        if (value) {
            onChangeIsOpened(value);
        } else {
            onChangeIsOpened(value, {
                type: undefined
            });
        }
    };
    const updateData = async () => {
        setIsLoading(true);
        await loadUser();
        setIsLoading(false);
    };
    useEffect(() => {
        if (value) {
            updateData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    return <Dialog value={value} onChange={onChange}>
        <StyledDialogLayout
            title="Manage integrations"
            onCloseClick={() => !isLoading && onChange(false)}
            isLoading={isLoading}
        >
            <ManageIntegrationsContent isLoading={isLoading} setIsLoading={setIsLoading} />
        </StyledDialogLayout>
    </Dialog>
}
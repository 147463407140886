import { OptionProps } from "./Option.type";
import { StyledCheckbox, StyledOption } from "./Option.styled";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";

export const Option = <T,>({ value, option, onChange }: OptionProps<T>) => {
    const isMultiple = Array.isArray(value);
    const isChecked = isMultiple && (value as T[]).includes(option.value);
    const handleOptionClick = () => {
        onChange(option.value);
    }
    return (
        <StyledOption isChecked={isChecked} onClick={handleOptionClick}>
            {isMultiple && <StyledCheckbox checked={isChecked} />}
            <ListItem title={option.label} img={option.img} />
        </StyledOption>
    );
};

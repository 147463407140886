import React, { useState, useEffect, useCallback, useRef } from "react";
import { searchBy$, SearchResult } from 'components/Nodes/SearchService';
import { useDebounce } from 'hooks/UseDebounce/UseDebounce.hook';
import { useOnClickOutside } from 'hooks/UseOnClickOutside/UseOnClickOutside.hook';
import { StyledDropdownContainer, StyledDropdown, StyledCloseIcon, StyledLoaderWrapper, StyledEmptyWrapper } from "./Search.styled";
import { SearchTextField } from '@/mui/SearchTextField/SearchTextField.coponent';
import { SearchResultView } from './components/SearchResultView/SearchResultView.component';
import { Box } from "@mui/material";
import { SearchProps } from './Search.type';
import { useStyles } from './Search.classes';
import { Loader } from "components/Loader/Loader.component";

export const Search: React.FC<SearchProps> = ({
    disabled
}) => {
    const classes = useStyles();
    let searchFieldRef = useRef<HTMLInputElement>(null);
    let contentRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [queryToSearchBy, setQueryToSearchBy] = useState<string>('');
    const [searchResult, setSearchResult] = useState<SearchResult>({
      users: [],
      nodes: [],
    });
    
    const loadData = () => {
        searchBy$(queryToSearchBy).subscribe(searchResult => {
          setSearchResult(searchResult);
          setIsLoading(false)
        });
    }
    const handler = useDebounce(() => {
        loadData();
    })
    useEffect(() => {
        if (queryToSearchBy) {
            handler();
        } else {
            setSearchResult({
                users: [],
                nodes: [],
            });
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryToSearchBy]);
    
    const handleChange = useCallback ((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setIsLoading(true)
        const searchTerm = event.target.value;
        setQueryToSearchBy(searchTerm);
    }, []);
    const onClearHandler = () => {
        setQueryToSearchBy('');
        setSearchResult({
            users: [],
            nodes: [],
        });
    }
    const handleClickOutside = (): void => {
        setTimeout(() => {
            setIsVisible(false)
        }, 200);
      };
    useOnClickOutside(contentRef, handleClickOutside, [searchFieldRef, '.MuiPaper-root']);
    const haveResults = (searchResult.nodes.length>0 || searchResult.users.length>0);
    useEffect(() => {
        if (isVisible && queryToSearchBy) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [isVisible, queryToSearchBy]);
    return (
        <StyledDropdownContainer>
            {!!queryToSearchBy && <StyledCloseIcon onClick={onClearHandler} />}
            <SearchTextField
                disabled={disabled}
                id="application_search"
                inputRef={searchFieldRef}
                value={queryToSearchBy}
                onChange={handleChange}
                onFocus={() => setIsVisible(true)}
            />
            {isVisible && !!queryToSearchBy && (
                <StyledDropdown ref={contentRef}>
                    {isLoading && <StyledLoaderWrapper>
                        <Loader size={40}/>
                    </StyledLoaderWrapper>}
                    <Box className={classes.searchResultWrapper}>
                        {haveResults ? <SearchResultView
                            search={searchResult}
                            setSearchResult={setSearchResult}
                            onNavigate={() => {
                                setIsVisible(false);
                                setQueryToSearchBy('');
                            }}
                            onUpdate={loadData}
                            refSearchField={searchFieldRef}
                        /> : <StyledEmptyWrapper>Nothing found</StyledEmptyWrapper>}
                    </Box>
                </StyledDropdown>
            )}
        </StyledDropdownContainer>
    )
}
import { setLocalStorage } from "./utils/SetLocalStorage/SetLocalStorage.util";
import { getLocalStorage } from "./utils/GetLocalStorage/GetLocalStorage.util";

export default class ChromeExtensionService {
    static async setLocalStorage(storage_data: { [key: string]: any }) {
        return setLocalStorage(storage_data);
    }
    static async getLocalStorage(keys: string[]) {
        return getLocalStorage(keys);
    }
}

import { useEffect, useState, useCallback } from 'react';

export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  timeout = 500,
  dependencies: any[] = []
): (immediate?: boolean, ...args: Parameters<T>) => void {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const debouncedCallback = useCallback(
    (immediate = false, ...args: Parameters<T>) => {
      if (timer) {
        clearTimeout(timer);
      }

      if (immediate) {
        callback(...args);
        setTimer(null);
      } else {
        const newTimer = setTimeout(() => {
          callback(...args);
          setTimer(null);
        }, timeout);

        setTimer(newTimer);
      }
    },
    [callback, timeout, timer, ...dependencies]
  );

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return debouncedCallback;
}

import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import styled from "styled-components";

export const StyledSectionForm = styled('div')`
    width: 50%;
    height: 100%;
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: ${BREAKPOINTS.small}px) {
        width: 100%;
    }
`;
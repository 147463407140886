import { FC, useCallback, useMemo, useState } from "react";
import { StyledAdd2Icon, StyledAddIcon, StyledButton, StyledDefaultEmojiIcon, StyledEmojiContainer, StyledEmojiIcon, StyledEmojiSelect, StyledEmojiWrapper, StyledMainIcon, StyledPageHeader } from "./Header.styled";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Menu } from "./components/Menu/Menu.component";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useSpaceCategoryPage } from "@/providers/v3/SpaceCategoryPage/SpaceCategoryPage.provider";
import { UpdateSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { ToastService } from "@/service/ToastService";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { AddButton } from "@/pages/v3/Space/components/Header/components/AddButton/AddButton.component";

export const Header: FC = () => {
  const {
    space,
  } = useSpacePageCommon();
  const {
    listOfNodes,
    update,
  } = useSpaceCategoryPage();
  const { loadSpaces } = useSpaces();
    const shouldRenederButtonText = useRenederAbove('tablet');
    const { addInSpace } = useAddInSpace();
    const [isOpenedSelect, setIsOpenedSelect] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const { isSharedSpace } = useIsShared();
    const isActive = isHover || isOpenedSelect;
    const icon = useMemo((): string => {
      const isPrivate = space && space.private;
      if (!space) {
        return '/icons/private-space-active-icon.svg'
      }
      if (isPrivate) {
        return '/icons/private-space-active-icon.svg';
      }
      return getIconPath(space.application, true);
  
    }, [space]);
    const updateEmoji = useCallback((emoji: string | null) => {
      !!space && UpdateSpaceFetch(space.id, {
        ...space,
        emoji: emoji,
      }).then(() => {
        update();
        loadSpaces();
        ToastService.showToast('success', 'Space emoji has been changed!');
      }).catch((err) => {
          ToastService.showToast('error', 'Some error occurred');
      });
  }, [loadSpaces, space, update]);
    const iconJsx = useMemo(() => {
      if (isSharedSpace) {
        return <StyledEmojiContainer>
          {!!space?.emoji && <StyledEmojiIcon >{space.emoji}</StyledEmojiIcon>}
          {!space?.emoji && <StyledMainIcon src={icon}/>}
        </StyledEmojiContainer>
      }
      return <StyledEmojiSelect isEmoji={!!space?.emoji} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onChange={(e) => updateEmoji(e.native)} onChangeIsOpened={setIsOpenedSelect}>
        {!!space?.emoji && <StyledEmojiIcon >{space.emoji}</StyledEmojiIcon>}
        {!space?.emoji && <StyledMainIcon src={icon}/>}
        <StyledEmojiWrapper isActive={isActive} >
          <StyledDefaultEmojiIcon isActive={isActive} />
          <StyledAdd2Icon isActive={isActive} />
        </StyledEmojiWrapper>
      </StyledEmojiSelect>
    }, [space?.emoji, icon, isActive, isSharedSpace, updateEmoji]);
    
    return <StyledPageHeader title={space?.name || ''} subtitle="Sense Space" icon={iconJsx} >
      {!isSharedSpace && <>
        {!!space && <Menu space={space} />}
        <AddButton />
      </>}
    </StyledPageHeader>
};
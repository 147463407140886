import { ApplicationIcon } from "@/components/ApplicationIcon/ApplicationIcon.component";
import { ArrowDropDown } from "@mui/icons-material";
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    border-radius: 6px;
    background: #FFF;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledContent = styled("div")`
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const StyledSpaceContant = styled('div')<{useCollapse: boolean}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: ${({useCollapse}) => useCollapse ? 'pointer' : 'default'};
`
export const StyledDropdownIcon = styled(ArrowDropDown)<{isOpened: boolean}>`
    transform: ${({isOpened}) => isOpened ? 'rotate(360deg)' : 'rotate(270deg)'};
    transition: transform 0.3s;
    margin-left: -10px;
    margin-right: 4px;
`
export const StyledSpaceName = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 12px;
`
export const StyledTagsContainer = styled('div')`
    border-top: 1px solid rgba(0, 0, 0, 0.08);
`
export const StyledNoTags = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 0px 10px 40px;
`

export const StyledSpaceImage = styled('img')`
    width: 24px;
    height: 24px;
`;
export const StyledEmojiWrapper = styled('div')`
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
import { styled } from "@mui/material";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledActivity = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 8px;
    border-radius: 6px;
    background: rgba(146, 102, 204, 0.08);
`;
export const StyledName = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
export const StyledChannel = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
export const StyledMassage = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    & a {
        word-break: break-all;
        color: #636363;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
        transition: color 0.3s;
        &.mention {
            color: #9266CC;
            font-weight: 500;
        }
        &:hover {
            color: #202020;
            text-decoration: underline;
        }
    }
`;
export const StyledDate = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
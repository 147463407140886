import { QueriesType } from '@/providers/Router/Router.type';
import { UseIsModalOpenedProps, UseIsModalOpenedReturnType } from './UseIsModalOpened.type';
import { useRouter } from '@/providers/Router/Router.provider';
import { useMemo } from 'react';

export const useIsModalOpened = (props: UseIsModalOpenedProps): UseIsModalOpenedReturnType => {
    const router = useRouter();
    const queryDialogs = useMemo(() => {
        const queryDialogs: string[] = [];
        if (Array.isArray(router.query.dialog)) {
            queryDialogs.push(...router.query.dialog);
        } else {
            if (router.query.dialog) {
                queryDialogs.push(router.query.dialog);
            }
        }
        return queryDialogs;
    }, [router.query.dialog]);
    const value = queryDialogs.includes(props.name);
    const onChange = (value: boolean, query?: QueriesType) => {
        if (value) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: props.name,
                    ...query
                }
            });
        } else {
            const newQuery: QueriesType = query ? query : {};
            if (Array.isArray(router.query.dialog)) {
                const newDoalogVal = router.query.dialog.filter((dialog) => dialog !== props.name);
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: newDoalogVal,
                        ...newQuery
                    }
                });
            } else {
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: undefined,
                        ...newQuery
                    }
                });
            }
        }
    };
    return { value, onChange };
};

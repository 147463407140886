import { Button, styled } from "@mui/material";
import { AddIcon } from "icons/add.icon";

export const StyledCreateSpaceButton = styled(Button)`
    display: flex;
    padding: 4px 16px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: rgb(146, 102, 204);
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    &:hover {
        background: rgb(146, 102, 204);
    }
`
export const StyledCreateSpaceIcon = styled(AddIcon)`
    width: 18px;
    height: 18px;
    & path {
        fill: #fff;
    }
`
export const StyledText = styled('div')`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export type AlertType = 'error' | 'success' | 'info' | 'warning';

const backgroundColors: Record<AlertType, string> = {
  warning: '#eb531b',
  error: '#f44336',
  success: '#498032',
  info: '#4781af',
};

type ToastType = { showToast(): void; toastElement: Element };

export class ToastService {
  static currentToasts: Array<ToastType> = [];

  static showToast(type: AlertType, text: string) {
    const toast = Toastify({
      text: text ?? 'Some error occurred',
      close: true,
      duration: type === 'error' ? 5000 : 3000,
      gravity: 'bottom',
      position: 'left',
      backgroundColor: backgroundColors[type],
    }) as ToastType;

    ToastService.currentToasts.push(toast);

    toast.showToast();
  }
}

import { FC, useCallback, useMemo } from "react";
import { PersonActivitiesProps } from "./PersonActivities.type";
import { StyledCaptionDot, StyledNode, StyledNodesTitle, StyledNodesWrapper, StyledPerson, StyledPersonCaption, StyledUserWrapper, StyledWrapper } from "./PersonActivities.styled";
import { Button } from "../../Fields/Button/Button.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";

export const PersonActivities: FC<PersonActivitiesProps> = ({
    nodes, person, type, className
}) => {
    const { user, invitedUsers, confirmedInvitedUsers } = useMe();
    const router = useRouter();
    const caption = type === 'workspace' ? 'My workspace' : 'External';
    const nodesJsx = nodes.map((node, index) => {
        return <StyledNode key={index} node={node} onUpdate={() => {}} />;
    });
    const isDisabled = useMemo(() => {
        if (person.email && invitedUsers.includes(person.email)) {
            return true;
        }
        return false;
    }, [invitedUsers, person.email]);
    const isConfirmed = useMemo(() => {
        if (confirmedInvitedUsers.find((user) => user.email === person.email)){
            return true;
        }
        return false;
    }, [confirmedInvitedUsers, person.email]);
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const sendInvite = useCallback(() => {
        const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
        if (isIndividual) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    invite: person.email,
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                }
            })
            return
        } else {
            person.email && user?.organizationId && router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'manage-users',
                    invite: person.email
                }
            })
        }
    }, [user?.paymentPlan.name, user?.organizationId, person.email, router])
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((e.target as Element).closest('.ignore--node-actions')) {
            return;
        }
        router.push({
            name: 'commonRelations',
            params: {
                fromUserId: person.id
            }
        })
    };
    return (
        <StyledWrapper className={className}>
            <StyledUserWrapper onClick={onClick}>
                <StyledPerson hasButton={type==='external'} person={person} caption={<StyledPersonCaption>
                    {caption}
                    <StyledCaptionDot />
                    {person.email}
                </StyledPersonCaption>} />
                {type==='external' && user?.isAdmin && !isConfirmed && <Button disabled={isDisabled} className="ignore--node-actions" onClick={sendInvite}>{isDisabled ? 'Pending invitation' : 'Invite user'}</Button>}
            </StyledUserWrapper>
            {!!nodes.length && <StyledNodesWrapper>
                <StyledNodesTitle>Recent updates</StyledNodesTitle>
                {nodesJsx}
            </StyledNodesWrapper>}
        </StyledWrapper>
    );
};
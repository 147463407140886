import styled from "@emotion/styled";
import { Arrow } from "./components/Arow/Arow.component";

export const StyledSpaceWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'active'
})<{active: boolean;}>`
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background: ${({ active }) => active ? 'rgba(0,0,0,.06)' : 'transparent'};
    border-radius: 8px;
    padding: 8px 8px;
    transition: all .2s ease-in-out;
    &:hover {
        background: rgba(0,0,0,.06);
    }
`;
export const StyledSpaceImage = styled('img')`
    width: 24px;
    height: 24px;
    margin-right: 12px;
`;
export const StyledSpaceContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 10000 1 0%;
`;
export const StyledSpaceName = styled('div')`
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
`;
export const StyledSpaceNotify = styled('div')`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--Accent-Primary, #9266CC);
`;
export const StyledEmojiWrapper = styled('div')`
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
`;
export const StyledEmoji = styled('div')`
    padding-top: 2px;
    font-size: 16px;
`;
export const StyledArrow = styled(Arrow, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{isActive: boolean}>`
    width: 16px;
    height: 16px;
    transition: .2s ease-in-out;
    transform: ${({isActive}) => isActive ? 'rotate(0)' : 'rotate(-90deg)'};
`;
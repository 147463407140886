import { envUrl } from 'service/helpers';
import { NodeRelationsGetQueryParams } from './Types/Get/Get.type';
import { SetNodeRelationsQueryParams, SetNodeRelationsBodyParams } from './Types/SetNodeRelations/SetNodeRelations.type';
import { HttpClient } from '@/axios/HttpClient';

export const GetNodeRelationsFetch = (query: NodeRelationsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/nodes/relations`, {
        params: {
            ...query,
            nodeIds: query.nodeIds?.join(','),
        },
    });
}
export const SetNodeRelationsFetch = (body: SetNodeRelationsBodyParams, query: SetNodeRelationsQueryParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/nodes/relations`, body, {
        params: query,
    });
}
import { FC, useMemo } from "react";
import { ActorProps } from "./Actor.type";
import { StyledAction, StyledDate, StyledName, StyledWrapper, StyledNameWrapper, StyledNodeWrapper } from "./Actor.styled";
import { getFullName } from "@/utils/v3/GetFullName/GetFullName.util";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { UserAvatar } from "@/components/v3/Other/UserAvatar/UserAvatar.component";
import { actionTypeToText } from "@/utils/v3/ActionTypeToText/ActionTypeToText.util";
import { formatActivityDate } from "@/utils/v3/FormatActivityDate/FormatActivityDate.util";

export const Actor: FC<ActorProps> = ({actor}) => {
    const isMobile = useRenederBelow('tablet');
    const fullName = useMemo(() => {
        return getFullName(actor?.user?.firstName, actor?.user?.lastName);
    }, [actor?.user?.firstName, actor?.user?.lastName]);
    const date = actor.properties.happenedAt
    const dateStr = useMemo(() => {
        return date ? formatActivityDate(date) : '';
    }, [date]);
    const nodeWithDateJsx = <>
        <StyledDate>{dateStr}</StyledDate>
    </>
    const action = useMemo(() => {
        if (!!actor?.properties.fromState && !!actor?.properties.toState && actor?.actionType === 'UPDATED' && !!actor?.properties.toState) {
            return `updated status to: ${actor?.properties.toState}`;
        } else {
            return actionTypeToText(actor?.actionType);
        }
    }, [actor?.actionType, actor?.properties.fromState, actor?.properties.toState]);
    return <StyledWrapper>
        <StyledNameWrapper>
            <UserAvatar avatar={actor.user?.avatar} />
            <StyledName>{fullName} <StyledAction>
                {action}
            </StyledAction></StyledName>
        </StyledNameWrapper>
        {isMobile && <StyledNodeWrapper>{nodeWithDateJsx}</StyledNodeWrapper>}
        {!isMobile && nodeWithDateJsx}
    </StyledWrapper>
};
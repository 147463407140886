import { useEffect, useState } from "react"
import { OtpValidateFetch } from "@/axios/OrganizationService/Otp/Otp.api";
import { OtpValidateAxiosResponseSuccessType } from "@/axios/OrganizationService/Otp/Types/Validate/Validate.type";
import { useRouter } from "providers/Router/Router.provider";
import { useMe } from "providers/MeProvider/Me.provider";
import { StyledButton, StyledCard, StyledDescription, StyledInput, StyledLoginPage, StyledSubmitButton, StyledTitle } from "../v3/Login/Login.styled";
import { ErrorsType } from "./TwoFA.type";
import { Terms } from "../v3/Login/components/Terms/Terms.component";

export const TwoFA: React.FC = () => {
    const router = useRouter();
    const [errors, setErrors] = useState<ErrorsType>({});
    const [code, setCode] = useState<string>('');
    const { login } = useMe();
    useEffect(() => {
        if (code) {
            setErrors({});
        }
    }, [code]);
    const validate = () => {
        let tempErrors: ErrorsType = {};
        if (!code) tempErrors.code = "Code is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    const submit = async () => {
        if (validate()) {
            await OtpValidateFetch({
                code,
                secret: router.params.secret as string
            }).then(async (res: OtpValidateAxiosResponseSuccessType) => {
                login(res.data.accessToken, () => {
                    router.push({
                        name: 'forYou'
                    })
                });
            }).catch(() => {
                setErrors({ code: "Invalid code" });
            })
        }
    };
    return <StyledLoginPage>
      <div>
        <StyledTitle>Two-Factor Authentication</StyledTitle>
        <StyledDescription>Open Authenticator App and enter code<br /> generated for your Sense account</StyledDescription>
        <StyledCard>
          <div>
            <StyledInput error={errors.code} value={code} onChange={(e) => setCode(e.target.value)} label="Code" />
          </div>
          <StyledSubmitButton size="large" onClick={submit}>Authenticate</StyledSubmitButton>
        </StyledCard>
        <StyledButton onClick={() => router.push({
          name: 'login'
        })} variant="tertiary">Back to Login</StyledButton>
      </div>
      <Terms />
    </StyledLoginPage>
}
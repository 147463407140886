import { RouterContextType } from "@/providers/Router/Router.type";

export const isShared = (router: RouterContextType): {
    isSharedSpace: boolean;
    isSharedNode: boolean;
} => {
    const isSharedSpace = router.type.includes('public') && (router.name === 'publicSpace' || router.name === 'publicSpaceCategory'  || router.name === 'publicSpaceTag');
    const isSharedNode = router.type.includes('public') && router.name === 'publicNode';
    return {
        isSharedSpace,
        isSharedNode
    }
};
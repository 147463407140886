import { NodeNeighboursDrawer } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/NodeNeighboursDrawer.component";
import { NodeNeighboursContent } from "./NodeNeighbours.content";

export const NodeNeighboursPage = () => {
  return (
    <>
      <NodeNeighboursContent />
      <NodeNeighboursDrawer />
    </>
  );
};

import { FC, useCallback, useMemo } from "react";
import { StyledPageHeader } from "./Header.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { HeaderProps } from "./Header.type";
import { UserAvatar } from "@/components/v3/Other/UserAvatar/UserAvatar.component";

export const Header: FC<HeaderProps> = ({
    user
}) => {
    const { user: userMe, invitedUsers, confirmedInvitedUsers } = useMe();
    const router = useRouter();
    const isGhostLabel = user?.isGhost ? 'External' : 'My workspace'
    const label = user?.email ? `${isGhostLabel} · ${user.email}` : '';
    const isDisabled = useMemo(() => {
        if (invitedUsers.includes(user.email)) {
            return true;
        }
        return false;
    }, [invitedUsers, user.email]);
    const isConfirmed = useMemo(() => {
        if (confirmedInvitedUsers.find((confirmedUser) => confirmedUser.email === user.email)){
            return true;
        }
        return false;
    }, [confirmedInvitedUsers, user.email]);
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const sendInvite = useCallback(() => {
        const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
        if (isIndividual) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    invite: user.email,
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                }
            })
            return
        }
        user.email && userMe?.organizationId && router.push({
            name: router.name,
            params: router.params,
            query: {
                ...router.query,
                dialog: 'manage-users',
                invite: user.email
            }
        })
    }, [user?.paymentPlan.name, user.email, userMe?.organizationId, router])
    return <StyledPageHeader
        title={`${user?.firstName ? user.firstName : 'no name'} ${user?.lastName ? user.lastName : ''}`}
        subtitle={label}
        icon={<UserAvatar avatar={user.avatar} />}
    >
        {userMe?.id !== user.id && user?.isGhost && userMe?.isAdmin && !isConfirmed && <Button onClick={sendInvite} disabled={isDisabled}>Invite user</Button>}
    </StyledPageHeader>
};
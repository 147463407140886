import { ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";

export const StyledListItemButton = styled(ListItemButton)`
    height: 40px;
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    padding-left: 56px;
    padding-right: 16px;
    cursor: pointer;
    & img {
        width: 24px;
        margin-right: 8px;
        &.avatar {
            border-radius: 50%;
        }
    }
    &:hover {
        background: rgba(0,0,0,.08);
    }
`;
export const StyledListItemText = styled(ListItemText)`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
`;
export const StyledListItemIcon = styled(ListItemIcon)`
    display: flex;
    justify-content: flex-end;
    min-width: 24px;
`;
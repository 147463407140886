import { FC } from "react";
import { TabValueType, TabsProps } from "./Tabs.type";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";

export const Tabs: FC<TabsProps> = ({
    value,
    onChange
}) => {
    return <StyledWrapper>
        <StyledTabs value={value} onChange={(newVal) => onChange(newVal as TabValueType)}>
            <StyledTab value="ALL">All</StyledTab>
            <StyledTab value="MY_WORKSPACE">My workspace</StyledTab>
            <StyledTab value="EXTERNAL">External</StyledTab>
        </StyledTabs>
    </StyledWrapper>
};
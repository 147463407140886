import { Box } from '@mui/material';
import { HttpClient } from '../../../axios/HttpClient';
import { ToastService } from '../../../service/ToastService';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {envUrl} from "../../../service/helpers";
import { ErrorsType } from './ChangePassword.type';
import { StyledFormWrapper } from '../SignupFromInvite/SignupFromInvite.styled';
import { StyledLoginPage, StyledTitle, StyledCard, StyledInput, StyledSubmitButton } from '../Login/Login.styled';
import { useRouter } from '@/providers/Router/Router.provider';
import { Terms } from '../Login/components/Terms/Terms.component';

export const ChangePassword = () => {

  const [isSent, setIsSent] = useState<boolean>(false);
  const history = useHistory();
  const router = useRouter();
  const resetKey = router.query.resetKey as string;

  const [errors, setErrors] = useState<ErrorsType>({});
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const validate = () => {
    let tempErrors: ErrorsType = {};

    if (!password) tempErrors.password = "Password is required";
    else if (password.length < 8) tempErrors.password = "Password is too short - should be 8 chars minimum";
    else if (!/\d/.test(password)) tempErrors.password = "Password must contain at least one number character";
    else if (!/[!@#$%^&*]/.test(password)) tempErrors.password = "Password must contain at least one special character";

    if (!rePassword) tempErrors.rePassword = "Please confirm your password";
    else if (rePassword !== password) tempErrors.rePassword = "Passwords must match";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const submit = () => {
    if (validate()) {
      const changePasswordRequest = {
        resetKey: resetKey,
        newPassword: password,
      };
      HttpClient.post(`${envUrl}/organization-service/v1/users/change-password`, changePasswordRequest)
        .then(response => response.data)
        .then(data => {
          setIsSent(true);
          ToastService.showToast('success', 'Password successfully changed');
          setTimeout(() => history.push('/login'), 5000);
        })
        .catch(error => {
          ToastService.showToast('error', error?.response?.data?.errorMessage);
        });
    }
  }
  return (
    <StyledLoginPage>
      <StyledFormWrapper>
        {!isSent && (
          <>
            <StyledTitle>Change password</StyledTitle>
            <StyledCard>
              <div>
                <StyledInput error={errors.password} value={password} onChange={(e) => setPassword(e.target.value)} label="Password" type="password" />
              </div>
              <div>
                <StyledInput error={errors.rePassword} value={rePassword} onChange={(e) => setRePassword(e.target.value)} label="Confirm password" type="password" />
              </div>
              <StyledSubmitButton size="large" onClick={submit}>Change password</StyledSubmitButton>
            </StyledCard>
          </>
        )}
        {isSent && (
          <Box>
            Password was successfully changed. You will be redirected to the login page in the next couple of seconds
          </Box>
        )}
      </StyledFormWrapper>
      <Terms />
    </StyledLoginPage>
  );
};

import { NodeType, RelationshipsType } from "@/components/v3/List/Node/Node.type";
import { NodeTypeType } from "@/types/Node/Node.type";
export const relationships: RelationshipsType = {
    "actors": [
        {
            "actionType": "",
            "user": {
                "id": "1",
                "firstName": "",
                "lastName": "",
                "avatar": "/users/1.png",
            },
            "userId": "1"
        },
        {
            "actionType": "",
            "user": {
                "id": "2",
                "firstName": "",
                "lastName": "",
                "avatar": "/users/2.png",
            },
            "userId": "2"
        },
        {
            "actionType": "",
            "user": {
                "id": "3",
                "firstName": "",
                "lastName": "",
                "avatar": "/users/3.jpg",
            },
            "userId": "3"
        }
    ],
  }
export const listOfNodes: {
    node: NodeType;
    relationships: RelationshipsType;
}[] = [
    {
        node: {
            "id": 1,
            "nodeType": NodeTypeType.EMAIL,
            "name": "Re: Team capacity Q1",
            "originalURI": "mail.google.com",
            "application": "Gmail",
            "relationshipNumber": 2,
            "activitiesNumber": 0,
            "createdAt": "2023-11-09T14:35:10",
            "categories": [
                "email"
            ],
        },
        relationships: {
            actors: [
                relationships.actors[0],
                relationships.actors[1]
            ]
        }
    },
    {
        node: {
            "id": -1,
            "nodeType": NodeTypeType.EMAIL,
            "name": "Project Requirements",
            "originalURI": "outlook",
            "application": "Outlook",
            "relationshipNumber": 4,
            "activitiesNumber": 0,
            "createdAt": "2023-11-09T14:35:10",
            "categories": [
                "email"
            ],
        },
        relationships: {
            actors: [
                relationships.actors[2],
                relationships.actors[1]
            ]
        }
    }
];
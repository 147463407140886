import { FC } from "react";
import { UserProps } from "./User.type";
import { Person } from "@/components/v3/List/Person/Person.component";
import { StyledWrapper } from "./User.styled";
import { Checkbox } from "@/components/v3/Fields/Checkbox/Checkbox.component";

export const User: FC<UserProps> = ({
    user, value, onChange,
}) => {
    const isActive = value.includes(user.id);
    const handleClick = () => {
        if (isActive) {
            onChange(value.filter((id) => id !== user.id));
        } else {
            onChange([...value, user.id]);
        }
    };
    return <StyledWrapper onClick={handleClick}>
        <Checkbox checked={isActive} />
        <Person person={user} />
    </StyledWrapper>
};
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { NodeType, NodeWrapperType } from "./GetAppOptionsFromNodes.type";

const getAppOptions = <T>(list: T[], getApplication: (item: T) => string): OptionType<string>[] => {
    const seen = new Set<string>();
    return list
        .filter((item) => {
            const application = getApplication(item);
            const isDuplicate = seen.has(application);
            seen.add(application);
            return !isDuplicate;
        })
        .map((item) => {
            return { label: getApplication(item), value: getApplication(item) };
        });
};

export const getAppOptionsFromNodes = (listOfNodes: NodeType[]): OptionType<string>[] => {
    return getAppOptions(listOfNodes, (node) => node.application);
};

export const getAppOptionsFromNodeWrappers = (listOfNodeWrappers: NodeWrapperType[]): OptionType<string>[] => {
    return getAppOptions(listOfNodeWrappers, (wrapper) => wrapper.node.application);
};

import { HttpClient, HttpLoginClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { UsersGetQueryParams } from './Types/Get/Get.type';
import { LoginBodyParams } from './Types/Login/Login.type';
import { LoginSSOBodyParams } from './Types/LoginSSO/LoginSSO.type';
import { PatchRolesQueryParams } from "./Types/PatchRoles/PatchRoles.type";
import { DeleteRolesQueryParams } from "./Types/DeleteRoles/DeleteRoles.type";
import { UpdateNotificationSettingsBodyParams } from "./Types/UpdateNotificationSettings/UpdateNotificationSettings.type";
import { InvitesBodyParams } from "./Types/Invites/Invites.type";
import { PatchBodyParams } from "./Types/Patch/Patch.type";
import { GetInvitesQueryParams } from "./Types/GetInvites/GetInvites.type";

export const GetUsersFetch = (query: UsersGetQueryParams) => {
    return HttpClient.get(`${envUrl}/organization-service/v1/users`, {
        params: query,
    });
}
export const GetUserFetch = (id: string) => {
    return HttpClient.get(`${envUrl}/organization-service/v1/users/${id}`);
}
export const LoginFetch = (body: LoginBodyParams) => {
    return HttpLoginClient.post(`${envUrl}/organization-service/v1/users/login`, body);
}
export const SessionFetch = () => {
    return HttpClient.get(`${envUrl}/organization-service/v1/users/me/session`);
}
export const LoginSSOFetch = (body: LoginSSOBodyParams) => {
    return HttpLoginClient.post(`${envUrl}/organization-service/v1/users/login/sso`, body);
}
export const LogoutFetch = (id: string) => {
    return HttpClient.post(`${envUrl}/organization-service/v1/users/${id}/logout`);
}
export const UpdateFetch = (id: string, body: PatchBodyParams) => {
    return HttpClient.patch(`${envUrl}/organization-service/v1/users/${id}`, body);
}
export const UpdateRoleFetch = (id: string, query: PatchRolesQueryParams) => {
    return HttpClient.patch(`${envUrl}/organization-service/v1/users/${id}/roles`, null, {
        params: query
    });
}
export const DeleteRoleFetch = (id: string, query: DeleteRolesQueryParams) => {
    return HttpClient.delete(`${envUrl}/organization-service/v1/users/${id}/roles`, {
        params: query
    });
}
export const GetInvitesFetch = (query: GetInvitesQueryParams) => {
    return HttpClient.get(`${envUrl}/organization-service/v1/users/invites`, {
        params: query
    });
}
export const SendInvitesFetch = (body: InvitesBodyParams) => {
    return HttpClient.post(`${envUrl}/organization-service/v1/users/invites`, body);
}
export const UpdateNotificationSettingsFetch = (id: string, body: UpdateNotificationSettingsBodyParams) => {
    return HttpClient.patch(`${envUrl}/organization-service/v1/users/${id}/update-notification-settings`, body);
}
import { FC } from "react";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { KeyResourcesProps } from "./KeyResources.type";
import { StyledAddIcon } from "../../../Sidebar/Sidebar.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { StyledEmptyCardWrapper, StyledNode, StyledNodesWrapper, StyledScrollWrapper, StyledSectionHeader, StyledWrapper } from "./KeyResources.styled";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

export const KeyResources: FC<KeyResourcesProps> = ({
    onUpdate
}) => {
    const {
        isFetchingKeyResources,
        listOfKeyResources,
    } = useSpacePageCommon();
    const { addInSpace } = useAddInSpace();
    const { isShared } = useIsShared();
    const isLargeScreen = useRenederAbove('large');
    const countOfNodes = isLargeScreen ? 8 : 6;
    const listOfAllKeyResources = [...listOfKeyResources];
    const { items, showMore, setShowMore } = useShowMore({ items: listOfAllKeyResources, limit: countOfNodes });
    const listOfAllKeyResourcesJsx = items.map((keyResource, i) => {
        return <StyledNode node={keyResource.node} onUpdate={onUpdate} key={i} />
    });
    return <LoaderLayout isLoading={isFetchingKeyResources} transparent={!!listOfAllKeyResources.length}>
        <StyledWrapper>
            {(!isShared || (isShared && !!listOfAllKeyResources.length)) && <StyledSectionHeader title="Key resources">
                {!!listOfAllKeyResources.length && !isShared && <Button 
                    onClick={() => addInSpace(true)}
                    icon={<StyledAddIcon />}
                    variant="tertiary"
                >Add</Button>}
            </StyledSectionHeader>}
            <LoaderLayout isLoading={isFetchingKeyResources} transparent={!!listOfAllKeyResources.length}>
                <EmptyStateLayoyt
                    value={!listOfAllKeyResources.length && !isFetchingKeyResources}
                    emptyNode={!isShared && <StyledEmptyCardWrapper>
                        <EmptyCard title="Add key resources" onClick={() => addInSpace(true)}>
                            Add the most important resources for this space
                        </EmptyCard>
                    </StyledEmptyCardWrapper>}
                >
                    <StyledScrollWrapper>
                        <StyledNodesWrapper countOfNodes={countOfNodes/2}>
                            {listOfAllKeyResourcesJsx}
                        </StyledNodesWrapper>
                    </StyledScrollWrapper>
                    {!showMore && listOfAllKeyResources.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
                </EmptyStateLayoyt>
            </LoaderLayout>
        </StyledWrapper>
    </LoaderLayout>
};
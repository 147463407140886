import { FC, useEffect, useMemo, useState } from "react";
import { AddFromCategoryProps } from "./AddFromCategory.type";
import { NodeType } from "../AddNodeItem/AddNodeItem.type";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { AddNodeItem } from "../AddNodeItem/AddNodeItem.component";
import { StyledSearchIcon, StyledSearchIconWrapper } from "@/components/v3/Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled";
import { Input } from "@/components/v3/Fields/Input/Input.component";
import { StyledFilledCard, StyledSectionHeader } from "./AddFromCategory.styled";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { Typography } from "@mui/material";
import { Select } from "@/components/v3/Fields/Select/Select.component";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { getAppOptionsFromNodes } from "@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { StyledCloseIcon } from "@/components/v3/Layouts/MainLayout/components/Header/components/Search/Search.styled";
import { EmptyState } from "./components/EmptyState/EmptyState.component";

export const AddFromCategory: FC<AddFromCategoryProps> = ({
    setIsFetchingLocal,
    listOfAddedNodes,
    onAdd, onRemove,
    category
}) => {
    const [listOfNodes, setListOfNodes] = useState<NodeType[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const appsOptions = useMemo((): OptionType<string>[] => {
        return getAppOptionsFromNodes(listOfNodes);
    }, [listOfNodes]);
    const loadListOfNodes = async () => {
        setIsFetchingLocal(true);
        await GetNodesFetch({
            categories: category.categories,
        }).then((res) => {
            setListOfNodes(res.data);
        });
        
        setIsFetchingLocal(false);
    };
    useEffect(() => {
        loadListOfNodes();
    }, []);
    const filteredListOfNodes = useMemo(() => {
        return listOfNodes.filter((node) => {
            if (selectedApps.length && !selectedApps.includes(node.application)) {
                return false;
            }
            if (!node.name.toLowerCase().includes(searchValue.toLowerCase()) && searchValue !== '') {
                return false;
            }
            return true;
        });
    }, [listOfNodes, searchValue, selectedApps]);
    const filteredListOfNodesJsx = filteredListOfNodes.map(node => {
        return (
          <AddNodeItem
            listOfAddedNodes={listOfAddedNodes}
            node={node}
            onAdd={onAdd}
            onRemove={onRemove}
            key={node.id}
          />
        )
      });
    
    return (
        <EmptyStateLayoyt
            value={!listOfNodes.length}
            emptyNode={<EmptyState categoryName={category.name} listOfApps={category.listOfApps} />}
        >
            <Input
                placeholder="Search in Sense"
                variant="filled"
                size='medium'
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                InputProps={{
                    startAdornment: (
                        <StyledSearchIconWrapper>
                            <StyledSearchIcon />
                        </StyledSearchIconWrapper>
                    ),
                    endAdornment: <>
                        {!!searchValue && <StyledCloseIcon onClick={() => setSearchValue('')} />}
                    </>,
                }}
            />
            <StyledFilledCard>
                <EmptyStateLayoyt
                    value={!filteredListOfNodes.length}
                    emptyNode={<Typography variant="body1" style={{ padding: '0 24px' }}>
                        No nodes found
                    </Typography>}
                >
                    <StyledSectionHeader title={category.name} >
                        {!!appsOptions.length && <Select
                            label="Apps"
                            value={selectedApps}
                            onChange={setSelectedApps}
                            options={appsOptions}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                            icon={<FilterIcon />}
                        />}
                    </StyledSectionHeader>
                    {filteredListOfNodesJsx}
                </EmptyStateLayoyt>
            </StyledFilledCard>
        </EmptyStateLayoyt>
    );
};
import { styled } from "@mui/material";
import { NodeIcon } from "@/components/v3/Other/NodeIcon/NodeIcon.component";

export const StyledWrapper = styled("div")`
    border-radius: 6px;
    background: #FFF;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledInfoWrapper = styled("div")`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: calc(100% - 80px);
`;
export const StyledNodeIcon = styled(NodeIcon)`
    width: 32px;
    height: 32px;
`;
export const StyledName = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 8px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
`;
export const StyledNoteContent = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    & * {
        margin: 0;
        color: #202020;
        font-family: Poppins;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
    }
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
    padding-right: 2px;
`;
import { FC } from "react";
import { ExternalProps } from "./External.type";
import { StyledPersonActivities, StyledSectionHeader } from "../All/All.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { ExternalEmptyState } from "../ExternalEmptyState/ExternalEmptyState.component";

export const External: FC<ExternalProps> = ({
    isFetching,
    externalUsers
}) => {
    const externalUsersJsx = externalUsers.map((el) => {
        return <StyledPersonActivities person={el.person} nodes={el.activities} type="external" key={el.userId} />
    });
    const isEmpty = !externalUsers.length && !isFetching;
    return <LoaderLayout isLoading={isFetching} transparent={!!externalUsers.length}>
        <StyledWrapper>
            <StyledSectionHeader title="External" />
            <EmptyStateLayoyt
                value={isEmpty}
                emptyNode={<ExternalEmptyState />}
            >
                <FilledCard>
                    {externalUsersJsx}
                </FilledCard>
            </EmptyStateLayoyt>
        </StyledWrapper>
    </LoaderLayout>
};
import { FC } from "react";
import { StyledContainer, StyledLink, StyledTermsLink, StyledWrapper } from "./Terms.styled";

export const Terms: FC = () => {
    return <StyledWrapper>
        <StyledContainer>
            <StyledTermsLink target="_blank" href="/terms">Terms of Service</StyledTermsLink>
            <StyledLink target="_blank" href="https://www.senseapp.ai/privacy" rel="noreferrer">Privacy</StyledLink>
        </StyledContainer>
    </StyledWrapper>
};
import styled from "@emotion/styled";
import { ListItemButton } from "@mui/material";

export const StyledListItemButton = styled(ListItemButton)`
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    & img {
        width: 24px;
        margin-right: 8px;
    }
    &:hover {
        background: rgba(0,0,0,.08);
    }
`;
export const StyledEmojiWrapper = styled('div')`
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
`;
export const StyledEmoji = styled('div')`
    padding-top: 2px;
    font-size: 16px;
`;
import { Box, styled } from "@mui/material";
import { Node } from "@/components/v3/List/Node/Node.component";
import { Person } from "@/components/v3/List/Person/Person.component";

export const StyledWrapper = styled('div')`
    padding: 16px;
    padding-top: 8px;
`;
export const StyledNode = styled(Node)`
    margin-top: 8px;
`;
export const StyledPerson = styled(Person)`
    padding: 12px 8px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    margin-top: 8px;
    transition: all 0.1s ease-in-out;
    &:hover {
        border: 1px solid #202020;
    }
`;
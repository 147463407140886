import { Box, Button, styled } from "@mui/material";

export const StyledPlansWrapper = styled(Box)`
    width: 720px;
`;
export const StyledOnboardingContent = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 40px;
`;
export const StyledOnboardingStep = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    max-width: 720px;
    width: 100%;
`;
export const StyledBtnContainer = styled(Box)`
    display: flex;
    justify-content: end;
`;
export const StyledButton = styled(Button)`
    background-color: #202020;
    color: white;
    &:hover {
        background-color: #202020;
    }
`;
import { SpaceIconProps } from "./SpaceIcon.type";
import { StyledEmojiIcon, StyledImg, StyledMaskedImg, StyledWrapper } from "./SpaceIcon.styled";
import { useMemo } from "react";
import { getIconPath } from "../../Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const SpaceIcon = ({ space, className, useSkeleton = true }: SpaceIconProps) => {
  const { user } = useMe();
  const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED' && useSkeleton;
  const imgSrc = useMemo(() => {
    if (space.private) {
      return '/icons/space.svg'
    }
    return getIconPath(space.application, true);
  }, [space]);
  return <StyledWrapper className={className}>
    
    {!isSubscriptionExpired && <>
      {!space.emoji && <StyledImg src={imgSrc} />}
      {!!space.emoji && <StyledEmojiIcon>{space.emoji}</StyledEmojiIcon>}
    </>}
    {isSubscriptionExpired && <StyledMaskedImg className={`skeleton-img`} />}
  </StyledWrapper>;
};

import { FC, useEffect, useMemo, useState } from 'react';
import {
    DialogActions,
    Typography,
} from '@mui/material';
import { SelectPeopleDialogProps } from "./SelectPeopleDialog.type";
import { getFullName } from 'utils/getFullName';
import { StyledCancel, StyledDialog, StyledDialogContent, StyledDialogTitle, StyledSubmitButton, StyledTextField } from './SelectPeopleDialog.styled';
import { Checkbox } from './components/Checkbox/Checkbox.components';

export const SelectPeopleDialog: FC<SelectPeopleDialogProps> = ({
    title,
    listOfUsers,
    value, onChange,
    selectedUsers, onChangeSelectedUsers
}) => {
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    useEffect(() => {
        value && setSelectedPeople(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])
    const addUsersHandler = () => {
        onChangeSelectedUsers(selectedPeople);
        onChange(false)
    };
    const filteredListOfUsers = useMemo(() => {
        return listOfUsers.filter(person => {
            const fullName = getFullName(person)
            return fullName.includes(searchValue) || person.email?.includes(searchValue);
        })
    }, [listOfUsers, searchValue])
    const listOfUsersJsx = filteredListOfUsers.map((person) => {
        const onChangeHandler = (value: boolean) => {
            if (value) {
                setSelectedPeople((selectedPeople: string[]) => [...selectedPeople, person.id]);
            } else {
                setSelectedPeople((selectedPeople: string[]) => selectedPeople.filter((el) => el !== person.id));
            }
        }
        const fullName = getFullName(person)
        const value = selectedPeople.includes(person.id)
        return <Checkbox
            key={person.id}
            label={fullName}
            caption={person.email}
            img={person.avatar || '/icons/no_avatar.svg'}
            value={value}
            onChange={onChangeHandler}
        />
    })
    return (
        <StyledDialog open={value} aria-labelledby="form-dialog-title" >
            <StyledDialogTitle id="form-dialog-title">
                <Typography variant="h1" component="h1" style={{ fontSize: '24px', fontWeight: 600}}>
                    {title}
                </Typography>
            </StyledDialogTitle>
            <StyledDialogContent>
                <StyledTextField style={{ width: '100%' }} label="Find user" variant="outlined" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                {listOfUsersJsx}
            </StyledDialogContent>
            <DialogActions>
                <StyledCancel onClick={() => onChange(false)} color="primary" variant="contained">
                    Cancel
                </StyledCancel>
                <StyledSubmitButton
                    onClick={() => addUsersHandler()}
                    variant="contained"
                >
                    Done
                </StyledSubmitButton>
            </DialogActions>
        </StyledDialog>
    );
};

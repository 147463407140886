import { FC, useRef, useState } from "react";
import { StyledAddIcon, StyledArrow } from "./AddButton.styled";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { Popover } from "@mui/material";
import { StyledDropdownContainer, StyledDropdownWrapper } from "@/components/v3/Fields/DropdownField/DropdownField.styled";
import { MenuItem } from "@/components/v3/Other/MenuItem/MenuItem.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { Button } from "@/components/v3/Fields/Button/Button.component";

export const AddButton: FC = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const { addInSpace } = useAddInSpace();
    const ref = useRef<HTMLButtonElement | null>(null);
    const fullWidth = false;
    const router = useRouter();
    return (
        <>
            <Button ref={ref} onClick={() => setIsOpened(true)} icon={<StyledAddIcon />}>
                Add
                <StyledArrow
                    width="16px"
                    height="16px"
                    active={isOpened}
                />
            </Button>
            <Popover
                open={isOpened}
                anchorEl={ref.current}
                onClose={() => setIsOpened(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <StyledDropdownContainer width={fullWidth && ref.current ? ref.current.offsetWidth : undefined} >
                    <StyledDropdownWrapper onClick={() => {
                        setTimeout(() => {
                            setIsOpened(false)
                        }, 0);
                    }}>
                        <MenuItem onClick={() => addInSpace()}>Add resources...</MenuItem>
                        <MenuItem onClick={() => router.push({
                            name: router.name,
                            params: router.params,
                            query: {
                                ...router.query,
                                dialog: 'create-note'
                            }
                        })}>Create note</MenuItem>
                    </StyledDropdownWrapper>
                </StyledDropdownContainer>
            </Popover>
        </>
    )
};
import { FC } from 'react';
import { MentionAreaProps } from './MentionArea.type';
import { MentionsInput, Mention } from 'react-mentions';

export const MentionArea: FC<MentionAreaProps> = ({
    value, onChange, mentions, className, singleLine = false, bottom
}) => {
    const transformedMentions = mentions.map(({ value, label, avatar }) => ({
        id: value,
        display: `@${label}`,
        avatar
    }));
    const host = document.getElementById('mentions') || document.body;
    let style: any = {
        minWidth: '400px',
        minHeight: !singleLine ? '100px' : undefined,
        highlighter: {
            overflow: 'hidden',
            color: '#9266CC',
            padding: '8px 16px',
            lineHeight: '20px',
            pointerEvents: 'none',
            fontWeight: 'normal',
            zIndex: 1,
        },
        input: {
            overflow: 'hidden',
            padding: '8px 16px',
            lineHeight: '20px',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            borderRadius: '4px',
            background: 'white',
        },
        suggestions: {
            zIndex: 100000,
            list: {
                backgroundColor: 'white',
                boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.24)',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                padding: '8px 0',
            },
            item: {
                padding: '12px 16px',
                borderBottom: '1px solid rgba(0,0,0,0.15)',

                '&focused': {
                    backgroundColor: 'rgba(0, 0, 0, .06)',
                },
            },
        },
    }
    if (bottom) {
        style.suggestions.top = 'unset';
        style.suggestions.bottom = bottom;
    }
    return (
        <MentionsInput
            suggestionsPortalHost={host}
            className={className}
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            placeholder='Type your comment...'
            style={style}>
            <Mention
                trigger="@"
                data={transformedMentions}
                renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
                    return (
                        <div className={`suggestion ${focused ? 'focused' : ''}`}>
                            {highlightedDisplay}
                        </div>
                    )
                }}
            />
        </MentionsInput>
    );
};

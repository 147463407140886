import { styled, Radio } from "@mui/material";

export const StyledContainer = styled('label')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.1s ease-in-out;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
`;
export const StyledRadio = styled(Radio)`
    & svg {
        fill: #202020;
    }
`;
export const StyledTitle = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
export const StyledCaption = styled('div')`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
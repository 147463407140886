import { IntegrationType } from "./CommingSoon.type";

export const listOfComingSoon: IntegrationType[] = [
    {
        appName: 'Airtable',
        icon: '/icons/apps/airtable.png'
    },
    {
        appName: 'Gitlab',
        icon: '/icons/apps/gitlab.png'
    },
    {
        appName: 'Intercom',
        icon: '/icons/apps/intercom.png'
    },
    {
        appName: 'Miro',
        icon: '/icons/icon-miro.png'
    },
    {
        appName: 'Salesforce',
        icon: '/icons/apps/salesforce.png'
    },
    {
        appName: 'Zendesk',
        icon: '/icons/apps/zendesk.png'
    },
    {
        appName: 'Zoom',
        icon: '/icons/apps/zoom.png'
    },
]
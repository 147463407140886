import { FC } from "react";
import { SpaceTagProps } from "./SpaceTag.type";
import { StyledSpaceTagContainer, StyledSpaceTagText, StyledDarkTagIcon, StyledWhiteTagIcon } from "./SpaceTag.styled";
import { useRouter } from "@/providers/Router/Router.provider";

export const SpaceTag: FC<SpaceTagProps> = ({ tag }) => {
    const router = useRouter();
    const isActive = router.name==='publicSpaceTag' && router.params.tagId === tag.id;
    return <StyledSpaceTagContainer isActive={isActive} onClick={() => router.push({
        name: 'publicSpaceTag',
        params: {
            tagId: tag.id,
            code: router.params.code as string,
        }
    })}>
        {isActive && <StyledDarkTagIcon />}
        {!isActive && <StyledWhiteTagIcon />}
        <StyledSpaceTagText >{tag.name}</StyledSpaceTagText>
    </StyledSpaceTagContainer>
}
import { Button } from '@/components/v3/Fields/Button/Button.component';
import { TextSkeleton } from '@/components/v3/Skeleton/TextSkeleton/TextSkeleton.component';
import { Box, Typography } from '@mui/material';
import { styled } from "@mui/material";

export const StyledSpaceWrapper = styled('div')`
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;
export const StyledButton = styled(Button)`
    width: 100%;
`;
export const StyledMeetingsContainer = styled(Box)`
    padding-left: 12px;
`;
export const StyledNoMeetings = styled(Typography)`
    color: #636363;
    font-weight: 400;
    padding: 16px;
`;
export const StyledMeetings = styled('div')`
    padding: 16px;
`;
export const StyledMeeting = styled(TextSkeleton)`
    color: #2F2C2C;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    font-family: Poppins;
`;
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { styled } from "@mui/material";

export const StyledFilledCard = styled(FilledCard)`
    margin-top: 16px;
`;
export const StyledWrapper = styled('div')`
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledCount = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
import { ListItem } from '@/components/v3/List/ListItem/ListItem.component';
import { Box } from '@mui/material';
import { styled } from "@mui/material";

export const StyledListItemNode = styled('div', {
    shouldForwardProp: (prop) => prop !== 'bottomButtons'
})<{bottomButtons?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    background: #FFF;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
    ${({ bottomButtons }) => !!bottomButtons && `
        display: block;
    `}
`;
export const StyledActionsWrapper = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const StyledListItem = styled(ListItem)`
    width: 100%;
`;
export const StyledMobileActionsWrapper = styled('div')`
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    padding-bottom: 8px;
    padding-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
`;
import { FC, useEffect, useRef, useState } from "react";
import { EditorProps } from "./Editor.type";
import { Editor as WysiwygEditor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { StyledWrapper } from "./Editor.styled";

export const Editor: FC<EditorProps> = ({ value, onChange, toolbar, className }) => {
    const lastHtml = useRef<string>(value);
    const [editorState, setEditorState] = useState(() => {
        if (value) {
            const blocksFromHtml = htmlToDraft(value);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    });

    useEffect(() => {
        if (value !== lastHtml.current) {
            const blocksFromHtml = htmlToDraft(value);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
        lastHtml.current = value;
    }, [value]);


    const onEditorStateChange = (newEditorState: EditorState) => {
        const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        if (lastHtml.current !== html) {
            onChange(html);
            lastHtml.current = html;
        }
        setEditorState(newEditorState);
    };

    return (
        <StyledWrapper className={className}>
            <WysiwygEditor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={toolbar}
            />
        </StyledWrapper>
    );
};
import { FC, useMemo } from "react";
import { NodeCardProps } from "./NodeCard.type";
import { StyledApplication, StyledContentWrapper, StyledExpiredLabel, StyledExpiredTip, StyledImageWrapper, StyledName, StyledNodeIcon, StyledNoteContentText, StyledNoteContentWrapper, StyledWrapper } from "./NodeCard.styled";
import { getLinkName } from "@/utils/v3/GetLinkName/GetLinkName.util";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { NodeTypeType } from "@/types/Node/Node.type";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { isItemExpired } from "@/utils/v3/IsItemExpired/IsItemExpired.util";
import { useRouter } from "@/providers/Router/Router.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { NodeMenu } from "../Node/components/Actions/components/NodeMenu/NodeMenu.component";
import { NodeCommentType } from "@/types/NodeComment/NodeComment.type";

export const NodeCard: FC<NodeCardProps> = ({ node, className, onUpdate, onNewComment }) => {
    const name = getLinkName(node.name);
    const { setActiveNodeId } = useNode();
    const router = useRouter();
    const { invitedUsers, confirmedInvitedUsers,user } = useMe();
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const isNodeExpired = isItemExpired(node.createdAt) && !node.sampleData && isIndividual;
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const menuElements = document.querySelectorAll(".MuiMenu-root");
        for (let i = 0; i < menuElements.length; i++) {
            if (menuElements[i].contains(e.target as Node)) {
                return;
            }
        }
        const dialogElements = document.querySelectorAll(".MuiDialog-root");
        for (let i = 0; i < dialogElements.length; i++) {
            if (dialogElements[i].contains(e.target as Node)) {
                return;
            }
        }
        const confirmationElement = document.getElementById("confirmation-dialog");
        if (confirmationElement && confirmationElement.contains(e.target as Node)) {
            return;
        }
        if ((e.target as Element).closest('.ignore--node-actions')) {
            return;
        }
        if (isNodeExpired) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                }
            });
        } else {
            setActiveNodeId(node.id)
        }
    };
    const onNewCommentHandler = (comment: NodeCommentType) => {
        onNewComment && onNewComment(comment);
        !onNewComment && onUpdate && onUpdate();
    };
    const isNote = node.nodeType === NodeTypeType.NOTE;
    return <StyledWrapper className={`${className} disable-node-drawer-close`} onClick={onClickHandler}>
        {!isNote && <StyledImageWrapper>
            <StyledNodeIcon node={node} />
            {isNodeExpired && <StyledExpiredTip />}
        </StyledImageWrapper>}
        {isNote && <StyledNoteContentWrapper>
            <StyledNoteContentText>
                {node.content && parse(sanitizeHtml(node.content, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                    allowedAttributes: {
                        ...sanitizeHtml.defaults.allowedAttributes,
                        'a': ['href', 'title', 'target']
                    }
                }))}   
            </StyledNoteContentText>
        </StyledNoteContentWrapper>}
        <StyledContentWrapper>
            {!isNote && <StyledName>{name}</StyledName>}
            {isNodeExpired && <StyledExpiredLabel>Upgrade your plan</StyledExpiredLabel>}
            {!isNodeExpired && <StyledApplication isExpired={isNodeExpired}>{node.application}</StyledApplication>}
        </StyledContentWrapper>
        {!isNodeExpired && <NodeMenu
            node={node}
            showCopy
            showAddComment
            onUpdate={onUpdate}
            onNewComment={onNewCommentHandler}
            buttonProps={{
                variant: 'tertiary',
                className: 'node-menu-button ignore--node-actions'
            }}
        />}
    </StyledWrapper>
};
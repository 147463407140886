import { FC, useMemo } from 'react';
import { IconProps } from './Icon.type';
import { getAppIcon } from "./Icon.utils";
import { StyledAppIcon, StyledAppIconImg, StyledIconWrapper } from './Icon.styled';
import { AppTypesKeys } from '@/types/AppTypes/AppTypes.type';
import { UserAvatar } from '@/components/v3/Other/UserAvatar/UserAvatar.component';

export const Icon: FC<IconProps> = ({ activity, useSkeleton = true, isExpired = false }) => {
  const msgBelongsToTeams = activity.permalink?.includes('teams');
  const msgBelongsToAsana = activity.permalink?.includes('asana');
  const msgBelongsToGitHub = activity.permalink?.includes('github');
  const msgBelongsToClickUp = activity.permalink?.includes('click_up');
  const msgBelongsToTrello = activity.permalink?.includes('trello');
  const msgBelongsToFigma = activity.permalink?.includes('figma');
  const source = useMemo(() => {
    const msgBelongsToJira = activity.permalink?.includes('atlassian');
    if (msgBelongsToJira) {
      return 'JIRA'
    }
    return ('source' in activity) ? activity.source : undefined;
  }, [activity])
  const application = useMemo((): AppTypesKeys => {
    if (msgBelongsToTeams) {
      return 'MicrosoftTeams'
    }
    if (msgBelongsToAsana) {
      return 'Asana'
    }
    if (msgBelongsToAsana) {
      return 'Asana'
    }
    if (msgBelongsToGitHub) {
      return 'Github'
    }
    if (msgBelongsToClickUp) {
      return 'ClickUp'
    }
    if (msgBelongsToTrello) {
      return 'Trello'
    }
    if (msgBelongsToFigma) {
      return 'Figma'
    }
    return 'Slack';
  }, [msgBelongsToAsana, msgBelongsToClickUp, msgBelongsToTrello, msgBelongsToFigma, msgBelongsToGitHub, msgBelongsToTeams])

  return (
    <StyledIconWrapper>
      <UserAvatar useSkeleton={useSkeleton} isExpired={isExpired} size="large" avatar={activity.avatar} />
      <StyledAppIcon>
        <StyledAppIconImg useSkeleton={useSkeleton} isExpired={isExpired} application={application} src={source ? getAppIcon(source) : undefined} />
      </StyledAppIcon>
    </StyledIconWrapper>
  );
};

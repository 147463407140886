import { ExpiredTip } from "@/components/v3/Other/ExpiredTip/ExpiredTip.component";
import { NodeIcon } from "@/components/v3/Other/NodeIcon/NodeIcon.component";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 4px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    position: relative;
    
    &:hover {
        border: 1px solid #202020;
    }
    @media (max-width: 768px) {
        display: inline;
    }
`;
export const StyledNodeIcon = styled(NodeIcon)`
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;
export const StyledName = styled('div')`
    display: inline;
`;
export const StyledExpiredLabel = styled(StyledName)`
    color: #636363;
`;
export const StyledExpiredTip = styled(ExpiredTip)`
    padding: 0px;
    min-width: auto;
    min-height: auto;
    width: auto;
    height: auto;
    margin-left: 4px;
`;
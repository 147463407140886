import { styled } from "@mui/material";

export const StyledContainer = styled('div')`
    position: relative;
`;
export const StyledWrapper = styled('div')`
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: calc(100% + 24px);
`;
export const StyledLine = styled('div')`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.08);
`;
import { BehaviorSubject, Subject } from 'rxjs';

export const ResetSubject = new Subject<boolean>();

export class DataSubject<T> extends BehaviorSubject<T> {
  constructor(protected readonly initValue: T) {
    super(initValue);
    this.subscribeForReset();

    this.reset = this.reset.bind(this);
    this.error = this.error.bind(this);
    this.error = this.error.bind(this);
    this.complete = this.complete.bind(this);
  }

  public reset() {
    this.next(this.initValue);
  }

  public error(err: any) {
    const { observers } = this;
    const len = observers.length;
    const copy = observers.slice();
    for (let i = 0; i < len; i++) {
      copy[i].error(err);
    }
  }

  public complete() {
    throw new Error(`Unsupported operation ${this}`);
  }

  protected subscribeForReset() {
    ResetSubject.subscribe(() => this.reset());
  }
}

export const getQueryParams = (search: string): Record<string, string | string[]> => {
    const queryParams = new URLSearchParams(search);
    
    const queryObj: Record<string, string | string[]> = {};
    queryParams.forEach((value, key) => {
      if (!queryObj[key]) {
        queryObj[key] = value;
      } else {
        const currentValue = queryObj[key];
        if (Array.isArray(currentValue)) {
          queryObj[key] = [...currentValue, value];
        } else {
          queryObj[key] = [currentValue, value];
        }
      }
    });
    return queryObj;
};

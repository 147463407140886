import { styled } from "@mui/material";

export const StyledSpan = styled('div')`
    display: inline-block;
    color: transparent;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;
export const StyledBg = styled('div')`
    background: rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 2px;
    border-radius: 6px;
`;
import { FC, useMemo, useState } from "react";
import { StyledWrapper, StyledFiltersWrapper, StyledSelect, StyledFiltersContainer } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import moment from "moment";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { getPeopleOptionsFromNodes } from "@/utils/v3/GetPeopleOptionsFromNodes/GetPeopleOptionsFromNodes.util";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { ActivityTypeWithUser } from "@/providers/v3/ForYouPage/ForYouPage.type";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";
import { ActivityGroup } from "@/components/v3/List/ActivityGroup/ActivityGroup.component";
import { EmptyState } from "@/pages/v3/ForYou/components/Notes/components/EmptyState/EmptyState.component";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { AddButton } from "../AddButton/AddButton.component";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

export const Notes: FC = () => {
    const {
        users,
    } = useMe();
    const {
        listOfNoteResentActivities,
        isFetchingNoteRecentActivities,
        update,
    } = useSpacePage();
    const { useNewActivityUI } = useFeatureFlags();
    const { isSharedSpace } = useIsShared();
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const groupedNodes = useMemo((): ActivityTypeWithUser[][] => {
        const groupsMap = new Map();
        listOfNoteResentActivities.forEach(wrapper => {
            const lastActor = findLastActor(wrapper.relationships?.actors || []);
            const happenedAtDate = lastActor?.properties.happenedAt || wrapper.node.createdAt;
            const momentDate = moment(happenedAtDate).format('YYYY-MM-DD');
            if (!groupsMap.has(momentDate)) {
                groupsMap.set(momentDate, []);
            }
            groupsMap.get(momentDate).push({
                ...wrapper,
                lastActor
            });
        });
        return Array.from(groupsMap.values());
      }, [listOfNoteResentActivities]);
      
    const groupsJsx = groupedNodes.map((group, i) => {
        return <ActivityGroup
            key={i}
            date={group[0].node.createdAt}
            group={group.map((wrapper) => {
                return {
                    node: wrapper.node,
                    activities: wrapper.messages,
                    relationships: wrapper.relationships,
                    users: users,
                    onUpdate: update,
                    importantUserIds: wrapper.importantUserIds,
                    lastActor: wrapper.lastActor
                }
            })}
        />
    })
    const peopleOptions = useMemo((): OptionType<string>[] => {
        return getPeopleOptionsFromNodes(listOfNoteResentActivities);
    }, [listOfNoteResentActivities]);
    const isEmpty = !groupedNodes.length && !isFetchingNoteRecentActivities;
    return <LoaderLayout isLoading={isFetchingNoteRecentActivities} transparent={!!groupedNodes.length}>
        <EmptyStateLayoyt
            value={isEmpty}
            emptyNode={<EmptyState />}
        >
            <StyledWrapper>
            <StyledFiltersWrapper>
                <StyledFiltersContainer>
                    <StyledSelect
                        label="People"
                        value={selectedPeople}
                        onChange={(val: any) => setSelectedPeople(val)}
                        options={peopleOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                </StyledFiltersContainer>
                {useNewActivityUI && <>
                    {!isSharedSpace && <AddButton />}
                </>}
            </StyledFiltersWrapper>
            {groupsJsx}
        </StyledWrapper>
        </EmptyStateLayoyt>
    </LoaderLayout>
};
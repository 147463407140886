import { ConnectApps } from "@/components/v3/Sections/ConnectApps/ConnectApps.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 40px 64px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding: 40px 16px;
    }
`;
export const StyledGradientWrapper = styled('div')`
    padding: 0 24px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding: 0 16px;
    }
`;
export const StyledConnectApps = styled(ConnectApps)`
    padding: 0;
`;
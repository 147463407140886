import { FC } from "react";
import { GroupLayoutProps } from "./GroupLayout.type";
import { StyledActionsWrapper, StyledFilledCard, StyledTitle } from "./GroupLayout.styled";

export const GroupLayout: FC<GroupLayoutProps> = ({
    title, actions, children,
}) => {
    return <StyledFilledCard>
        <StyledTitle>
            {title}
            {!!actions && <StyledActionsWrapper>
                {actions}
            </StyledActionsWrapper>}
        </StyledTitle>
        {children}
    </StyledFilledCard>
};
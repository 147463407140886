import { NodeTypeType } from "@/types/Node/Node.type";
import { PersonTypeWithUser } from "../../People.type";

export const externalUsersMock: PersonTypeWithUser[] = [
    {
        activities: [{
            id: 1,
            name: "Paved Ads Sense",
            originalURI: "spreadsheets",
            application: "Jira",
            comments: [],
            categories: [],
            sampleData: false,
            activitiesNumber: 0,
            relationshipNumber: 0,
            nodeType: NodeTypeType.RESOURCE_NODE,
            createdAt: new Date().toISOString()
            
        }],
        userId: "1",
        person: {
            id: "1",
            firstName: "Anastasia",
            lastName: "Reich",
            email: "anastasia@acme.com",
            avatar: "/users/3.png",
            isGhost: true,
        }
    }
];
// 
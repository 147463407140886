import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)`
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
`;
export const StyledText = styled('span')`
    text-align: center;
`;
export const StyledLoginButton = styled(Button)`
    margin: 16px 0 0 0;
`;
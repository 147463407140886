import { styled } from "@mui/material";
import { AddTagIcon } from "@/icons/add-tag.icon";
import { EmojiSelect } from "@/components/Fields/EmojiSelect/EmojiSelect.component";
import { EmojiIcon } from "@/icons/emoji.icon";

export const StyledAddSpaceTagContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 8px 56px;
    cursor: pointer;
    &:hover {
        background: rgba(0,0,0,.08);
    }
`
export const StyledAddSpaceTagText = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 12px;
`
export const StyledAddTagIconContainer = styled('div')`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const StyledAddTagIcon = styled(AddTagIcon)`
    width: 18px;
    height: 18px;
`
export const StyledSpaceTagInput = styled('input')`
    background: transparent;
    border: none;
    outline: none;
    margin-left: 12px;
`
export const StyledEmojiSelect = styled(EmojiSelect)`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledDefaultEmojiIcon = styled(EmojiIcon)`
    width: 16px;
    height: 16px;
`
import { FC } from "react";
import { StyledContent, StyledWrapper } from "./LoaderLayout.styled";
import { Loader } from "../../Other/Loader/Loader.component";
import { LoaderLayoutProps } from "./LoaderLayout.type";

export const LoaderLayout: FC<LoaderLayoutProps> = ({
    className,
    size,
    children,
    isLoading,
    transparent = false,
}) => {
    return <StyledContent className={className}>
        {(!isLoading || transparent) && children}
        {!!isLoading && <StyledWrapper transparent={transparent}>
            <Loader size={size} />
        </StyledWrapper>}
    </StyledContent>
};
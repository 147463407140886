import { DialogContent, IconButton, Typography } from "@mui/material";
import { DisableTwoFAModalProps } from "./DisableTwoFAModal.type";
import { Close } from '@mui/icons-material';
import { Content } from "./components/Content/Content.component";
import { StyledDialog, StyledDialogTitle } from "./DisableTwoFAModal.styled";

export const DisableTwoFAModal = ({ value, onClose }: DisableTwoFAModalProps) => {
    return <StyledDialog
    open={value}
    onClose={() => onClose()}
    aria-labelledby="settings-dialog-title"
    aria-describedby="settings-dialog-description"
  >
    <StyledDialogTitle id="alert-dialog-title">
      <Typography variant="h1" style={{ fontSize: '24px ', fontWeight: 600 }}>
        Disable Two-Factor Authentication
      </Typography>
      <IconButton
        onClick={() => onClose()}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
    </StyledDialogTitle>
    <DialogContent>
      <Content onClose={onClose} />
    </DialogContent>
  </StyledDialog>
}
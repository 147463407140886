import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const zIndex = {
  modalListGradient: 1,
  spaceMenuDropdown: 1,
}

export const senseTheme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1281,
      lg: 1396,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
    ].join(','),
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      textTransform: 'none',
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      color: 'rgba(32, 32, 32, 0.7)'
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '21px',
    }
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 'unset',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:disabled': {
          color: 'rgba(0, 0, 0, 0.16)',
          backgroundColor: 'rgba(0, 0, 0, 0.16)',
        },
      },
      contained: {
        backgroundColor: '#FFE7E0',
        color: '#202020',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#FFE7E0',
        }
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#202020',
        },
      }
    },
    MuiList: {
      padding: {
        marginTop: 8,
        paddingTop: 0,
        paddingBottom: 16,
      }
    }
  },
  palette: {
    warning: {
      // light: will be calculated from palette.primary.main,
      main: '#FF0000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
}));

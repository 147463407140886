import { FC, useMemo, useState } from "react";
import { InviteUsersProps } from "./InviteUsers.type";
import { StyledCount, StyledFilledCard, StyledWrapper } from "./InviteUsers.styled";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { SelectUsersDialog } from "@/components/v3/Modals/SelectUsersDialog/SelectUsersDialog.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";

export const InviteUsers: FC<InviteUsersProps> = ({
    users, setUsers
}) => {
    const { user, users: listOfUsers } = useMe();
    const router = useRouter();
    const filteredListOfUsers = useMemo(() => {
        return listOfUsers.filter((el) => el.id !== user?.id)
    }, [listOfUsers, user]);
    const [isOpenedManageUsersModal, setIsOpenedManageUsersModal] = useState<boolean>(false);
    const invitedText = useMemo(() => {
        if (users.length === 0) {
            return 'Visible only for me';
        }
        if (users.length === 1) {
            return '1 user invited';
        }
        return `${users.length} users invited`;
    }, [users]);
    return <StyledFilledCard>
        <SectionHeader title="Invite users" caption="Visible only for users invited to the space" />
        <StyledWrapper>
            <StyledCount>{invitedText}</StyledCount>
            <Button variant="secondary" onClick={() => {
                if (!!filteredListOfUsers.length) {
                    setIsOpenedManageUsersModal(true);
                } else {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: [router.query.dialog as string, 'manage-users'],
                        }
                    });
                }
            }}>Manage</Button>
        </StyledWrapper>
        <SelectUsersDialog
            title="Manage users"
            isOpened={isOpenedManageUsersModal}
            setIsOpened={setIsOpenedManageUsersModal}
            users={users}
            setUsers={(users) => {
                setUsers(users);
                setIsOpenedManageUsersModal(false);
            }}
            listOfUsers={filteredListOfUsers}
        />
    </StyledFilledCard>
};
import { Box } from "@mui/material";
import { useState } from "react";
import { IntegrationProps } from "./CommingSoonIntegration.type";
import { StyledListItemNode, StyledListItem } from "../../../Integration/Integration.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { EventTypes, useAmplitude } from "@/service/TrackingService";

export const CommingSoonIntegration = ({icon, appName}: IntegrationProps) => {
    const trackEvent = useAmplitude();
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    return (
        <StyledListItemNode>
            <StyledListItem
                rounded={false}
                img={icon}
                title={appName}
                imgSize="large"
            />
            <Box>
                <Button
                    onClick={() => {
                        trackEvent(EventTypes.USER_VOTED, { app: appName })
                        setIsDisabled(true)
                    }}
                    disabled={isDisabled}
                    variant={isDisabled ? 'tertiary' : 'secondary'}
                >
                    {isDisabled ? 'You voted' : 'I need it' }
                </Button>
            </Box>
        </StyledListItemNode>
    )
}
import { FC } from "react";
import { StyledMainIcon, StyledPageHeader } from "./Header.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Header: FC = () => {
    const { user } = useMe();
    return <StyledPageHeader
        title="For you"
        subtitle={`${user?.firstName} ${user?.lastName}`}
        icon={<StyledMainIcon src={user?.avatar || '/icons/no_avatar.svg'} />}
        useSkeleton={false}
    />
};
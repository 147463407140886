export enum NodeTypeType {
    BASE_NODE = 'BASE_NODE',
    RESOURCE_NODE = 'RESOURCE_NODE',
    MESSAGE = 'MESSAGE',
    TASK = 'TASK',
    EMAIL = 'EMAIL',
    WIKI = 'WIKI',
    EVENT = 'EVENT',
    CALENDAR = 'CALENDAR',
    NOTE = 'NOTE',
}
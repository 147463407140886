import { OpenLinkIcon } from "@/icons/v3/open-link.icon";
import { styled } from "@mui/material";

export const StyledLink = styled('a')`
    text-decoration: none;
    margin-left: 8px;
`
export const StyledOpenLinkIcon = styled(OpenLinkIcon)`
    fill: #ffffff;
    path {
        stroke: #ffffff;
    }
`
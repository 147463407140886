export const generateAppNameFromType = (type: string | undefined) => {
  switch (type) {
    case 'asana':
      return 'Asana'
    case 'github':
      return 'GitHub'
    case 'click_up':
      return 'ClickUp'
    case 'trello':
      return 'Trello'
    case 'slack':
      return 'Slack'
    case 'atlassian':
      return 'Atlassian'
    case 'google_drive':
      return 'Google drive'
    case 'dropbox_business':
      return 'Dropbox for business'
    case 'gmail':
      return 'Gmail'
    case 'microsoft_teams':
      return 'Microsoft teams'
    case 'outlook':
      return 'Outlook'
    case 'notion':
      return 'Notion'
    case 'google_calendar':
      return 'Google Calendar'
  }
}
export const defineApp = (appName: string) => {
  if (appName === 'Slack') {
    return 'slack'
  } else if (appName === 'Asana') {
    return 'asana'
  } else if (appName === 'Trello') {
    return 'trello'
  } else if (appName === 'GitHub') {
    return 'github'
  } else if (appName === 'ClickUp') {
    return 'click_up'
  } else if (appName === 'Figma') {
    return 'figma'
  } else if (appName === 'Atlassian (Jira, Confluence)') {
    return 'atlassian'
  } else if (appName === 'Microsoft (Teams, SharePoint)') {
    return 'microsoft_teams'
  } else if (appName === 'Dropbox for Business') {
    return 'dropbox_business'
  } else if (appName === 'Gmail') {
    return 'gmail'
  } else if (appName === 'Google Drive') {
    return 'google_drive'
  } else if (appName === 'Google Calendar') {
    return 'google_calendar'
  } else if (appName === 'Outlook') {
    return 'outlook'
  } else if (appName === 'Notion') {
    return 'notion'
  }
}

import { useEffect, useState } from "react";
import { RenameNodeModalProps } from "./RenameNodeModal.type";
import { ToastService } from "@/service/ToastService";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { StyledCancelButton, StyledInput } from "./RenameNodeModal.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { PatchNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";

export const RenameNodeModal = ({onUpdate, node, value, onChange}: RenameNodeModalProps) => {
    const [name, setName] = useState(node.name);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setName(node.name);
    }, [node.name])

    const renameHandler = async () => {
        if (!name.length) return
        setIsLoading(true)
        await PatchNodesFetch(node.id, {
            name: name
        }).then(() => {
            onUpdate();
            setIsLoading(false);
            onChange(false);
        }).catch( error => {
            ToastService.showToast('error', 'Some error occurred!')
        })
    }

    return (
        <Dialog value={value} onChange={onChange} size="small" >
            <DialogLayout
                title="Rename resource"
                isLoading={isLoading}
                actions={<>
                    <StyledCancelButton size="large" variant="error" onClick={() => onChange(false)}>
                        Cancel
                    </StyledCancelButton>
                    <Button size="large" onClick={renameHandler} disabled={!name}>
                        Save
                    </Button>
                </>}
            >
                <StyledInput value={name} onChange={(e) => setName(e.target.value)} />
            </DialogLayout>
        </Dialog>
    )
}
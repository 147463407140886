import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { BoardsGetQueryParams } from './Types/Get/Get.type';
import { BoardsSynchronizeBodyParams } from './Types/Synchronize/Synchronize.type';

export const GetBoardsFetch = (query: BoardsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/trello-service/v1/boards`, {
        params: query,
    });
}
export const SynchronizeBoardsFetch = (body: BoardsSynchronizeBodyParams) => {
    return HttpClient.post(`${envUrl}/trello-service/v1/grants`, body);
}
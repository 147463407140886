import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MeProvider } from 'providers/MeProvider/Me.provider';
import { WalkthroughProvider } from './providers/WalkthroughProvider/Walkthrough.provider';
import { FeatureFlagsProvider } from 'providers/FeatureFlags/FeatureFlags.provider';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterProvider } from 'providers/Router/Router.provider';
import { ThemeProvider } from '@mui/material/styles';
import { senseTheme } from './Layouts/theme';
import { ChromeExtensionProvider } from './providers/ChromeExtension/ChromeExtension.provider';
import { MainProvider } from './providers/MainProvider/Main.provider';
import { ResponsiveProvider } from './providers/v3/Responsive/Responsive.provider';
import { ModalProvider } from './store/ModalContext';

ReactDOM.render(
  <ThemeProvider theme={senseTheme}>
    <ResponsiveProvider>
      <Router>
        <RouterProvider>
          <ChromeExtensionProvider>
            <MainProvider>
              <MeProvider>
                <ModalProvider>
                  <FeatureFlagsProvider>
                    <WalkthroughProvider>
                      <React.StrictMode>
                        <App />
                      </React.StrictMode>
                    </WalkthroughProvider>
                  </FeatureFlagsProvider>
                </ModalProvider>
              </MeProvider>
            </MainProvider>
          </ChromeExtensionProvider>
        </RouterProvider>
      </Router>
    </ResponsiveProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

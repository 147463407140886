import { ImportnatIcon } from "@/icons/important.icon"
import { styled } from "@mui/material";

export const StyledImportantLabel = styled('div')`
    border-radius: 999px;
    background: rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-top: 12px;
    display: inline-flex;
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: 8px;
    white-space: nowrap;
`
export const StyledImportnatIcon = styled(ImportnatIcon)`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { styled } from "@mui/material";

export const StyledLogo = styled("div")`
    height: 56px;
    padding: 12px 16px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
`;
export const StyledPublicTitle = styled('div')`
    padding: 16px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

export const StyledCreateAccountButton = styled(Button)`
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
`
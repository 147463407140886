import { ActionTypeType } from "./ActorActionToString.type";

export const actorActionToString = (action: ActionTypeType): string => {
    switch (action) {
        case 'CREATOR':
        case 'CREATED_BY':
            return 'Author';
        case 'ASSIGNEE':
            return 'Assignee';
        case 'WATCHER':
            return 'Watcher';
        case 'ATTENDEE':
            return 'Attendee';
        case 'FROM':
            return 'Sender';
        case 'CC':
        case 'BCC':
        case 'TO':
            return 'Receiver';
        default:
            return 'Contributor';
    }
}
import { FC, useMemo } from "react";
import { ImportantForDialogProps } from "./ImportantForDialog.type";
import { SelectPeopleDialog } from "@/components/SelectPeopleDialog/SelectPeopleDialog.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { PostImportantFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { ToastService } from "@/service/ToastService";

export const ImportantForDialog: FC<ImportantForDialogProps> = ({ nodeId, value, onChange }) => {
    const { users, user } = useMe();
    const listOfUsers = useMemo(() => {
        return users.filter((el) => el.id !== user?.id)
    }, [users, user]);
    const onChangeHandler = (value: boolean) => {
        onChange(value);
    };
    const onChangeSelectedUsersHandler = async (users: string[]) => {
        await PostImportantFetch({
            nodeId,
            users
        }).then(() => {
            ToastService.showToast('success', 'Resource marked as important for selected users');
        });
    };
    return <SelectPeopleDialog
        title="Select users"
        value={value}
        onChange={onChangeHandler}
        selectedUsers={[]}
        onChangeSelectedUsers={onChangeSelectedUsersHandler}
        listOfUsers={listOfUsers}
    />
};
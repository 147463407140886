import { styled } from "@mui/material";
import { WalkthroughPositionType } from './WalkthroughWrapper.type';
const Arrow = () => <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" fill="none">
<path d="M0 10.3919L12 -0.000427246L12 20.7842L0 10.3919Z" fill="#9266CC"/>
</svg>

const ArrowComponent = ({className}: {className?: string}) => <div className={className}><Arrow /></div>;


export const StyledContainer = styled('div')<{
  isVisible: boolean;
  top: number;
  left: number;
  minWidth: number;
  maxWidth?: number;
  usePortal: boolean;
}>`
  position: ${(props) => (props.usePortal ? 'fixed' : 'absolute')};
  background-color: #9266CC;
  border-radius: 1rem;
  padding: 1rem;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  min-width: ${(props) => props.minWidth}px;
  max-width: ${(props) => props.maxWidth}px;
  text-align: left;
  z-index: 100;
  box-shadow: 0px 8px 16px 2px #00000029;
`;
export const StyledTitle = styled('div')`
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const StyledDescription = styled('div')`
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-top: 0.25rem;
  margin-bottom: 1rem;
`;
export const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledButton = styled('div')`
  color: #FFFFFF;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: rgba(255, 255, 255, 0.32);
  border-radius: 0.375rem;
  cursor: pointer;
`;
export const StyledSkipButton = styled('div')`
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`;
export const StyledArrow = styled(ArrowComponent)<{
  position: WalkthroughPositionType;
  top: number;
  left: number;
  isVisible: boolean;
}>`
  position: absolute;
  transform: ${(props) => props.position === 'top' && 'rotate(270deg)'};
  transform: ${(props) => props.position === 'bottom' && 'rotate(90deg)'};
  // transform: ${(props) => (props.position === 'top' || props.position === 'bottom') && 'rotate(90deg)'};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  z-index: 100;
`;

export const StyledWalkthroughWrapper = styled('div')`
  position: relative;
`;

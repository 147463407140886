import { Box } from '@mui/material';
import { styled } from "@mui/material";
export const StyledAccWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
export const StyledListItemNode = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid rgba(0,0,0,.08);
    &:last-child {
        border-bottom: none;
    }
`;
export const StyledSubtitle = styled('div', {
    shouldForwardProp: prop => prop !== 'isActive'
})<{
    isActive: boolean;
}>`
    color: ${props => props.isActive ? '#008D17' : '#FF0000'};
`;
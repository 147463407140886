import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { MessagesGetQueryParams } from './Types/Get/Get.type';

export const GetMessagesFetch = (query: MessagesGetQueryParams) => {
    return HttpClient.get(`${envUrl}/azure-service/v1/messages`, {
        params: {
            ...query,
            resourceIds: query.resourceIds.join(','),
        },
    });
}
import { FC, useMemo } from "react";
import { SpaceTagProps } from "./SpaceTag.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { StyledSpaceTagContainer, StyledDarkTagIcon, StyledWhiteTagIcon, StyledSpaceTagText, StyledEmojiWrapper, StyledEmoji } from "../../../Spaces/components/Space/components/SpaceTag/SpaceTag.styled";

export const SpaceTag: FC<SpaceTagProps> = ({ tag }) => {
    const router = useRouter();
    const isActive = router.name==='publicSpaceTag' && router.params.tagId === tag.id;
    const imgJsx = useMemo(() => {
        if (!!tag.emoji) {
          return <StyledEmojiWrapper>
            <StyledEmoji>{tag.emoji}</StyledEmoji>
          </StyledEmojiWrapper>
        }
        return <>
            {isActive && <StyledDarkTagIcon />}
            {!isActive && <StyledWhiteTagIcon />}
        </>
      }, [isActive, tag.emoji]);
    return <StyledSpaceTagContainer isActive={isActive} onClick={() => router.push({
        name: 'publicSpaceTag',
        params: {
            tagId: tag.id,
            code: router.params.code as string,
        }
    })}>
        {imgJsx}
        <StyledSpaceTagText >{tag.name}</StyledSpaceTagText>
    </StyledSpaceTagContainer>
}
import { FC, useMemo } from "react";
import { ActivityGroupProps } from "./ActivityGroup.type";
import { Activity } from "../Activity/Activity.component";
import { StyledCalendarIcon, StyledDateWrapper, StyledTitleText } from "./ActivityGroup.styled";
import { formatGroupDate } from "@/utils/v3/FormatGroupDate/FormatGroupDate.util";
import { GroupLayout } from "../../Layouts/GroupLayout/GroupLayout.component";

export const ActivityGroup: FC<ActivityGroupProps> = ({
    date,
    group
}) => {
    
    const dateStr = useMemo(() => {
        return formatGroupDate(date);
    }, [date]);
    const listOfActivitiesJsx = group.map((item, i) => {
        return <Activity {...item} key={i} />
    });
    return <GroupLayout title={<StyledDateWrapper>
        <StyledCalendarIcon />
        <StyledTitleText>{dateStr}</StyledTitleText>
    </StyledDateWrapper>}>
        {listOfActivitiesJsx}
    </GroupLayout>
};
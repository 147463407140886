import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";
import { Close } from '@mui/icons-material';
import { Payment } from "../../../Payment/Payment.component";
import { StyledBanner, StyledButton, StyledNote } from "./TrialOverBanner.styled";

const useStyles = makeStyles(theme => ({
    paper: {
        minWidth: '650px',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            widthMax: '720px',
            minWidth: '0',
        },
    },
}));

export const TrialOverBanner = () => {
    const classes = useStyles();
    const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);
    const handlePaymentDialogClose = useMemo(() => {
        return () => setOpenPaymentDialog(false);
    }, [setOpenPaymentDialog]);

const addHandler = () => {
    setOpenPaymentDialog(true)
}
    return <StyledBanner>
        <StyledNote>Your trial period is over. No worries, your data is still automatically synced and stored. Add subscription plan to continue using Sense. </StyledNote>
        <StyledButton onClick={addHandler}>Add subscription plan</StyledButton>
        <Dialog
            id="payment"
            classes={{ paper: classes.paper }}
            open={openPaymentDialog}
            onClose={handlePaymentDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1" style={{ fontSize: '24px ', fontWeight: 600 }}>
                    Add subscription plan
                </Typography>
                <IconButton
                    onClick={handlePaymentDialogClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Payment />
            </DialogContent>
        </Dialog>
    </StyledBanner>
}
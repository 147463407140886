import { Button } from "@/components/v3/Fields/Button/Button.component";
import { NodeCard } from "@/components/v3/List/NodeCard/NodeCard.component";
import { FC } from "react";
import { StyledNodesWrapper, StyledScrollWrapper, StyledSectionHeader, StyledWrapper } from "./RecentNotes.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";

export const RecentNotes: FC = () => {
    const { isFetchingNoteRecentActivities, listOfNoteResentActivities, setActiveTab, update } = useForYouPage();
    const isLargeScreen = useRenederAbove('large');
    const countOfRecentNodes = isLargeScreen ? 4 : 3;
    const recentNodes = listOfNoteResentActivities.slice(0, countOfRecentNodes);
    const nodesJsx = recentNodes.map((el, i) => {
        return <NodeCard key={i} node={el.node} onUpdate={update} />
    });
    const isEmpty = !isFetchingNoteRecentActivities && !nodesJsx.length;
    return <EmptyStateLayoyt value={isEmpty} emptyNode={<></>}>
        <StyledWrapper>
            <StyledSectionHeader title="Recent notes" >
                <Button onClick={() => setActiveTab('NOTES')} variant="tertiary">Show all</Button>
            </StyledSectionHeader>
            <LoaderLayout isLoading={isFetchingNoteRecentActivities} transparent={!!nodesJsx.length}>
                <StyledScrollWrapper>
                    <StyledNodesWrapper countOfRecentNodes={countOfRecentNodes}>
                        {nodesJsx}
                    </StyledNodesWrapper>
                </StyledScrollWrapper>
            </LoaderLayout>
        </StyledWrapper>
    </EmptyStateLayoyt>
};
import { TabPanelProps } from './TabPanel.type';
import { StyledPanel } from './TabPanel.styled';

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, classes, index, ...other } = props;
  
    return (
      <StyledPanel
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </StyledPanel>
    );
};
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { ShareNodeDialogProps } from "./ShareNodeDialog.type";
import { Close } from '@mui/icons-material';
import { StyledActions, StyledDescription, StyledDialog, StyledDialogContent, StyledDialogTitle, StyledLinkWrapper, StyledName, StyledNodeInfo, StyledNodeTitle, StyledRevoke, StyledSenseTextField, StyledSubmit } from "./ShareNodeDialog.styled";
import useCopyToClipboard from "@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook";
import { ConfirmDialog } from "./components/ConfirmDialog/ConfirmDialog.component";
import { SharingNodeGenerateFetch, SharingNodeGetFetch, SharingNodeRevokeFetch } from "@/axios/AiService/Sharing/Sharing.api";
import { SharingNodeGenerateAxiosResponseSuccessType } from "@/axios/AiService/Sharing/Types/NodeGenerate/NodeGenerate.type";
import { SharingNodeGetAxiosResponseSuccessType } from "@/axios/AiService/Sharing/Types/NodeGet/NodeGet.type";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { getLinkName } from "@/utils/GetLinkName/GetLinkName.util";
import { NodeIcon } from "@/components/v3/Other/NodeIcon/NodeIcon.component";
import { AppName } from "@/components/v3/Other/AppName/AppName.component";

export const ShareNodeDialog = ({ isOpened, setIsOpened, node: nodeProps }: ShareNodeDialogProps) => {
    const { node: nodeProvider } = useNode();
    const node = nodeProps || nodeProvider;
    const nodeIdNumber = node?.id;
    const [code, setCode] = useState<string>('');
    const [openConfirDialog, setOpenConfirDialog] = useState<boolean>(false);
    const { copyToClipboard } = useCopyToClipboard();
    const submitHandle = useCallback(async () => {
        nodeIdNumber && await SharingNodeGenerateFetch({
            nodeId: nodeIdNumber,
        }).then((res: SharingNodeGenerateAxiosResponseSuccessType) => {
            setCode(res.data.code);
        });
    }, [nodeIdNumber]);
    const loadCode = useCallback(async () => {
        nodeIdNumber && await SharingNodeGetFetch({
            nodeId: nodeIdNumber,
        }).then((res: SharingNodeGetAxiosResponseSuccessType) => {
            setCode(!!res.data.length ? res.data[0].code : '');

        });
    }, [nodeIdNumber]);
    const revokeHandle = useCallback(async () => {
        if (!code) {
           return; 
        }
        await SharingNodeRevokeFetch({
            code: code,
        }).then((res: SharingNodeGenerateAxiosResponseSuccessType) => {
            setCode('');
        });
    }, [code]);
    useEffect(() => {
        if (isOpened) {
            loadCode();
        }
    }, [isOpened, loadCode]);
    useEffect(() => {
        setCode('');
    }, [nodeIdNumber]);
    const link = useMemo(() => {
        if (!code) {
            return '';
        }
        return `${window.location.protocol}//${window.location.host}/public/node/${code}`;
    }, [code]);
    const copyHandle = () => {
        copyToClipboard(link, 'Link copied to clipboard');
    }
    const isShared = !!link;
    const name = getLinkName(node?.name || '');
    return <StyledDialog
        open={isOpened}
        onClose={() => setIsOpened(false)}
        aria-labelledby="settings-dialog-title"
        aria-describedby="settings-dialog-description"
    >
        <StyledDialogTitle id="alert-dialog-title">
            <Typography variant="h1" style={{ fontSize: '24px ', fontWeight: 600 }}>
                Share node
            </Typography>
            <IconButton
                onClick={() => setIsOpened(false)}
                style={{
                position: 'absolute',
                right: 8,
                top: 8,
                }}
            >
                <Close />
            </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
            <StyledNodeInfo>
                {node && <NodeIcon node={node} />}
                <StyledNodeTitle>
                    <StyledName long={!!node?.name} title={node?.name}>{name}</StyledName>
                    <p>{node && <AppName node={node} />}</p>
                </StyledNodeTitle>
            </StyledNodeInfo>
            <StyledDescription>
                Anyone with the link will be able to browse all relationships of this node, except emails and discussions. They might not have access to the resources from your apps, if you didn’t grant them this access via your apps.
            </StyledDescription>
            <StyledLinkWrapper>
                <StyledSenseTextField
                    id="code"
                    type="code"
                    label="Sharing link"
                    value={link}
                    disabled={!link}
                />
                <StyledActions>
                    {!isShared && <StyledSubmit onClick={submitHandle}>Create sharing link</StyledSubmit>}
                    {isShared && <StyledRevoke onClick={() => setOpenConfirDialog(true)}>Revoke</StyledRevoke>}
                    {isShared && <StyledSubmit onClick={copyHandle}>Copy link</StyledSubmit>}
                    
                </StyledActions>
            </StyledLinkWrapper>
        </StyledDialogContent>
        <ConfirmDialog isOpened={openConfirDialog} setIsOpened={setOpenConfirDialog} revoke={revokeHandle} />
    </StyledDialog>
}
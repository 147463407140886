import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 24px 32px 40px 32px;
    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SideDrawerMainLayout } from 'Layouts/SideDrawerMainLayout';
import { SideBarMenu } from 'components/SideBarMenu/SideBarMenu.component';
import { MainContent } from 'Layouts/MainContent';
import React, { useState } from 'react';
import { TrackRecentVisit } from 'components/TrackRecentVisit';
import { TrialOverBanner } from "./components/TrialOverBanner/TrialOverBanner.component";
import { useMe } from 'providers/MeProvider/Me.provider';
import { NewChannelProvider } from "../../components/Space/NewSpaceContext";
import { Search } from "./components/Search/Search.component";
import { StyledContent, StyledGridContent, StyledGridMenu, StyledMainContent, StyledMainContentNode, StyledMainContentTop, StyledMainContentWrapper, StyledPro } from './MainContent.styled';
import { useRouter } from '@/providers/Router/Router.provider';
import { isShared } from '@/utils/IsShared/IsShared.utils';
import { AppMenu } from '@/components/v3/Layouts/MainLayout/components/Header/components/User/components/AppMenu/AppMenu.component';

export const APPS = ['Slack', 'Jira', 'Confluence', 'MicrosoftTeams'];
export const MainContentLayout: React.FC = () => {
  const theme = useTheme();
  const isMatchedXs = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();
  const { user } = useMe();
  const isSubscriptionExpired = user?.subscriptionStatus === "EXPIRED";
  const [openCreationDialog, setOpenCreationDialog] = useState(false);
  const isPro = user && user.paymentPlan.name === 'PRO';
  const { isSharedSpace, isSharedNode } = isShared(router);
  const displaySidebar = !isMatchedXs && !isSharedNode;
  return (
    <>
      <StyledMainContent container>
        {isSubscriptionExpired && <TrialOverBanner />}
        <TrackRecentVisit />
        {displaySidebar && (
          <StyledGridMenu>
            <NewChannelProvider>
              {user && <SideBarMenu
                user={user}
                setOpenCreationDialog={setOpenCreationDialog}
                openCreationDialog={openCreationDialog}
              />}
            </NewChannelProvider>
          </StyledGridMenu>
        )}
        <StyledGridContent displaySidebar={displaySidebar}>
          <StyledContent isSubscriptionExpired={isSubscriptionExpired} isSharedSpace={isSharedSpace || isSharedNode}>
            {!isSharedSpace && !isSharedNode && <StyledMainContentTop isSubscriptionExpired={isSubscriptionExpired} display="flex" justifyContent="space-between">
              <Box
                display="flex"
                style={{
                  width: '70%',
                }}
              >
                <Box display="flex">{isMatchedXs && <SideDrawerMainLayout />}</Box>
                <Box style={{ width: '100%' }}>
                  <Search disabled={isSubscriptionExpired} />
                </Box>
              </Box>
              <Box display="flex">
                {isPro && <StyledPro>PRO</StyledPro>}
                <AppMenu />
              </Box>
            </StyledMainContentTop>}
            <StyledMainContentNode>
              <StyledMainContentWrapper>
                <MainContent />
              </StyledMainContentWrapper>
            </StyledMainContentNode>
          </StyledContent>
        </StyledGridContent>
      </StyledMainContent>
    </>
  );
};

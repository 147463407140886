import { IntegrationSpaceType, IntegrationTypeType } from "./ConnectChannels.type";

const getName = (integration: IntegrationSpaceType): string => {
    switch (integration) {
        case 'slack':
            return 'Slack';
        case 'teams':
            return 'Teams';
        case 'asana':
            return 'Asana';
        case 'github':
            return 'GitHub';
        case 'jira':
            return 'Jira';
        case 'click_up':
            return 'ClickUp';
        case 'trello':
            return 'Trello';
        case 'confluence':
            return 'Confluence';
    }
};
export const getType = (integration: IntegrationSpaceType): IntegrationTypeType => {
    switch (integration) {
        case 'slack':
            return 'channels';
        case 'teams':
            return 'channels';
        case 'asana':
            return 'projects';
        case 'github':
            return 'repositories';
        case 'jira':
            return 'projects';
        case 'click_up':
            return 'spaces';
        case 'trello':
            return 'boards';
        case 'confluence':
            return 'spaces';
    }
};

export const getTitle = (integration: IntegrationSpaceType) => {
    const name = getName(integration);
    const type = getType(integration);
    return `Select ${name} ${type}`
};
export const getDescription = (integration: IntegrationSpaceType) => {
    const type = getType(integration);
    return `Sense will organize and interconnect all scattered resources from the selected ${type}. Every Sense workspaces member will be able to see all resources from these ${type}, however they might not have an access to the original resources, if it is not granted by the app.`
};
export const getEmptyDescriptionTitle = (integration: IntegrationSpaceType) => {
    const type = getType(integration);
    return `No ${type} in this app`
};
export const getEmptyDescription = (integration: IntegrationSpaceType) => {
    const type = getType(integration);
    return `It might be that you don’t have any ${type} in this app or there is some issue during connection of the app. Try to add ${type} or re-connect this app. `
};
export const getButtonLabel = (integration: IntegrationSpaceType, count: number) => {
    const type = getType(integration);
    return `Add ${count ? count : ''} ${type}`
};
export const getIcon = (integration: IntegrationSpaceType) => {
    switch (integration) {
        case 'slack':
            return 'Slack';
        case 'teams':
            return 'Microsoft Teams';
        case 'asana':
            return 'Asana';
        case 'github':
            return 'GitHub';
        case 'jira':
            return 'Jira';
        case 'click_up':
            return 'ClickUp';
        case 'trello':
            return 'Trello';
        case 'confluence':
            return 'Confluence';
    }
};
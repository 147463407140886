import { GetNodeRelationsFetch } from "@/axios/AiService/NodeRelations/NodeRelations.api";
import { NodeRelationTypeWithUser } from "./FetchNodeRelations.type";
import { UserType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";
import { NodeRelationsAxiosResponseSuccessType } from "@/axios/AiService/NodeRelations/Types/Get/Get.type";
import { addUsersToRelations } from "../AddUsersToRelations/AddUsersToRelations.util";

export const fetchNodeRelations = async (users: UserType[], nodeIds: number[]): Promise<NodeRelationTypeWithUser[]> => {
  try {
    const res: NodeRelationsAxiosResponseSuccessType = await GetNodeRelationsFetch({ nodeIds });
    return Array.isArray(res.data) ? addUsersToRelations(users, res.data) : [];
  } catch (error) {
    console.error('Error fetching node relations:', error);
    return [];
  }
};

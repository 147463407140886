import { FC } from "react";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledAddIcon, StyledList, StyledSectionHeader, StyledEmptyCardWrapper } from "../../Sidebar.styled";
import { Channel } from "@/components/v3/List/Channel/Channel.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

export const Channels: FC = () => {
    const {
        isFetchingChannels,
        listOfChannels,
    } = useSpacePageCommon();
    const { isShared } = useIsShared();
    const router = useRouter();
    const { items, showMore, setShowMore } = useShowMore({ items: listOfChannels, limit: 5 });
    const listOfChannelsJsx = items.map((channel, i) => {
        return <Channel channel={channel} key={i} />
    });
    const isEmpty = !listOfChannels.length && !isFetchingChannels;
    return <>
        <LoaderLayout isLoading={isFetchingChannels} transparent={!!listOfChannels.length}>
            {(!isEmpty || (isEmpty && !isShared)) && <>
                <StyledSectionHeader title="Channels">
                    {!!listOfChannels.length && !isShared && <Button 
                        onClick={() => {
                            router.push({
                                name: router.name,
                                params: router.params,
                                query: {
                                    ...router.query,
                                    dialog: 'edit-space',
                                    scroll: 'apps'
                                }
                            })
                        }}
                        icon={<StyledAddIcon />}
                        variant="tertiary"
                    >Connect</Button>}
                </StyledSectionHeader>
                <EmptyStateLayoyt
                    value={!listOfChannels.length && !isFetchingChannels}
                    emptyNode={<StyledEmptyCardWrapper>
                        <EmptyCard title="Connect apps to space" onClick={() => {
                            router.push({
                                name: router.name,
                                params: router.params,
                                query: {
                                    ...router.query,
                                    dialog: 'edit-space',
                                    scroll: 'apps'
                                }
                            })
                        }}>
                            All resources from your alls will be automatically organized in the space
                        </EmptyCard>
                    </StyledEmptyCardWrapper>}
                >
                    <StyledList>
                        {listOfChannelsJsx}
                        {!showMore && listOfChannels.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
                    </StyledList>
                </EmptyStateLayoyt>
            </>}
        </LoaderLayout>
    </>
};
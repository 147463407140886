import { FC } from "react";
import { CalendarEventsProps } from "./CalendarEvents.type";
import { groupEvents } from "./CalendarEvents.util";
import { CalendarGroup } from "./components/CalendarGroup/CalendarGroup.component";

export const CalendarEvents: FC<CalendarEventsProps> = ({ events, startHour, baseWidth, useSkeleton = true }) => {
    const groups = groupEvents(events);
    const groupsJsx = groups.map((group, i) => {
        return (
            <CalendarGroup useSkeleton={useSkeleton} group={group} key={i} startHour={startHour} groupLeft={0} groupWidth={baseWidth} baseWidth={baseWidth} />
        )
    })
    return (
        <div>
            {groupsJsx}
        </div>
    )
}
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";

export const StyledInputWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;
export const StyledButton = styled(Button)`
    margin-left: 16px;
`;
export const StyledFilledCard = styled(FilledCard)`
    margin-top: 24px;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
import { Select } from "@/components/v3/Fields/Select/Select.component";
import { Box, InputLabel, Typography, styled } from "@mui/material";

export const StyledBanner = styled(Box)`
    padding: 12px 24px;
    width: 100%;
    height: 56px;
    background: #202020;
    z-index: 200;
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
`;
export const StyledNote = styled(Typography)`
    padding-top: 7px;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;

export const StyledLineButtons = styled(Box)`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
`;
export const StyledButton = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 200px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0px 8px 0px 8px;
    font-weight: 500;
    height: 32px;
    align-items: center;
    color: #FFFFFF;
    background-color: #202020;
    & img {
        width: 16px;
        height: 16px;
    }
    & span {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
        text-align: center;
        flex-grow: 1;
    }
`;
export const StyledFeatureBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
export const StyledSubscriptionPlan = styled(Select)`
    margin-bottom: 16px;
`;
export const StyledInputLabel = styled(InputLabel)`
    margin-bottom: 8px;
`;
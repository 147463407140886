import { FC, useMemo } from "react";
import { SpaceTagProps } from "./SpaceTag.type";
import { StyledEmoji, StyledEmojiWrapper, StyledSpaceTag, StyledSpaceTagContainer, StyledSpaceTagText, StyledWhiteTagIcon } from "./SpaceTag.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";

export const SpaceTag: FC<SpaceTagProps> = ({ tag, onAdd }) => {
    const imgJsx = useMemo(() => {
        if (!!tag.emoji) {
          return <StyledEmojiWrapper>
            <StyledEmoji>{tag.emoji}</StyledEmoji>
          </StyledEmojiWrapper>
        }
        return <>
            <StyledWhiteTagIcon />
        </>
      }, [tag.emoji]);
    return <StyledSpaceTag>
            <StyledSpaceTagContainer>
                {imgJsx}
                <StyledSpaceTagText >{tag.name}</StyledSpaceTagText>
            </StyledSpaceTagContainer>
            <Button center onClick={() => onAdd(tag)}>Add here</Button>
        </StyledSpaceTag>
}
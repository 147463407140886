import { Box } from "@mui/material";
import { useHistory } from 'react-router-dom';
import {HttpClient} from "../../axios/HttpClient";
import {envUrl} from "../../service/helpers";
import { useMe } from 'providers/MeProvider/Me.provider';
import {
  StyledButton,
  StyledChromeExtensionPicture,
  StyledContentRelationships,
  StyledLinkButton,
  StyledListBlock,
  StyledOnboardingButtons,
  StyledOnboardingContent,
  StyledOnboardingStep
} from "./ChromeExtension.styled";


export const ChromeExtensionPage = () => {
  const history = useHistory();
  const { loadUser, user } = useMe();
  const isIndividual = localStorage.getItem('plan') === 'INDIVIDUAL';
  const isPro = user?.paymentPlan.name === 'PRO';
  const onboardingStepNumber = isPro ? '2 / 3' : isIndividual ? '3 / 3' : '3 / 4'

  const handleInstallation = () => {
    if (isIndividual) {
      HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
        onboarded: true,
      }).then(() => {
       loadUser();
        console.log('onboarding passed');
      });
      window.open(
          'https://chrome.google.com/webstore/detail/sense/obemfgmikdfkejnfioiodicdhjnnojla',
          '_blank'
      );
      localStorage.setItem('selectedSpace', 'All Spaces' );
      history.push(`/for-you`);
      localStorage.removeItem("onboardingStep");
      localStorage.removeItem("plan");
    } else {
      localStorage.setItem("onboardingStep", "invite")
      history.push('/invite');
      window.open(
          'https://chrome.google.com/webstore/detail/sense/obemfgmikdfkejnfioiodicdhjnnojla',
          '_blank'
      );
    }
  };

  const handleSkipInstallation = async () => {
    if (isIndividual) {
      await HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
        onboarded: true,
      }).then(() => {
        loadUser();
        console.log('onboarding passed');
      });
      localStorage.setItem('selectedSpace', 'All Spaces' );
      history.push(`/for-you`);
      localStorage.removeItem("onboardingStep");
      localStorage.removeItem("plan");
    } else {
      history.push('/invite');
      localStorage.setItem("onboardingStep", "invite");
    }
  }

  return (
    <>
      <StyledOnboardingContent>
        <StyledOnboardingStep>
          <Box mt={2}>
            <Box style={{ fontSize: '14px' }}>{onboardingStepNumber}</Box>
            <span style={{ color: '#2F2C2C', fontSize: '24px', fontWeight: 700, lineHeight: '1.43' }}>Install Chrome Extension</span>
          </Box>
          <StyledContentRelationships>
            <StyledListBlock>
              <StyledChromeExtensionPicture>
                <video src="/chrome-extension-video.mp4" autoPlay loop muted width="720px" height="560px" />
              </StyledChromeExtensionPicture>
            </StyledListBlock>
          </StyledContentRelationships>
          <StyledOnboardingButtons>
            <StyledLinkButton
              onClick={handleSkipInstallation}
            >
              <span>Skip for now</span>
            </StyledLinkButton>
            <StyledButton
              onClick={handleInstallation}
            >
              <span>Install now</span>
            </StyledButton>
          </StyledOnboardingButtons>
        </StyledOnboardingStep>
      </StyledOnboardingContent>
    </>
  );
};

import { createContext, useContext, useEffect, useState, ReactNode, useCallback } from "react";
import { SpaceCategoryPageContextType, SpaceNodeTypeWithRelationships } from "./SpaceCategoryPage.type";
import { useMe } from "providers/MeProvider/Me.provider";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Get/Get.type";
import { ToastService } from "service/ToastService";
import { fetchNodeRelations } from "utils/FetchNodeRelations/FetchNodeRelations.util";
import { addRelationshipsToNodes } from "utils/AddRelationshipsToNodes/AddRelationshipsToNodes.util";
import { useModal } from "store/ModalContext";
import { useRouter } from "@/providers/Router/Router.provider";
import { GetAllResourcesFetch } from "@/axios/SenseFacade/Public/Public.api";
import { AllResourcesAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetAllResources/GetAllResources.type";
import { addUsersToRelations } from "@/utils/AddUsersToRelations/AddUsersToRelations.util";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { useSpacePageCommon } from "../SpacePageCommon/SpacePageCommon.provider";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";

const defaultValue: SpaceCategoryPageContextType = {
    listOfNodes: [],
    update: () => undefined,
    isLoading: false,
    updateNodeNameById: () => undefined,
    listOfSpaceCategoryUsers: [],
};

const SpaceCategoryPageContext = createContext<SpaceCategoryPageContextType>(defaultValue);
export const useSpaceCategoryPage = () => useContext<SpaceCategoryPageContextType>(SpaceCategoryPageContext);

export const SpaceCategoryPageProvider = (props: { children: ReactNode }) => {
    const router = useRouter();
    const { isSharedSpace } = useIsShared();
    const spaceId = router.params.spaceId as string | undefined;
    const code = router.params.code as string | undefined;
    const category = router.params.category as string;
    const {
        update: updateSpacePageCommon,
        listOfSpaceUsers
    } = useSpacePageCommon();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { users } = useMe();
    const [listOfNodes, setListOfNodes] = useState<SpaceNodeTypeWithRelationships[]>([]);
    const { closeRenameNodeModal } = useModal()
    const loadNodes = useCallback(async () => {
        if (isSharedSpace) {
            if (!code) { return }
            setIsLoading(true)
            await GetAllResourcesFetch({
                code
            }).then(async (res: AllResourcesAxiosResponseSuccessType) => {
                const listOfRelationships = await addUsersToRelations(listOfSpaceUsers, res.data.resourceDetails.filter(el => !!el.relationships).map(el => el.relationships!));
                const wrappers = res.data.resourceDetails.map((node) => {
                    return {
                        node: node.node,
                        relationships: listOfRelationships.find(info => info.nodeId === node.node.id)!,
                        importantUserIds: []
                    }
                })
                setListOfNodes(wrappers.filter(wrapper => wrapper.node.categories.includes(category)));
            });
            setIsLoading(false)
        } else {
            if (!spaceId) { return }
            setIsLoading(true)
            await GetNodesFetch({
                spaceId,
                categories: [category]
            }).then(async (res: NodesAxiosResponseSuccessType) => {
                const listOfRelationships = await fetchNodeRelations(listOfSpaceUsers, res.data.map((node) => node.id));
                const nodeIds = res.data.map((el) => el.id)
                const importantUsers = await fetchImportantUsers(nodeIds);
                const wrappers = res.data.map((node) => {
                    const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                    return {
                        node,
                        relationships: listOfRelationships.find(info => info.nodeId === node.id),
                        importantUserIds: importantInfo.userIds
                    }
                });
                setListOfNodes(wrappers);
            }).catch(() => {
                ToastService.showToast('error', 'Some error occurred!');
            });
            setIsLoading(false)
        }
    }, [category, code, isSharedSpace, spaceId, listOfSpaceUsers])
    
    const updateHandler = useCallback(async () => {
        updateSpacePageCommon();
        loadNodes();
    }, [updateSpacePageCommon, loadNodes]);
    useEffect(() => {
        loadNodes();
    }, [loadNodes]);

    const updateNodeNameById = () => {
        loadNodes();
        closeRenameNodeModal()
    }
    const trackEvent = useAmplitude();
    useEffect(() => {
        if (router.name === 'publicSpaceCategory') {
            trackEvent(EventTypes.SHARED_SPACE_CATEGORY_VISITED)
        }
    }, [router.name, trackEvent]);
    useEffect(() => {
        const handleUpdate = () => {
            updateHandler();
        };
        window.addEventListener('updateDataByNodeDrawer', handleUpdate);
        return () => {
            window.removeEventListener('updateDataByNodeDrawer', handleUpdate);
        };
      }, [updateHandler]);
    const contextValue: SpaceCategoryPageContextType = {
        listOfNodes,
        update: updateHandler,
        isLoading,
        updateNodeNameById,
        listOfSpaceCategoryUsers: users
    };

    return <SpaceCategoryPageContext.Provider value={contextValue}>
        {props.children}
    </SpaceCategoryPageContext.Provider>;
};

export default SpaceCategoryPageContext;
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { ProjectsGetQueryParams } from './Types/Get/Get.type';
import { ProjectsSynchronizeBodyParams } from './Types/Synchronize/Synchronize.type';

export const GetProjectsFetch = (query: ProjectsGetQueryParams) => {
    return HttpClient.get(`${envUrl}/asana-service/v1/projects`, {
        params: query,
    });
}
export const SynchronizeProjectsFetch = (body: ProjectsSynchronizeBodyParams) => {
    return HttpClient.post(`${envUrl}/asana-service/v1/grants`, body);
}
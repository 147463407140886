import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
`;
export const StyledDescription = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 8px;
`;
import { FC, useEffect, useMemo, useState } from "react";
import { StyledNode, StyledWrapper } from "./ListOfNodes.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { NodeType, RelationshipsType } from "@/components/v3/List/Node/Node.type";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Get/Get.type";
import { fetchNodeRelations } from "@/utils/FetchNodeRelations/FetchNodeRelations.util";
import { getAppOptionsFromNodes } from "@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { getPeopleOptionsFromNodes } from "@/utils/v3/GetPeopleOptionsFromNodes/GetPeopleOptionsFromNodes.util";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { StyledFiltersContainer, StyledFiltersWrapper, StyledSelect } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyState } from "./components/EmptyState/EmptyState.component";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";

export const ListOfNodes: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const { followedNodes, user, users } = useMe();
    const [listOfImportantWithRelationships, setListOfImportantWithRelationships] = useState<{
        node: NodeType;
        relationships?: RelationshipsType;
        importantUserIds: string[];
    }[]>([]);
    const loadImportantNodeNodes = async () => {
        if (!user) {
            return;
        }
        await GetNodesFetch({
            userId: user.id,
            relationType: 'FOLLOWED_BY'
        }).then(async (res: NodesAxiosResponseSuccessType) => {
            setIsLoading(true);
            const listOfRelationships = await fetchNodeRelations(users, res.data.map((node) => node.id));
            const nodeIds = res.data.map((el) => el.id)
            const importantUsers = await fetchImportantUsers(nodeIds);
            setListOfImportantWithRelationships(res.data.map((node) => {
                const relationships = listOfRelationships.find((relationship) => relationship.nodeId === node.id)!;
                const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                return {
                    node,
                    relationships: {
                        ...relationships!,
                        actors: relationships!.actors.filter((actor) => {
                            return actor.actionType !== 'FOLLOWED_BY';
                        })
                    },
                    importantUserIds: importantInfo.userIds
                }
            }));
            setIsLoading(false);
        });
    }
    const appsOptions = useMemo((): OptionType<string>[] => {
        return getAppOptionsFromNodes(listOfImportantWithRelationships.map((el) => el.node));
    }, [listOfImportantWithRelationships]);
    const peopleOptions = useMemo((): OptionType<string>[] => {
        return getPeopleOptionsFromNodes(listOfImportantWithRelationships.map((el) => {
            return {
                ...el.node,
                relationships: el.relationships
            }
        }));
    }, [listOfImportantWithRelationships]);
    const filteredListOfImportantWithRelationships = useMemo(() => {
        return listOfImportantWithRelationships.filter((el) => {
            if (selectedApps.length && !selectedApps.includes(el.node.application)) {
                return false;
            }
            if (selectedPeople.length) {
                const actorIds = el.relationships?.actors?.map(actor => actor.userId) || [];
                if (!actorIds.some(id => selectedPeople.includes(id))) {
                    return false;
                }
            }
            return true;
        });
    }, [listOfImportantWithRelationships, selectedApps, selectedPeople]);
    const listOfNodesJsx = useMemo(() => {
        return filteredListOfImportantWithRelationships.map((wrapper, i) => {
            return <StyledNode
                node={wrapper.node}
                relationships={wrapper.relationships}
                key={i}
                onUpdate={loadImportantNodeNodes}
                users={users}
                importantUserIds={wrapper.importantUserIds}
            />
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredListOfImportantWithRelationships, users]);
    useEffect(() => {
        loadImportantNodeNodes();
    }, [followedNodes]);
    return <StyledWrapper>
            <LoaderLayout isLoading={isLoading} transparent={!!listOfNodesJsx.length}>
                <EmptyStateLayoyt
                    value={followedNodes.length === 0}
                    emptyNode={<EmptyState />}
                >
                     <StyledFiltersWrapper>
                        <StyledFiltersContainer>
                            <StyledSelect
                                label="Apps"
                                value={selectedApps}
                                onChange={(val: any) => setSelectedApps(val)}
                                options={appsOptions}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                                icon={<FilterIcon />}
                            />
                            <StyledSelect
                                label="People"
                                value={selectedPeople}
                                onChange={(val: any) => setSelectedPeople(val)}
                                options={peopleOptions}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                                icon={<FilterIcon />}
                            />
                        </StyledFiltersContainer>
                    </StyledFiltersWrapper>
                    <FilledCard>
                        {listOfNodesJsx}
                    </FilledCard>
                </EmptyStateLayoyt>
            </LoaderLayout>
            <CreateNoteDrawer onUpdate={loadImportantNodeNodes} />
        </StyledWrapper>
};
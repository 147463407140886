import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { FC } from "react";
import { AllProps } from "./All.type";
import { StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { StyledExternalSectionHeader, StyledPersonActivities, StyledSectionHeader } from "./All.styled";
import { ExternalEmptyState } from "../ExternalEmptyState/ExternalEmptyState.component";

export const All: FC<AllProps> = ({
    isFetching,
    externalUsers,
    myWorkspaceUsers
}) => {
    const myWorkspaceUsersJsx = myWorkspaceUsers.map((el) => {
        return <StyledPersonActivities person={el.person} nodes={[]} type="workspace" key={el.userId} />
    });
    const externalUsersJsx = externalUsers.map((el) => {
        return <StyledPersonActivities person={el.person} nodes={el.activities} type="external" key={el.userId} />
    });
    const isEmpty = !externalUsers.length && !myWorkspaceUsers.length && !isFetching;
    const isEmptyExternal = !externalUsers.length && !isFetching;
    return <LoaderLayout isLoading={isFetching} transparent={!!myWorkspaceUsers.length || !!externalUsers.length}>
        <EmptyStateLayoyt
            value={isEmpty}
            emptyNode={<></>}
        >
            <StyledWrapper>
                {!!myWorkspaceUsersJsx.length && <>
                    <StyledSectionHeader title="My workspace" />
                    <FilledCard>
                        {myWorkspaceUsersJsx}
                    </FilledCard>
                </>}
                <StyledExternalSectionHeader title="External" />
                <EmptyStateLayoyt
                    value={isEmptyExternal}
                    emptyNode={<ExternalEmptyState />}
                >
                    <FilledCard>
                        {externalUsersJsx}
                    </FilledCard>
                </EmptyStateLayoyt>
            </StyledWrapper>
        </EmptyStateLayoyt>
    </LoaderLayout>
};
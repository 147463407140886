import { styled } from "@mui/material";
import { SizeType } from "./UserAvatar.type";

const getImageSize = (size: SizeType) => {
    switch (size) {
        case 'small':
            return '16px';
        case 'medium':
            return '24px';
        default:
            return '32px';
    }
};

export const StyledMaskedImg = styled('div', {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'rounded'
})<{size: SizeType; rounded: boolean}>`
    display: block;
    ${({rounded}) => rounded && 'border-radius: 50%;'}
    width: ${({size}) => getImageSize(size)};
    height: ${({size}) => getImageSize(size)};
    min-width: ${({size}) => getImageSize(size)};
    min-height: ${({size}) => getImageSize(size)};
    background: rgba(0, 0, 0, 0.06);
`;
export const StyledImg = styled('img', {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'rounded'
})<{size: SizeType; rounded: boolean}>`
    display: block;
    ${({rounded}) => rounded && 'border-radius: 50%;'}
    width: ${({size}) => getImageSize(size)};
    height: ${({size}) => getImageSize(size)};
    object-fit: contain;
`;
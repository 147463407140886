import { styled } from "@mui/system";
import {
    StyledNodeContainer,
    StyledNodeContent,
    StyledNodeContentName,
    StyledNodeIcon,
    StyledNodeWrapper,
    StyledTextContent,
    StyledPeople
} from "../../../Node/Node.styled";

export const StyledStyledNodeContent = styled(StyledNodeContent)`
    align-items: center;
`;
export const StyledStyledNodeContainer = styled(StyledNodeContainer)`
    padding: 8px;
`;
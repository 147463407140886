import { FC } from "react";
import { SpaceCategoryContent } from "./SpaceCategory.content";
import { SpaceCategoryPageProvider } from "@/providers/v3/SpaceCategoryPage/SpaceCategoryPage.provider";
import { SpacePageCommonProvider } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { NodeNeighboursDrawer } from "@/providers/v3/NodeProvider/components/NodeNeighboursDrawer/NodeNeighboursDrawer.component";

export const SpaceCategoryPage: FC = () => {
    return <SpacePageCommonProvider>
        <SpaceCategoryPageProvider>
            <SpaceCategoryContent />
            <NodeNeighboursDrawer/>
        </SpaceCategoryPageProvider>
    </SpacePageCommonProvider>
};
import { FC, useRef, useState } from "react";
import { SpaceTagInputProps } from "./SpaceTagInput.type";
import { StyledAddSpaceTagContainer, StyledAddSpaceTagText, StyledAddTagIcon, StyledAddTagIconContainer, StyledDefaultEmojiIcon, StyledEmojiSelect, StyledSpaceTagInput } from "./SpaceTagInput.styled";
import { CreateSpaceTagsFetch } from "@/axios/AiService/SpaceTags/SpaceTags.api";
import useOnClickOutside from "@/hooks/UseOnClickOutside/UseOnClickOutside.hook";
import { useRouter } from "@/providers/Router/Router.provider";
import { CreateSpaceTagsAxiosResponseSuccessType } from "@/axios/AiService/SpaceTags/Types/Post/Post.type";

export const SpaceTagInput: FC<SpaceTagInputProps> = ({ onUpdate, spaceId }) => {
    let contentRef = useRef<HTMLDivElement>(null);
    let inputRef = useRef<HTMLInputElement>(null); 
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [emoji, setEmoji] = useState<string | null>(null);
    const router = useRouter();
    const createTagHandler = async () => {
        await CreateSpaceTagsFetch(spaceId, {
            name: name,
            emoji: emoji
        }).then((res: CreateSpaceTagsAxiosResponseSuccessType) => {
            onUpdate();
            setName('');
            setEmoji(null);
            setIsCreateMode(false);
            router.push({
                name: 'spaceTag',
                params: {
                    tagId: res.data.id,
                    spaceId: spaceId 
                }
            })
        });
    }
    const handleClickOutside = () => {
        setIsCreateMode(false)
    }
    useOnClickOutside(contentRef, handleClickOutside, ['.MuiPaper-root']);
    return <>
        {!isCreateMode && <StyledAddSpaceTagContainer onClick={() => setIsCreateMode(true)}>
            <StyledAddTagIconContainer>
                <StyledAddTagIcon />
            </StyledAddTagIconContainer>
            <StyledAddSpaceTagText>Create tag</StyledAddSpaceTagText>
        </StyledAddSpaceTagContainer>}
        {isCreateMode && <StyledAddSpaceTagContainer ref={contentRef} onClick={() => {
            setIsCreateMode(true);
            inputRef.current?.focus();
        }}>
            <StyledEmojiSelect onChange={(e) => setEmoji(e.native)}>
                {!!emoji ? emoji : <StyledDefaultEmojiIcon />}
            </StyledEmojiSelect>
            <StyledSpaceTagInput
                ref={inputRef}
                autoFocus
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Type tag name"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        createTagHandler();
                    }
                }}
            />
        </StyledAddSpaceTagContainer>}
    </>
}
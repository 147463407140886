import { FC, useMemo } from "react";
import { PublicSpaceProps } from "./PublicSpace.type";
import { SpaceCategory } from './components/SpaceCategory/SpaceCategory.component';
import { useRouter } from "@/providers/Router/Router.provider";
import {
  Box,
  Collapse,
  List,
} from '@mui/material';
import { SpaceTag } from "./components/SpaceTag/SpaceTag.component";
import { getIconPath } from "../Spaces/components/Space/Space.utils";
import { StyledArrow, StyledEmoji, StyledEmojiWrapper, StyledSpaceImage, StyledSpaceWrapper } from "../Spaces/components/Space/Space.styled";

export const PublicSpace: FC<PublicSpaceProps> = ({ space, categories, tags }) => {
  const router = useRouter();
  const isActive = true;
  const isNativeSpace = space.application==='Sense';
  const isPrivate = space.private;
  const listOfSpaceCategoriesJsx = categories.map((category, i) => {
    return <SpaceCategory category={category} key={i} />
  })
  const listOfSpaceTagsJsx = tags.map((tag, i) => {
    return <SpaceTag tag={tag} key={i} />
  })
  const imgSrc = useMemo(() => {
    if (isPrivate) {
      return isActive ? '/icons/private-space-active-icon.svg' : '/icons/private-space-icon.svg';
    }
    return getIconPath(space.application, isActive);
  }, [isPrivate, isActive, space.application]);
  const imgJsx = useMemo(() => {
    if (!!space.emoji) {
      return <StyledEmojiWrapper>
        <StyledEmoji>{space.emoji}</StyledEmoji>
      </StyledEmojiWrapper>
    }
    return <StyledSpaceImage src={imgSrc} />
  }, [imgSrc, space.emoji]);
  return <>
    <StyledSpaceWrapper
      onClick={() => {
        router.push({
          name: 'publicSpace',
          params: {
            code: router.params.code as string,
          }
        })
      }}
      active={router.name==='publicSpace' && isActive}
      id="walkthrough-spaces"
    >
      <StyledArrow isActive={isActive} />
      {imgJsx}
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {space.name}
      </Box>
    </StyledSpaceWrapper>
    <Collapse in={isActive} timeout="auto" unmountOnExit>
      <List component="nav" disablePadding>
        {!isNativeSpace && listOfSpaceCategoriesJsx}
        {isNativeSpace && listOfSpaceTagsJsx}
      </List>
    </Collapse>
  </>
}
import { styled } from "@mui/material";
import { TagIcon as TagDarkIcon } from "@/icons/tag-dark.icon";
import { TagIcon as TagWhiteIcon } from "@/icons/tag-white.icon";

export const StyledSpaceTagContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'isActive'
})<{isActive: boolean}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 8px 56px;
    cursor: pointer;
    &:hover {
        background: rgba(0,0,0,.08);
    }
    background-color: ${props => props.isActive ? 'rgba(25, 118, 210, 0.08);' : 'tarnsparant;'};
`
export const StyledSpaceTagText = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 12px;
`
export const StyledDarkTagIcon = styled(TagDarkIcon)`
    width: 24px;
    height: 24px;
`
export const StyledWhiteTagIcon = styled(TagWhiteIcon)`
    width: 24px;
    height: 24px;
`
import { FC, useEffect, useRef, useState } from "react";
import { StyledMentionArea, StyledWrapper } from "./AddComment.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { CreateCommentFetch } from "@/axios/AiService/Comments/Comments.api";
import { ToastService } from "@/service/ToastService";
import { getFullName } from "@/utils/v3/GetFullName/GetFullName.util";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { AddCommentProps } from "./AddComment.type";

export const AddComment: FC<AddCommentProps> = ({
    setHeight
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { users } = useMe();
    const { node, update } = useNode();
    const [comment, setComment] = useState<string>('');
    useEffect(() => {
        setComment('');
    }, [node]);
    useEffect(() => {
        const height = ref.current?.clientHeight;
        setHeight(height || 0);
    }, [comment, setHeight]);
    const addHandler = async () => {
        if (!node) return;
        await CreateCommentFetch({nodeId: node.id}, {
            content: comment
        }).then(async (res) => {
            ToastService.showToast('success', 'Comment was successfully added');
            await update();
            setComment('');
        });
    };
    return (
        <StyledWrapper ref={ref}>
            <StyledMentionArea
                singleLine
                value={comment}
                onChange={setComment}
                mentions={users.map(el => ({ label: getFullName(el.firstName, el.lastName, el.email), value: el.id, avatar: el.avatar }))}
                bottom="73px"
            />
            <Button size="large" variant="tertiary" onClick={addHandler}>
                Post
            </Button>
        </StyledWrapper>
    );
};
import { Box, styled } from "@mui/material";

export const StyledNavigationItem = styled(Box, {
    shouldForwardProp: prop => prop !== 'active'
  })<{active: boolean}>`
    height: 48px;
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    & img {
        width: 24px;
        margin-right: 8px;
        &.avatar {
            border-radius: 50%;
        }
    }
    &:hover {
        background: rgba(0,0,0,.08);
    }
    ${({active}) => active && `
        background: rgba(0,0,0,.08);
        padding: 0 16px;
    `}
`;
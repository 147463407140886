import { Button } from "@/components/v3/Fields/Button/Button.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";

export const StyledWrapper = styled('div')`
    padding: 0 32px 0 32px;
    margin-bottom: 32px;
    @media (max-width: 768px) {
        padding: 0 16px 0 16px;
    }
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
export const StyledSectionHeaderActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.mobileRotate}px) {
        width: 100%;
        justify-content: flex-start;
        margin-top: 8px;
    }
`;
export const StyledShowAllButton = styled(Button)`
    margin-top: 16px;
`;
export const StyledFilledCard = styled(FilledCard)`
    border: 2px dashed #9266CC;
`;
export const StyledDeclineAllButton = styled(Button)`
    margin-left: 8px;
`;
import { FC, useCallback, useMemo } from "react";
import { RelationCheckboxButtonProps } from "./RelationCheckboxButton.type";
import { StyledCheckboxButton } from "./RelationCheckboxButton.styled";

export const RelationCheckboxButton: FC<RelationCheckboxButtonProps> = ({ children, value, onChange, val }) => {
    const isActive = useMemo(() => val.every(item => value.includes(item)), [value, val]);

    const onChangeHandler = useCallback((isChecked: boolean) => {
        let newValue = [...value];
        if (isChecked) {
            val.forEach(item => {
                if (!newValue.includes(item)) {
                    newValue.push(item);
                }
            });
        } else {
            newValue = newValue.filter(item => !val.includes(item));
        }
        onChange(newValue);
    }, [value, val, onChange]);


    return <StyledCheckboxButton value={isActive} onChange={onChangeHandler}>{children}</StyledCheckboxButton>
}
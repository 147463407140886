import { SenseTextFieldProps } from './SenseTextField.type';
import { StyledTextField } from './SenseTextField.styled';

export const SenseTextField = ({ id, type, label, value, onChange, error, ...rest }: SenseTextFieldProps) => {
  return (
    <StyledTextField
      id={id}
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      variant="outlined"
      error={error}
      {...rest}
    />
  );
};

import React, { useState, useEffect, useCallback, useRef } from "react";
import { searchBy$, SearchResult } from 'components/Nodes/SearchService';
import { useDebounce } from 'hooks/UseDebounce/UseDebounce.hook';
import { useOnClickOutside } from 'hooks/UseOnClickOutside/UseOnClickOutside.hook';
import { StyledDropdownContainer, StyledDropdown, StyledCloseIcon, StyledEmptyWrapper } from "./Search.styled";
import { SearchResultView } from './components/SearchResultView/SearchResultView.component';
import { SearchProps } from './Search.type';
import { SearchTextField } from "./components/SearchTextField/SearchTextField.coponent";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { SearchGetFetch } from "@/axios/AiService/Serach/Serach.api";
import { SearchGetAxiosResponseSuccessType } from "@/axios/AiService/Serach/Types/Get/Get.type";
import { NodeType } from "@/components/v3/List/Node/Node.type";
import { PersonType } from "@/components/v3/List/Person/Person.type";

export const Search: React.FC<SearchProps> = ({
    disabled
}) => {
    let searchFieldRef = useRef<HTMLInputElement>(null);
    let contentRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [queryToSearchBy, setQueryToSearchBy] = useState<string>('');
    const [listOfNodes, setListOfNodes] = useState<NodeType[]>([]);
    const [listOfPeople, setListOfPeople] = useState<PersonType[]>([]);
    
    const loadData = async () => {
        setIsLoading(true)
        await SearchGetFetch({
            query: queryToSearchBy,
        }).then((res: SearchGetAxiosResponseSuccessType) => {
            setListOfNodes(res.data.nodes);
            setListOfPeople(res.data.users);
        });
        setIsLoading(false)
    }
    const clear = () => {
        setQueryToSearchBy('');
        setIsVisible(false);
        setListOfNodes([]);
        setListOfPeople([]);
    };
    const handler = useDebounce(() => {
        setIsVisible(true);
        loadData();
    })
    useEffect(() => {
        if (queryToSearchBy) {
            handler();
        } else {
            clear();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryToSearchBy]);
    
    const handleChange = useCallback ((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setIsLoading(true)
        const searchTerm = event.target.value;
        setQueryToSearchBy(searchTerm);
    }, []);
    const onClearHandler = () => {
        setQueryToSearchBy('');
        clear()
    }
    const handleClickOutside = (): void => {
        setTimeout(() => {
            setIsVisible(false)
        }, 200);
      };
    useOnClickOutside(contentRef, handleClickOutside, [searchFieldRef, '.MuiPaper-root']);
    const haveResults = (listOfNodes.length>0 || listOfPeople.length>0);
    useEffect(() => {
        if (isVisible && queryToSearchBy) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [isVisible, queryToSearchBy]);
    return (
        <StyledDropdownContainer>
            {!!queryToSearchBy && <StyledCloseIcon onClick={onClearHandler} />}
            <SearchTextField
                disabled={disabled}
                inputRef={searchFieldRef}
                value={queryToSearchBy}
                onChange={handleChange}
                onFocus={() => setIsVisible(true)}
            />
            {isVisible && !!queryToSearchBy && (
                <StyledDropdown ref={contentRef}>
                    <LoaderLayout isLoading={isLoading} transparent={haveResults}>
                        {haveResults ? <SearchResultView
                            listOfNodes={listOfNodes}
                            listOfPeople={listOfPeople}
                            clear={clear}
                            onUpdate={loadData}
                        /> : <StyledEmptyWrapper>Nothing found</StyledEmptyWrapper>}
                    </LoaderLayout>
                </StyledDropdown>
            )}
        </StyledDropdownContainer>
    )
}
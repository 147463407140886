import { FC, useEffect, useMemo, useState } from 'react';
import { useIsModalOpened } from '@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook';
import { Dialog } from '../../Other/Dialog/Dialog.component';
import { DialogLayout } from '../../Layouts/DialogLayout/DialogLayout.component';
import { StyledFilledCard, StyledIndividualTip, StyledInput, StyledInputWrapper, StyledNoUsers, StyledSectionHeader, StyledSendInviteButton } from '@/pages/v3/InviteTeammates/InviteTeammates.styled';
import { Button } from '../../Fields/Button/Button.component';
import { InvitedUsersListItem } from '@/pages/v3/InviteTeammates/components/InvitedUsersListItem/InvitedUsersListItem.component';
import { ErrorsType } from '@/pages/v3/InviteTeammates/InviteTeammates.type';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { useRouter } from '@/providers/Router/Router.provider';
import { UserType } from "@/axios/OrganizationService/Users/Types/GetInvites/GetInvites.type";
import { useAmplitude } from '@/service/TrackingService';
import { HttpClient } from '@/axios/HttpClient';
import { envUrl } from '@/service/helpers';
import { ToastService } from '@/service/ToastService';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';

export const ManageUsersDialog: FC = () => {
    const { value, onChange } = useIsModalOpened({ name: 'manage-users' });
    const onChangeHandler = (value: boolean) => {
        onChange(value, {
            invite: undefined,
        });
    };
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<ErrorsType>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    const router = useRouter();
    const { user, invitedUsers, confirmedInvitedUsers, loadInvitedUsers } = useMe();
    const users = useMemo((): UserType[] => {
      const pendingUsers = invitedUsers.map((email: string) => ({ 'fullName': '', 'email': email, 'status': 'pending', isAdmin: false } as UserType));
      const invitedFullUsers = confirmedInvitedUsers.map((user: any) => ({ 'fullName': user.fullName, 'email': user.email, 'status': 'invited', 'avatar': user.avatar, 'id': user.id, isAdmin: user.isAdmin } as UserType));
      return [...invitedFullUsers, ...pendingUsers]
    }, [invitedUsers, confirmedInvitedUsers]);
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const isCreateSpace = router.query.isCreateSpace === 'true';
    const isEditSpace = router.query.isEditSpace === 'true';
  
    const validate = (newEmail: string) => {
      let tempErrors: ErrorsType = {};
      if (!newEmail) tempErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(newEmail)) tempErrors.email = "Invalid email format";
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
    };
    const submit = async (newEmail: string) => {
      if (validate(newEmail)) {
        if (isIndividual) {
          router.push({
            name: router.name,
            params: router.params,
            query: {
                ...router.query,
                dialog: !!router.query.dialog ? Array.isArray(router.query.dialog) ? [...router.query.dialog, 'payment'] : [router.query.dialog as string, 'payment'] : 'payment',
                invite: !!newEmail ? newEmail : undefined
            }
        })
          return
        }
        setIsLoading(true);
        await HttpClient.post(`${envUrl}/organization-service/v1/users/invites`, {
          organizationId: user?.organizationId,
          emails: [newEmail],
        })
          .then(response => response.data)
          .then(async data => {
            ToastService.showToast('success', 'Invites successfully sent!');
            setEmail('');
            if (!!router.query.invite) {
              router.push({
                name: router.name,
                params: router.params,
                query: {
                  ...router.query,
                  invite: undefined,
                  dialog: isCreateSpace ? 'create-space' : isEditSpace ? 'edit-space' : router.query.dialog,
                }
              })
            }
            await loadInvitedUsers()
            setIsLoading(false);
          })
          .catch(error => {
            ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
            setIsLoading(false);
          });
      }
    };
    useEffect(() => {
      if (router.query.invite && !!router.query.dialog) {
        submit(router.query.invite as string)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query.invite]);
    
    const headerTitle = useMemo(() => {
      if (users.length>1) {
        return `${users.length} teammates to be invited`
      }
      return `${users.length} teammate to be invited`
    }, [users.length]);
    const { confirm, component } = useConfirm({
      title: "Remove Users",
      content: <>
        Removing users will erase all their data on Sense. They will not be able to use their Sense account anymore.
        The billing will be updated in the end of your current billing cycle.
      </>,
      cancelButtonProps: {
        children: "Keep users",
        variant: 'primary',
      },
      submitButtonProps: {
        children: "Remove users",
        variant: 'error'
      },
    });
    const removeAllHandler = async () => {
      confirm(async (result) => {
        if (!!result) {
          setIsLoading(true);
          await HttpClient.delete(`${envUrl}/organization-service/v1/users/invites`, {
            data: {
              organizationId: user?.organizationId,
              emails: users.map((user) => user.email),
            }
          })
            .then(response => response.data)
            .then(data => {
              ToastService.showToast('success', 'Email successfully removed!');
            })
            .catch(error => {
              ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
            })
            await loadInvitedUsers();
            setIsLoading(false);
        }
      })
    }
    return <Dialog
        value={value}
        onChange={onChangeHandler}
        size="large"
    >
        <DialogLayout title="Manage Users" onCloseClick={() => onChangeHandler(false)}>
            <StyledInputWrapper>
              <StyledInput size="medium" variant="outlined" error={errors.email} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your teammate email" />
              <StyledSendInviteButton variant={!!users.length ? 'secondary' : 'primary'} center onClick={() => submit(email)} size="large">Add</StyledSendInviteButton>
            </StyledInputWrapper>
            <StyledFilledCard>
              {!!users.length && !isIndividual ? <>
                <StyledSectionHeader title={headerTitle} >
                  <Button onClick={removeAllHandler} variant="tertiary" size="medium" >Remove all</Button>
                </StyledSectionHeader>
                {users.map((user, i) => {
                  return <InvitedUsersListItem isLoading={isLoading} setIsLoading={setIsLoading} key={i} user={user} />
                })}
              </> : <StyledNoUsers>
                Add your teammates to let them access single place for your team work
              </StyledNoUsers>}
            </StyledFilledCard>
        </DialogLayout>
        {component}
    </Dialog>
}
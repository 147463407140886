import { FC } from "react";

export const SenseIcon: FC = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10161_10394)">
        <rect x="1" y="1" width="22" height="22" rx="11" fill="#636363"/>
        <ellipse cx="8.26906" cy="17.5127" rx="4.93703" ry="4.93703" fill="white" fillOpacity="0.32"/>
        <circle cx="16.1636" cy="6.85371" r="4.93703" fill="white" fillOpacity="0.32"/>
        <path d="M6.08203 16.8771C6.08203 17.5394 6.4771 18.2482 7.20914 18.9221C8.25492 19.9098 10.0792 20.4792 12.1359 20.4792C15.9239 20.4792 18.3292 18.5619 18.3292 15.5408C18.3292 13.2052 16.9581 11.7993 14.0532 11.1369L11.6711 10.5676C10.1954 10.2306 9.47499 9.53341 9.47499 8.54574C9.47499 7.31405 10.5324 6.52391 12.1824 6.52391C13.5535 6.52391 14.6225 7.11651 15.3313 8.17391C15.7032 8.63869 16.1215 8.84785 16.6095 8.84785C17.3648 8.84785 17.876 8.3482 17.876 7.61616C17.876 6.88412 17.4926 6.14045 16.7722 5.48975C15.7264 4.52531 14.0299 3.97919 12.1359 3.97919C8.60351 3.97919 6.30281 5.8732 6.30281 8.78975C6.30281 11.0905 7.66231 12.5429 10.3929 13.1704L12.7634 13.7514C14.4831 14.1581 15.2268 14.8204 15.2268 15.8894C15.2268 17.1792 14.1694 17.9228 12.3102 17.9228C10.9275 17.9228 9.74224 17.3883 8.84752 16.3542C8.28978 15.75 7.85985 15.5292 7.33696 15.5292C6.62816 15.5292 6.08203 16.0869 6.08203 16.8771Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_10161_10394">
        <rect x="1" y="1" width="22" height="22" rx="11" fill="white"/>
        </clipPath>
        </defs>
    </svg>
};
import { EmojiSelect } from "@/components/v3/Fields/EmojiSelect/EmojiSelect.component";
import { PageHeader } from "@/components/v3/Sections/PageHeader/PageHeader.component";
import { Add2Icon } from "@/icons/add2.icon";
import { EmojiIcon } from "@/icons/emoji.icon";
import { styled } from "@mui/material";
import { AddIcon } from "@/icons/v3/add.icon";
import { Button } from "@/components/v3/Fields/Button/Button.component";

export const StyledPageHeader = styled(PageHeader)`
    padding: 24px 32px 0px 32px;
    @media (max-width: 768px) {
        padding: 24px 16px 0px 16px;
    }
`;
export const StyledEmojiContainer = styled('div')`
    height: 40px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledEmojiSelect = styled(EmojiSelect, {
    shouldForwardProp: (prop) => prop !== 'isEmoji'
})<{isEmoji: boolean}>`
    position: relative;
    height: 40px;
    ${({isEmoji}) => isEmoji ? 'margin-right: 24px;' : 'margin-right: 16px;'}
    ${({isEmoji}) => isEmoji ? 'margin-left: -8px;' : 'margin-left: 0;'}
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledMainIcon = styled('img')`
    width: 40px;
    height: 40px;
    margin-right: 0 !important;
    
`
export const StyledEmojiWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{isActive: boolean;}>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: ${({isActive}) => isActive ? '12px' : '40px'};
    line-height: 1;
    position: absolute;
    transform: ${({ isActive }) => isActive ? 'translate(8px, -4px)' : 'translate(0, 0)'};
    top: 0px;
    right: 0px;
    background-color: white;
    border-radius: ${({isActive}) => isActive ? '40px' : '5px'};
    border: ${({isActive}) => isActive ? '1px solid rgba(0, 0, 0, 0.08)' : '0px solid rgba(0, 0, 0, 0.08)'};
    pointer-events: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding: ${({isActive}) => isActive ? '4px' : '0'};
    transition: all 0.2s ease;
`
export const StyledEmojiIcon = styled('div')`
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 1;
`
export const StyledAdd2Icon = styled(Add2Icon, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{isActive: boolean}>`
    width: ${({isActive}) => isActive ? '6px' : '0'};
    height: ${({isActive}) => isActive ? '6px' : '0'};
    opacity: ${({isActive}) => isActive ? '1' : '0'};
    margin-left: ${({isActive}) => isActive ? '2px' : '0'};
    transition: ${({isActive}) => isActive ? '0.01s' : '0.3s'} ease;
`
export const StyledDefaultEmojiIcon = styled(EmojiIcon, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{isActive: boolean}>`
    width: ${({isActive}) => isActive ? '12px' : '0'};
    height: ${({isActive}) => isActive ? '12px' : '0'};
    margin-left: ${({isActive}) => isActive ? '0' : '-4px'};
    transition: all 0.2s ease;
`
export const StyledButton = styled(Button)`
    margin-left: 8px;
`
export const StyledAddIcon = styled(AddIcon)`
    width: 18px;
    height: 18px;
    & path {
        fill: #fff;
    }
`
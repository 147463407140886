import { SvgIcon, SvgIconProps } from '@mui/material';

export const RelationshipsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Slice</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group" transform="translate(1.500000, 2.500000)" fill="#636363">
                <path d="M3,3.75 L0.5,3.75 C0.223858,3.75 0,3.97386 0,4.25 L0,6.75 C0,7.02614 0.223858,7.25 0.5,7.25 L3,7.25 C3.27614,7.25 3.5,7.02614 3.5,6.75 L3.5,4.25 C3.5,3.97386 3.27614,3.75 3,3.75 Z" id="Path" fillRule="nonzero"></path>
                <path d="M12,0 L9,0 C8.72386,0 8.5,0.223858 8.5,0.5 L8.5,3.5 C8.5,3.77614 8.72386,4 9,4 L12,4 C12.2761,4 12.5,3.77614 12.5,3.5 L12.5,0.5 C12.5,0.223858 12.2761,0 12,0 Z" id="Path" fillRule="nonzero"></path>
                <path d="M12,7 L9,7 C8.72386,7 8.5,7.22386 8.5,7.5 L8.5,10.5 C8.5,10.7761 8.72386,11 9,11 L12,11 C12.2761,11 12.5,10.7761 12.5,10.5 L12.5,7.5 C12.5,7.22386 12.2761,7 12,7 Z" id="Path" fillRule="nonzero"></path>
                <path d="M2.75,5.5 C2.75,5.08579 3.08579,4.75 3.5,4.75 L6,4.75 C6.41421,4.75 6.75,5.08579 6.75,5.5 C6.75,5.91421 6.41421,6.25 6,6.25 L3.5,6.25 C3.08579,6.25 2.75,5.91421 2.75,5.5 Z" id="Path"></path>
                <path d="M8.50001,1.24997848 C8.91422356,1.24997848 9.25001,1.58578352 9.25001,1.99999708 C9.25001,2.41421065 8.91422356,2.74999708 8.50001,2.74999708 L7.49694065,2.7499908 C7.39869701,2.74958874 7.30136629,2.76863254 7.21057427,2.80601779 C7.11977437,2.84340627 7.03727296,2.89840721 6.96784009,2.96784009 C6.89840721,3.03727296 6.84340627,3.11977437 6.80601779,3.21057427 C6.76863254,3.30136629 6.74958874,3.39869701 6.74999708,3.50001 L6.7499908,7.5030792 C6.74958873,7.60133043 6.76863162,7.69865649 6.80601779,7.78945573 C6.84340327,7.88024835 6.89840535,7.96275019 6.96784009,8.03218991 C7.03727296,8.10162279 7.11977437,8.15662373 7.21057427,8.19401221 C7.30136345,8.23139629 7.39868188,8.25043646 7.50001,8.25002323 L8.50001,8.25002323 C8.91422356,8.25002323 9.25001,8.58580967 9.25001,9.00002323 C9.25001,9.41423679 8.91422356,9.75003605 8.50001,9.75003605 L7.50311619,9.75003605 C7.20689047,9.75124365 6.91335099,9.69381319 6.63944573,9.58102779 C6.36552563,9.46823627 6.11664704,9.30231721 5.9071609,9.09283107 C5.69769972,8.88335487 5.53178869,8.63449213 5.4189967,8.36057088 C5.30621986,8.08667139 5.24879115,7.79315973 5.24997848,7.50001 L5.24997848,3.50307935 C5.2487911,3.20686303 5.30622267,2.91333677 5.41900221,2.63944573 C5.53179373,2.36552563 5.69771279,2.11664704 5.90717991,1.90717991 C6.11664704,1.69771279 6.36552563,1.53179373 6.63944573,1.41900221 C6.91333677,1.30622267 7.20686303,1.2487911 7.50001,1.24997848 L8.50001,1.24997848 Z" id="Path" fillRule="nonzero"></path>
            </g>
        </g>
    </SvgIcon>
  );
};

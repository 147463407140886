import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Node } from "@/components/v3/List/Node/Node.component";
import { DoneIcon } from "@/icons/v3/done.icon";
import { styled } from "@mui/material";

export const StyledNode = styled(Node)`
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledDoneIcon = styled(DoneIcon)`
    & path {
        stroke: #ffffff;
    }
`;
export const StyledButton = styled(Button)<{center: boolean}>`
    ${({ center }) => !center && `
        margin-left: 8px;
    `}
`;
export const StyledAfterWrapper = styled('div')`
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
`;
import { OnboardingLayout } from '@/components/v3/Layouts/OnboardingLayout/OnboardingLayout.component';
import { SectionHeader } from '@/components/v3/Sections/SectionHeader/SectionHeader.component';
import { BREAKPOINTS } from '@/hooks/v3/UseResponsive/BreackPoints';
import { styled } from "@mui/material";

export const StyledOnboardingLayout = styled(OnboardingLayout)`
    padding-bottom: 96px;
`;
export const StyledActions = styled('div')`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 24px 32px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledActionsContainer = styled('div')`
    width: 100%;
    max-width: 720px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
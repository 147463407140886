import { useState } from "react";
import { ManageModal } from "./components/ManageModal/ManageModal.component";
import { ManageButtonProps } from "./ManageButton.type";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledButton } from "./ManageButton.styled";

export const ManageButton = ({accounts, disconnectHandler, connectHandler, appName, icon, bottomButtons}: ManageButtonProps) => {
    const [isOpenedDialog, setIsOpenedDialog] = useState(false);
    return (
        <>
            <StyledButton bottomButtons={!!bottomButtons} center={bottomButtons} variant="secondary" onClick={() => {
                setIsOpenedDialog(true)
            }}>
                Manage
            </StyledButton>
            <ManageModal
                connectHandler={connectHandler}
                disconnectHandler={disconnectHandler}
                accounts={accounts}
                icon={icon}
                appName={appName}
                value={isOpenedDialog}
                onChange={setIsOpenedDialog}
            />
        </>
    )
}